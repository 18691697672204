import "./__styles__/Icon.scss";
import React from "react";

const ExportUp = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.79995 4.2L6.99995 1L4.19995 4.2H5.79995V9H8.19995V4.2H9.79995Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6 5.7998H12.2C12.6416 5.7998 13 6.1582 13 6.5998V12.1998C13 12.6414 12.6416 12.9998 12.2 12.9998H1.8C1.3584 12.9998 1 12.6414 1 12.1998V6.5998C1 6.1582 1.3584 5.7998 1.8 5.7998H3.4"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExportUp;
