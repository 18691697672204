// COPIED AND MODIFIED FROM:
// https://github.com/rjsf-team/react-jsonschema-form/blob/f8378c48d7ef51bc47c1c150578189d5a613f2c5/packages/core/src/components/templates/ObjectFieldTemplate.tsx
import React, { DetailedHTMLProps } from "react";
import {
  descriptionId,
  FormContextType,
  getTemplate,
  getUiOptions,
  ObjectFieldTemplatePropertyType,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  titleId,
} from "@rjsf/utils";
import { Group } from "./__styles__/ObjectFieldTemplate";

export const ObjectFieldTemplate = <
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(
  props: ObjectFieldTemplateProps<T, S, F>
) => {
  const {
    description,
    idSchema,
    properties,
    registry,
    required,
    schema,
    title,
    uiSchema,
  } = props;
  const options = getUiOptions<T, S, F>(uiSchema);

  const TitleFieldTemplate = getTemplate<"TitleFieldTemplate", T, S, F>(
    "TitleFieldTemplate",
    registry,
    options
  );

  const DescriptionFieldTemplate = getTemplate<
    "DescriptionFieldTemplate",
    T,
    S,
    F
  >("DescriptionFieldTemplate", registry, options);

  const columns = uiSchema?.["ui:columns"];
  let bodyStyle: DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >["style"];

  if (columns) {
    bodyStyle = {
      display: "grid",
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gap: "0rem 1rem",
    };
  }

  return (
    <Group id={idSchema.$id}>
      {title && (
        <TitleFieldTemplate
          id={titleId<T>(idSchema)}
          title={title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      {description && (
        <DescriptionFieldTemplate
          id={descriptionId<T>(idSchema)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <div style={{ ...bodyStyle }}>
        {properties.map(
          (prop: ObjectFieldTemplatePropertyType) => prop.content
        )}
      </div>
    </Group>
  );
};
