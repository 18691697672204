import React, { useContext } from "react";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";
import { useExportAccountBoundaryMutation } from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import ExportButton from "../../../Common/ExportButton";
import { OperationsMenuButton } from "./Button";
import MenuOption from "./MenuOption";

const AccountBoundaryMenu = () => {
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [exportAccountBoundary] = useExportAccountBoundaryMutation({
    onCompleted: () => {
      addSuccessToast(
        "Export successfully requested. You can expect an email link once the file is ready to download."
      );
    },
    onError: () => {
      addErrorToast("Failed to export account boundary");
    },
    variables: {
      accountId: account!.id,
    },
  });

  return (
    <MenuOption
      title="Account boundary"
      helperText="Upload a GeoJSON with the new account boundary to kick off the update process."
      buttons={
        <>
          <ExportButton
            onClick={exportAccountBoundary}
            styleVariant="outlineLight"
          />
          <OperationsMenuButton
            icon="plus"
            text="Update"
            onClick={() =>
              history.push({
                pathname: buildLink("dispatchUpdateAccountBoundary"),
              })
            }
          />
        </>
      }
    />
  );
};

export default AccountBoundaryMenu;
