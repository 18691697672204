import React from "react";
import { formatDate } from "common/utils/strings";
import {
  CancelationReason,
  CertificateUploadStatus,
} from "../../generated/graphql";
import { BANNER_TYPES } from "../Common/__styles__/Banner";
import Banner from "../Common/Banner";
import { DataRow, Header, Section } from "./__styles__/Settings";
import HideFromPublicAction from "./HideFromPublicAction";
import MobileDocumentLink from "./MobileDocumentLink";
import { DocumentUploadContext } from "./Tabs";

export interface SettingsProps {
  prevLocation: { pathname: string; search?: string } | string;
  fileUrl: string;
}

export const CANCELATION_REASON_TEXT = {
  [CancelationReason.CANNOT_GEOCODE]:
    "Forerunner was unable to process this file due to problems with geocoding.",
  [CancelationReason.NOT_EC]:
    "Forerunner was unable to process this file because it is not an Elevation Certificate.",
  [CancelationReason.QUALITY]:
    "Forerunner was unable to process this file due to problems with quality.",
  default: "Forerunner was unable to process this Elevation Certificate.",
} as const;

export default ({ fileUrl }: SettingsProps) => {
  const { documentUpload } = React.useContext(DocumentUploadContext);
  return (
    <React.Fragment>
      <Section>
        <Header>General Info</Header>
        <DataRow>
          <div>File type</div>
          <div>{documentUpload.accountDocumentType.name}</div>
        </DataRow>
        {!!documentUpload.issuedAt && (
          <DataRow>
            <div>Issue date</div>
            <div>{formatDate(documentUpload.issuedAt)}</div>
          </DataRow>
        )}
      </Section>
      <HideFromPublicAction
        documentUploadId={documentUpload.id}
        hiddenFromPublic={documentUpload.hiddenFromPublic}
        hasSensitiveDocumentType={
          documentUpload.accountDocumentType.isSensitive
        }
        isViewable={
          !documentUpload.certificateUpload ||
          documentUpload.certificateUpload?.status ===
            CertificateUploadStatus.COMPLETED
        }
        propertyHiddenFromPublic={!!documentUpload.property?.hiddenFromPublic}
      />
      {documentUpload.certificateUpload?.status ===
        CertificateUploadStatus.CANCELED && (
        <Banner type={BANNER_TYPES.ATTENTION}>
          <span>
            {
              CANCELATION_REASON_TEXT[
                documentUpload.certificateUpload?.cancelationReason ?? "default"
              ]
            }
          </span>{" "}
          Please upload a new file or reach out to support@withforerunner.com
          for support.
        </Banner>
      )}

      <MobileDocumentLink fileUrl={fileUrl} documentUpload={documentUpload} />
    </React.Fragment>
  );
};
