import React from "react";
import {
  CreateWarningProps,
  getCreatePropertyWarnings,
} from "common-client/utils/createAndEditPropertyWarnings";
import PropertyWarnings from "./PropertyWarnings";

export default ({
  point,
  geocacheParcelId,
  error,
  overlap,
  selectedProperty,
  formAddress,
}: CreateWarningProps) => {
  const warnings = getCreatePropertyWarnings({
    point,
    geocacheParcelId,
    error,
    overlap,
    selectedProperty,
    formAddress,
  });

  return <PropertyWarnings header={"Property Warnings"} warnings={warnings} />;
};
