import "./__styles__/Icon.scss";
import React from "react";

const SpeechBubble = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0C1.794 0 0 1.57467 0 3.51133C0 5.448 1.794 7.022 4 7.022C4.23123 7.02208 4.46212 7.00448 4.69067 6.96933L6.50733 7.95933C6.55627 7.98585 6.61101 7.99982 6.66667 8C6.75507 8 6.83986 7.96488 6.90237 7.90237C6.96488 7.83986 7 7.75507 7 7.66667V5.80733C7.30972 5.50937 7.5575 5.15314 7.72911 4.75912C7.90073 4.3651 7.99279 3.94105 8 3.51133C8 1.57467 6.206 0 4 0Z"
        fill="white"
      />
    </svg>
  );
};

export default SpeechBubble;
