import React from "react";
import { useGetAccountRastersQuery } from "../../../../generated/graphql";
import { spacing } from "../../../../stitches.config";
import { AuthContext } from "../../../Authorization/AuthContext";
import { Box } from "../../../Common/__styles__/Layout";
import { Button } from "../../../Common/Button";
import Divider from "../../../Common/Divider";
import { EmptyState } from "../../../Common/EmptyState";
import { Header, HeaderTitle, Section } from "../../__styles__/Content";
import { useAddRasterModal } from "./AddRasterModal";
import Table from "./Table";

export const Rasters = () => {
  const { account } = React.useContext(AuthContext);
  const [showAddBaseMapModal] = useAddRasterModal();
  const { data, loading } = useGetAccountRastersQuery({
    variables: {
      id: account!.id,
    },
  });

  const rasterData = data?.account?.rasters || [];
  const hasBaseMaps = !loading && rasterData.length > 0;

  return (
    <Box>
      <Header>
        <HeaderTitle>Raster settings</HeaderTitle>
        <Button
          styleVariant="outlineLight"
          onClick={showAddBaseMapModal}
          size="small"
          leftIconName="plus"
        >
          Add Raster
        </Button>
      </Header>
      <Divider></Divider>
      {!loading &&
        (hasBaseMaps ? (
          <Section>
            <Table rasterData={rasterData} />
          </Section>
        ) : (
          <EmptyState
            icon="add-raster"
            title="No rasters for this account"
            body="Add raster to get started."
            paddingTop={spacing["2xl"].value}
            paddingBottom={spacing["2xl"].value}
          />
        ))}
    </Box>
  );
};

export default Rasters;
