import React from "react";
import { useModal } from "react-modal-hook";
import {
  ParcelImportConfiguration,
  useDeleteParcelImportConfigurationMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { track } from "../../../../utils/tracking";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";

export interface UseDeleteParcelImportConfigurationModalProps {
  parcelImportConfiguration: ParcelImportConfiguration;
  onUpdate: () => void;
}

export const useDeleteParcelImportConfigurationModal = ({
  parcelImportConfiguration,
  onUpdate,
}: UseDeleteParcelImportConfigurationModalProps) => {
  const [
    showDeleteParcelImportConfigurationModal,
    hideDeleteParcelImportConfigurationModal,
  ] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideDeleteParcelImportConfigurationModal();
        }}
      >
        <DeleteParcelImportConfiguration
          onCancel={hideDeleteParcelImportConfigurationModal}
          onUpdate={onUpdate}
          parcelImportConfiguration={parcelImportConfiguration}
        />
      </Modal>
    ),
    [parcelImportConfiguration]
  );

  return [
    showDeleteParcelImportConfigurationModal,
    hideDeleteParcelImportConfigurationModal,
  ] as const;
};

export const DeleteParcelImportConfiguration = ({
  onCancel,
  onUpdate,
  parcelImportConfiguration,
}: {
  onCancel: () => void;
  onUpdate: () => void;
  parcelImportConfiguration: ParcelImportConfiguration;
}) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [deleteParcelImportConfiguration, { loading }] =
    useDeleteParcelImportConfigurationMutation({
      onCompleted: () => {
        addSuccessToast(`The parcel import source was successfully deleted`);
      },
      onError: () => {
        addErrorToast(
          `There was an error deleting this parcel import source. Please try again. If the problem persists, please email support@withforerunner.com`
        );
      },
    });
  const handleDelete = async () => {
    if (loading) return;

    track("Deleted parcel import source", {});
    await deleteParcelImportConfiguration({
      variables: {
        parcelImportConfigurationId: parcelImportConfiguration.id,
      },
    });
    onUpdate();
    onCancel();
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Delete ArcGIS rest service source</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <p>
            Deleting the current ArcGIS rest service source will stop all future
            parcel data refreshes from this connection and your dashboard will
            display the most recent import. Are you sure you want to delete the
            connection?
          </p>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              size="medium"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button styleVariant="alert" onClick={handleDelete} size="medium">
              I understand, and want to delete it
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};
