import React from "react";
import { Route, Switch } from "react-router-dom";
import RequestForm from "./RequestForm";
import ResetForm from "./ResetForm";

export default () => (
  <Switch>
    <Route exact path="/password-reset" component={RequestForm} />
    <Route path="/password-reset/:requestId" component={ResetForm} />
  </Switch>
);
