import React from "react";
import { JsonEditor } from "json-edit-react";
import { useFormContext } from "react-hook-form";
import { NODE_ENV } from "common/env";
import { SubmissionsBuilderFormDataStructure } from "../types";

const FormJSONPreview = () => {
  const { getValues, reset } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  const formValues = getValues();

  const canEdit = NODE_ENV === "development";

  return (
    <div>
      <JsonEditor
        maxWidth={"100%"}
        restrictEdit={!canEdit}
        restrictAdd={!canEdit}
        restrictDelete={!canEdit}
        data={formValues}
        onUpdate={({ newData }) => {
          reset(newData);
        }}
      />
    </div>
  );
};

export default FormJSONPreview;
