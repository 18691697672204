import React, { useContext } from "react";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import {
  AdditionalFieldLabelInputWrapper,
  DisplayNameInputWrapper,
  RowWrapper,
  ShowToPublicWrapper,
  SideIconButton,
  SourceInputWrapper,
} from "../__styles__/ParcelImportWizard";
import { ParcelImportWizardContext } from "../context";
import {
  AdditionalFieldNameInput as FieldNameInput,
  ImportFieldDisplayNameInput as DisplayNameInput,
  ImportFieldIsPublicToggle as IsPublicToggle,
  ImportFieldSourceSelect as SourceSelect,
} from "../ImportFieldInputs";
import { AdditionalFieldConfig, PARCEL_IMPORT_CONFIG_WORKFLOW } from "../types";

const AdditionalFieldRow = ({
  fieldConfig,
  fieldIndex,
  removeField,
}: {
  fieldConfig: AdditionalFieldConfig;
  fieldIndex: number;
  removeField: (fieldIndex: number) => void;
}) => {
  const { hasPublicPortal, workflow } = useContext(ParcelImportWizardContext);

  const fieldPath = `additionalFields.${fieldIndex}` as const;

  return (
    <RowWrapper>
      <AdditionalFieldLabelInputWrapper>
        <FieldNameInput fieldIndex={fieldIndex} fieldPath={fieldPath} />
      </AdditionalFieldLabelInputWrapper>
      <SourceInputWrapper>
        <SourceSelect
          disabled={workflow !== PARCEL_IMPORT_CONFIG_WORKFLOW.CREATE}
          fieldConfig={fieldConfig}
          fieldName={`${fieldPath}.source`}
        />
        {workflow !== PARCEL_IMPORT_CONFIG_WORKFLOW.DISPLAY_SETTINGS && (
          <SideIconButton
            onClick={() => removeField(fieldIndex)}
            styleVariant={"ghost"}
            aria-label={"Remove field"}
          >
            <Icon iconName={"x"} color="contentSecondary" size={16} />
          </SideIconButton>
        )}
      </SourceInputWrapper>
      <DisplayNameInputWrapper>
        <DisplayNameInput fieldPath={fieldPath} />
      </DisplayNameInputWrapper>
      {hasPublicPortal && (
        <ShowToPublicWrapper>
          <IsPublicToggle fieldPath={fieldPath} />
        </ShowToPublicWrapper>
      )}
    </RowWrapper>
  );
};

export default AdditionalFieldRow;
