import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BASE_MAP_SOURCE_TYPE } from "common/constants";
import Icon, { Icons } from "../../../Common/Icons";
import Table from "../../../Common/Tables/Table";
import FileDisplay from "../../../Inputs/FileDisplay";
import { AccountBaseMapSettings } from "./__queries__/getAccountBaseMaps";
import { ActionButton } from "./ActionButton";

const BASE_MAP_SOURCE_TO_ICON: Record<BASE_MAP_SOURCE_TYPE, Icon> = {
  [BASE_MAP_SOURCE_TYPE.SLIPPY]: Icons.SLIPPY,
  [BASE_MAP_SOURCE_TYPE.MAPBOX]: Icons.SLIPPY,
  [BASE_MAP_SOURCE_TYPE.IMAGE_SERVER]: Icons.IMAGE_SERVER,
  [BASE_MAP_SOURCE_TYPE.MAP_SERVER]: Icons.MAP_SERVER,
} as const;

export default ({
  baseMapData,
}: {
  baseMapData: Array<AccountBaseMapSettings>;
}) => {
  const columns: Array<ColumnDef<AccountBaseMapSettings>> = useMemo(
    () => [
      {
        accessorKey: "task",
        id: "task",
        size: 100,
        cell: ({ row }) => {
          return (
            <FileDisplay
              fileIcon={BASE_MAP_SOURCE_TO_ICON[row.original.sourceType]}
              titleText={row.original.name}
              onClick={row.getToggleExpandedHandler()}
            />
          );
        },
      },
      {
        id: "actions",
        size: 150,
        maxSize: 150,
        cell: ({ row }) => <ActionButton baseMap={row.original} />,
        meta: { className: "actions-cell" },
      },
    ],
    []
  );

  return (
    <Table<AccountBaseMapSettings>
      css={{
        "& .actions-cell": {
          textAlign: "right",
        },
      }}
      currentData={baseMapData}
      columns={columns}
      loadingDetails={{
        loading: false,
        loadingText: "Loading base map data",
        noDataText: "No Basemaps found",
      }}
      minRows={baseMapData.length}
      tableStyleDetails={{
        hasFlushedSides: true,
      }}
      excludeTableHeader
      excludePaginationNav
    />
  );
};
