import "./__styles__/EditPermitInfo.scss";
import React from "react";
import cx from "classnames";
import { omit, pick, startCase } from "lodash";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { buildLink } from "common/routing";
import {
  compareDatesWithoutTime,
  DATE_COMPARISON_OPERATOR,
} from "common/utils/dates";
import {
  GetCertificateQuery,
  PermitType,
  UpdatePermitInput,
  useUpdatePermitMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Header, Section } from "../../Common/__styles__/FullPageFormLayout";
import FullPageFormLayout from "../../Common/FullPageFormLayout";
import HeaderNav from "../../Common/HeaderNav";
import { InformationalTooltip } from "../../Common/Tooltip";
import {
  Datepicker,
  Select,
  Text,
  Textarea,
} from "../../Inputs/react-hook-form";

const DataSourceTooltip = () => {
  return (
    <div styleName="data-source-tooltip">
      <InformationalTooltip tooltipText="This value is pulled directly from your Elevation Certificate. To edit, please update your Certificate." />
    </div>
  );
};

const populateFormData = (
  certificate: NonNullable<GetCertificateQuery["certificate"]>
) => {
  const { permit } = certificate;
  return {
    permitNumber: permit.permitNumber,
    permitDate: permit.permitDate,
    type: permit.type,
    final: permit.final,
    floodProofingCertificateRequired: permit.floodProofingCertificateRequired,
    vZoneDesignCertificateRequired: permit.vZoneDesignCertificateRequired,
    comments: permit.comments,
    ...omit(certificate, ["permit"]),
  };
};

export const PermitNotFound = ({
  prevLocation,
  loading,
}: {
  prevLocation: string;
  loading: boolean;
}) => {
  const message = loading ? "Loading" : "Could not find permit";
  return (
    <div styleName="container">
      <HeaderNav subtitle="Permit Info" prevLocation={prevLocation} />
      <div styleName="no-permit-message">{message}</div>
    </div>
  );
};

type PermitInfoFormStructure = {
  permitNumber: Maybe<string>;
  permitDate: Maybe<string>;
  issuedAt: Maybe<string>;
  buildingUse: Maybe<string>;
  type: Maybe<PermitType>;
  floodZone: Maybe<string>;
  final: Maybe<boolean>;
  finishedConstruction: boolean;
  floodProofingCertificateRequired: Maybe<boolean>;
  vZoneDesignCertificateRequired: Maybe<boolean>;
  comments: Maybe<string>;
};

export default ({
  certificate,
  prevLocation,
}: {
  certificate: NonNullable<GetCertificateQuery["certificate"]>;
  prevLocation: string;
}) => {
  const history = useHistory();
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const formData = populateFormData(certificate);
  const returnTo = {
    pathname: buildLink("documentUploadDetail", {
      id: certificate.documentUpload.id,
    }),
    state: { prevLocation },
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<PermitInfoFormStructure>({
    defaultValues: {
      permitNumber: formData.permitNumber,
      permitDate: formData.permitDate,
      issuedAt: formData.issuedAt,
      buildingUse: startCase(formData.buildingUse ?? ""),
      type: formData.type,
      floodZone: formData.floodZone,
      final: formData.final,
      finishedConstruction: formData.finishedConstruction,
      floodProofingCertificateRequired:
        formData.floodProofingCertificateRequired,
      vZoneDesignCertificateRequired: formData.vZoneDesignCertificateRequired,
      comments: formData.comments,
    },
  });
  const [updatePermit] = useUpdatePermitMutation({
    onCompleted: () => {
      addSuccessToast("Your permit was saved");
      history.push(returnTo);
    },
    onError: () => {
      addErrorToast(
        "There was an issue updating this permit. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  });

  const onSubmit = async (formData: PermitInfoFormStructure) => {
    const data: UpdatePermitInput = pick(formData, [
      "permitNumber",
      "type",
      "final",
      "floodProofingCertificateRequired",
      "vZoneDesignCertificateRequired",
      "comments",
    ]);
    // Graphql doens't like empty string on dates, so we need to set it to null
    data.permitDate = formData.permitDate || null;

    await updatePermit({ variables: { data, id: certificate.permit.id } });
  };

  const permitTypeOptions = Object.values(PermitType).map(permitType => {
    return { value: permitType, label: startCase(permitType) };
  });

  const yesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  return (
    <FullPageFormLayout
      subtitle="Permit Info"
      prevLocation={returnTo}
      onSave={handleSubmit(onSubmit)}
    >
      <form>
        <Header>
          <h1>Permit Info</h1>
          <h2>Provide a few details on the permit.</h2>
        </Header>
        <Section>
          <div styleName="input">
            <Text
              {...register("permitNumber")}
              label="Permit number"
              size="small"
              error={errors.permitNumber?.message}
            />
          </div>
          <div styleName="input">
            <Datepicker
              control={control as unknown as Control<FieldValues, any>}
              rules={{
                validate: val => {
                  if (
                    compareDatesWithoutTime(val, new Date(), {
                      operator: DATE_COMPARISON_OPERATOR.AFTER,
                    })
                  ) {
                    return "Permit date cannot be a future date";
                  }
                  return;
                },
              }}
              name="permitDate"
              label="Permit date"
              size="small"
              error={errors.permitDate?.message}
            />
          </div>
          <div styleName={cx("input", "with-tooltip")}>
            <Datepicker
              control={control as unknown as Control<FieldValues, any>}
              name="issuedAt"
              label="EC issue date"
              size="small"
              error={errors.issuedAt?.message}
              disabled={true}
            >
              <DataSourceTooltip />
            </Datepicker>
          </div>
          <div styleName={cx("input", "with-tooltip")}>
            <Text
              {...register("buildingUse")}
              label="Building type"
              size="small"
              error={errors.buildingUse?.message}
              disabled={true}
            >
              <DataSourceTooltip />
            </Text>
          </div>
          <div styleName="input">
            <Select
              control={control}
              name="type"
              label="New building or SI/SD?"
              options={permitTypeOptions}
              error={errors.type?.message}
              size="small"
            />
          </div>
          <div styleName={cx("input", "with-tooltip")}>
            <Text
              {...register("floodZone")}
              label="Flood zone"
              size="small"
              error={errors.floodZone?.message}
              disabled={true}
            >
              <DataSourceTooltip />
            </Text>
          </div>
          <div styleName="input">
            <Select
              control={control}
              name="final"
              label="Final permit"
              options={yesNoOptions}
              error={errors.final?.message}
              size="small"
            />
          </div>
          <div styleName={cx("input", "with-tooltip")}>
            <Select
              control={control}
              name="finishedConstruction"
              label="Finished construction EC?"
              options={yesNoOptions}
              error={errors.finishedConstruction?.message}
              size="small"
              disabled={true}
            >
              <DataSourceTooltip />
            </Select>
          </div>
          <div styleName="input">
            <Select
              control={control}
              name="floodProofingCertificateRequired"
              label="Floodproofing Certificate required?"
              options={yesNoOptions}
              error={errors.floodProofingCertificateRequired?.message}
              size="small"
            />
          </div>
          <div styleName="input">
            <Select
              control={control}
              name="vZoneDesignCertificateRequired"
              label="V-Zone Design Certificate required?"
              options={yesNoOptions}
              error={errors.vZoneDesignCertificateRequired?.message}
              size="small"
            />
          </div>
          <div styleName="input">
            <Textarea
              {...register("comments")}
              label="Comments"
              size="small"
              error={errors.comments?.message}
            />
          </div>
        </Section>
      </form>
    </FullPageFormLayout>
  );
};
