import React, { Dispatch } from "react";
import {
  DisplayAccountPickerState,
  IdentifyUserAction,
} from "common-client/utils/useIdentifyUserFlow";
import { Section, Sections } from "../../Common/Layout";
import { Title } from "../../Common/Typography";
import SelectionCardGroup from "../../Inputs/SelectionCardGroup";
import { AccountLogo, AccountTitle } from "../../SideNav/__styles__/SideNav";
import NavigationCard from "../CommonLoginComponents/NavigationCard";
import { ErrorMessage } from "../ErrorMessage";

export const AccountPicker = ({
  state,
  dispatch,
  onSubmit,
}: {
  state: DisplayAccountPickerState;
  dispatch: Dispatch<IdentifyUserAction>;
  onSubmit: ({
    email,
    account,
  }: {
    email: string;
    account: { subdomain: string };
  }) => void;
}) => {
  const { accounts, email, error } = state;

  const onSelectOption = ({ subdomain }: { subdomain: string }) => {
    onSubmit({ email, account: { subdomain } });
  };

  return (
    <Sections>
      {error && <ErrorMessage error={error} />}
      <Section>
        <Title type="semiBold" size="large">
          Sign in to your account
        </Title>
        <NavigationCard
          onClick={() => {
            dispatch({ type: "goBack" });
          }}
        >
          {email}
        </NavigationCard>
        <SelectionCardGroup
          name="accounts"
          onChange={subdomain => onSelectOption({ subdomain })}
          options={accounts.map(account => {
            const leftComponent = account.logoUrl ? (
              <AccountLogo src={account.logoUrl} alt={`${account.name} Logo`} />
            ) : (
              <AccountTitle>{account.name[0]}</AccountTitle>
            );
            return {
              id: account.id,
              title: account.name,
              value: account.subdomain,
              leftComponent,
              style: {
                alignItems: "center",
              },
              "data-testid": `account-card-${account.id}`,
              //this ensures that you can click a selected option when you use the back button in the browser
              onClick: () => onSelectOption({ subdomain: account.subdomain }),
            };
          })}
          borderStyle="ghost"
          spacingStyle="none"
          data-orientation="vertical"
          style={{
            maxHeight: "280px",
            overflowY: "auto",
          }}
        />
      </Section>
    </Sections>
  );
};
