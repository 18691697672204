import React, { useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import MultiLogicFilter from "../../../../Common/MultiLogicFilter";
import { Required } from "../../../../Inputs/__styles__/Label";
import { FilterContext } from "../FilterContext";
import { UpsertSisdRuleFormStructure } from "../upsertSisdRuleModal";
import {
  LogicFiltersDescription,
  LogicFiltersTitle,
  LogicFiltersWrapper,
} from "./__styles__/LogicFilters";
import LogicFilterBuilder from "./LogicFilterBuilder";
import useFilterBuilderState from "./useFilterBuilderState";
import { convertRuleFilterToFilterSegmentProps } from "./utils";

const LogicFilters = () => {
  const { filterTargetMetadata } = useContext(FilterContext);
  const { control } = useFormContext<UpsertSisdRuleFormStructure>();
  const filterBuilderState = useFilterBuilderState(control);
  const { completedFilters, removeFilter } = filterBuilderState;

  const multiLogicFilterData = useMemo(() => {
    return convertRuleFilterToFilterSegmentProps(
      completedFilters,
      filterTargetMetadata
    );
  }, [completedFilters, filterTargetMetadata]);

  return (
    <LogicFiltersWrapper>
      <LogicFiltersTitle>
        Logic filter(s) <Required>*</Required>
      </LogicFiltersTitle>
      <LogicFiltersDescription>
        This determines which properties the rule will be applied to.
      </LogicFiltersDescription>
      <MultiLogicFilter
        filters={multiLogicFilterData}
        onFilterRemove={removeFilter}
        includeAndPrefix
      />
      <LogicFilterBuilder {...filterBuilderState} />
    </LogicFiltersWrapper>
  );
};

export default LogicFilters;
