import React, { useCallback, useMemo } from "react";
import { debounce } from "lodash";
import { SavedView } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import SavedViewsClusterLayer, {
  viewsClusterLayerId,
  viewsClusterSourceId,
} from "../layers/savedViewCluster";
import {
  findRelevantFeature,
  LayerConfig,
  LayerHook,
  LayerHookBuilder,
  LayerId,
} from "./types";

export type SavedViewsClusterLayerConfig = LayerConfig<
  Array<Pick<SavedView, "id">>,
  [number, number]
>;

const savedViewsClusterHook: LayerHookBuilder<SavedViewsClusterLayerConfig> = ({
  config,
}) => {
  const { addErrorToast } = useStatusToasts();
  const addErrorToastCallback = useCallback(() => {
    addErrorToast(
      `Unable to load table view data. Please try again. If the issue continues, contact support@withforerunner.com`
    );
  }, []);
  const interactiveLayerIds: Array<LayerId> = useMemo(
    () =>
      config.value?.length
        ? [viewsClusterLayerId, `${viewsClusterLayerId}-label`]
        : [],
    [config.value?.length]
  );

  return useMemo<LayerHook>(() => {
    return {
      interactiveLayerIds,
      // Need to be lower than properties
      zIndex: -1,
      canHandleHover: () => false,
      canHandleClick: () => !!config.interactive?.click,
      onClick: ({ event }) => {
        const feature = findRelevantFeature(event, interactiveLayerIds);

        if (feature) {
          config.onClick?.(event.lngLat as [number, number]);
          return true;
        } else {
          return false;
        }
      },
      render: () => {
        if (!config.value?.length) {
          return null;
        }
        return (
          <SavedViewsClusterLayer
            savedViewIds={config.value.map(view => view.id)}
          />
        );
      },

      shouldHandleLoadError: error => error.sourceId === viewsClusterSourceId,
      onLoadError: () => {
        debounce(() => {
          addErrorToastCallback();
        }, 1000)();
      },
    };
  }, [config]);
};

export default savedViewsClusterHook;
