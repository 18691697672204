import "./__styles__/Icon.scss";
import React from "react";

export default () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="23" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="23" stroke="#E39600" />
      <path
        d="M10 10C12.4853 10 14.5 9.32843 14.5 8.5C14.5 7.67157 12.4853 7 10 7C7.51472 7 5.5 7.67157 5.5 8.5C5.5 9.32843 7.51472 10 10 10Z"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50003 8.5V15.5C5.49725 15.7405 5.66793 15.9776 5.9977 16.1915C6.32747 16.4054 6.80665 16.5897 7.39485 16.7289C7.98306 16.8681 8.66304 16.9581 9.37749 16.9914C10.0919 17.0246 10.8199 17.0002 11.5 16.92"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 8.5V10"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 12C5.50072 12.2338 5.66534 12.4642 5.98073 12.673C6.29611 12.8818 6.75353 13.0632 7.31648 13.2026C7.87944 13.342 8.53234 13.4356 9.22311 13.4759C9.91387 13.5163 10.6234 13.5023 11.295 13.435"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_2543_8025)">
        <path
          d="M14.5005 14.2497C14.6079 14.3933 14.7448 14.512 14.9021 14.598C15.0594 14.6839 15.2334 14.735 15.4122 14.7478C15.5909 14.7606 15.7704 14.7348 15.9383 14.6722C16.1063 14.6095 16.2588 14.5115 16.3855 14.3847L17.1355 13.6347C17.3632 13.399 17.4892 13.0832 17.4863 12.7555C17.4835 12.4277 17.352 12.1142 17.1203 11.8825C16.8885 11.6507 16.575 11.5193 16.2472 11.5164C15.9195 11.5136 15.6037 11.6395 15.368 11.8672L14.938 12.2947"
          stroke="#E39600"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5001 13.7503C15.3927 13.6068 15.2557 13.488 15.0984 13.4021C14.9411 13.3161 14.7672 13.265 14.5884 13.2522C14.4096 13.2394 14.2302 13.2652 14.0622 13.3279C13.8943 13.3905 13.7418 13.4885 13.6151 13.6153L12.8651 14.3653C12.6374 14.6011 12.5114 14.9168 12.5142 15.2446C12.5171 15.5723 12.6485 15.8858 12.8803 16.1176C13.112 16.3493 13.4256 16.4808 13.7533 16.4836C14.081 16.4865 14.3968 16.3605 14.6326 16.1328L15.0601 15.7053"
          stroke="#E39600"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2543_8025">
          <rect
            width="6"
            height="6"
            fill="white"
            transform="translate(12 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
