import React from "react";
import {
  DateInput,
  Fieldset,
  InputRow,
  Note,
  SectionTitle,
  TextInput,
} from "./Common";

export default function FullDataExtractionGroup1({ get, set, errors }) {
  return (
    <>
      <SectionTitle>Property Information</SectionTitle>

      <TextInput
        name="buildingOwnerName"
        value={get("buildingOwnerName")}
        onChange={set("buildingOwnerName")}
        error={errors.buildingOwnerName}
        label="Building Owner's Name"
      />

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        error={errors.address}
        label="Address"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          error={errors.address}
          label="City"
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          error={errors.address}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          error={errors.address}
          label="Zipcode"
        />
      </InputRow>

      <Note>If Zipcode is not present, please leave blank.</Note>

      <TextInput
        name="propertyDescription"
        value={get("propertyDescription")}
        onChange={set("propertyDescription")}
        error={errors.propertyDescription}
        label="Property Location"
      />

      <SectionTitle>SECTION I - ELIGIBILITY CERTIFICATION</SectionTitle>
      <TextInput
        name="nfipCommunityNumber"
        value={get("nfipCommunityNumber")}
        onChange={set("nfipCommunityNumber")}
        error={errors.nfipCommunityNumber}
        label="Community Number"
      />

      <TextInput
        name="firmMapNumber"
        value={get("firmMapNumber")}
        onChange={set("firmMapNumber")}
        error={errors.firmMapNumber}
        label="Panel Number"
      />
      <Note>
        Note: If there are two separate numbers, please transcribe the entire
        field as-is.
      </Note>

      <TextInput
        name="firmSuffix"
        value={get("firmSuffix")}
        onChange={set("firmSuffix")}
        error={errors.firmSuffix}
        label="Suffix"
      />

      <DateInput
        name="firmIndexDate"
        value={get("firmIndexDate")}
        onChange={set("firmIndexDate")}
        error={errors.firmIndexDate}
        label="Date of FIRM"
      />

      <TextInput
        name="floodZone"
        value={get("floodZone")}
        onChange={set("floodZone")}
        error={errors.floodZone}
        label="Firm Zone"
      />
      <Note>
        Note: If multiple Flood Zones are present, please enter them separated
        by commas.
      </Note>

      <TextInput
        name="baseFloodElevation"
        value={get("baseFloodElevation")}
        onChange={set("baseFloodElevation")}
        error={errors.baseFloodElevation}
        label="Base Flood Elev"
      />
      <Note>
        Note: if there is a unit of measurement (example: feet) please do not
        include it. However, do include any N/A values. If multiple elevations
        are present, please enter them separated by commas.
      </Note>

      <DateInput
        label="Date"
        name="issuedAt"
        value={get("issuedAt")}
        onChange={set("issuedAt")}
        error={errors.issuedAt}
      />

      <SectionTitle>SECTION II - ELEVATION CERTIFICATE</SectionTitle>

      <Fieldset legend="FIRM ZONE A1-A30:">
        <TextInput
          name="topOfBottomFloor"
          value={get("topOfBottomFloor")}
          onChange={set("topOfBottomFloor")}
          error={errors.topOfBottomFloor}
          label="...the lowest floor is at an elevation of"
        />
        <Note>
          If there is a unit of measurement (example: feet) please do not
          include it. However, do include any N/A values.
        </Note>
      </Fieldset>
      <Fieldset legend="FIRM ZONE V, V1-V30:">
        <TextInput
          name="bottomOfLowestHorizontalStructuralMember"
          value={get("bottomOfLowestHorizontalStructuralMember")}
          onChange={set("bottomOfLowestHorizontalStructuralMember")}
          error={errors.bottomOfLowestHorizontalStructuralMember}
          label="...the bottom of the lowest floor beam is at an elevation of"
        />
        <Note>
          If there is a unit of measurement (example: feet) please do not
          include it. However, do include any N/A values.
        </Note>
      </Fieldset>
      <Fieldset legend="FIRM ZONE A, A99, AO, AH:">
        <TextInput
          name="topOfBottomFloorAlt"
          value={get("topOfBottomFloorAlt")}
          onChange={set("topOfBottomFloorAlt")}
          error={errors.topOfBottomFloorAlt}
          label="...has the lowest floor elevation of"
        />
        <Note>
          If there is a unit of measurement (example: feet) please do not
          include it. However, do include any N/A values.
        </Note>
        <TextInput
          name="highestAdjacentGrade"
          value={get("highestAdjacentGrade")}
          onChange={set("highestAdjacentGrade")}
          error={errors.highestAdjacentGrade}
          label="...the highest adjacent grade next to the building is"
        />
        <Note>
          If there is a unit of measurement (example: feet) please do not
          include it. However, do include any N/A values.
        </Note>
      </Fieldset>

      <SectionTitle>SECTION III - FLOODPROOFING CERTIFICATION</SectionTitle>

      <TextInput
        name="certifierName"
        value={get("certifierName")}
        onChange={set("certifierName")}
        error={errors.certifierName}
        label="Certifier's Name"
      />

      <TextInput
        name="certifierLicenseNumber"
        value={get("certifierLicenseNumber")}
        onChange={set("certifierLicenseNumber")}
        error={errors.certifierLicenseNumber}
        label="License Number"
      />
    </>
  );
}
