import React from "react";
import Examples from "../../../../images/transcription/expiration-year-examples.png";
import { Alert, Form, Note, RadioInput, SectionTitle } from "./Common";

const VersionExtraction = ({ year, handleFormYearChange, errors }) => (
  <Form>
    <Alert>
      👋 Hello Worker! Please transcribe the Expiration Year on the PDF. The
      entire date should be located in the top right corner of the document.
      Please only transcribe the year.
    </Alert>

    <Note>Examples:</Note>
    <img src={Examples} style={{ width: "100%" }} alt="Examples" />

    <SectionTitle>Elevation Certificate — Expiration year</SectionTitle>

    <RadioInput
      name="expirationYear"
      value={year}
      onChange={handleFormYearChange}
      error={errors.expirationYear}
      options={[
        { value: "1982", text: "1982" },
        { value: "1984", text: "1984" },
        { value: "1987", text: "1987" },
        { value: "1990", text: "1990" },
        { value: "1993", text: "1993" },
        { value: "1996", text: "1996" },
        { value: "1999", text: "1999" },
        { value: "2002", text: "2002" },
        { value: "2005", text: "2005" },
        { value: "2009", text: "2009" },
        { value: "2012", text: "2012" },
        { value: "2015", text: "2015" },
        { value: "2018", text: "2018" },
        { value: "2020", text: "2020" },
        { value: "2022", text: "2022" },
        { value: "2026", text: "2026" },
      ]}
    />
  </Form>
);

export default VersionExtraction;
