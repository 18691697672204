import "./__styles__/Icon.scss";
import React from "react";

const RepetitiveLoss = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 10.333H7.00004C6.63185 10.333 6.33337 10.6315 6.33337 10.9997V16.9997C6.33337 17.3679 6.63185 17.6663 7.00004 17.6663H13C13.3682 17.6663 13.6667 17.3679 13.6667 16.9997V10.9997C13.6667 10.6315 13.3682 10.333 13 10.333Z"
      stroke="#E39600"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33337 14H13.6667"
      stroke="#E39600"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.666H11"
      stroke="#E39600"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15.666H11"
      stroke="#E39600"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13 1V7H19" stroke="#E39600" strokeMiterlimit="10" />
    <path
      d="M19 20.25V7L13 1H1V10V20.25"
      stroke="#E39600"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1 20.5V23.5H19V20.5"
      stroke="#E39600"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default RepetitiveLoss;
