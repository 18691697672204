import React, { useState } from "react";
import { useModal } from "react-modal-hook";
import { formatCollection, pluralize } from "common/utils/strings";
import {
  DfeRule,
  PropertyInformationSection,
  useDeletePropertyInformationSectionMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { Button } from "../../../../Common/Button";
import Modal from "../../../../Common/Modal";

export type DeleteOverviewSectionModalProps = {
  section: Pick<PropertyInformationSection, "id" | "label"> & {
    accountPropertyAttributes: Array<{ id: string }>;
  };
  ruleDefinition: {
    rules: Array<Pick<DfeRule, "referenceAttribute" | "name">>;
  };
  onCancel: () => void;
  onUpdate: () => void;
};

export const useDeleteOverviewSectionModal = ({
  onUpdate,
  ruleDefinition,
}: Pick<DeleteOverviewSectionModalProps, "onUpdate" | "ruleDefinition">) => {
  const [props, setProps] =
    useState<Pick<DeleteOverviewSectionModalProps, "section">>();

  const [show, hideDeleteOverviewSectionModal] = useModal(() => {
    if (!props) {
      return null;
    }

    return (
      <Modal onRequestClose={hideDeleteOverviewSectionModal}>
        <DeleteOverviewSectionModal
          section={props.section}
          ruleDefinition={ruleDefinition}
          onCancel={hideDeleteOverviewSectionModal}
          onUpdate={() => {
            hideDeleteOverviewSectionModal();
            onUpdate();
          }}
        />
      </Modal>
    );
  }, [props]);

  const showDeleteOverviewSectionModal = (
    props: Pick<DeleteOverviewSectionModalProps, "section">
  ) => {
    setProps(props);
    show();
  };
  return [
    showDeleteOverviewSectionModal,
    hideDeleteOverviewSectionModal,
  ] as const;
};

export const DeleteOverviewSectionModal = ({
  section,
  ruleDefinition,
  onCancel,
  onUpdate,
}: DeleteOverviewSectionModalProps) => {
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const [deletePropertyInformationSection, { loading }] =
    useDeletePropertyInformationSectionMutation({
      onCompleted: () => {
        addSuccessToast("Section deleted successfully");
        onUpdate();
      },
      onError: () => {
        addErrorToast("Failed to delete section");
      },
    });

  const deleteSection = () => {
    void deletePropertyInformationSection({ variables: { id: section.id } });
  };

  const associatedRules = ruleDefinition.rules
    .filter(rule =>
      section.accountPropertyAttributes.some(
        attribute => rule.referenceAttribute === attribute.id
      )
    )
    .map(rule => `"${rule.name}"`);

  const canDelete = associatedRules.length === 0;

  let contentText;
  if (canDelete) {
    contentText =
      "Deleting a section cannot be undone. This will permanently remove the section and all associated attributes. However, attribute sources will remain accessible.";
  } else {
    const rulesCollection = formatCollection(associatedRules);
    const ruleText = pluralize({
      count: associatedRules.length,
      options: { singular: "rule", plural: "rules" },
    });
    const ruleTextWithArticle = pluralize({
      count: associatedRules.length,
      options: { singular: "this rule", plural: "these rules" },
    });
    contentText = `This section cannot be deleted because one or more attributes are linked to the ${rulesCollection} Design Flood Elevation logic ${ruleText}. Edit or delete ${ruleTextWithArticle} from the settings page first in order to proceed.`;
  }

  return (
    <Container>
      <HeaderSection>
        <h1>Delete "{section.label}" section</h1>
      </HeaderSection>
      <ContentSection>{contentText}</ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          {canDelete && (
            <Button
              size="medium"
              styleVariant="alert"
              onClick={deleteSection}
              loading={loading}
            >
              I understand, delete section
            </Button>
          )}
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
