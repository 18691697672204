import React, { MouseEvent } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import {
  AccountPropertyWarningDefinition,
  Raster,
} from "../../generated/graphql";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useIsMobileWidth } from "../Guest/utils";
import { AccountDocumentTypes } from "./__queries__/map";
import {
  CloseButton,
  Container,
  Header,
  Title,
} from "./__styles__/LayersPanel";
import LayerControl from "./LayerControl";
import { BaseMap, CustomMap, FIRM, SavedView } from "./layers";

interface LayersPanelProps {
  firms: Array<FIRM>;
  rasters: Array<Raster>;
  customMaps: Array<CustomMap>;
  close: (event: MouseEvent | KeyboardEvent) => void;
  baseMaps: Array<BaseMap>;
  accountDocumentTypes: AccountDocumentTypes;
  accountPropertyWarningDefinitions: Array<AccountPropertyWarningDefinition>;
  savedViews?: Array<SavedView>;
  disableRasterToggle?: boolean;
  disableFirmToggle?: boolean;
  testId?: string;
}

const LayersPanel = ({
  firms,
  rasters,
  customMaps,
  close,
  baseMaps,
  accountDocumentTypes,
  accountPropertyWarningDefinitions,
  savedViews,
  disableRasterToggle,
  disableFirmToggle,
  testId,
}: LayersPanelProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const height = useWindowSize().height;

  const hotkeysRef = useHotkeys<HTMLDivElement>("esc", e => {
    close(e);
  });

  return (
    <Container
      style={isMobile ? { maxHeight: `${height - 84}px` } : {}}
      ref={hotkeysRef}
      data-testid={testId}
    >
      <Header>
        <Title>{t("map-layers-title")}</Title>
        <CloseButton
          onClick={close}
          aria-label="Close Map Layers panel"
          data-testid={`${testId}-close-button`}
          autoFocus
        />
      </Header>
      <LayerControl
        firms={firms}
        rasters={rasters}
        customMaps={customMaps}
        baseMaps={baseMaps}
        accountDocumentTypes={accountDocumentTypes}
        accountPropertyWarningDefinitions={accountPropertyWarningDefinitions}
        savedViews={savedViews}
        disableRasterToggle={disableRasterToggle}
        disableFirmToggle={disableFirmToggle}
      />
    </Container>
  );
};

export default LayersPanel;
