import { get, isNull } from "lodash";
import {
  ABOVE_OR_BELOW,
  ADJACENT_GRADE_TYPE,
  BOOLEAN_OPTIONS,
  BUILDING_ELEVATION_SOURCE,
  BUILDING_LOCATED_IN_CBRS_OR_OPA,
  PERMIT_ISSUE_TYPE,
  UNIT_OF_MEASUREMENT,
} from "common/constants";
import { CertificateType } from "../../../__queries__";
import { DocumentFormFieldDescription } from "..";
import { CertificateFormFieldDottableKeys } from "../CertificatesInputs";
import { ExtractedFieldProps } from "./ExtractedField";

const buildExtractedField = <T extends CertificateFormFieldDottableKeys>(
  data: DocumentFormFieldDescription<T>
) => {
  return data;
};

const certificateValue = (
  certificate: CertificateType,
  attribute: CertificateFormFieldDottableKeys
) => {
  if (isNull(get(certificate, attribute))) {
    return get(certificate.extractedData, attribute);
  }

  return get(certificate, attribute);
};

const DEFAULT_DATUM_OPTIONS = [
  { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
  { value: "navd_1988", text: "NAVD / NAVD 1988" },
  { value: "other", text: "Other" },
];

const LEGACY_DATUM_OPTIONS = [
  { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
  { value: "other", text: "Other" },
];
export const YES_NO_OPTIONS = [
  { value: "true", text: "Yes" },
  { value: "false", text: "No" },
];

export const YES_NO_UNKNOWN_OPTIONS = [
  { value: BOOLEAN_OPTIONS.YES, text: "Yes" },
  { value: BOOLEAN_OPTIONS.NO, text: "No" },
  { value: BOOLEAN_OPTIONS.UNKNOWN, text: "Unknown" },
];

const ADJACENT_GRADE_TYPE_OPTIONS = [
  { value: ADJACENT_GRADE_TYPE.NATURAL, text: "Natural" },
  { value: ADJACENT_GRADE_TYPE.FINISHED, text: "Finished" },
];

const CBRS_OR_OPA_OPTIONS = [
  { value: BUILDING_LOCATED_IN_CBRS_OR_OPA.CBRS, text: "CBRS" },
  { value: BUILDING_LOCATED_IN_CBRS_OR_OPA.OPA, text: "OPA" },
];

export const BUILDING_ELEVATION_SOURCE_OPTIONS = [
  {
    value: BUILDING_ELEVATION_SOURCE.CONSTRUCTION_DRAWINGS,
    text: "Construction drawings",
  },
  {
    value: BUILDING_ELEVATION_SOURCE.BUILDING_UNDER_CONSTRUCTION,
    text: "Building under construction",
  },
  {
    value: BUILDING_ELEVATION_SOURCE.FINISHED_CONSTRUCTION,
    text: "Finished construction",
  },
];

export const UNIT_OF_MEASUREMENT_OPTIONS = [
  { value: UNIT_OF_MEASUREMENT.FEET, text: "Feet" },
  { value: UNIT_OF_MEASUREMENT.METERS, text: "Meters" },
];

export const ABOVE_OR_BELOW_OPTIONS = [
  { value: ABOVE_OR_BELOW.ABOVE, text: "Above" },
  { value: ABOVE_OR_BELOW.BELOW, text: "Below" },
];

export const PERMIT_ISSUE_TYPE_OPTIONS = [
  { value: PERMIT_ISSUE_TYPE.NEW_CONSTRUCTION, text: "New construction" },
  {
    value: PERMIT_ISSUE_TYPE.SUBSTANTIAL_IMPROVEMENT,
    text: "Substantial improvement",
  },
];

export const YES_NO_NA_OPTIONS = [
  { value: "true", text: "Yes" },
  { value: "false", text: "No" },
  { value: "N/A", text: "N/A" },
];

type FieldDefaults = Pick<
  ExtractedFieldProps<CertificateFormFieldDottableKeys>,
  "label" | "type" | "format" | "name" | "hint" | "options" | "value"
>;

export const commonFields: {
  [key in CertificateFormFieldDottableKeys]: FieldDefaults;
} = {
  buildingOwnerName: {
    label: "Building Owner's Name",
    type: "shortText",
    name: "buildingOwnerName",
  },
  streetAddress: {
    label: "Address",
    type: "shortText",
    name: "streetAddress",
  },
  city: {
    label: "City",
    type: "shortText",
    name: "city",
  },
  state: {
    label: "State",
    type: "shortText",
    name: "state",
  },
  zipcode: {
    label: "Zipcode",
    type: "shortText",
    name: "zipcode",
  },
  propertyDescription: {
    label: "A3. Property Description",
    name: "propertyDescription",
    type: "shortText",
  },
  buildingUse: {
    label: "A4. Building Use",
    name: "buildingUse",
    type: "radioButtons",
    options: [
      { value: "residential", text: "Residential" },
      { value: "non_residential", text: "Non-Residential" },
      { value: "addition", text: "Addition" },
      { value: "accessory", text: "Accessory" },
      { value: "other", text: "Other" },
    ],
    format: "constant",
  },
  latitude: {
    label: "A5. Latitude",
    name: "latitude",
    type: "shortText",
  },
  longitude: {
    label: "A5. Longitude",
    name: "longitude",
    type: "shortText",
  },
  buildingDiagramNumber: {
    label: "A7. Building Diagram Number",
    name: "buildingDiagramNumber",
    type: "shortText",
  },
  "crawlspaceInfo.sqft": {
    label: "A8. a) Square footage of crawlspace or enclosure(s)",
    name: "crawlspaceInfo.sqft",
    type: "shortText",
    format: "sqFt",
  },
  "crawlspaceInfo.numFloodOpenings": {
    label:
      "A8. b) Number of permanent flood openings in the crawlspace or enclosure(s)",
    name: "crawlspaceInfo.numFloodOpenings",
    type: "shortText",
  },
  "crawlspaceInfo.netAreaOfFloodOpenings": {
    label: "A8. c) Total net area of flood openings in A8. b",
    name: "crawlspaceInfo.netAreaOfFloodOpenings",
    type: "shortText",
    format: "sqIn",
  },
  "crawlspaceInfo.engineeredFloodOpenings": {
    label: "A8. d) Engineered flood openings",
    name: "crawlspaceInfo.engineeredFloodOpenings",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "crawlspaceInfo.oneFloodOpeningTwoSides": {
    label:
      "A8. b) Is there at least one permanent flood opening on two different sides of each enclosed area?",
    name: "crawlspaceInfo.oneFloodOpeningTwoSides",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_NA_OPTIONS,
  },
  "crawlspaceInfo.numNonEngineeredOpenings": {
    label: "A8. c) Non-engineered flood openings",
    name: "crawlspaceInfo.numNonEngineeredOpenings",
    type: "shortText",
  },
  "crawlspaceInfo.numEngineeredOpenings": {
    label: "A8. c) Engineered flood openings",
    name: "crawlspaceInfo.numEngineeredOpenings",
    type: "shortText",
  },
  "crawlspaceInfo.netAreaNonEngineeredOpenings": {
    label:
      "A8. d) Total net open area of non-engineered flood openings in A8.c",
    name: "crawlspaceInfo.netAreaNonEngineeredOpenings",
    type: "shortText",
  },
  "crawlspaceInfo.ratedAreaOfEngineeredOpenings": {
    label: "A8. e) Total rated area of engineered flood openings in A8.c",
    name: "crawlspaceInfo.ratedAreaOfEngineeredOpenings",
    type: "shortText",
  },
  "attachedGarageInfo.sqft": {
    label: "A9. a) Square footage of attached garage",
    name: "attachedGarageInfo.sqft",
    type: "shortText",
    format: "sqFt",
  },
  "attachedGarageInfo.numFloodOpenings": {
    label: "A9. b) Number of permanent flood openings in the attached garage",
    name: "attachedGarageInfo.numFloodOpenings",
    type: "shortText",
  },
  "attachedGarageInfo.netAreaOfFloodOpenings": {
    label: "A9. c) Total net area of flood openings in A9. b",
    name: "attachedGarageInfo.netAreaOfFloodOpenings",
    type: "shortText",
    format: "sqIn",
  },
  "attachedGarageInfo.engineeredFloodOpenings": {
    label: "A9. d) Engineered flood openings",
    name: "attachedGarageInfo.engineeredFloodOpenings",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "attachedGarageInfo.oneFloodOpeningTwoSides": {
    label:
      "A9. b) Is there at least one permanent flood opening on two different sides of the attached garage?",
    name: "attachedGarageInfo.oneFloodOpeningTwoSides",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_NA_OPTIONS,
  },
  "attachedGarageInfo.numNonEngineeredOpenings": {
    label: "A9. c) Non-engineered flood openings",
    name: "attachedGarageInfo.numNonEngineeredOpenings",
    type: "shortText",
  },
  "attachedGarageInfo.numEngineeredOpenings": {
    label: "A9. c) Engineered flood openings",
    name: "attachedGarageInfo.numEngineeredOpenings",
    type: "shortText",
  },
  "attachedGarageInfo.netAreaNonEngineeredOpenings": {
    label:
      "A9. d) Total net open area of non-engineered flood openings in A9.c",
    name: "attachedGarageInfo.netAreaNonEngineeredOpenings",
    type: "shortText",
  },
  "attachedGarageInfo.ratedAreaOfEngineeredOpenings": {
    label: "A9. e) Total rated area of engineered flood openings in A9.c",
    name: "attachedGarageInfo.ratedAreaOfEngineeredOpenings",
    type: "shortText",
  },
  nfipCommunityName: {
    label: "B1. NFIP Community Name",
    name: "nfipCommunityName",
    type: "shortText",
  },
  nfipCommunityNumber: {
    label: "B1. Community Number",
    name: "nfipCommunityNumber",
    type: "shortText",
  },
  nfipCountyName: {
    label: "B2. County Name",
    name: "nfipCountyName",
    type: "shortText",
  },
  nfipState: {
    label: "B3. State",
    name: "nfipState",
    type: "shortText",
  },
  firmMapNumber: {
    label: "B4. Map/Panel Number",
    type: "shortText",
    name: "firmMapNumber",
    hint: "If there are two separate numbers, please transcribe the entire field as-is.",
  },
  firmSuffix: {
    label: "B5. Suffix",
    name: "firmSuffix",
    type: "shortText",
  },
  firmIndexDate: {
    label: "B6. Date of FIRM Index",
    name: "firmIndexDate",
    type: "datePicker",
    format: "date",
  },
  firmPanelEffectiveDate: {
    label: "B7. FIRM Panel Effective/Revised Date",
    type: "datePicker",
    name: "firmPanelEffectiveDate",
    format: "date",
  },
  floodZone: {
    label: "B8. Flood Zone(s)",
    type: "shortText",
    name: "floodZone",
    hint: "If multiple Flood Zones are present, please enter them separated by commas.",
  },
  baseFloodElevation: {
    label: "B9. Base Flood Elevation(s)",
    type: "shortText",
    name: "baseFloodElevation",
    hint: "If there is a unit of measurement (example: feet) please do not include it. However, do include any N/A values. If multiple elevations are present, please enter them separated by commas.",
  },
  baseFloodElevationSource: {
    label: "B10. Indicate the source of the Base Flood Elevation (BFE) data",
    name: "baseFloodElevationSource",
    type: "radioButtons",
    options: [
      { value: "fis_profile", text: "FIS Profile" },
      { value: "firm", text: "FIRM" },
      { value: "community_determined", text: "Community Determined" },
      { value: "other", text: "Other" },
    ],
    format: "constant",
  },
  baseFloodElevationDatum: {
    label: "B11. Indicate the elevation datum used for the BFE",
    name: "baseFloodElevationDatum",
    type: "radioButtons",
    options: DEFAULT_DATUM_OPTIONS,
    format: "constant",
  },
  isBuildingLocatedInCbrsOrOpa: {
    label:
      "B12. Is the building located in a Coastal Barrier Resources System (CBRS) area or Otherwise Protected Area (OPA)?",
    name: "isBuildingLocatedInCbrsOrOpa",
    type: "radioButtons",
    options: YES_NO_OPTIONS,
    format: "boolean",
  },
  buildingLocatedInCbrsOrOpaDesignationDate: {
    label: "Designation Date",
    name: "buildingLocatedInCbrsOrOpaDesignationDate",
    type: "datePicker",
    format: "date",
  },
  buildingLocatedInCbrsOrOpa: {
    label:
      "Indicate Coastal Barrier Resources System (CBRS) area or Otherwise Protected Area (OPA)",
    name: "buildingLocatedInCbrsOrOpa",
    type: "radioButtons",
    options: CBRS_OR_OPA_OPTIONS,
    format: "constant",
  },
  buildingLocatedSeawardOfLimwa: {
    label:
      "B13. Is the building located seaward of the Limit of Moderate Wave Action (LiMWA)?",
    name: "buildingLocatedSeawardOfLimwa",
    type: "radioButtons",
    options: YES_NO_OPTIONS,
    format: "boolean",
  },
  buildingElevationSource: {
    label: "C1. Building Elevations are based on",
    name: "buildingElevationSource",
    type: "radioButtons",
    options: [
      { value: "construction_drawings", text: "Construction Drawings" },
      {
        value: "building_under_construction",
        text: "Building Under Construction",
      },
      { value: "finished_construction", text: "Finished Construction" },
    ],
    format: "constant",
  },
  benchmarkUtilized: {
    label: "C2. Benchmark Utilized",
    name: "benchmarkUtilized",
    type: "shortText",
  },
  elevationDatum: {
    label: "C2. Elevation datum",
    name: "elevationDatum",
    type: "radioButtons",
    options: DEFAULT_DATUM_OPTIONS,
    format: "constant",
  },
  verticalDatum: {
    label: "Vertical Datum",
    name: "verticalDatum",
    type: "radioButtons",
    options: DEFAULT_DATUM_OPTIONS,
    format: "constant",
  },
  elevationDatumComments: {
    label: "C2. Conversion/Comments",
    name: "elevationDatumComments",
    type: "shortText",
    hint: "If this question does not appear on the file, please leave blank.",
  },
  "buildingElevationInfo.topOfBottomFloor.value": {
    label: "C2. a) Top of bottom floor",
    type: "shortText",
    name: "buildingElevationInfo.topOfBottomFloor.value",
    hint: "If there is a unit of measurement (example: feet) please do not include it. However, do include any N/A values.",
  },
  "buildingElevationInfo.topOfBottomFloor.units": {
    label: "C2. a) Top of bottom floor units",
    name: "buildingElevationInfo.topOfBottomFloor.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.topOfNextHigherFloor.value": {
    label: "C2. b) Top of next higher floor",
    name: "buildingElevationInfo.topOfNextHigherFloor.value",
    type: "shortText",
  },
  "buildingElevationInfo.topOfNextHigherFloor.units": {
    label: "C2. b) Top of next higher floor units",
    name: "buildingElevationInfo.topOfNextHigherFloor.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value": {
    label: "C2. c) Bottom of the lowest horizontal structural",
    name: "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value",
    type: "shortText",
    hint: "If there is a unit of measurement (example: feet) please do not include it. However, do include any N/A values.",
  },
  "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.units": {
    label: "C2. c) Bottom of the lowest horizontal structural member units",
    name: "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.attachedGarage.value": {
    label: "C2. d) Attached garage",
    name: "buildingElevationInfo.attachedGarage.value",
    type: "shortText",
  },
  "buildingElevationInfo.attachedGarage.units": {
    label: "C2. d) Attached garage units",
    name: "buildingElevationInfo.attachedGarage.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value": {
    label: "C2. e) Lowest elevation of machinery or equipment",
    name: "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value",
    type: "shortText",
  },
  "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.units": {
    label: "C2. e) Lowest elevation of machinery or equipment units",
    name: "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.lowestAdjacentGrade.type": {
    label: "C2. f) Lowest adjacent grade type",
    name: "buildingElevationInfo.lowestAdjacentGrade.type",
    type: "radioButtons",
    options: ADJACENT_GRADE_TYPE_OPTIONS,
    format: "constant",
  },
  "buildingElevationInfo.lowestAdjacentGrade.value": {
    label: "C2. f) Lowest adjacent grade",
    name: "buildingElevationInfo.lowestAdjacentGrade.value",
    type: "shortText",
  },
  "buildingElevationInfo.lowestAdjacentGrade.units": {
    label: "C2. f) Lowest adjacent grade units",
    name: "buildingElevationInfo.lowestAdjacentGrade.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.highestAdjacentGrade.type": {
    label: "C2. g) Highest adjacent grade type",
    name: "buildingElevationInfo.highestAdjacentGrade.type",
    type: "radioButtons",
    options: ADJACENT_GRADE_TYPE_OPTIONS,
  },
  "buildingElevationInfo.highestAdjacentGrade.value": {
    label: "C2. g) Highest adjacent grade",
    name: "buildingElevationInfo.highestAdjacentGrade.value",
    type: "shortText",
  },
  "buildingElevationInfo.highestAdjacentGrade.units": {
    label: "C2. g) Highest adjacent grade units",
    name: "buildingElevationInfo.highestAdjacentGrade.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value":
    {
      label:
        "C2. h) Lowest adjacent grade at lowest elevation of deck or stairs",
      name: "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value",
      type: "shortText",
      hint: "If this question does not appear on the file, please leave blank.",
    },
  "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.units":
    {
      label:
        "C2. h) Lowest adjacent grade at lowest elevation of deck or stairs units",
      name: "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.units",
      type: "radioButtons",
      options: UNIT_OF_MEASUREMENT_OPTIONS,
    },
  "certifierInfo.name": {
    label: "Certifier's name",
    type: "shortText",
    name: "certifierInfo.name",
  },
  "certifierInfo.licenseNumber": {
    label: "Certifier's license number",
    type: "shortText",
    name: "certifierInfo.licenseNumber",
  },
  "certifierInfo.email": {
    label: "Certifier's email address",
    type: "shortText",
    name: "certifierInfo.email",
  },
  "certifierInfo.telephone": {
    label: "Certifier's telephone number",
    type: "shortText",
    name: "certifierInfo.telephone",
  },
  issuedAt: {
    label: "Date",
    type: "datePicker",
    name: "issuedAt",
    format: "date",
  },
  comments: {
    label: "Comments",
    name: "comments",
    type: "textarea",
    hint: "If this question does not appear on the file, please leave blank.",
  },
  conversionFactorUsed: {
    label: "Conversion factor used?",
    name: "conversionFactorUsed",
    type: "radioButtons",
    options: YES_NO_OPTIONS,
    format: "boolean",
  },
  coordinatesProvidedBySurveyor: {
    label: "Were lat and long in Section A provided by a surveyor? ",
    name: "coordinatesProvidedBySurveyor",
    type: "radioButtons",
    options: YES_NO_OPTIONS,
    format: "boolean",
  },
  "buildingMeasurementInfo.measurementsBasedOn": {
    label: "Building measurements are based on:",
    name: "buildingMeasurementInfo.measurementsBasedOn",
    type: "radioButtons",
    options: BUILDING_ELEVATION_SOURCE_OPTIONS,
  },
  "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value": {
    label: "E1. a) Top of bottom floor is",
    name: "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value",
    type: "shortText",
  },
  "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units": {
    label: "E1. a) Units",
    name: "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow":
    {
      label: "E1. a) Above or below the HAG",
      name: "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow",
      type: "radioButtons",
      options: ABOVE_OR_BELOW_OPTIONS,
    },
  "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value": {
    label: "E1. b) Top of bottom floor",
    name: "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value",
    type: "shortText",
  },
  "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units": {
    label: "E1. b) Units",
    name: "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow":
    {
      label: "E1. b) Above or below the LAG",
      name: "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow",
      type: "radioButtons",
      options: ABOVE_OR_BELOW_OPTIONS,
    },
  "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value": {
    label: "E2. Next higher floor",
    name: "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value",
    type: "shortText",
  },
  "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units": {
    label: "E2. Units",
    name: "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow":
    {
      label: "E2. Above or below the HAG",
      name: "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow",
      type: "radioButtons",
      options: ABOVE_OR_BELOW_OPTIONS,
    },
  "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value":
    {
      label: "E3. Attached garage (top of slab)",
      name: "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value",
      type: "shortText",
    },
  "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units":
    {
      label: "E3. Units",
      name: "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units",
      type: "radioButtons",
      options: UNIT_OF_MEASUREMENT_OPTIONS,
    },
  "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow":
    {
      label: "E3. Above or below the HAG",
      name: "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow",
      type: "radioButtons",
      options: ABOVE_OR_BELOW_OPTIONS,
    },
  "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value":
    {
      label: "E4. Top of platform of machinery and/or equipment",
      name: "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value",
      type: "shortText",
    },
  "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units":
    {
      label: "E4. Units",
      name: "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units",
      type: "radioButtons",
      options: UNIT_OF_MEASUREMENT_OPTIONS,
    },
  "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow":
    {
      label: "E4. Above or below the HAG",
      name: "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow",
      type: "radioButtons",
      options: ABOVE_OR_BELOW_OPTIONS,
    },
  "buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance": {
    label: "E5. Top of bottom floor elevated in accordance with ordinance",
    name: "buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance",
    type: "radioButtons",
    format: "capitalize",
    options: YES_NO_UNKNOWN_OPTIONS,
  },
  "communityInfo.sectionCTakenFromOtherAuthorizedSource": {
    label: "G1. Section C taken from other documentation",
    name: "communityInfo.sectionCTakenFromOtherAuthorizedSource",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "communityInfo.sectionECompletedByLocalOfficial": {
    label: "G2. a) Section E completed by local official",
    name: "communityInfo.sectionECompletedByLocalOfficial",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "communityInfo.sectionHCompletedByLocalOfficial": {
    label: "G2. b) Section H completed by local official",
    name: "communityInfo.sectionHCompletedByLocalOfficial",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "communityInfo.sectionGCommentsProvidedByLocalOfficial": {
    label: "G3. Section G comments provided by local official",
    name: "communityInfo.sectionGCommentsProvidedByLocalOfficial",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "communityInfo.communityInfoProvidedForFloodplainManagementPurposes": {
    label: "G4. Information provided for floodplain management purposes",
    name: "communityInfo.communityInfoProvidedForFloodplainManagementPurposes",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "communityInfo.permitNumber": {
    label: "G5. Permit number",
    name: "communityInfo.permitNumber",
    type: "shortText",
  },
  "communityInfo.permitDateIssued": {
    label: "G6. Date permit issued",
    name: "communityInfo.permitDateIssued",
    type: "datePicker",
  },
  "communityInfo.certificateOfComplianceOrOccupancyDateIssued": {
    label: "G7. Date of compliance/occupancy issued",
    name: "communityInfo.certificateOfComplianceOrOccupancyDateIssued",
    type: "datePicker",
  },
  "communityInfo.permitIssuedForNewConstructionOrSI": {
    label: "G8. Permit has been issued for",
    name: "communityInfo.permitIssuedForNewConstructionOrSI",
    type: "radioButtons",
    options: PERMIT_ISSUE_TYPE_OPTIONS,
  },
  "communityInfo.elevations.asBuiltLowestFloor.value": {
    label: "G9. a) Elevation of as build lowest floor",
    name: "communityInfo.elevations.asBuiltLowestFloor.value",
    type: "shortText",
  },
  "communityInfo.elevations.asBuiltLowestFloor.units": {
    label: "G9. a) Units",
    name: "communityInfo.elevations.asBuiltLowestFloor.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "communityInfo.elevations.asBuiltLowestFloor.datum": {
    label: "G9. a) Datum",
    name: "communityInfo.elevations.asBuiltLowestFloor.datum",
    type: "shortText",
  },
  "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value":
    {
      label: "G9. b) Elevation of as built lowest horizontal structural member",
      name: "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value",
      type: "shortText",
    },
  "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units":
    {
      label: "G9. b) Units",
      name: "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units",
      type: "radioButtons",
      options: UNIT_OF_MEASUREMENT_OPTIONS,
    },
  "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum":
    {
      label: "G9. b) Datum",
      name: "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum",
      type: "shortText",
    },
  "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value": {
    label: "G10. a) BFE of flooding",
    name: "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value",
    type: "shortText",
  },
  "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units": {
    label: "G10. a) Units",
    name: "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units",
    type: "radioButtons",
    options: UNIT_OF_MEASUREMENT_OPTIONS,
  },
  "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum": {
    label: "G10. a) Datum",
    name: "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum",
    type: "shortText",
  },
  "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value":
    {
      label: "G10. b) Community's minimum elevation requirement",
      name: "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value",
      type: "shortText",
    },
  "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units":
    {
      label: "G10. b) Units",
      name: "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units",
      type: "radioButtons",
      options: UNIT_OF_MEASUREMENT_OPTIONS,
    },
  "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum":
    {
      label: "G10. b) Datum",
      name: "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum",
      type: "shortText",
    },
  "communityInfo.varianceIssued": {
    label: "G11. Variance issued",
    name: "communityInfo.varianceIssued",
    type: "radioButtons",
    format: "boolean",
    options: YES_NO_OPTIONS,
  },
  "communityInfo.localOfficialInfo.name": {
    label: "Local official's name",
    name: "communityInfo.localOfficialInfo.name",
    type: "shortText",
  },
  "communityInfo.localOfficialInfo.telephone": {
    label: "Local official's telephone",
    name: "communityInfo.localOfficialInfo.telephone",
    type: "shortText",
  },
  "communityInfo.localOfficialInfo.email": {
    label: "Local official's email",
    name: "communityInfo.localOfficialInfo.email",
    type: "shortText",
  },
  "communityInfo.localOfficialInfo.dateSigned": {
    label: "Date signed",
    name: "communityInfo.localOfficialInfo.dateSigned",
    type: "datePicker",
  },
  "communityInfo.localOfficialInfo.comments": {
    label: "Section G comments",
    name: "communityInfo.localOfficialInfo.comments",
    type: "shortText",
  },
};

export const FIELDS_1982: Array<FieldDefaults> = [
  commonFields.buildingOwnerName,
  commonFields.streetAddress,
  commonFields.city,
  commonFields.state,
  {
    ...commonFields.zipcode,
    label: "ZIP Code",
  },
  {
    ...commonFields.propertyDescription,
    label: "Property Location",
  },
  {
    ...commonFields.nfipCommunityNumber,
    label: "Community Number",
  },
  {
    ...commonFields.firmMapNumber,
    label: "Panel Number",
  },
  {
    ...commonFields.firmSuffix,
    label: "Suffix",
  },
  {
    ...commonFields.firmIndexDate,
    label: "Date of FIRM",
  },
  {
    ...commonFields.floodZone,
    label: "FIRM Zone",
  },
  {
    ...commonFields.baseFloodElevation,
    label: "Base Flood Elev",
  },
  commonFields.issuedAt,
  {
    ...commonFields["buildingElevationInfo.topOfBottomFloor.value"],
    label: "lowest floor elevation of",
  },
  {
    ...commonFields[
      "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
    ],
    label: "...the bottom of the lowest floor beam is at an elevation of",
  },
  {
    ...commonFields["buildingElevationInfo.highestAdjacentGrade.value"],
    label: "...the highest adjacent grade next to the building is",
  },
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
];

export const FIELDS_1984_1987: Array<FieldDefaults> = [
  commonFields.buildingOwnerName,
  commonFields.streetAddress,
  commonFields.city,
  commonFields.state,
  commonFields.zipcode,
  {
    ...commonFields.firmMapNumber,
    label: "FIA MAP PANEL ON WHICH PROPERTY IS LOCATED",
  },
  {
    ...commonFields.floodZone,
    label: "FIA MAP ZONE IN WHICH PROPERTY IS LOCATED",
  },
  { ...commonFields.firmPanelEffectiveDate, label: "FIA MAP EFFECTIVE DATE" },
  {
    ...commonFields.baseFloodElevation,
    label: "BASE FLOOD ELEVATION AT THE PROPOSED SITE",
  },
  {
    ...commonFields["buildingElevationInfo.topOfBottomFloor.value"],
    label:
      "I certify that the property location described above has a lowest floor at an elevation of",
  },
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
  commonFields.issuedAt,
];

export const FIELDS_1990: Array<FieldDefaults> = [
  commonFields.buildingOwnerName,
  commonFields.streetAddress,
  commonFields.city,
  commonFields.state,
  {
    ...commonFields.zipcode,
    label: "ZIP Code",
  },
  {
    ...commonFields.buildingDiagramNumber,
    label:
      "1. Using the flood insurance manual... indicate the proper diagram number",
  },
  {
    ...commonFields["buildingElevationInfo.topOfBottomFloor.value"],
    label: "2. FIRM Zones A1-A30... is at an elevation of",
  },
  {
    ...commonFields[
      "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
    ],
    label: "3. FIRM Zones V1-V30... is at an elevation of",
  },
  commonFields.benchmarkUtilized,
  {
    ...commonFields.verticalDatum,
    options: LEGACY_DATUM_OPTIONS,
    label:
      "5. Indicate the elevation datum system used in determining the above reference level elevations",
  },
  {
    ...commonFields.baseFloodElevationDatum,
    options: LEGACY_DATUM_OPTIONS,
    label:
      "6. Indicate the elevation datum system used on the FIRM for base flood elevations",
  },
  {
    ...commonFields.baseFloodElevationSource,
    label: "7. Is the reference level based on actual construction?",
  },
  {
    ...commonFields.nfipCommunityNumber,
    label: "Community No.",
  },
  {
    ...commonFields.firmMapNumber,
    label: "Panel No.",
  },
  {
    ...commonFields.firmSuffix,
    label: "Suffix",
  },
  {
    ...commonFields.firmIndexDate,
    label: "Date of FIRM",
  },
  {
    ...commonFields.floodZone,
    label: "FIRM Zone",
  },
  {
    ...commonFields.baseFloodElevation,
    label: "Base Flood Elev.",
  },
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
  commonFields.issuedAt,
];

export const FIELDS_1993_1996_1999: Array<FieldDefaults> = [
  commonFields.buildingOwnerName,
  { ...commonFields.streetAddress, label: "Building Street Address" },
  { ...commonFields.propertyDescription, label: "Other Description" },
  commonFields.city,
  commonFields.state,
  {
    ...commonFields.zipcode,
    label: "ZIP Code",
    hint: "If Zip Code is not present, please leave blank.",
  },
  { ...commonFields.nfipCommunityNumber, label: "1. Community Number" },
  {
    ...commonFields.firmMapNumber,
    label: "2. Panel Number",
  },
  {
    ...commonFields.firmSuffix,
    label: "3. Suffix",
  },
  {
    ...commonFields.firmIndexDate,
    label: "4. Date of FIRM Index",
  },
  {
    ...commonFields.floodZone,
    label: "5. FIRM Zone",
  },
  { ...commonFields.baseFloodElevation, label: "6. Base Flood Elevation" },
  {
    ...commonFields.baseFloodElevationDatum,
    label:
      "7. Indicate the elevation datum system used on the FIRM for Base Flood Elevations",
    options: [
      { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
      { value: "other", text: "Other" },
    ],
  },
  {
    ...commonFields.buildingDiagramNumber,
    label:
      "1. The diagram number... that best describes the subject building’s reference level",
  },
  {
    ...commonFields["buildingElevationInfo.topOfBottomFloor.value"],
    label: "2. a) FIRM Zones A1-A30... is at an elevation of",
  },
  {
    ...commonFields[
      "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
    ],
    label: "2. b) FIRM Zones V1-V30... is at an elevation of",
  },
  {
    ...commonFields.elevationDatum,
    label:
      "3. Indicate the elevation datum system used in determining the above",
    options: [
      { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
      { value: "other", text: "Other" },
    ],
  },
  {
    ...commonFields.buildingElevationSource,
    label: "5. The reference level elevation is based on",
    options: [
      { value: "acutal_construction", text: "Actual Construction" },
      { value: "construction_drawings", text: "Construction Drawings" },
    ],
  },
  {
    ...commonFields["buildingElevationInfo.lowestAdjacentGrade.value"],
    label: "6. The elevation of the lowest grade immediately adjacent",
  },
  commonFields.issuedAt,
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
];

export const FIELDS_2002_2005: Array<FieldDefaults> = [
  commonFields.buildingOwnerName,
  {
    ...commonFields.streetAddress,
    label: "Building Street Address",
  },
  commonFields.city,
  commonFields.state,
  {
    ...commonFields.zipcode,
    label: "ZIP Code",
  },
  {
    ...commonFields.propertyDescription,
    label: "Property Description",
  },
  {
    ...commonFields.buildingUse,
    label: "Building Use",
  },
  commonFields.latitude,
  commonFields.longitude,
  commonFields.nfipCommunityName,
  commonFields.nfipCommunityNumber,
  commonFields.nfipCountyName,
  commonFields.nfipState,
  {
    ...commonFields.firmMapNumber,
    label: "B4. Map and Panel Number",
  },
  commonFields.firmMapNumber,
  commonFields.firmSuffix,
  commonFields.firmIndexDate,
  commonFields.firmPanelEffectiveDate,
  {
    ...commonFields.floodZone,
    label: "B8. FIRM Zone",
  },
  {
    ...commonFields.baseFloodElevation,
    label: "B9. Base Flood Elevation",
  },
  commonFields.baseFloodElevationSource,
  {
    ...commonFields.baseFloodElevationDatum,
    label:
      "7. Indicate the elevation datum system used on the FIRM for Base Flood Elevations",
  },
  commonFields.buildingElevationSource,
  {
    ...commonFields.buildingDiagramNumber,
    label: "C2. Building Diagram Number",
  },
  commonFields.benchmarkUtilized,
  {
    ...commonFields.verticalDatum,
    label: "Datum",
  },
  commonFields.elevationDatumComments,
  {
    ...commonFields["buildingElevationInfo.topOfBottomFloor.value"],
    label: "C3. a) Top of bottom floor",
  },
  {
    ...commonFields["buildingElevationInfo.topOfNextHigherFloor.value"],
    label: "C3. b) Top of next higher floor",
  },
  {
    ...commonFields[
      "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
    ],
    label: "C3. c) Bottom of the lowest horizontal structural member",
  },
  {
    ...commonFields["buildingElevationInfo.attachedGarage.value"],
    label: "C3. d) Attached garage",
  },
  {
    ...commonFields[
      "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value"
    ],
    label: "C3. e) Lowest elevation of machinery or equipment",
  },
  {
    ...commonFields["buildingElevationInfo.lowestAdjacentGrade.value"],
    label: "C3. f) Lowest adjacent grade",
  },
  {
    ...commonFields["buildingElevationInfo.highestAdjacentGrade.value"],
    label: "C3. g) Highest adjacent grade",
  },
  {
    ...commonFields["crawlspaceInfo.numFloodOpenings"],
    label: "C3. h) No. of permanent openings (flood vents)",
  },
  {
    ...commonFields["crawlspaceInfo.netAreaOfFloodOpenings"],
    label: "C3. i) Total area of all permanent openings (flood vents)",
  },
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
  commonFields.issuedAt,
  commonFields.comments,
];

export const FIELDS_2012_2022 = [
  commonFields.buildingOwnerName,
  commonFields.streetAddress,
  commonFields.city,
  commonFields.state,
  commonFields.zipcode,
  commonFields.propertyDescription,
  commonFields.buildingUse,
  commonFields.latitude,
  commonFields.longitude,
  commonFields.buildingDiagramNumber,
  commonFields["crawlspaceInfo.sqft"],
  commonFields["crawlspaceInfo.numFloodOpenings"],
  commonFields["crawlspaceInfo.netAreaOfFloodOpenings"],
  commonFields["crawlspaceInfo.engineeredFloodOpenings"],
  commonFields["attachedGarageInfo.sqft"],
  commonFields["attachedGarageInfo.numFloodOpenings"],
  commonFields["attachedGarageInfo.netAreaOfFloodOpenings"],
  commonFields["attachedGarageInfo.engineeredFloodOpenings"],
  commonFields.nfipCommunityName,
  commonFields.nfipCommunityNumber,
  commonFields.nfipCountyName,
  commonFields.nfipState,
  commonFields.firmMapNumber,
  commonFields.firmSuffix,
  commonFields.firmIndexDate,
  commonFields.firmPanelEffectiveDate,
  commonFields.floodZone,
  commonFields.baseFloodElevation,
  commonFields.baseFloodElevationSource,
  commonFields.baseFloodElevationDatum,
  commonFields.buildingElevationSource,
  commonFields.benchmarkUtilized,
  commonFields.elevationDatum,
  commonFields.verticalDatum,
  commonFields.elevationDatumComments,
  commonFields["buildingElevationInfo.topOfBottomFloor.value"],
  commonFields["buildingElevationInfo.topOfNextHigherFloor.value"],
  commonFields[
    "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
  ],
  commonFields["buildingElevationInfo.attachedGarage.value"],
  commonFields[
    "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value"
  ],
  commonFields["buildingElevationInfo.lowestAdjacentGrade.value"],
  commonFields["buildingElevationInfo.highestAdjacentGrade.value"],
  commonFields[
    "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value"
  ],
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
  commonFields.issuedAt,
  commonFields.comments,
];

export const MODERN_EC = [
  commonFields.buildingOwnerName,
  commonFields.streetAddress,
  commonFields.city,
  commonFields.state,
  commonFields.zipcode,
  commonFields.propertyDescription,
  commonFields.buildingUse,
  commonFields.latitude,
  commonFields.longitude,
  commonFields.buildingDiagramNumber,
  commonFields["crawlspaceInfo.sqft"],
  commonFields["crawlspaceInfo.oneFloodOpeningTwoSides"],
  commonFields["crawlspaceInfo.numNonEngineeredOpenings"],
  commonFields["crawlspaceInfo.numEngineeredOpenings"],
  commonFields["crawlspaceInfo.netAreaNonEngineeredOpenings"],
  commonFields["crawlspaceInfo.ratedAreaOfEngineeredOpenings"],
  {
    ...commonFields["crawlspaceInfo.netAreaOfFloodOpenings"],
    label: "A8. f) Sum of A9.d and A9.e",
  },
  commonFields["attachedGarageInfo.sqft"],
  commonFields["attachedGarageInfo.oneFloodOpeningTwoSides"],
  commonFields["attachedGarageInfo.numNonEngineeredOpenings"],
  commonFields["attachedGarageInfo.numEngineeredOpenings"],
  commonFields["attachedGarageInfo.netAreaNonEngineeredOpenings"],
  commonFields["attachedGarageInfo.ratedAreaOfEngineeredOpenings"],
  {
    ...commonFields["attachedGarageInfo.netAreaOfFloodOpenings"],
    label: "A9. f) Sum of A9.d and A9.e",
  },
  { ...commonFields.nfipCommunityName, label: "B1. a) NFIP Community Name" },
  {
    ...commonFields.nfipCommunityNumber,
    label: "B1. b) NFIP Community Identification Number",
  },
  commonFields.nfipCountyName,
  commonFields.nfipState,
  commonFields.firmMapNumber,
  commonFields.firmSuffix,
  commonFields.firmIndexDate,
  commonFields.firmPanelEffectiveDate,
  commonFields.floodZone,
  commonFields.baseFloodElevation,
  commonFields.baseFloodElevationSource,
  commonFields.baseFloodElevationDatum,
  commonFields.isBuildingLocatedInCbrsOrOpa,
  commonFields.buildingLocatedInCbrsOrOpaDesignationDate,
  commonFields.buildingLocatedInCbrsOrOpa,
  commonFields.buildingLocatedSeawardOfLimwa,
  commonFields.buildingElevationSource,
  commonFields.benchmarkUtilized,
  commonFields.elevationDatum,
  commonFields.verticalDatum,
  commonFields.elevationDatumComments,
  commonFields.conversionFactorUsed,
  commonFields["buildingElevationInfo.topOfBottomFloor.value"],
  commonFields["buildingElevationInfo.topOfBottomFloor.units"],
  commonFields["buildingElevationInfo.topOfNextHigherFloor.value"],
  commonFields["buildingElevationInfo.topOfNextHigherFloor.units"],
  commonFields[
    "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
  ],
  commonFields[
    "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.units"
  ],
  commonFields["buildingElevationInfo.attachedGarage.value"],
  commonFields["buildingElevationInfo.attachedGarage.units"],
  commonFields[
    "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value"
  ],
  commonFields[
    "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.units"
  ],
  commonFields["buildingElevationInfo.lowestAdjacentGrade.type"],
  commonFields["buildingElevationInfo.lowestAdjacentGrade.value"],
  commonFields["buildingElevationInfo.lowestAdjacentGrade.units"],
  commonFields["buildingElevationInfo.highestAdjacentGrade.type"],
  commonFields["buildingElevationInfo.highestAdjacentGrade.value"],
  commonFields["buildingElevationInfo.highestAdjacentGrade.units"],
  commonFields[
    "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value"
  ],
  commonFields[
    "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.units"
  ],
  commonFields.coordinatesProvidedBySurveyor,
  commonFields["certifierInfo.name"],
  commonFields["certifierInfo.licenseNumber"],
  commonFields["certifierInfo.email"],
  commonFields["certifierInfo.telephone"],
  commonFields.issuedAt,
  commonFields.comments,
  commonFields["buildingMeasurementInfo.measurementsBasedOn"],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units"
  ],
  commonFields[
    "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow"
  ],
  commonFields["buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance"],
  commonFields["communityInfo.sectionCTakenFromOtherAuthorizedSource"],
  commonFields["communityInfo.sectionECompletedByLocalOfficial"],
  commonFields["communityInfo.sectionHCompletedByLocalOfficial"],
  commonFields["communityInfo.sectionGCommentsProvidedByLocalOfficial"],
  commonFields[
    "communityInfo.communityInfoProvidedForFloodplainManagementPurposes"
  ],
  commonFields["communityInfo.permitNumber"],
  commonFields["communityInfo.permitDateIssued"],
  commonFields["communityInfo.certificateOfComplianceOrOccupancyDateIssued"],
  commonFields["communityInfo.permitIssuedForNewConstructionOrSI"],
  commonFields["communityInfo.elevations.asBuiltLowestFloor.value"],
  commonFields["communityInfo.elevations.asBuiltLowestFloor.units"],
  commonFields["communityInfo.elevations.asBuiltLowestFloor.datum"],
  commonFields[
    "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value"
  ],
  commonFields[
    "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units"
  ],
  commonFields[
    "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum"
  ],
  commonFields[
    "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value"
  ],
  commonFields[
    "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units"
  ],
  commonFields[
    "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum"
  ],
  commonFields[
    "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value"
  ],
  commonFields[
    "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units"
  ],
  commonFields[
    "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum"
  ],
  commonFields["communityInfo.varianceIssued"],
  commonFields["communityInfo.localOfficialInfo.name"],
  commonFields["communityInfo.localOfficialInfo.telephone"],
  commonFields["communityInfo.localOfficialInfo.email"],
  commonFields["communityInfo.localOfficialInfo.dateSigned"],
  commonFields["communityInfo.localOfficialInfo.comments"],
];

export const buildFields = ({
  certificate,
  fields,
  lowConfidenceFields,
}: {
  certificate: CertificateType;
  fields: Array<FieldDefaults>;
  lowConfidenceFields: Array<CertificateFormFieldDottableKeys>;
}) => {
  return fields.map(field => {
    return buildExtractedField({
      ...field,
      value: certificateValue(certificate, field.name),
      isLowConfidence: lowConfidenceFields.includes(field.name),
    });
  });
};
