import React, { useContext } from "react";
import { Button } from "../../../../Common/Button";
import { FilterContext, FilterContextStructure } from "../FilterContext";
import {
  FilterBuilderFooter,
  FilterBuilderInputsWrapper,
  LogicFilterBuilderWrapper,
} from "./__styles__/LogicFilterBuilder";
import { MapFilterInputs, SelectFilterInputs } from "./FilterInputs";
import FilterTargetDropdown from "./FilterTargetDropdown";
import { FilterTarget } from "./types";
import useFilterBuilderState from "./useFilterBuilderState";

type LogicFilterBuilderProps = Omit<
  ReturnType<typeof useFilterBuilderState>,
  "removeFilterSummary"
>;

const filterInputByFilterTarget = (
  filterTarget: Maybe<FilterTarget>,
  filterContext: FilterContextStructure
) => {
  const inputType = filterTarget
    ? filterContext.filterTargetMetadata[filterTarget]!.inputType
    : null;
  switch (inputType) {
    case "map-select":
      return MapFilterInputs;
    case "select":
      return SelectFilterInputs;
    default:
      return () => null;
  }
};

const LogicFilterBuilder = ({
  addNewFilter,
  changeFilterInputsValues,
  changeFilterTarget,
  clearBuilderValues,
  dirty,
  filter,
  filterTarget,
  selectedFilterTargets,
  valid,
}: LogicFilterBuilderProps) => {
  const filterContext = useContext(FilterContext);
  const FilterInputs = filterInputByFilterTarget(filterTarget, filterContext);

  return (
    <LogicFilterBuilderWrapper>
      <FilterBuilderInputsWrapper>
        <FilterTargetDropdown
          currentFilterTarget={filterTarget}
          changeFilterTarget={changeFilterTarget}
          clearFilterTarget={clearBuilderValues}
          selectedFilterTargets={selectedFilterTargets}
        />
        <FilterInputs
          values={filter}
          onFilterInputValuesChange={changeFilterInputsValues}
          filterTarget={filterTarget}
        />
      </FilterBuilderInputsWrapper>
      <FilterBuilderFooter>
        <Button
          styleVariant="primary"
          disabled={!valid}
          onClick={addNewFilter}
          leftIconName="plus"
          size="medium"
          type="button"
        >
          Add filter
        </Button>
        {dirty && (
          <Button
            styleVariant="ghost"
            onClick={clearBuilderValues}
            size="medium"
            type="button"
          >
            Clear
          </Button>
        )}
      </FilterBuilderFooter>
    </LogicFilterBuilderWrapper>
  );
};

export default LogicFilterBuilder;
