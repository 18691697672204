import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Icons } from "../../../Common/Icons";
import Table from "../../../Common/Tables/Table";
import FileDisplay from "../../../Inputs/FileDisplay";
import { AccountRasterSettings } from "./__queries__";

export default ({
  rasterData,
}: {
  rasterData: Array<AccountRasterSettings>;
}) => {
  const columns: Array<ColumnDef<AccountRasterSettings>> = useMemo(
    () => [
      {
        accessorKey: "task",
        id: "task",
        size: 100,
        cell: ({ row }) => {
          return (
            <FileDisplay
              fileIcon={Icons.IMAGE_SERVER}
              titleText={row.original.layerName}
              onClick={row.getToggleExpandedHandler()}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Table<AccountRasterSettings>
      css={{
        "& .actions-cell": {
          textAlign: "right",
        },
      }}
      currentData={rasterData}
      columns={columns}
      loadingDetails={{
        loading: false,
        loadingText: "Loading raster data",
        noDataText: "No rasters found",
      }}
      minRows={rasterData.length}
      tableStyleDetails={{
        hasFlushedSides: true,
      }}
      excludeTableHeader
      excludePaginationNav
    />
  );
};
