import React from "react";
import { MIME_TYPE } from "common/constants";
import { colors } from "../../../stitches.config";
import { Icon, Icons } from "../../Common/Icons/LucideIcons";

export interface DocumentUploadIconProps {
  documentUploadMimeType: MIME_TYPE;
}

export default ({ documentUploadMimeType }: DocumentUploadIconProps) => {
  let icon: keyof typeof Icons;
  let color: keyof typeof colors;

  switch (documentUploadMimeType) {
    case MIME_TYPE.JPEG:
    case MIME_TYPE.JPG:
    case MIME_TYPE.PNG:
      icon = "file-image";
      color = "contentInfo";
      break;
    case MIME_TYPE.MP4:
      icon = "file-video";
      color = "contentCritical";
      break;
    case MIME_TYPE.PDF:
    default:
      icon = "file-text";
      color = "contentInfo";
      break;
  }

  // the wrapping div is needed to force the icon to be 24x24
  // without it, large filenames cause the icon to shrink in size
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Icon iconName={icon} color={color} size={"24"} />
    </div>
  );
};
