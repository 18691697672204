import React, { useContext } from "react";
import { useHistory } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import {
  SubmissionType,
  useDeleteSubmissionTypeMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import { useDeleteModal } from "../../../Common/DeleteModal";
import DropdownMenu, { ActionsProps } from "../../../Inputs/DropdownMenu";

export type ActionButtonProps = {
  submissionType: Pick<SubmissionType, "id" | "name" | "isDeletable">;
  onDeleteSuccess: () => void;
};

export const ActionButton = ({
  submissionType,
  onDeleteSuccess,
}: ActionButtonProps) => {
  const history = useHistory();
  const { authorized } = useContext(AuthContext);

  const canEditSubmissionType = authorized({
    resource: RESOURCE_NAME.SUBMISSION_TYPE,
    permission: "update",
  });

  const canDeleteSubmissionType = authorized({
    resource: RESOURCE_NAME.SUBMISSION_TYPE,
    permission: "delete",
  });
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [deleteSubmissionType, { loading: deleteLoading }] =
    useDeleteSubmissionTypeMutation({
      onCompleted: () => {
        addSuccessToast("Your record type was successfully deleted");
        onDeleteSuccess();
      },
      onError: () => {
        addErrorToast(
          "There was a problem deleting the record type. Please try again or contact support@withforerunner.com"
        );
      },
    });

  const [showDeleteSubmissionTypeModal] = useDeleteModal({
    headerText: "Delete record type",
    subheaderText: submissionType.name,
    Content: (
      <p>
        <span>Are you sure you would like to delete this record type? </span>
        <b>This action cannot be undone. </b>
        <span>
          This record type can only be deleted because there are no existing
          records associated with it.
        </span>
      </p>
    ),
    deleteButtonText: "Delete",
    onDelete: () => {
      void deleteSubmissionType({
        variables: { submissionTypeId: submissionType.id },
      });
    },
  });

  const deleteDisabled =
    !canDeleteSubmissionType || !submissionType.isDeletable || deleteLoading;

  const actions: Array<ActionsProps> = [
    {
      label: "Edit record type",
      disabled: !canEditSubmissionType,
      onClick: () => {
        history.push({
          pathname: buildLink("submissionsBuilder", {
            submissionTypeId: submissionType.id,
          }),
          state: {
            prevLocation: history.location.pathname,
          },
        });
      },
    },
    {
      label: "Delete",
      variant: "red",
      disabled: deleteDisabled,
      onClick: showDeleteSubmissionTypeModal,
    },
  ];

  return <DropdownMenu actions={actions} isTableAction />;
};
