import React from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { formatCoordinates } from "common/utils/coordinates";
import { useDeletePropertyMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";
import { AuthContext } from "../../Authorization/AuthContext";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { Button } from "../../Common/Button";
import Modal from "../../Common/Modal";
import { Body } from "../../Common/Typography";
import { Checkbox } from "../../Inputs/react-hook-form";
import { DeleteModalFieldset } from "./__styles__/DeletePropertyModal";

const PUBLIC_WEBSITE_DISCLAIMER =
  "The property will no longer accessible from your public website.";
export interface UseDeletePropertyModalProps {
  property: {
    id: string;
    latitude: number;
    longitude: number;
    fullAddress?: Maybe<string>;
    streetAddress?: Maybe<string>;
    city?: Maybe<string>;
  };
  updateMap: () => void;
  closeModal: () => void;
  onDelete: () => void;
}

export const useDeletePropertyModal = ({
  property,
  updateMap,
  onDelete,
}: UseDeletePropertyModalProps) => {
  const [showDeletePropertyModal, hideDeletePropertyModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideDeletePropertyModal();
        }}
      >
        <DeletePropertyConfirmation
          updateMap={updateMap}
          closeModal={hideDeletePropertyModal}
          property={property}
          onDelete={onDelete}
        />
      </Modal>
    ),
    [property]
  );

  return [showDeletePropertyModal, hideDeletePropertyModal] as const;
};

interface DeletePropertyConfirmationForm {
  confirmFileDelete: boolean;
  confirmDashboardRemoval: boolean;
  confirmPublicWebsiteRemoval?: boolean;
}

export const DeletePropertyConfirmation = ({
  updateMap,
  closeModal,
  property,
  onDelete,
}: UseDeletePropertyModalProps) => {
  const { account } = React.useContext(AuthContext);
  const {
    formState: { isValid },
    control,
  } = useForm<DeletePropertyConfirmationForm>({
    defaultValues: {
      confirmFileDelete: false,
      confirmDashboardRemoval: false,
      confirmPublicWebsiteRemoval: account?.publicPortal.enabled
        ? false
        : undefined,
    },
  });
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [deleteProperty, { loading }] = useDeletePropertyMutation({
    onCompleted: () => {
      track("Deleted Property", {
        streetAddress: property.streetAddress,
        latitude: property.latitude,
        longitude: property.longitude,
        city: property.city,
      });

      updateMap();

      addSuccessToast("The property was successfully deleted");

      onDelete();
    },
    onError: () => {
      addErrorToast(
        "There was an error deleting this property. Please try again. If the problem persists, please email support@withforerunner.com"
      );
    },
  });

  const handleDelete = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await deleteProperty({
      variables: { propertyId: property.id },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Delete property</h1>
        <h2>
          {property.fullAddress ??
            formatCoordinates({
              latitude: property.latitude!,
              longitude: property.longitude!,
            })}
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <Body size="default" type="regular">
            By deleting this property, you confirm that:
          </Body>
          <DeleteModalFieldset>
            <Checkbox
              control={control}
              required={true}
              name="confirmFileDelete"
              label="All data and uploaded files associated with this property will be deleted."
            />
            <Checkbox
              control={control}
              required={true}
              name="confirmDashboardRemoval"
              label="The property will be removed from the dashboard for all users."
            />
            {account?.publicPortal.enabled && (
              <Checkbox
                control={control}
                required={true}
                name="confirmPublicWebsiteRemoval"
                label={PUBLIC_WEBSITE_DISCLAIMER}
              />
            )}
          </DeleteModalFieldset>
          <Body size="default" type="regular">
            This action <strong>cannot</strong> be undone.
          </Body>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              size="medium"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              styleVariant="alert"
              disabled={!isValid}
              loading={loading}
              onClick={handleDelete}
              size="medium"
            >
              Delete property
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};
