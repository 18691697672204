import React, { useContext, useState } from "react";
import { useModal } from "react-modal-hook";
import {
  SisdRule,
  useDeleteSisdRuleMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  ButtonSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import {
  SisdContainer,
  SisdContentSection,
} from "./__styles__/deleteSisdRuleModal";

export type DeleteSisdRuleModalProps = Pick<SisdRule, "name"> & {
  onCancel: () => void;
  onUpdate: () => void;
};

export const useDeleteSiSdRuleModal = (
  props: Omit<DeleteSisdRuleModalProps, "onCancel" | "name">
) => {
  const [rule, setRule] = useState<{ name: string }>({ name: "" });

  const [show, hideDeleteSisdRuleModal] = useModal(
    () => (
      <Modal onRequestClose={hideDeleteSisdRuleModal}>
        <DeleteSisdRuleModal
          name={rule.name}
          {...props}
          onCancel={hideDeleteSisdRuleModal}
        />
      </Modal>
    ),

    [rule]
  );
  const showDeleteSisdRuleModal = ({
    name,
  }: Pick<DeleteSisdRuleModalProps, "name">) => {
    setRule({ name });
    show();
  };

  return [showDeleteSisdRuleModal, hideDeleteSisdRuleModal] as const;
};

export const DeleteSisdRuleModal = ({
  name,
  onCancel,
  onUpdate,
}: DeleteSisdRuleModalProps) => {
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const [deleteSisdRule] = useDeleteSisdRuleMutation();
  const { account } = useContext(AuthContext);

  const handleDelete = async () => {
    await deleteSisdRule({
      variables: { data: { name, accountId: account!.id } },
      onCompleted: () => {
        addSuccessToast(`${name} rule was successfully deleted.`);
        onUpdate();
        onCancel();
      },
      onError: () => {
        addErrorToast(`Failed to delete ${name} rule.`);
        // We refetch in case it was a timing issue and a rule now uses the attribute
        onUpdate();
      },
    });
  };

  return (
    <SisdContainer>
      <HeaderSection>
        <h1>Delete "{name}" SI/SD rule</h1>
      </HeaderSection>
      <FormSection>
        <SisdContentSection>
          Deleting an SI/SD rule cannot be undone. Warnings and SI/SD summaries
          will be recalculated on properties that were previously impacted by
          this rule.
        </SisdContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="medium" styleVariant="alert" onClick={handleDelete}>
            I understand, delete rule
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </SisdContainer>
  );
};
