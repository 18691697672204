import React from "react";
import { useForm } from "react-hook-form";
import { getPath } from "common/routing";
import { ProvideEmailState } from "common-client/utils/useIdentifyUserFlow";
import { Button } from "../../Common/Button";
import { Section, Sections } from "../../Common/Layout";
import { Title } from "../../Common/Typography";
import { Text } from "../../Inputs/react-hook-form";
import { ErrorMessage } from "../ErrorMessage";

type FormData = {
  email: string;
};

export const ProvideEmail = ({
  onSubmit,
  state,
  loading,
}: {
  onSubmit: (email: string) => Promise<void>;
  state: ProvideEmailState;
  loading: boolean;
}) => {
  const { error, email: startingEmail } = state;

  const { watch, handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      email: startingEmail ?? "",
    },
  });

  const [email] = watch(["email"]);
  const disabled = !email || loading;

  const handleFormData = async (data: FormData) => {
    await onSubmit(data.email);
  };

  return (
    <Sections as="form" onSubmit={handleSubmit(handleFormData)}>
      {error && (
        <ErrorMessage
          error={error}
          passwordResetPath={getPath("passwordReset")}
        />
      )}
      <Section>
        <Title type="semiBold" size="large">
          Sign in to your account
        </Title>
        <Text label="Email" {...register("email")} />
      </Section>
      <Button
        onClick={handleSubmit(handleFormData)}
        size="medium"
        styleVariant="primary"
        disabled={disabled}
        stretch
      >
        Continue
      </Button>
    </Sections>
  );
};
