import React from "react";
import { buildFullLink } from "common/routing";
import { arrayHasExactlyOneItem } from "common/utils/arrays";
import { getAppPrefix } from "common/utils/url";
import { GetUserAccountsQuery } from "../../generated/graphql";
import { spacing } from "../../stitches.config";
import { parsePrevLocationParam } from "../../utils/params";
import { Body } from "../Common/Typography";
import { AccountLogo, AccountTitle } from "../SideNav/__styles__/SideNav";
import NavigationCard from "./CommonLoginComponents/NavigationCard";

export const ProvideCredentialsNavigationCard = ({
  accounts,
  currentAccount,
  email,
}: {
  accounts: NonNullable<GetUserAccountsQuery["getUserAccountsByEmail"]>;
  currentAccount: NonNullable<
    GetUserAccountsQuery["getUserAccountsByEmail"]
  >[0];
  email: string;
}) => {
  const prev = parsePrevLocationParam("provideCredentials");
  const prefix = getAppPrefix();

  const backUrl = buildFullLink("login", {
    params: {},
    queryParams: {
      prev,
      //We omit the email when there is only one account because otherwise we would immediately redirect them back to this Provide Credentials page
      ...(arrayHasExactlyOneItem(accounts) ? {} : { email }),
    },
    prefix,
  });

  if (arrayHasExactlyOneItem(accounts)) {
    return (
      <NavigationCard
        onClick={() => {
          window.location.href = backUrl;
        }}
      >
        {email}
      </NavigationCard>
    );
  }

  return (
    <NavigationCard
      onClick={() => {
        window.location.href = backUrl;
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: spacing.s.value,
        }}
      >
        {currentAccount.logoUrl ? (
          <AccountLogo size={32} src={currentAccount.logoUrl} />
        ) : (
          <AccountTitle size={32}>{currentAccount.name[0]}</AccountTitle>
        )}
        <Body size="default" type="emphasis">
          {currentAccount.name}
        </Body>
      </div>
    </NavigationCard>
  );
};
