import React from "react";
import { isEmpty } from "lodash";
import { QueryDescription } from "common/utils/queryBuilder";
import {
  DataSourceComputations,
  useDataSourceRecomputationsAffectingQueryQuery,
} from "../../../generated/graphql";
import { spacing } from "../../../stitches.config";
import { BANNER_TYPES } from "../__styles__/Banner";
import { FlexRow } from "../__styles__/Layout";
import { Emphasis } from "../__styles__/Typography";
import Banner from "../Banner";

const POLL_INTERVAL_IN_MILLISECONDS = 10 * 1000;

export interface DataSyncBannerProps {
  query?: QueryDescription;
  dataSourceComputationsAffectingQuery?: Array<DataSourceComputations>;
}

export const DataSyncBanner = ({
  query,
  dataSourceComputationsAffectingQuery = [],
}: DataSyncBannerProps) => {
  const { data } = useDataSourceRecomputationsAffectingQueryQuery({
    variables: { query },
    pollInterval: POLL_INTERVAL_IN_MILLISECONDS,
    onError: error => {
      console.error("Error fetching data source recomputations", error);
    },
  });

  const computations = data?.dataSourceComputationsAffectingQuery;

  if (
    !isEmpty(computations) ||
    !isEmpty(dataSourceComputationsAffectingQuery)
  ) {
    return (
      <FlexRow
        style={{
          padding: `0px ${spacing.l} ${spacing.m} ${spacing.l}`,
        }}
      >
        <Banner
          items={[
            <span>
              <Emphasis>Data sync in progress.</Emphasis> Some values in the
              table may not match property panel information until the update is
              complete.
            </span>,
          ]}
          type={BANNER_TYPES.INFO}
          loading
        />
      </FlexRow>
    );
  }

  return null;
};
