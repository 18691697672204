import React from "react";
import { toLower } from "lodash";
import {
  ABOVE_OR_BELOW_OPTIONS,
  BUILDING_ELEVATION_SOURCE_OPTIONS,
  PERMIT_ISSUE_TYPE_OPTIONS,
  UNIT_OF_MEASUREMENT_OPTIONS,
  YES_NO_NA_OPTIONS,
  YES_NO_OPTIONS,
  YES_NO_UNKNOWN_OPTIONS,
} from "../../../DocumentUploads/Forms/ElevationCertificates/ExtractedFields/utils";
import {
  Attachments,
  Callout,
  DateInput,
  Fieldset,
  InputRow,
  Note,
  NullableRadioInput,
  RadioInput,
  SectionTitle,
  TextInput,
} from "./Common";

export default function FullDataExtractionGroup8({ get, set, errors }) {
  return (
    <>
      <SectionTitle>Section A — Property Information</SectionTitle>

      <TextInput
        name="buildingOwnerName"
        value={get("buildingOwnerName")}
        onChange={set("buildingOwnerName")}
        error={errors.buildingOwnerName}
        label="A1. Building Owner's Name"
      />

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        error={errors.address}
        label="A2. Building Street Address"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          error={errors.address}
          label="City"
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          error={errors.address}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          error={errors.address}
          label="Zipcode"
        />
      </InputRow>

      <Note>If Zipcode is not present, please leave blank.</Note>

      <TextInput
        name="propertyDescription"
        value={get("propertyDescription")}
        onChange={set("propertyDescription")}
        error={errors.propertyDescription}
        label="A3. Property Description"
      />

      <Fieldset legend="A4. Building Use">
        <RadioInput
          name="buildingUse"
          value={get("buildingUse")}
          valueFormatter={toLower}
          onChange={set("buildingUse")}
          error={errors.buildingUse}
          options={[
            { value: "residential", text: "Residential" },
            { value: "non_residential", text: "Non-Residential" },
            { value: "addition", text: "Addition" },
            { value: "accessory", text: "Accessory" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <Fieldset legend="A5. Latitude/Longitude">
        <InputRow>
          <TextInput
            name="latitude"
            value={get("latitude")}
            onChange={set("latitude")}
            error={errors.latitude}
            label="Latitude"
          />

          <TextInput
            name="longitude"
            value={get("longitude")}
            onChange={set("longitude")}
            error={errors.longitude}
            label="Longitude"
          />
        </InputRow>
      </Fieldset>

      <TextInput
        name="buildingDiagramNumber"
        value={get("buildingDiagramNumber")}
        onChange={set("buildingDiagramNumber")}
        error={errors.buildingDiagramNumber}
        label="A7. Building Diagram Number"
      />

      <Fieldset legend="A8. For a building with a crawlspace or enclosure(s):">
        <TextInput
          name="crawlspaceSqft"
          value={get("crawlspaceSqft")}
          onChange={set("crawlspaceSqft")}
          error={errors.crawlspaceSqft}
          label="A8. a) Square footage of crawlspace or enclosure(s)"
        />

        <Fieldset legend="A8. b) Is there at least one permanent flood opening on two different sides of each enclosed area?">
          <RadioInput
            name="crawlspaceOneFloodOpeningTwoSides"
            value={get("crawlspaceOneFloodOpeningTwoSides")}
            onChange={set("crawlspaceOneFloodOpeningTwoSides")}
            error={errors.crawlspaceOneFloodOpeningTwoSides}
            options={YES_NO_NA_OPTIONS}
          />

          <Note>
            If this question does not appear on the document, please leave
            blank.
          </Note>
        </Fieldset>

        <Fieldset legend="A8. c) Enter number of permanent flood openings in the crawlspace or enclosures within 1.0 foot above adjacent grade:">
          <TextInput
            name="numNonEngineeredCrawlspaceOpenings"
            value={get("numNonEngineeredCrawlspaceOpenings")}
            onChange={set("numNonEngineeredCrawlspaceOpenings")}
            error={errors.numNonEngineeredCrawlspaceOpenings}
            label="Non-engineered flood openings:"
          />
          <TextInput
            name="numEngineeredCrawlspaceOpenings"
            value={get("numEngineeredCrawlspaceOpenings")}
            onChange={set("numEngineeredCrawlspaceOpenings")}
            error={errors.numEngineeredCrawlspaceOpenings}
            label="Engineered flood openings:"
          />
        </Fieldset>
        <TextInput
          name="netAreaNonEngineeredCrawlspaceOpenings"
          value={get("netAreaNonEngineeredCrawlspaceOpenings")}
          onChange={set("netAreaNonEngineeredCrawlspaceOpenings")}
          error={errors.netAreaNonEngineeredCrawlspaceOpenings}
          label="A8. d) Total net open area of non-engineered flood openings in A8.c:"
        />
        <TextInput
          name="ratedAreaOfEngineeredCrawlspaceOpenings"
          value={get("ratedAreaOfEngineeredCrawlspaceOpenings")}
          onChange={set("ratedAreaOfEngineeredCrawlspaceOpenings")}
          error={errors.ratedAreaOfEngineeredCrawlspaceOpenings}
          label="A8. e) Total rated area of engineered flood openings in A8.c:"
        />
        <TextInput
          name="netAreaOfCrawlspaceFloodOpenings"
          value={get("netAreaOfCrawlspaceFloodOpenings")}
          onChange={set("netAreaOfCrawlspaceFloodOpenings")}
          error={errors.netAreaOfCrawlspaceFloodOpenings}
          label="A8. f) Sum of A8.d and A8.e rated area:"
        />
      </Fieldset>
      <Fieldset legend="A9. For a building with an attached garage:">
        <TextInput
          name="garageSqft"
          value={get("garageSqft")}
          onChange={set("garageSqft")}
          error={errors.garageSqft}
          label="A9. a) Square footage of attached garage"
        />
        <Fieldset legend="A9. b) Is there at least one permanent flood opening on two different sides of the attached garage?">
          <RadioInput
            name="garageOneFloodOpeningTwoSides"
            value={get("garageOneFloodOpeningTwoSides")}
            onChange={set("garageOneFloodOpeningTwoSides")}
            error={errors.garageOneFloodOpeningTwoSides}
            options={YES_NO_NA_OPTIONS}
          />
          <Note>
            If this question does not appear on the document, please leave
            blank.
          </Note>
        </Fieldset>

        <Fieldset legend="A9. c) Enter number of permanent flood openings in the attached garage:">
          <TextInput
            name="numNonEngineeredGarageOpenings"
            value={get("numNonEngineeredGarageOpenings")}
            onChange={set("numNonEngineeredGarageOpenings")}
            error={errors.numNonEngineeredGarageOpenings}
            label="Non-engineered flood openings:"
          />
          <TextInput
            name="numEngineeredGarageOpenings"
            value={get("numEngineeredGarageOpenings")}
            onChange={set("numEngineeredGarageOpenings")}
            error={errors.numEngineeredGarageOpenings}
            label="Engineered flood openings:"
          />
        </Fieldset>
        <TextInput
          name="netAreaNonEngineeredGarageOpenings"
          value={get("netAreaNonEngineeredGarageOpenings")}
          onChange={set("netAreaNonEngineeredGarageOpenings")}
          error={errors.netAreaNonEngineeredGarageOpenings}
          label="A9. d) Total net open area of non-engineered flood openings in A9.c"
        />
        <TextInput
          name="ratedAreaOfEngineeredGarageOpenings"
          value={get("ratedAreaOfEngineeredGarageOpenings")}
          onChange={set("ratedAreaOfEngineeredGarageOpenings")}
          error={errors.ratedAreaOfEngineeredGarageOpenings}
          label="A9. e) Total rated area of engineered flood openings in A9.c:"
        />
        <TextInput
          name="netAreaOfGarageFloodOpenings"
          value={get("netAreaOfGarageFloodOpenings")}
          onChange={set("netAreaOfGarageFloodOpenings")}
          error={errors.netAreaOfGarageFloodOpenings}
          label="A9. f) Sum of A9.d and A9.e rated area:"
        />
      </Fieldset>
      <SectionTitle>
        Section B - Flood Insurance Rate Map (FIRM) Information
      </SectionTitle>

      <TextInput
        name="nfipCommunityName"
        value={get("nfipCommunityName")}
        onChange={set("nfipCommunityName")}
        error={errors.nfipCommunityName}
        label="B1. a) NFIP Community Name"
      />

      <TextInput
        name="nfipCommunityNumber"
        value={get("nfipCommunityNumber")}
        onChange={set("nfipCommunityNumber")}
        error={errors.nfipCommunityNumber}
        label="B1. b) NFIP Community Identification Number"
      />

      <TextInput
        name="nfipCountyName"
        value={get("nfipCountyName")}
        onChange={set("nfipCountyName")}
        error={errors.nfipCountyName}
        label="B2. County Name"
      />

      <TextInput
        name="nfipState"
        value={get("nfipState")}
        onChange={set("nfipState")}
        error={errors.nfipState}
        label="B3. State"
      />

      <TextInput
        name="firmMapNumber"
        value={get("firmMapNumber")}
        onChange={set("firmMapNumber")}
        error={errors.firmMapNumber}
        label="B4. Map/Panel Number"
      />
      <Note>
        Note: If there are two separate numbers, please transcribe the entire
        field as-is.
      </Note>

      <TextInput
        name="firmSuffix"
        value={get("firmSuffix")}
        onChange={set("firmSuffix")}
        error={errors.firmSuffix}
        label="B5. Suffix"
      />

      <DateInput
        name="firmIndexDate"
        value={get("firmIndexDate")}
        onChange={set("firmIndexDate")}
        error={errors.firmIndexDate}
        label="B6. FIRM Index Date"
      />

      <DateInput
        name="firmPanelEffectiveDate"
        value={get("firmPanelEffectiveDate")}
        onChange={set("firmPanelEffectiveDate")}
        error={errors.firmPanelEffectiveDate}
        label="B7. FIRM Panel Effective/Revised Date"
      />

      <TextInput
        name="floodZone"
        value={get("floodZone")}
        onChange={set("floodZone")}
        error={errors.floodZone}
        label="B8. Flood Zone(s)"
      />
      <Note>
        Note: If multiple Flood Zones are present, please enter them separated
        by commas.
      </Note>

      <TextInput
        name="baseFloodElevation"
        value={get("baseFloodElevation")}
        onChange={set("baseFloodElevation")}
        error={errors.baseFloodElevation}
        label="B9. Base Flood Elevation(s)"
      />
      <Note>
        Note: if there is a unit of measurement (example: feet) please do not
        include it. However, do include any N/A values. If multiple elevations
        are present, please enter them separated by commas.
      </Note>

      <Fieldset legend="B10. Indicate the source of the Base Flood Elevation (BFE) data">
        <RadioInput
          name="baseFloodElevationSource"
          valueFormatter={toLower}
          value={get("baseFloodElevationSource")}
          onChange={set("baseFloodElevationSource")}
          error={errors.baseFloodElevationSource}
          options={[
            { value: "fis_profile", text: "FIS Profile" },
            { value: "firm", text: "FIRM" },
            { value: "community_determined", text: "Community Determined" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <Fieldset legend="B11. Indicate the elevation datum used for the BFE">
        <RadioInput
          name="baseFloodElevationDatum"
          value={get("baseFloodElevationDatum")}
          valueFormatter={toLower}
          onChange={set("baseFloodElevationDatum")}
          error={errors.baseFloodElevationDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
            { value: "navd_1988", text: "NAVD / NAVD 1988" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <Fieldset legend="B12. Is the building located in a Coastal Barrier Resources System (CBRS) area or Otherwise Protected Area (OPA)?">
        <NullableRadioInput
          name="isBuildingLocatedInCbrsOrOpa"
          value={get("isBuildingLocatedInCbrsOrOpa")}
          onChange={set("isBuildingLocatedInCbrsOrOpa")}
          error={errors.isBuildingLocatedInCbrsOrOpa}
        />

        <DateInput
          label="Designation Date"
          name="buildingLocatedInCbrsOrOpaDesignationDate"
          value={get("buildingLocatedInCbrsOrOpaDesignationDate")}
          onChange={set("buildingLocatedInCbrsOrOpaDesignationDate")}
          error={errors.buildingLocatedInCbrsOrOpaDesignationDate}
        />

        <Fieldset legend="Indicate Coastal Barrier Resources System (CBRS) area or Otherwise Protected Area (OPA)">
          <RadioInput
            name="buildingLocatedInCbrsOrOpa"
            value={get("buildingLocatedInCbrsOrOpa")}
            onChange={set("buildingLocatedInCbrsOrOpa")}
            error={errors.buildingLocatedInCbrsOrOpa}
            options={[
              { value: "cbrs", text: "CBRS" },
              { value: "opa", text: "OPA" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="B13. Is the building located seaward of the Limit of Moderate Wave Action (LiMWA)?">
        <NullableRadioInput
          name="buildingLocatedSeawardOfLimwa"
          value={get("buildingLocatedSeawardOfLimwa")}
          onChange={set("buildingLocatedSeawardOfLimwa")}
          error={errors.buildingLocatedSeawardOfLimwa}
        />
      </Fieldset>

      <SectionTitle>Section C - Building Elevation Information</SectionTitle>

      <Fieldset legend="C1. Building Elevations are based on">
        <RadioInput
          name="buildingElevationSource"
          value={get("buildingElevationSource")}
          valueFormatter={toLower}
          onChange={set("buildingElevationSource")}
          error={errors.buildingElevationSource}
          options={[
            { value: "construction_drawings", text: "Construction Drawings" },
            {
              value: "building_under_construction",
              text: "Building Under Construction",
            },
            { value: "finished_construction", text: "Finished Construction" },
          ]}
        />
      </Fieldset>

      <TextInput
        name="benchmarkUtilized"
        value={get("benchmarkUtilized")}
        onChange={set("benchmarkUtilized")}
        error={errors.benchmarkUtilized}
        label="C2. Benchmark utilized"
      />

      <Fieldset legend="Vertical Datum">
        <RadioInput
          name="verticalDatum"
          value={get("verticalDatum")}
          valueFormatter={toLower}
          onChange={set("verticalDatum")}
          error={errors.verticalDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
            { value: "navd_1988", text: "NAVD / NAVD 1988" },
            { value: "other", text: "Other" },
          ]}
        />
        <Note>If blank, please skip.</Note>
      </Fieldset>

      <Fieldset legend="Elevation Datum">
        <RadioInput
          name="elevationDatum"
          value={get("elevationDatum")}
          valueFormatter={toLower}
          onChange={set("elevationDatum")}
          error={errors.elevationDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
            { value: "navd_1988", text: "NAVD / NAVD 1988" },
            { value: "other", text: "Other" },
          ]}
        />
        <Note>If blank, please skip.</Note>
      </Fieldset>

      <Fieldset legend="Conversion factor used?">
        <NullableRadioInput
          name="conversionFactorUsed"
          value={get("conversionFactorUsed")}
          onChange={set("conversionFactorUsed")}
          error={errors.conversionFactorUsed}
        />
      </Fieldset>

      <Callout>
        For the following questions, please provide the answer to the tenth of a
        foot (example: 10.2). <br /> Also, if there is a unit of measurement
        (example: feet) please do not include it. However, do include any N/A
        values.
      </Callout>

      <Fieldset legend="C2. a) Top of bottom floor">
        <TextInput
          name="topOfBottomFloor"
          value={get("buildingElevationInfo.topOfBottomFloor.value")}
          onChange={set("buildingElevationInfo.topOfBottomFloor.value")}
          error={errors["buildingElevationInfo.topOfBottomFloor.value"]}
        />
        <Fieldset legend="Units">
          <RadioInput
            name="topOfBottomFloorUnits"
            label="Units"
            value={get("buildingElevationInfo.topOfBottomFloor.units")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.topOfBottomFloor.units")}
            error={errors["buildingElevationInfo.topOfBottomFloor.units"]}
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. b) Top of next higher floor">
        <TextInput
          name="topOfNextHigherFloor"
          value={get("buildingElevationInfo.topOfNextHigherFloor.value")}
          onChange={set("buildingElevationInfo.topOfNextHigherFloor.value")}
          error={errors["buildingElevationInfo.topOfNextHigherFloor.value"]}
        />
        <Fieldset legend="Units">
          <RadioInput
            name="topOfNextHigherFloorUnits"
            label="Units"
            value={get("buildingElevationInfo.topOfNextHigherFloor.units")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.topOfNextHigherFloor.units")}
            error={errors["buildingElevationInfo.topOfNextHigherFloor.units"]}
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. c) Bottom of the lowest horizontal structural member">
        <TextInput
          name="bottomOfLowestHorizontalStructuralMember"
          value={get(
            "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
          )}
          onChange={set(
            "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
          )}
          error={
            errors[
              "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="bottomOfLowestHorizontalStructuralMemberUnits"
            label="Units"
            value={get(
              "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.units"
            )}
            valueFormatter={toLower}
            onChange={set(
              "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.units"
            )}
            error={
              errors[
                "buildingElevationInfo.bottomOfLowestHorizontalStructuralMember.units"
              ]
            }
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. d) Attached garage">
        <TextInput
          name="attachedGarage"
          value={get("buildingElevationInfo.attachedGarage.value")}
          onChange={set("buildingElevationInfo.attachedGarage.value")}
          error={errors["buildingElevationInfo.attachedGarage.value"]}
        />
        <Fieldset legend="Units">
          <RadioInput
            name="attachedGarageUnits"
            label="Units"
            value={get("buildingElevationInfo.attachedGarage.units")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.attachedGarage.units")}
            error={errors["buildingElevationInfo.attachedGarage.units"]}
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. e) Lowest elevation of machinery or equipment">
        <TextInput
          name="lowestElevationOfMachineryOrEquipment"
          value={get(
            "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value"
          )}
          onChange={set(
            "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value"
          )}
          error={
            errors[
              "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="lowestElevationOfMachineryOrEquipmentUnits"
            label="Units"
            value={get(
              "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.units"
            )}
            valueFormatter={toLower}
            onChange={set(
              "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.units"
            )}
            error={
              errors[
                "buildingElevationInfo.lowestElevationOfMachineryOrEquipment.units"
              ]
            }
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. f) Lowest adjacent grade">
        <Fieldset legend="Type">
          <RadioInput
            name="lowestAdjacentGradeType"
            value={get("buildingElevationInfo.lowestAdjacentGrade.type")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.lowestAdjacentGrade.type")}
            error={errors["buildingElevationInfo.lowestAdjacentGrade.type"]}
            options={[
              { value: "natural", text: "Natural" },
              { value: "finished", text: "Finished" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
        <TextInput
          name="lowestAdjacentGrade"
          value={get("buildingElevationInfo.lowestAdjacentGrade.value")}
          onChange={set("buildingElevationInfo.lowestAdjacentGrade.value")}
          error={errors["buildingElevationInfo.lowestAdjacentGrade.value"]}
        />
        <Fieldset legend="Units">
          <RadioInput
            name="lowestAdjacentGradeUnits"
            label="Units"
            value={get("buildingElevationInfo.lowestAdjacentGrade.units")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.lowestAdjacentGrade.units")}
            error={errors["buildingElevationInfo.lowestAdjacentGrade.units"]}
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. g) Highest adjacent grade">
        <Fieldset legend="Type">
          <RadioInput
            name="highestAdjacentGradeType"
            value={get("buildingElevationInfo.highestAdjacentGrade.type")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.highestAdjacentGrade.type")}
            error={errors["buildingElevationInfo.highestAdjacentGrade.type"]}
            options={[
              { value: "natural", text: "Natural" },
              { value: "finished", text: "Finished" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
        <TextInput
          name="highestAdjacentGrade"
          value={get("buildingElevationInfo.highestAdjacentGrade.value")}
          onChange={set("buildingElevationInfo.highestAdjacentGrade.value")}
          error={errors["buildingElevationInfo.highestAdjacentGrade.value"]}
        />
        <Fieldset legend="Units">
          <RadioInput
            name="highestAdjacentGradeUnits"
            label="Units"
            value={get("buildingElevationInfo.highestAdjacentGrade.units")}
            valueFormatter={toLower}
            onChange={set("buildingElevationInfo.highestAdjacentGrade.units")}
            error={errors["buildingElevationInfo.highestAdjacentGrade.units"]}
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="C2. h) Lowest adjacent grade at lowest elevation of deck or stairs">
        <TextInput
          name="lowestAdjacentGradeAtLowestElevationOfDeckOrStairs"
          value={get(
            "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value"
          )}
          onChange={set(
            "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value"
          )}
          error={
            errors[
              "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="lowestAdjacentGradeAtLowestElevationOfDeckOrStairsUnits"
            label="Units"
            value={get(
              "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.units"
            )}
            valueFormatter={toLower}
            onChange={set(
              "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.units"
            )}
            error={
              errors[
                "buildingElevationInfo.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs.units"
              ]
            }
            options={[
              { value: "feet", text: "Feet" },
              { value: "meters", text: "Meters" },
            ]}
          />
          <Note>If blank, please skip.</Note>
        </Fieldset>
      </Fieldset>

      <SectionTitle>
        Section D - Surveyor, Engineer, or Architect Certification
      </SectionTitle>

      <Fieldset legend="Were lat and long in Section A provided by a surveyor?">
        <NullableRadioInput
          name="coordinatesProvidedBySurveyor"
          value={get("coordinatesProvidedBySurveyor")}
          onChange={set("coordinatesProvidedBySurveyor")}
          error={errors.coordinatesProvidedBySurveyor}
        />
      </Fieldset>

      <TextInput
        name="certifierName"
        value={get("certifierInfo.name")}
        onChange={set("certifierInfo.name")}
        error={errors["certifierInfo.name"]}
        label="Certifier's Name"
      />

      <TextInput
        name="certifierLicenseNumber"
        value={get("certifierInfo.licenseNumber")}
        onChange={set("certifierInfo.licenseNumber")}
        error={errors["certifierInfo.licenseNumber"]}
        label="License Number"
      />

      <TextInput
        name="certifierTelephone"
        value={get("certifierInfo.telephone")}
        onChange={set("certifierInfo.telephone")}
        error={errors["certifierInfo.telephone"]}
        label="Telephone"
      />

      <TextInput
        name="certifierEmail"
        value={get("certifierInfo.email")}
        onChange={set("certifierInfo.email")}
        error={errors["certifierInfo.email"]}
        label="Email"
      />

      <DateInput
        label="Date"
        name="issuedAt"
        value={get("issuedAt")}
        onChange={set("issuedAt")}
        error={errors.issuedAt}
      />

      <TextInput
        name="comments"
        value={get("comments")}
        onChange={set("comments")}
        error={errors.elevationDatumComments}
        label="Comments"
      />
      <Note>
        If this question does not appear on the document, please leave blank.
      </Note>

      <SectionTitle>Section E - Building Measurement Information</SectionTitle>

      <Fieldset legend="Building measurements are based on">
        <RadioInput
          name="buildingMeasurementInfo.measurementsBasedOn"
          value={get("buildingMeasurementInfo.measurementsBasedOn")}
          onChange={set("buildingMeasurementInfo.measurementsBasedOn")}
          error={errors["buildingMeasurementInfo.measurementsBasedOn"]}
          options={BUILDING_ELEVATION_SOURCE_OPTIONS}
        />
      </Fieldset>

      <Fieldset legend="E1. a) Top of bottom floor">
        <TextInput
          name="buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value"
          value={get(
            "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value"
          )}
          onChange={set(
            "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value"
          )}
          error={
            errors[
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units"
            value={get(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>
        <Fieldset legend="Above or below the HAG">
          <RadioInput
            label="Above or below"
            name="buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow"
            value={get(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowHag.aboveOrBelow"
              ]
            }
            options={ABOVE_OR_BELOW_OPTIONS}
          />
        </Fieldset>
      </Fieldset>

      <Fieldset legend="E1. b) Top of bottom floor">
        <TextInput
          name="buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value"
          value={get(
            "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value"
          )}
          onChange={set(
            "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value"
          )}
          error={
            errors[
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units"
            value={get(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>
        <Fieldset legend="Above or below the LAG">
          <RadioInput
            name="buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow"
            value={get(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.topOfBottomFloorAboveOrBelowLag.aboveOrBelow"
              ]
            }
            options={ABOVE_OR_BELOW_OPTIONS}
          />
        </Fieldset>
      </Fieldset>

      <Fieldset legend="E2. Next higher floor">
        <TextInput
          name="buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value"
          value={get(
            "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value"
          )}
          onChange={set(
            "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value"
          )}
          error={
            errors[
              "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.value"
            ]
          }
        />

        <Fieldset legend="Units">
          <RadioInput
            name="buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units"
            value={get(
              "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>

        <Fieldset legend="Above or below the HAG">
          <RadioInput
            name="buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow"
            value={get(
              "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.nextHigherFloorAboveOrBelowHag.aboveOrBelow"
              ]
            }
            options={ABOVE_OR_BELOW_OPTIONS}
          />
        </Fieldset>
      </Fieldset>

      <Fieldset legend="E3. Attached garage (top of slab)">
        <TextInput
          name="buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value"
          value={get(
            "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value"
          )}
          onChange={set(
            "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value"
          )}
          error={
            errors[
              "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units"
            value={get(
              "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>
        <Fieldset legend="Above or below the HAG">
          <RadioInput
            name="buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow"
            value={get(
              "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.attachedGarageTopOfSlabAboveOrBelowHag.aboveOrBelow"
              ]
            }
            options={ABOVE_OR_BELOW_OPTIONS}
          />
        </Fieldset>
      </Fieldset>

      <Fieldset legend="E4. Top of machinery platform and/or equipment">
        <TextInput
          name="buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value"
          value={get(
            "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value"
          )}
          onChange={set(
            "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value"
          )}
          error={
            errors[
              "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.value"
            ]
          }
        />
        <Fieldset legend="Units">
          <RadioInput
            name="buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units"
            value={get(
              "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>
        <Fieldset legend="Above or below the HAG">
          <RadioInput
            name="buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow"
            value={get(
              "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow"
            )}
            onChange={set(
              "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow"
            )}
            error={
              errors[
                "buildingMeasurementInfo.offsets.topOfMachineryPlatformAboveOrBelowHag.aboveOrBelow"
              ]
            }
            options={ABOVE_OR_BELOW_OPTIONS}
          />
        </Fieldset>
      </Fieldset>

      <Fieldset legend="E5. Top of bottom floor elevated in accordance with ordinance?">
        <RadioInput
          name="buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance"
          value={get(
            "buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance"
          )}
          onChange={set(
            "buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance"
          )}
          error={
            errors[
              "buildingMeasurementInfo.topOfBottomFloorElevatedPerOrdinance"
            ]
          }
          options={YES_NO_UNKNOWN_OPTIONS}
        />
      </Fieldset>

      <SectionTitle>Section G - Community Information</SectionTitle>

      <Fieldset legend="G1. Section C taken from other documentation">
        <RadioInput
          name="communityInfo.sectionCTakenFromOtherAuthorizedSource"
          value={get("communityInfo.sectionCTakenFromOtherAuthorizedSource")}
          onChange={set("communityInfo.sectionCTakenFromOtherAuthorizedSource")}
          error={errors["communityInfo.sectionCTakenFromOtherAuthorizedSource"]}
          options={YES_NO_OPTIONS}
        />
      </Fieldset>

      <Fieldset legend="G2. a) Section E completed by local official">
        <RadioInput
          name="communityInfo.sectionECompletedByLocalOfficial"
          value={get("communityInfo.sectionECompletedByLocalOfficial")}
          onChange={set("communityInfo.sectionECompletedByLocalOfficial")}
          error={errors["communityInfo.sectionECompletedByLocalOfficial"]}
          options={YES_NO_OPTIONS}
        />
      </Fieldset>

      <Fieldset legend="G2. b) Section H completed by local official">
        <RadioInput
          name="communityInfo.sectionHCompletedByLocalOfficial"
          value={get("communityInfo.sectionHCompletedByLocalOfficial")}
          onChange={set("communityInfo.sectionHCompletedByLocalOfficial")}
          error={errors["communityInfo.sectionHCompletedByLocalOfficial"]}
          options={YES_NO_OPTIONS}
        />
      </Fieldset>

      <Fieldset legend="G3. Section G comments provided">
        <RadioInput
          name="communityInfo.sectionGCommentsProvidedByLocalOfficial"
          value={get("communityInfo.sectionGCommentsProvidedByLocalOfficial")}
          onChange={set(
            "communityInfo.sectionGCommentsProvidedByLocalOfficial"
          )}
          error={
            errors["communityInfo.sectionGCommentsProvidedByLocalOfficial"]
          }
          options={YES_NO_OPTIONS}
        />
      </Fieldset>

      <Fieldset legend="G4. Information provided for floodplain management purposes">
        <RadioInput
          name="communityInfo.communityInfoProvidedForFloodplainManagementPurposes"
          value={get(
            "communityInfo.communityInfoProvidedForFloodplainManagementPurposes"
          )}
          onChange={set(
            "communityInfo.communityInfoProvidedForFloodplainManagementPurposes"
          )}
          error={
            errors[
              "communityInfo.communityInfoProvidedForFloodplainManagementPurposes"
            ]
          }
          options={YES_NO_OPTIONS}
        />
      </Fieldset>

      <TextInput
        label="G5. Permit number"
        name="communityInfo.permitNumber"
        value={get("communityInfo.permitNumber")}
        onChange={set("communityInfo.permitNumber")}
        error={errors["communityInfo.permitNumber"]}
      />

      <DateInput
        label="G6. Date permit issued"
        name="communityInfo.permitDateIssued"
        value={get("communityInfo.permitDateIssued")}
        onChange={set("communityInfo.permitDateIssued")}
        error={errors["communityInfo.permitDateIssued"]}
      />

      <DateInput
        label="G7. Date certificate of compliance/occupancy issued"
        name="communityInfo.certificateOfComplianceOrOccupancyDateIssued"
        value={get(
          "communityInfo.certificateOfComplianceOrOccupancyDateIssued"
        )}
        onChange={set(
          "communityInfo.certificateOfComplianceOrOccupancyDateIssued"
        )}
        error={
          errors["communityInfo.certificateOfComplianceOrOccupancyDateIssued"]
        }
      />

      <Fieldset legend="G8. Permit issued for">
        <RadioInput
          name="communityInfo.permitIssuedForNewConstructionOrSI"
          value={get("communityInfo.permitIssuedForNewConstructionOrSI")}
          onChange={set("communityInfo.permitIssuedForNewConstructionOrSI")}
          error={errors["communityInfo.permitIssuedForNewConstructionOrSI"]}
          options={PERMIT_ISSUE_TYPE_OPTIONS}
        />
      </Fieldset>

      <Fieldset legend="G9. a) Elevation of as-built lowest floor">
        <TextInput
          name="communityInfo.elevations.asBuiltLowestFloor.value"
          value={get("communityInfo.elevations.asBuiltLowestFloor.value")}
          onChange={set("communityInfo.elevations.asBuiltLowestFloor.value")}
          error={errors["communityInfo.elevations.asBuiltLowestFloor.value"]}
        />

        <Fieldset legend="Units">
          <RadioInput
            name="communityInfo.elevations.asBuiltLowestFloor.units"
            value={get("communityInfo.elevations.asBuiltLowestFloor.units")}
            onChange={set("communityInfo.elevations.asBuiltLowestFloor.units")}
            error={errors["communityInfo.elevations.asBuiltLowestFloor.units"]}
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>

        <TextInput
          label="Datum"
          name="communityInfo.elevations.asBuiltLowestFloor.datum"
          value={get("communityInfo.elevations.asBuiltLowestFloor.datum")}
          onChange={set("communityInfo.elevations.asBuiltLowestFloor.datum")}
          error={errors["communityInfo.elevations.asBuiltLowestFloor.datum"]}
        />
      </Fieldset>

      <Fieldset legend="G9. b) Elevation of lowest horizontal structure">
        <TextInput
          name="communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value"
          value={get(
            "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value"
          )}
          onChange={set(
            "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value"
          )}
          error={
            errors[
              "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.value"
            ]
          }
        />

        <Fieldset legend="Units">
          <RadioInput
            name="communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units"
            value={get(
              "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units"
            )}
            onChange={set(
              "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units"
            )}
            error={
              errors[
                "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>

        <TextInput
          label="Datum"
          name="communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum"
          value={get(
            "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum"
          )}
          onChange={set(
            "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum"
          )}
          error={
            errors[
              "communityInfo.elevations.bottomOfAsBuiltLowestHorizontalStructuralMember.datum"
            ]
          }
        />
      </Fieldset>

      <Fieldset legend="G10. a) BFE at building site">
        <TextInput
          name="communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value"
          value={get(
            "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value"
          )}
          onChange={set(
            "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value"
          )}
          error={
            errors[
              "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.value"
            ]
          }
        />

        <Fieldset legend="Units">
          <RadioInput
            name="communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units"
            value={get(
              "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units"
            )}
            onChange={set(
              "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units"
            )}
            error={
              errors[
                "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>

        <TextInput
          label="Datum"
          name="communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum"
          value={get(
            "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum"
          )}
          onChange={set(
            "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum"
          )}
          error={
            errors[
              "communityInfo.elevations.bfeOrDepthOfFloodingAtBuildingSite.datum"
            ]
          }
        />
      </Fieldset>

      <Fieldset legend="G10. b) Community minimum elevation requirement">
        <TextInput
          name="communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value"
          value={get(
            "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value"
          )}
          onChange={set(
            "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value"
          )}
          error={
            errors[
              "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.value"
            ]
          }
        />

        <Fieldset legend="Units">
          <RadioInput
            name="communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units"
            value={get(
              "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units"
            )}
            onChange={set(
              "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units"
            )}
            error={
              errors[
                "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.units"
              ]
            }
            options={UNIT_OF_MEASUREMENT_OPTIONS}
          />
        </Fieldset>

        <TextInput
          label="Datum"
          name="communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum"
          value={get(
            "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum"
          )}
          onChange={set(
            "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum"
          )}
          error={
            errors[
              "communityInfo.elevations.communityMinimumRequirementOfLowestFloorOrLowestHorizontalStructuralMember.datum"
            ]
          }
        />
      </Fieldset>

      <Fieldset legend="G.11 Variance issued">
        <RadioInput
          name="communityInfo.varianceIssued"
          value={get("communityInfo.varianceIssued")}
          onChange={set("communityInfo.varianceIssued")}
          error={errors["communityInfo.varianceIssued"]}
          options={YES_NO_OPTIONS}
        />
      </Fieldset>

      <TextInput
        label="Local Official's Name"
        name="communityInfo.localOfficialInfo.name"
        value={get("communityInfo.localOfficialInfo.name")}
        onChange={set("communityInfo.localOfficialInfo.name")}
        error={errors["communityInfo.localOfficialInfo.name"]}
      />

      <TextInput
        label="Telephone"
        name="communityInfo.localOfficialInfo.telephone"
        value={get("communityInfo.localOfficialInfo.telephone")}
        onChange={set("communityInfo.localOfficialInfo.telephone")}
        error={errors["communityInfo.localOfficialInfo.telephone"]}
      />

      <TextInput
        label="Email"
        name="communityInfo.localOfficialInfo.email"
        value={get("communityInfo.localOfficialInfo.email")}
        onChange={set("communityInfo.localOfficialInfo.email")}
        error={errors["communityInfo.localOfficialInfo.email"]}
      />

      <DateInput
        label="Date Signed"
        name="communityInfo.localOfficialInfo.dateSigned"
        value={get("communityInfo.localOfficialInfo.dateSigned")}
        onChange={set("communityInfo.localOfficialInfo.dateSigned")}
        error={errors["communityInfo.localOfficialInfo.dateSigned"]}
      />

      <TextInput
        label="Section G Comments"
        name="communityInfo.localOfficialInfo.comments"
        value={get("communityInfo.localOfficialInfo.comments")}
        onChange={set("communityInfo.localOfficialInfo.comments")}
        error={errors["communityInfo.localOfficialInfo.comments"]}
      />

      <Attachments set={set} get={get} />
    </>
  );
}
