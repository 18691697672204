import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { CommonModal, CommonModalComponentProps } from "./CommonModal";
import Modal from "./Modal";

export const useDeleteModal = ({
  headerText,
  subheaderText,
  Content,
  deleteButtonText,
  size,
  onDelete,
}: {
  headerText: string;
  subheaderText?: string;
  Content: ReactNode;
  deleteButtonText: string;
  size?: CommonModalComponentProps["size"];
  onDelete: () => void;
}) => {
  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <Modal onRequestClose={hideDeleteModal}>
      <DeleteModalForm
        headerText={headerText}
        subheaderText={subheaderText}
        Content={Content}
        deleteButtonText={deleteButtonText}
        size={size}
        onClose={hideDeleteModal}
        onDelete={onDelete}
      />
    </Modal>
  ));

  return [showDeleteModal, hideDeleteModal] as const;
};

export type DeleteModalFormProps = {
  headerText: string;
  subheaderText?: string;
  Content: ReactNode;
  deleteButtonText?: string;
  onClose: () => void;
  onDelete: () => void;
} & Pick<CommonModalComponentProps, "size">;

export const DeleteModalForm = ({
  headerText,
  subheaderText,
  Content,
  deleteButtonText = "Yes, delete",
  size,
  onClose,
  onDelete,
}: DeleteModalFormProps) => {
  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(() => {
    onDelete();
    onClose();
  });

  return (
    <CommonModal
      title={headerText}
      subtitle={subheaderText}
      onSubmit={onSubmit}
      onCancel={onClose}
      loading={false}
      disabled={false}
      size={size}
      variant="destructive"
      primaryButtonText={deleteButtonText}
      content={Content}
    />
  );
};
