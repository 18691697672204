import React from "react";
import { SpinnerContainer } from "./__styles__/FullPageLoadingScreen";
import Icon, { ICON_COLORS, Icons } from "./Icons";

const FullPageLoadingScreen: React.FC = () => {
  return (
    <SpinnerContainer>
      <Icon icon={Icons.LOADING} color={ICON_COLORS.WHITE} />
    </SpinnerContainer>
  );
};

export default FullPageLoadingScreen;
