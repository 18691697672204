import React from "react";
import { SUPPORT_EMAIL } from "common/constants";
import { FullScreenContainer } from "./__styles__/InsufficientPermissions";
import { EmptyState } from "./EmptyState";

export const INSUFFICIENT_PERMISSIONS_LABEL = "insufficient-permissions";

export const InsufficientPermissions = () => (
  <EmptyState
    icon="error-alert"
    title="You don't have access to this page"
    body="Contact your account administrator to gain access, or request help."
    primaryButtonProps={{
      label: "Contact us",
      onClick: () => window.open(`mailto:${SUPPORT_EMAIL}`),
    }}
    componentLabel={INSUFFICIENT_PERMISSIONS_LABEL}
  />
);

export const FullScreenPermissionsError = () => {
  return (
    <FullScreenContainer>
      <InsufficientPermissions />
    </FullScreenContainer>
  );
};
