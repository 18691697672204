import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { DamageDegree } from "../../../../../../Submissions/Widgets";
import BaseWidgetPreview from "./BaseWidgetPreview";

const DamageDegreeWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <DamageDegree {...props} />;

  return (
    <BaseWidgetPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseWidgetPreview>
  );
};

export default DamageDegreeWidgetPreview;
