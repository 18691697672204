import React, { useContext, useEffect, useRef } from "react";
import { bbox, buffer, envelope, featureCollection } from "@turf/turf";
import { Bounds } from "viewport-mercator-project";
import { arrayHasExactlyOneItem } from "common/utils/arrays";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Icon } from "../../Common/Icons/LucideIcons";
import {
  LegendControl,
  MapTool,
  MapToolLabel,
} from "../__styles__/FullPageMap";
import { LayerContext } from "../layers";
import { DeviceLocationsConfig } from "./useUserDeviceLocations";

export const UserDeviceLocationLegendControl = ({
  setViewport,
  deviceLocationsConfig,
}: {
  setViewport: (_: {
    bounds?: Bounds;
    zoom?: number;
    latitude?: number;
    longitude?: number;
  }) => void;
  deviceLocationsConfig: DeviceLocationsConfig;
}) => {
  const { toggleLayer, isLayerVisible } = useContext(LayerContext);
  const legendControlRef = useRef<HTMLButtonElement>(null);

  const isVisible = isLayerVisible({
    group: "deviceLocations",
    id: "allDevices",
  });

  const { addWarningToast } = useStatusToasts();

  const { value, hasTrackableLocations, canViewUserDeviceLocations } =
    deviceLocationsConfig;

  const locations = value?.map(({ location }) => location) ?? [];

  const zoomToLocations = () => {
    if (arrayHasExactlyOneItem(locations)) {
      setViewport({
        zoom: 18,
        latitude: locations[0].coordinates[1],
        longitude: locations[0].coordinates[0],
      });
    } else if (locations.length > 1) {
      const fullEnvelope = envelope(
        featureCollection(
          locations.map(location => ({
            type: "Feature",
            geometry: location,
            properties: {},
          }))
        )
      );

      const bufferedEnvelope = buffer(fullEnvelope, 150, { units: "meters" });
      const bounds = bbox(bufferedEnvelope);

      setViewport({
        bounds: [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
      });
    }
  };

  const handleClick = async () => {
    const newIsVisible = !isVisible;

    const canTrack = await hasTrackableLocations();

    if (newIsVisible && !canTrack) {
      addWarningToast("No user locations available");
      legendControlRef.current?.blur();
      return;
    }

    toggleLayer({
      group: "deviceLocations",
      id: "allDevices",
      isVisible: newIsVisible,
    });

    if (newIsVisible) {
      zoomToLocations();
    }
  };

  useEffect(() => {
    if (isVisible) {
      legendControlRef.current?.focus();
    } else {
      legendControlRef.current?.blur();
    }
  }, [isVisible]);

  if (!canViewUserDeviceLocations) {
    return null;
  }

  return (
    <MapTool>
      <MapToolLabel>Find my team</MapToolLabel>
      <LegendControl
        styleVariant="outlineLight"
        active={isVisible}
        onClick={handleClick}
        ref={legendControlRef}
      >
        <Icon iconName="user-search" color="contentPrimary" size={16} />
      </LegendControl>
    </MapTool>
  );
};
