import React, { useContext, useEffect } from "react";
import { omit } from "lodash";
import { useHistory, useLocation } from "react-router";
import { buildLink } from "common/routing";
import {
  MapsForAccountQuery,
  useMapsForAccountQuery,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import { GuestMap } from "../Guest/Explore";
import { InternalMapContextProvider } from "./InternalMapContextProvider";
import { LayerContextProvider } from "./layers";
import { InternalMap } from "./Map";

export default () => {
  const { isGuest } = useContext(AuthContext);

  const { loading, error, data } = useMapsForAccountQuery({
    variables: { filterHidden: isGuest, isGuest },
    fetchPolicy: "network-only",
  });

  if (loading || error || !data?.account?.bounds) {
    return <div />;
  }

  return <MapWithState account={data.account} />;
};

const MapDisplay = ({
  account,
  isGuest,
}: {
  account: NonNullable<MapsForAccountQuery["account"]>;
  isGuest: boolean;
}) =>
  isGuest ? (
    <GuestMap account={account} />
  ) : (
    <InternalMapContextProvider>
      <InternalMap account={account} />
    </InternalMapContextProvider>
  );

const MapWithState = ({
  account,
}: {
  account: NonNullable<MapsForAccountQuery["account"]>;
}) => {
  const { isGuest } = useContext(AuthContext);
  const defaultState = useDefaultState();

  return (
    <LayerContextProvider account={account} defaultState={defaultState}>
      <MapDisplay account={account} isGuest={isGuest} />
    </LayerContextProvider>
  );
};

const useDefaultState = () => {
  const { account, isGuest } = useContext(AuthContext);
  const location =
    useLocation<Maybe<{ savedViewId?: string; prevLocation?: string }>>();
  const history = useHistory();

  const savedViewId = location.state?.savedViewId;

  const defaultDocumentState: Record<string, boolean> = isGuest
    ? {}
    : account?.accountDocumentTypes.reduce((acc, type, index) => {
        acc[type.id] = savedViewId ? false : index === 0;
        return acc;
      }, {} as Record<string, boolean>) ?? {};

  const defaultState = {
    documents: defaultDocumentState,
    ...(savedViewId && { savedViews: { [savedViewId]: true } }),
  };

  // useEffect runs on a different thread/tick than the initial render, so we can confidently
  // grab a value from location.state before it gets removed in the useEffect
  useEffect(() => {
    const stateSavedViewId = location.state?.savedViewId;
    if (stateSavedViewId) {
      const newState = omit(location.state, "savedViewId");
      if (isGuest) {
        newState.prevLocation = buildLink("residentSavedView", {
          savedViewId: stateSavedViewId,
        });
      }
      history.replace({ state: newState });
    }
  }, []);

  return defaultState;
};
