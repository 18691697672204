import React, { useContext, useEffect, useState } from "react";
import { isNil } from "lodash";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { BOOLEAN_OPTIONS } from "common/constants";
import {
  DfeRule,
  DfeRuleHandleOverride,
  DfeRuleType,
  useDeleteDfeRuleMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import { Datepicker, Radio } from "../../../Inputs/react-hook-form";
import { HandleOverridesText } from "./__styles__/upsertDfeRuleModal";
import { RevalidateEcsFormSection } from "./RevalidateEcsFormSection";

export type DeleteDfeRuleModalProps = Pick<DfeRule, "name" | "type"> & {
  onCancel: () => void;
  onUpdate: () => void;
};

export const useDeleteDfeRuleModal = () => {
  const [props, setProps] =
    useState<Omit<DeleteDfeRuleModalProps, "onCancel">>();

  const [show, hideDeleteDfeRuleModal] = useModal(() => {
    if (!props) {
      return null;
    }

    return (
      <Modal onRequestClose={hideDeleteDfeRuleModal}>
        <DeleteDfeRuleModal
          name={props.name}
          type={props.type}
          onCancel={hideDeleteDfeRuleModal}
          onUpdate={() => {
            hideDeleteDfeRuleModal();
            props.onUpdate();
          }}
        />
      </Modal>
    );
  }, [props]);

  const showDeleteDfeRuleModal = (
    props: Omit<DeleteDfeRuleModalProps, "onCancel">
  ) => {
    setProps(props);
    show();
  };
  return [showDeleteDfeRuleModal, hideDeleteDfeRuleModal] as const;
};

type DeleteDfeRuleFormStructure = {
  handleOverride?: DfeRuleHandleOverride;
  shouldRevalidate?: Omit<BOOLEAN_OPTIONS, "UNKNOWN">;
  revalidateStartDate?: Date;
};

export const DeleteDfeRuleModal = ({
  name,
  onCancel,
  onUpdate,
  type,
}: DeleteDfeRuleModalProps) => {
  const { account } = useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const { control, getValues, handleSubmit, trigger, watch } =
    useForm<DeleteDfeRuleFormStructure>();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [revalidateEcs, setRevalidateEcs] = useState<boolean | undefined>();

  const [deleteDfeRule, { loading }] = useDeleteDfeRuleMutation({
    onCompleted: () => {
      addSuccessToast(`${name} rule was successfully deleted.`);
      onUpdate();
    },
    onError: () => addErrorToast(`Failed to delete ${name} rule.`),
  });

  useEffect(() => {
    watch(({ handleOverride, shouldRevalidate, revalidateStartDate }) => {
      if (type === DfeRuleType.MANUAL) {
        setSubmitButtonDisabled(!handleOverride);
      } else if (
        isNil(shouldRevalidate) ||
        (shouldRevalidate === BOOLEAN_OPTIONS.YES && !revalidateStartDate)
      ) {
        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
      }

      setRevalidateEcs(shouldRevalidate === BOOLEAN_OPTIONS.YES);
    });
  }, []);

  const headerText = `Delete ${name} rule`;

  const handleOverrideOptions = [
    {
      value: DfeRuleHandleOverride.DELETE,
      text: "Delete existing DFE values for properties associated with this rule",
    },
    {
      value: DfeRuleHandleOverride.KEEP,
      text: "Retain existing DFE values for properties associated with this rule",
    },
  ];
  const manualContent = (
    <>
      <HandleOverridesText>
        This action will result in the permanent deletion of a manual Design
        Flood Elevation logic rule. Please select how to proceed with values
        that have already been manually entered:
      </HandleOverridesText>
      <h2>
        Note: deleting values triggers re-calculation of DFE-related EC errors
        and property warnings.
      </h2>
      <div style={{ marginTop: "12px" }}>
        <Radio
          control={control}
          name="handleOverride"
          options={handleOverrideOptions}
          required={false}
        />
      </div>
    </>
  );

  const ecRevalidationOptions = [
    {
      value: BOOLEAN_OPTIONS.YES,
      text: "Yes",
      children: revalidateEcs ? (
        <div
          style={{ margin: "0 0 8px 48px" }}
          data-testid="revalidate-ecs-container"
        >
          <Datepicker
            control={control as unknown as Control<FieldValues, any>}
            name={"revalidateStartDate"}
            rules={{
              validate: _ =>
                getValues("shouldRevalidate") === BOOLEAN_OPTIONS.YES &&
                !getValues("revalidateStartDate")
                  ? "Start date is required when revalidating ECs"
                  : undefined,
            }}
            onChange={_ => trigger("revalidateStartDate")}
            label={"Start date"}
            compactLabel={true}
            required={true}
          />
        </div>
      ) : undefined,
    },
    {
      value: BOOLEAN_OPTIONS.NO,
      text: "No",
    },
  ];

  const nonManualContent = (
    <>
      This action will result in the permanent deletion of{" "}
      {type === DfeRuleType.AUTOMATIC ? "an automatic" : "a minimum height"}{" "}
      Design Flood Elevation logic rule. Properties impacted by this rule will
      revert back to other applicable logic; if there is none for a given
      location, DFE will not apply.
      <RevalidateEcsFormSection
        name="shouldRevalidate"
        control={control}
        ecRevalidationOptions={ecRevalidationOptions}
        disabled={false}
      />
    </>
  );

  const content =
    type === DfeRuleType.MANUAL ? manualContent : nonManualContent;

  const deleteRule = async (data: DeleteDfeRuleFormStructure) => {
    await deleteDfeRule({
      variables: {
        data: {
          accountId: account!.id,
          name,
          handleOverride: data.handleOverride,
          shouldRevalidate: data.shouldRevalidate === BOOLEAN_OPTIONS.YES,
          revalidateStartDate: data.revalidateStartDate,
        },
      },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>{headerText}</h1>
      </HeaderSection>
      <ContentSection>{content}</ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="alert"
            onClick={handleSubmit(deleteRule)}
            loading={loading}
            disabled={submitButtonDisabled}
          >
            I understand, delete rule
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
