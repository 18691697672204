import "./__styles__/Icon.scss";
import React from "react";

export default () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3C5.86739 3 5.74021 3.05268 5.64645 3.14645C5.55268 3.24021 5.5 3.36739 5.5 3.5V6C5.50003 6.1326 5.55273 6.25975 5.6465 6.3535L7.6465 8.3535C7.7408 8.44458 7.8671 8.49498 7.9982 8.49384C8.1293 8.4927 8.25471 8.44011 8.34741 8.34741C8.44011 8.25471 8.4927 8.1293 8.49384 7.9982C8.49498 7.8671 8.44458 7.7408 8.3535 7.6465L6.5 5.793V3.5C6.5 3.36739 6.44732 3.24021 6.35355 3.14645C6.25979 3.05268 6.13261 3 6 3Z"
        fill="#434A58"
      />
      <path
        d="M6 2.55844e-06C4.7011 -0.00119941 3.43723 0.421145 2.4 1.203L1.5 2.55844e-06L0.3 3.4L3.9 3.2L3 2.0025C3.90071 1.32732 5.00299 0.976005 6.12828 1.00546C7.25358 1.03492 8.33596 1.44343 9.20012 2.16481C10.0643 2.8862 10.6596 3.8782 10.8896 4.98013C11.1196 6.08206 10.9708 7.22937 10.4674 8.2362C9.96398 9.24304 9.13539 10.0504 8.11584 10.5276C7.09629 11.0047 5.94551 11.1237 4.84992 10.8652C3.75432 10.6067 2.77808 9.98591 2.07934 9.10335C1.3806 8.22079 1.00028 7.12818 1 6.0025C1 5.86989 0.947322 5.74272 0.853553 5.64895C0.759785 5.55518 0.632608 5.5025 0.5 5.5025C0.367392 5.5025 0.240215 5.55518 0.146447 5.64895C0.0526784 5.74272 0 5.86989 0 6.0025C0.000494409 7.18908 0.352808 8.34888 1.0124 9.33524C1.67199 10.3216 2.60923 11.0903 3.70563 11.544C4.80203 11.9977 6.00835 12.1162 7.17207 11.8844C8.3358 11.6526 9.40466 11.081 10.2435 10.2418C11.0824 9.40254 11.6536 8.33344 11.8849 7.16963C12.1162 6.00581 11.9972 4.79954 11.543 3.70333C11.0888 2.60711 10.3198 1.67019 9.33316 1.01101C8.34652 0.351833 7.18658 2.66144e-06 6 2.55844e-06V2.55844e-06Z"
        fill="#434A58"
      />
    </svg>
  );
};
