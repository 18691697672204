import React, { FC, useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { SUBMISSION_TYPE_MODULE } from "common/constants";
import { BANNER_TYPES } from "../../../../../Common/__styles__/Banner";
import Banner from "../../../../../Common/Banner";
import { Button } from "../../../../../Common/Button";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { Body } from "../../../../../Common/Typography";
import { BuilderSidebarHeader } from "../__styles__/SubmissionsBuilder";
import {
  inputTypeToFieldsListLabelMap,
  MODULES,
  SubmissionsBuilderFieldType,
} from "../constants";
import { SubmissionsBuilderContextInstance } from "../context";
import { SupportedMobileVersionsContextInstance } from "../contexts/supportedMobileVersionsContext";
import { SelectedSubmissionsBuilderField } from "../reducer";
import {
  SubmissionsBuilderFieldSettings,
  SubmissionsBuilderFormDataStructure,
} from "../types";
import { getAllInputNames } from "../utils";
import { SupportedMobileVersionsBannerWrapper } from "./__styles__/FieldSettingsView";
import CheckboxWidgetSettings from "./FieldSettings/CheckboxWidgetSettings";
import DamageDegreeWidgetSettings from "./FieldSettings/DamageDegreeWidgetSettings";
import DateWidgetSettings from "./FieldSettings/DateWidgetSettings";
import DocumentUploadFieldSettings from "./FieldSettings/DocumentUploadFieldSettings";
import DropdownWidgetSettings from "./FieldSettings/DropdownWidgetSettings";
import NumberWidgetSettings from "./FieldSettings/NumberFieldSettings";
import NumberWithUnitsFieldSettings from "./FieldSettings/NumberWithUnitsFieldSettings";
import ObjectFieldTemplateSettings from "./FieldSettings/ObjectFieldTemplateSettings";
import PropertyMarketFieldSettings from "./FieldSettings/PropertyMarketValueFieldSettings";
import TagsWidgetSettings from "./FieldSettings/TagsWidgetSettings";
import TextareaWidgetSettings from "./FieldSettings/TextareaWidgetSettings";
import TextWidgetSettings from "./FieldSettings/TextWidgetSettings";

const fieldKeyToSettingsFormMap: Record<
  SubmissionsBuilderFieldType,
  FC<SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings>
> = {
  checkbox: CheckboxWidgetSettings,
  DamageDegree: DamageDegreeWidgetSettings,
  date: DateWidgetSettings,
  DocumentUploader: DocumentUploadFieldSettings,
  group: ObjectFieldTemplateSettings,
  number: NumberWidgetSettings,
  NumberWithUnits: NumberWithUnitsFieldSettings,
  PropertyMarketValue: PropertyMarketFieldSettings,
  select: DropdownWidgetSettings,
  Tags: TagsWidgetSettings,
  text: TextWidgetSettings,
  textarea: TextareaWidgetSettings,
};

const SupportedMobileVersionsBanner = ({
  lastUnsupportedMobileVersion,
}: {
  lastUnsupportedMobileVersion: Maybe<string>;
}) => {
  const message = !isEmpty(lastUnsupportedMobileVersion)
    ? `This field is not available in mobile versions ${lastUnsupportedMobileVersion} and below, which may affect the accessibility of the form in the app.`
    : "This field is not currently available in the mobile app but we are working on adding it in a future update.";

  return (
    <SupportedMobileVersionsBannerWrapper>
      <Banner type={BANNER_TYPES.ATTENTION} items={[message]} />
    </SupportedMobileVersionsBannerWrapper>
  );
};

const FieldSettingsView = ({ readOnly }: { readOnly: boolean }) => {
  const { watch } = useFormContext<SubmissionsBuilderFormDataStructure>();
  const supportedMobileVersionsMetaByFieldType = useContext(
    SupportedMobileVersionsContextInstance
  );
  const { selectedField, toggleFormSettingsMode } = useContext(
    SubmissionsBuilderContextInstance
  );

  const [associatedModules, setAssociatedModules] = useState<
    Array<SUBMISSION_TYPE_MODULE>
  >([]);

  const modules = watch("modules");
  useEffect(() => {
    setAssociatedModules(
      MODULES.filter(module => modules.includes(module.type))
        .filter(module => {
          const inputNames = getAllInputNames([...module.inputs]);
          return inputNames.some(
            inputName => inputName === selectedField?.name
          );
        })
        .map(module => module.type)
    );
  }, [modules, selectedField?.name]);

  if (!selectedField) {
    return null;
  }

  const FieldSettingsForm = fieldKeyToSettingsFormMap[selectedField.inputType];
  const title =
    selectedField.inputType === "DamageDegree"
      ? "Damage degree"
      : inputTypeToFieldsListLabelMap[selectedField.inputType];

  const { possiblyUnsupported, lastUnsupportedMobileVersion } =
    supportedMobileVersionsMetaByFieldType[selectedField.inputType];

  // when a field is selected, the side column changes its content to show the field settings
  // when you're in field mode, you can navigate BACK to selected fields
  return (
    <>
      <BuilderSidebarHeader role="none">
        <Button
          aria-label="Back to form fields"
          style={{ padding: 0 }}
          styleVariant="ghost"
          onClick={toggleFormSettingsMode}
        >
          <Icon color="contentSecondary" iconName="chevron-left" size={16} />
        </Button>
        <Body size="large" type="emphasis">
          {`${title} field properties`}
        </Body>
      </BuilderSidebarHeader>
      {possiblyUnsupported && (
        <SupportedMobileVersionsBanner
          lastUnsupportedMobileVersion={lastUnsupportedMobileVersion}
        />
      )}
      {/* adding `key` prop here to force re-mounting of component on selectedField change */}
      <FieldSettingsForm
        key={selectedField.name}
        {...selectedField}
        isModuleInput={!!associatedModules.length}
        readOnly={readOnly}
      />
    </>
  );
};

export default FieldSettingsView;
