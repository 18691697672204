import React from "react";
import { Badge, BadgeColorOptions } from "./__styles__/Badge";

export default ({
  color,
  text,
}: {
  color: BadgeColorOptions;
  text: string;
}) => <Badge color={color}>{text}</Badge>;
