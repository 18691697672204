import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AppContainer } from "./__styles__/TranscriptionApp";
import Dashboard from "./Dashboard";
import Task from "./Task";

export default function TranscriptionApp() {
  return (
    <AppContainer>
      <BrowserRouter basename="/admin/tasks/">
        <Switch>
          <Redirect exact path="/" to="/dashboard" />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/task" component={Task} />
        </Switch>
      </BrowserRouter>
    </AppContainer>
  );
}
