import React from "react";
import { LayoutTitleRow, Page } from "./__styles__/FullWidthLayout";
import { Title } from "./Typography";

export const Layout = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Page>
      <LayoutTitleRow>
        <Title size="default" type="regular">
          {title}
        </Title>
      </LayoutTitleRow>
      {children}
    </Page>
  );
};
