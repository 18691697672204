import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Checkbox } from "../../../../../../Inputs";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import {
  SubmissionsBuilderFieldSettings,
  SubmissionsBuilderFormDataStructure,
} from "../../types";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";

const PropertyMarketFieldSettings = ({
  fieldPath,
  isModuleInput,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { setValue, watch } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  const startingEnumValue = watch(`${fieldPath}.enum`);

  const [checked, setChecked] = useState(
    startingEnumValue?.includes("squareFootageEstimate" as any)
  );

  useEffect(() => {
    const constantEnumValues = [
      "automatedAssessedValue",
      "otherValueEstimate",
    ] as const;

    if (checked) {
      setValue(
        `${fieldPath}.enum`,
        [...constantEnumValues, "squareFootageEstimate"],
        { shouldValidate: true }
      );
    } else {
      // Have to spread because it's readonly
      setValue(`${fieldPath}.enum`, [...constantEnumValues], {
        shouldValidate: true,
      });
    }
  }, [checked]);

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={isModuleInput}
        readOnly={readOnly}
      />
      <Checkbox
        value={checked}
        onChange={setChecked}
        name="includeSquareFootage"
        label="Include Square footage estimate"
        disabled={readOnly}
      />
    </WidgetSettingsContainer>
  );
};

PropertyMarketFieldSettings.displayName = "PropertyMarketFieldSettings";

export default PropertyMarketFieldSettings;
