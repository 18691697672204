import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { CustomTextarea } from "../../../../../../Submissions/Widgets";
import BaseWidgetPreview from "./BaseWidgetPreview";

const TextareaWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <CustomTextarea {...props} />;

  return (
    <BaseWidgetPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseWidgetPreview>
  );
};

export default TextareaWidgetPreview;
