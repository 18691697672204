import React from "react";
import { ApolloError } from "@apollo/client";
import { useEditRepetitiveLossPropertyMutation } from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import EditPropertyWarnings from "../Property/EditPropertyWarnings";
import PropertyForm, { ActionProps, FormData } from "../Property/PropertyForm";
import { QueueActions } from "./Actions";
import { RepetitiveLossWithProperty } from "./GeocodeRepetitiveLoss";
const EditRepetitiveLossProperty = ({
  repetitiveLoss,
  onIgnore,
  onSkip,
  onError,
  issueCount,
  redirectAfterMutation,
}: {
  repetitiveLoss: RepetitiveLossWithProperty;
  onIgnore: () => void;
  onSkip: () => void;
  onError: (error: ApolloError) => void;
  issueCount: number;
  redirectAfterMutation: (nextIssueId?: Maybe<string>) => void;
}) => {
  const { addSuccessToast } = useStatusToasts();

  const [resolveIssue, { error, loading }] =
    useEditRepetitiveLossPropertyMutation({
      onCompleted: data => {
        const property = data.updateProperty;
        addSuccessToast(`Property edited at ${property.streetAddress}`);

        const nextIssueId = property.account?.repetitiveLossIssue?.id;
        redirectAfterMutation(nextIssueId);
      },
      onError,
    });

  const onSave = async (formData: FormData, options: { next: boolean }) => {
    await resolveIssue({
      variables: {
        data: {
          ...formData,
          id: repetitiveLoss.property.id,
          ignoreParcel: formData.geocacheParcelId === null,
        },
        getNext: options.next,
        ...(options.next && { currentRLNumber: repetitiveLoss.rLNumber }),
      },
    });
  };

  const { parcel, latitude, longitude } = repetitiveLoss.property;

  const point = latitude && longitude ? { latitude, longitude } : null;

  return (
    <PropertyForm
      actions={({ disabled, validateInput }: ActionProps) =>
        QueueActions({
          disabled,
          issueCount,
          onIgnore,
          onSave: () =>
            validateInput((data: FormData) => onSave(data, { next: false })),
          onSaveAndContinue: () =>
            validateInput((data: FormData) => onSave(data, { next: true })),
          onSkip,
          loading,
        })
      }
      headerText="Review un-assigned property issue"
      title="Edit property details"
      subtitle="Please ensure that the property details below are correct."
      property={repetitiveLoss.property}
      point={point}
      parcel={parcel ?? null}
      zoom={18}
      error={error?.message ?? null}
      warningsComponent={props => {
        return (
          <EditPropertyWarnings {...props} header={null} showNone={false} />
        );
      }}
      canSubmitProperty={true}
      editingProperty={true}
    />
  );
};

export default EditRepetitiveLossProperty;
