import "./__styles__/Icon.scss";
import React from "react";

const Eyeball = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.375 5C0.375 5 2.625 0.875 6 0.875C9.375 0.875 11.625 5 11.625 5C11.625 5 9.375 9.125 6 9.125C2.625 9.125 0.375 5 0.375 5Z"
        stroke="#2A70FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.875C7.03553 6.875 7.875 6.03553 7.875 5C7.875 3.96447 7.03553 3.125 6 3.125C4.96447 3.125 4.125 3.96447 4.125 5C4.125 6.03553 4.96447 6.875 6 6.875Z"
        stroke="#2A70FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Eyeball;
