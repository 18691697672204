import React from "react";
import { useForm } from "react-hook-form";
import { WEB_REQUIRED_PASSWORD_RESET_MESSAGE } from "common/constants";
import { isValidEmail } from "common/utils/strings";
import { useRequestPasswordResetMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { parseResetRequiredParam } from "../../../utils/params";
import { Button } from "../../Common/Button";
import FullPageBackgroundWithLogo from "../../Common/FullPageBackgroundWithLogo";
import { Section, Sections } from "../../Common/Layout";
import { Body, Title } from "../../Common/Typography";
import { Text } from "../../Inputs/react-hook-form";
import { Container } from "../CommonLoginComponents/__styles__/FormWithFullPageBackground";
import { ErrorMessage } from "../ErrorMessage";

const SuccessMessage = () => {
  return (
    <Container>
      <Section>
        <Title type="semiBold" size="large">
          Success!
        </Title>
        <Body size="default" type="regular">
          If the account with the provided email exists we will email you
          instructions for resetting your password.
        </Body>
      </Section>
    </Container>
  );
};

interface FormStructure {
  email: string;
}

export default () => {
  const { addErrorToast } = useStatusToasts();

  const [completed, setCompleted] = React.useState(false);

  const [requestReset, { loading }] = useRequestPasswordResetMutation({
    onCompleted: () => {
      setCompleted(true);
    },
    onError: error => {
      if (error.graphQLErrors[0]?.extensions?.code === "USER_ERROR") {
        addErrorToast(error.message);
      } else {
        addErrorToast(
          "There was an issue requesting a password reset. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormStructure>({});

  const showResetRequiredMessage = parseResetRequiredParam("passwordReset");

  return (
    <FullPageBackgroundWithLogo>
      {completed ? (
        <SuccessMessage />
      ) : (
        <Container>
          <Sections
            as="form"
            onSubmit={handleSubmit(async userProvidedData => {
              await requestReset({
                variables: { email: userProvidedData.email },
              });
            })}
          >
            <Section>
              {showResetRequiredMessage && (
                <ErrorMessage
                  error={{ message: WEB_REQUIRED_PASSWORD_RESET_MESSAGE }}
                />
              )}
              <Title type="semiBold" size="large">
                Reset Password
              </Title>
              <Body size="default" type="regular">
                Please enter your email below and we will send you instructions
                to reset your password.
              </Body>
              <Text
                label="Email"
                type="email"
                data-testid="email"
                error={errors.email?.message}
                {...register("email", {
                  validate: val => {
                    if (!isValidEmail(val)) {
                      return "A valid email is required";
                    }
                    return;
                  },
                })}
              />
            </Section>
            <Button
              onClick={handleSubmit(async userProvidedData => {
                await requestReset({
                  variables: { email: userProvidedData.email },
                });
              })}
              disabled={loading}
              styleVariant="primary"
              size="medium"
              stretch
            >
              Submit
            </Button>
          </Sections>
        </Container>
      )}
    </FullPageBackgroundWithLogo>
  );
};
