import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "../Common/FullWidthLayout";
import List from "./List";

export default () => {
  return (
    <Layout title="Resident Logs">
      <Switch>
        <Route path="/logs" exact component={List} />
      </Switch>
    </Layout>
  );
};
