import React, { useEffect, useState } from "react";
import { RESOURCE_NAME } from "common/authorization";
import { useUpdateCarouselSettingsMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";
import { AuthContextType } from "../../Authorization/types";
import { ContentSection } from "../../Common/__styles__/Modal";
import { useDeleteModal } from "../../Common/DeleteModal";
import HeaderNav from "../../Common/HeaderNav";
import { ActionsProps } from "../../Inputs/DropdownMenu";
import {
  CarouselModalRightContainer,
  CarouselModalWrapper,
  CounterContainer,
  DeleteStreetViewParagraph,
} from "./__styles__/Carousel";
import { Carousel, CarouselDocument } from "./Carousel";
import CarouselModalActionButton from "./FullPageCarouselActionButton";

const Counter = ({ index, total }: { index: number; total: number }) => {
  return (
    <CounterContainer>
      {index + 1} / {total}
    </CounterContainer>
  );
};

interface DeleteStreetViewModalHook {
  onSubmit: () => void;
}
export const useDeleteStreetViewModal = ({
  onSubmit,
}: DeleteStreetViewModalHook) => {
  const [show, hide] = useDeleteModal({
    headerText: "Delete Streetview image",
    Content: (
      <ContentSection style={{ padding: "16px" }}>
        <DeleteStreetViewParagraph>
          This action cannot be undone. This will permanently delete the image.
        </DeleteStreetViewParagraph>
      </ContentSection>
    ),
    deleteButtonText: "Delete",
    onDelete: onSubmit,
  });

  return [show, hide] as const;
};

const FullPageCarouselModal = ({
  activeIndex,
  setActiveIndex,
  documents,
  modalClose,
  propertyId,
  authContext,
}: {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  documents: CarouselDocument[];
  modalClose: () => void;
  propertyId: string;
  authContext: AuthContextType;
}) => {
  const [updateSettings] = useUpdateCarouselSettingsMutation();
  const [actions, setActions] = useState<ActionsProps[]>([]);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const canEditSettings = authContext.authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "update",
  });

  const total = documents.length;

  const [show] = useDeleteStreetViewModal({
    onSubmit: async () => {
      await updateSettings({
        variables: {
          id: propertyId,
          data: {
            streetViewEnabled: false,
          },
        },
        onCompleted: () => {
          track("Google streetview image deleted", {
            propertyId,
            userId: authContext.user?.id ?? null,
          });
          addSuccessToast("Your Streetview image was deleted");
          if (documents.length > 1) {
            setActiveIndex(0);
          }
          modalClose();
        },
        onError: () => {
          addErrorToast("Failed to delete Streetview image");
        },
      });
    },
  });

  useEffect(() => {
    const generateActions = () => {
      let actions: ActionsProps[] = [];
      const documentUploadId = documents[activeIndex]?.id;

      if (activeIndex !== 0) {
        actions.push({
          label: "Make cover photo",
          onClick: async () => {
            await updateSettings({
              variables: {
                id: propertyId,
                data: {
                  coverImageDocumentUploadId: documentUploadId ?? null,
                },
              },
              onCompleted: () => {
                track("Cover photo updated", {
                  propertyId,
                  userId: authContext.user?.id ?? null,
                });
                addSuccessToast("Your cover photo was updated");
                setActiveIndex(0);
                modalClose();
              },
              onError: () => {
                addErrorToast("Failed to update cover photo");
              },
            });
          },
        });
      }

      if (!documentUploadId) {
        actions.push({
          label: "Delete Streetview image",
          onClick: show,
          variant: "red",
        });
      }
      return actions;
    };

    let actions: ActionsProps[] = [];

    if (canEditSettings) {
      actions = generateActions();
    }

    setActions(actions);
  }, [activeIndex]);

  const rightContainer = (
    <CarouselModalRightContainer
      style={{ justifyContent: total > 1 ? "space-between" : "flex-end" }}
    >
      {total > 1 && <Counter index={activeIndex} total={total} />}
      {canEditSettings && <CarouselModalActionButton actions={actions} />}
    </CarouselModalRightContainer>
  );

  return (
    <CarouselModalWrapper>
      <HeaderNav
        rightContainer={rightContainer}
        onClose={modalClose}
        style={{ position: "static", flex: "none" }}
        dark={true}
      />
      <Carousel
        documents={documents}
        index={activeIndex}
        setIndex={setActiveIndex}
        size={"fullscreen"}
        toggleFullscreenModal={modalClose}
      />
    </CarouselModalWrapper>
  );
};

export default FullPageCarouselModal;
