import React from "react";
import { FieldProps } from "@rjsf/utils";
import { NumberWithUnitsInput } from "../../../../../../Submissions/Fields";
import BaseFieldPreview from "./BaseFieldPreview";

const NumberWithUnitsFieldPreview = (props: FieldProps) => {
  const widgetPreview = <NumberWithUnitsInput {...props} />;
  return <BaseFieldPreview widgetPreview={widgetPreview} {...props} />;
};

export default NumberWithUnitsFieldPreview;
