import React, { ReactNode, useContext } from "react";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { shouldShowMSCLink } from "common-client/utils/firms";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { PropertyAttributeLinkContainer } from "../../../__styles__";
import { FIRMInfoContext } from "../../FIRMInfoContext";
import { RenderMode } from "../../types";
import { Blank, FIRMInfoRow } from "./FIRMInfoRow";

export const MSCLinkRow = ({
  label,
  isPublic,
  tooltip,
  size,
}: {
  label: string;
  isPublic: boolean;
  tooltip?: React.ReactNode;
  size: RenderMode;
}) => {
  const { firms } = useContext(FIRMInfoContext);
  const showMSCLink = shouldShowMSCLink(firms);

  if (!showMSCLink) {
    return null;
  }

  const valueForFIRM = (firm: FIRMWithWarnings) => {
    return firm.mscLink ? "Link" : "-";
  };

  const wrapper = ({
    children,
    firmId,
  }: {
    children?: ReactNode;
    firmId: string;
  }) => {
    const firm = firms.find(firm => firm.id === firmId)!;

    if (firm.mscLink) {
      return (
        <PropertyAttributeLinkContainer>
          <span>
            <Icon iconName="link" color="contentInfo" size={16} />
          </span>
          <a href={firm.mscLink} target="_blank">
            {children}
          </a>
        </PropertyAttributeLinkContainer>
      );
    } else {
      return <Blank />;
    }
  };

  return (
    <FIRMInfoRow
      property="fisPropertyLink"
      valueForFIRM={valueForFIRM}
      label={label}
      isPublic={isPublic}
      tooltip={tooltip}
      wrap={wrapper}
      size={size}
    />
  );
};
