import React from "react";
import { useMutation } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { buildLink } from "common/routing";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import {
  AdminLoginMutation,
  AdminLoginMutationVariables,
  GetUserAccountsQuery,
  UserLoginErrorCode,
  UserLoginMutation,
  UserLoginMutationVariables,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { ResetRequiredParamInput } from "../../utils/params";
import { Button } from "../Common/Button";
import { Section, Sections } from "../Common/Layout";
import { Title } from "../Common/Typography";
import { Text } from "../Inputs/react-hook-form";
import { SecondaryActions } from "./CommonLoginComponents/SecondaryActions";
import { ErrorMessage } from "./ErrorMessage";
import { LoginFormStructure, LoginPageProps } from "./LoginPage";
import { ProvideCredentialsNavigationCard } from "./ProvideCredentialsNavigationCard";

export const ProvideCredentials = ({
  dispatch,
  error,
  loginMutation,
  onSuccess,
  passwordResetPath,
  title,
  accounts,
  currentAccount,
}: {
  dispatch: React.Dispatch<MFAFlowAction>;
  error: Maybe<{ message: string; code?: UserLoginErrorCode }>;
  loginMutation: LoginPageProps["loginMutation"];
  onSuccess: () => void;
  passwordResetPath?: string;
  title: string;
  accounts: NonNullable<GetUserAccountsQuery["getUserAccountsByEmail"]>;
  currentAccount: Maybe<
    NonNullable<GetUserAccountsQuery["getUserAccountsByEmail"]>[0]
  >;
}) => {
  const { addErrorToast } = useStatusToasts();
  const { register, handleSubmit, watch } = useFormContext();
  const history = useHistory();

  const [email, password] = watch(["email", "password"]);
  const disabled = !email || !password;

  const [login] = useMutation<
    AdminLoginMutation | UserLoginMutation,
    AdminLoginMutationVariables & UserLoginMutationVariables
  >(loginMutation, {
    onCompleted: data => {
      if (data.login.ok) {
        onSuccess();
      } else if (
        data.login.error?.code === UserLoginErrorCode.PASSWORD_RESET_REQUIRED
      ) {
        history.push(
          buildLink("passwordReset", {}, {
            resetRequired: "true",
          } satisfies ResetRequiredParamInput)
        );
      } else {
        dispatch({ type: "login", data: data.login });
      }
    },
    onError: () => {
      addErrorToast(
        `There was an issue logging in. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleLogin = async (loginData: LoginFormStructure) => {
    await login({
      variables: { email: loginData.email!, password: loginData.password! },
    });
  };

  return (
    <Sections as="form" onSubmit={handleSubmit(handleLogin)}>
      {error && (
        <ErrorMessage error={error} passwordResetPath={passwordResetPath!} />
      )}
      <Section>
        <Title type="semiBold" size="large">
          {title}
        </Title>
        {currentAccount ? (
          <ProvideCredentialsNavigationCard
            accounts={accounts}
            email={email}
            currentAccount={currentAccount}
          />
        ) : (
          <Text label="Email" {...register("email")} />
        )}
        <Text label="Password" {...register("password")} type="password" />
        {passwordResetPath && (
          <SecondaryActions
            actions={[
              {
                onClick: () => history.push(passwordResetPath),
                text: "Forgot your password?",
              },
            ]}
          />
        )}
      </Section>
      <Button
        onClick={handleSubmit(handleLogin)}
        size="medium"
        styleVariant="primary"
        disabled={disabled}
        stretch
      >
        Sign in
      </Button>
    </Sections>
  );
};
