import React, { useContext, useState } from "react";
import Form, { IChangeEvent } from "@rjsf/core";
import {
  RegistryFieldsType,
  RegistryWidgetsType,
  RJSFValidationError,
} from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { flatMap, isArray, isEqual, isObject, merge } from "lodash";
import { SubmissionDefaultValuesContext } from "common/services/formBuilderService";
import { formatDateString } from "common/utils/dates";
import { BuildSISDRuleParams } from "common/utils/improvements";
import { isEqualWithoutUndefined } from "common/utils/objects";
import { transformError } from "common-client/utils/submissions";
import {
  CertificateUpload,
  DocumentUpload,
  GetSisdRuleDataQuery,
  Submission,
  SubmissionType,
  SubmissionTypeVersion,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import Disclaimer from "../Common/Disclaimer";
import { form } from "./__styles__/Form";
import {
  DocumentUploadInput,
  NumberInput,
  NumberWithUnitsInput,
  PropertyMarketValueInput,
  TagsInput,
} from "./Fields";
import { DocumentUpload as DocumentUploadFieldType } from "./Fields/DocumentUploadField";
import { MODULE_CONFIGURATIONS } from "./modules";
import {
  DescriptionFieldTemplate,
  ObjectFieldTemplate,
  TitleFieldTemplate,
} from "./Templates";
import {
  CustomCheckbox,
  CustomDatePicker,
  CustomSelect,
  CustomText,
  CustomTextarea,
  DamageDegree,
} from "./Widgets";

export type FormDataType = Record<string, unknown>;

export interface SubmissionFormContextType {
  documentUploads: Maybe<
    Array<
      Pick<DocumentUpload, "id" | "originalFilename" | "hiddenFromPublic"> & {
        certificateUpload?: Maybe<
          Pick<CertificateUpload, "status" | "cancelationReason">
        >;
      }
    >
  >;
  defaultValues: SubmissionDefaultValuesContext;
  editing: boolean;
}

export const SubmissionFormContext =
  React.createContext<SubmissionFormContextType>({
    documentUploads: null,
    defaultValues: {} as SubmissionDefaultValuesContext,
    editing: false,
  });

export type RelatedSubmission = Pick<Submission, "id"> & {
  submissionTypeVersion: {
    submissionType: Pick<SubmissionType, "modules" | "name">;
  };
};

export interface FormComponentProps {
  submitFormRef?: React.RefObject<HTMLButtonElement>;
  submissionType: {
    modules: SubmissionType["modules"];
    currentVersion: Pick<SubmissionTypeVersion, "formStructure">;
  };
  existingSubmission?: Maybe<
    Pick<Submission, "formData"> & {
      documentUploads: Array<
        Pick<DocumentUpload, "id" | "originalFilename" | "hiddenFromPublic">
      >;
    }
  >;
  disabled?: boolean;
  readonly?: boolean;
  onSubmit: (formData: FormDataType) => void;
  onChange?: (data: {
    isDirty: boolean;
    documentUploadsInProgress: boolean;
  }) => void;
  defaultValues?: Omit<
    SubmissionDefaultValuesContext,
    "units" | "boolean" | "date"
  >;
  initialFormData?: FormDataType;
  relatedSubmissions: RelatedSubmission[];
  account: GetSisdRuleDataQuery["account"];
  property: { FIRMInfo?: GetSisdRuleDataQuery["FIRMInfo"] };
}

const isDocumentUploadField = (field: any) => {
  return isArray(field) && field.length && !!field[0].accountDocumentTypeId;
};

const widgets: RegistryWidgetsType = {
  SelectWidget: CustomSelect,
  TextareaWidget: CustomTextarea,
  DateWidget: CustomDatePicker,
  DamageDegree: DamageDegree,
  TextWidget: CustomText,
  CheckboxWidget: CustomCheckbox,
};

const fields: RegistryFieldsType = {
  DocumentUploader: DocumentUploadInput,
  Tags: TagsInput,
  NumberField: NumberInput,
  NumberWithUnits: NumberWithUnitsInput,
  PropertyMarketValue: PropertyMarketValueInput,
};

export const FormComponent = ({
  submitFormRef,
  submissionType,
  existingSubmission,
  relatedSubmissions,
  initialFormData,
  disabled = false,
  readonly = false,
  onSubmit,
  onChange = () => {},
  defaultValues,
  account,
  property,
}: FormComponentProps) => {
  const finalInitialFormData =
    initialFormData ?? existingSubmission?.formData ?? {};

  const [formState, setFormState] =
    useState<FormDataType>(finalInitialFormData);

  const [validated, setValidated] = useState(false);

  const { account: contextAccount, admin } = useContext(AuthContext);

  const { modules, currentVersion } = submissionType;
  const { schema, uiSchema } = currentVersion.formStructure;

  const checkDocumentUploadsInProgress = (data: any): boolean => {
    for (const key of Object.keys(data)) {
      if (isObject(data[key])) {
        const subValue = checkDocumentUploadsInProgress(data[key]);
        if (subValue) {
          return true;
        }
      }

      if (isDocumentUploadField(data[key])) {
        const validUpload = data[key].every(
          (item: DocumentUploadFieldType) =>
            !item.status || item.status === "valid"
        );

        if (!validUpload) {
          return true;
        }
      }
    }
    return false;
  };

  const editing = !!existingSubmission;

  const handleChange = ({ formData: rawFormData }: IChangeEvent) => {
    // This might seem odd - after all, this is called `handleChange` - but we need to do this
    // because sometimes react-json-schema injects `undefined` for certain fields after first render
    // if those fields are missing from the form data but are in the form schema, which results
    // in `handleChange` being called even though nothing changed (i.e. the user did nothing)
    if (isEqualWithoutUndefined(formState, rawFormData)) {
      return;
    }

    const formData = modules.reduce(
      (formData, module) => {
        return MODULE_CONFIGURATIONS[module].onFormDataChange(formData, {
          rules:
            account?.sisdRuleDefinition[0]?.rules.filter(
              (rule): rule is BuildSISDRuleParams =>
                rule.__typename === "SISDRule"
            ) ?? [],
          firms: (property.FIRMInfo?.firms ?? []).map(firm => ({
            sfha: firm.sfha,
            jurisdictions: firm.jurisdictions,
            depth: firm.depth ?? null,
            stringDepth: firm.stringDepth ?? null,
            staticBFE: firm.staticBFE ?? null,
            stringStaticBFE: firm.stringStaticBFE ?? null,
            floodzone: firm.floodzone,
          })),
        });
      },
      { ...rawFormData }
    );

    setFormState(formData);

    const documentUploadsInProgress = checkDocumentUploadsInProgress(formData);

    onChange({
      isDirty: !isEqual(existingSubmission?.formData, formData),
      documentUploadsInProgress,
    });
  };

  const handleSubmit = ({
    formData,
  }: {
    formData: FormDataType;
    previousFormData: FormDataType;
  }) => {
    setFormState(formData);

    onSubmit(formData);
  };

  const onError = (formErrors: Array<RJSFValidationError>) => {
    // onSubmit isn't called if there are errors, so we have to set validated state here. We only want to
    // live validate after the initial submit click, so we don't show a bunch of errors on page load
    setValidated(true);
    const firstErrorElement = formErrors
      .map(error => {
        const replacedName = error.property?.replace(/\./g, "_");
        // For reasons that are unknown to me, sometimes properties don't start with a `.`,
        // so we are checking if a leading `_` is present before prepending `root`
        const rootName = replacedName?.startsWith("_")
          ? `root${replacedName}`
          : `root_${replacedName}`;

        return document.getElementById(rootName);
      })
      .filter(element => element !== null)
      .sort((a, b) => {
        const aRect = a.getBoundingClientRect();
        const bRect = b.getBoundingClientRect();
        return aRect.top - bRect.top || aRect.left - bRect.left;
      })[0];

    // Some inputs, such as DocumentUploadField, don't receive focus,
    // so we want to scroll and then focus
    firstErrorElement?.scrollIntoView({ block: "center" });
    firstErrorElement?.focus();
  };

  const { parcel, property: defaultProperty, user } = defaultValues ?? {};

  const disclaimers = flatMap(modules, module => {
    return MODULE_CONFIGURATIONS[module].buildDisclaimers({
      relatedSubmissions,
      user: { isAdmin: !!admin, accountId: contextAccount!.id },
    });
  });

  const submissionGates = modules.reduce((chain, module) => {
    const gate = MODULE_CONFIGURATIONS[module].buildSubmitGate();
    return ({
      formData,
      previousFormData,
    }: {
      formData: FormDataType;
      previousFormData: FormDataType;
    }) => {
      return gate({
        formData,
        previousFormData,
        relatedSubmissions,
        onComplete: chain,
      });
    };
  }, handleSubmit);

  return (
    <SubmissionFormContext.Provider
      value={{
        editing,
        documentUploads: existingSubmission?.documentUploads ?? null,
        defaultValues: merge(
          {
            units: {
              feet: "feet",
              inches: "inches",
              hours: "hours",
              minutes: "minutes",
              days: "days",
            } as const,
            boolean: { true: true, false: false } as const,
            parcel: {
              mailingAddress:
                parcel?.mailingAddress1 && parcel.mailingAddress2
                  ? `${parcel.mailingAddress1} ${parcel.mailingAddress2}`
                  : undefined,
            },
            user: {
              fullName:
                user?.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : undefined,
            },
            date: {
              today: formatDateString({
                dateString: new Date().toDateString(),
                format: "YYYY-MM-DD",
              }),
            },
            property: {
              improvementValue:
                defaultProperty?.improvementValue || parcel?.improvementValue,
            },
          },
          defaultValues
        ),
      }}
    >
      {disclaimers.map((disclaimer, index) => (
        <Disclaimer key={index} message={disclaimer} />
      ))}
      <Form
        disabled={disabled}
        readonly={readonly}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        widgets={widgets}
        fields={fields}
        onSubmit={() =>
          submissionGates({
            formData: formState,
            previousFormData: finalInitialFormData,
          })
        }
        className={form()}
        formData={formState}
        noHtml5Validate={false}
        transformErrors={errors => {
          return errors.map(error => ({
            ...error,
            ...transformError(error),
          }));
        }}
        showErrorList={false}
        // We only want to liveValidate after initial form submission
        liveValidate={validated}
        onError={onError}
        onChange={handleChange}
        templates={{
          TitleFieldTemplate,
          DescriptionFieldTemplate,
          ObjectFieldTemplate,
        }}
      >
        {submitFormRef && (
          <button
            ref={submitFormRef}
            type="submit"
            style={{ display: "none" }}
          />
        )}
      </Form>
    </SubmissionFormContext.Provider>
  );
};
