import React from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { formatDateString } from "../../../utils/dates";
import { DownloadAnchor } from "../../Common/__styles__/downloadLink";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import { ManualPaginationConfig } from "../../Common/Tables/hooks";
import Table from "../../Common/Tables/Table";
import FileDisplay from "../../Inputs/FileDisplay";
import {
  CreatedDate,
  ExpandedContainer,
  IconWrapper,
  Info,
  StatusWrapper,
  Text,
  Title,
} from "./__styles__/ImportsTable";

export interface RecentImportTask {
  id: string;
  name: string;
  error?: string;
  createdAt: string;
  csvDownloadHref?: string;
  disabled?: boolean;
  status: string;
  icon: Icon;
  actionButton?: ({}) => JSX.Element;
}

type Props = {
  loading: boolean;
  recentImportTasks: RecentImportTask[];
  manualPaginationConfig: ManualPaginationConfig;
};

const rowCanExpand = (row: Row<RecentImportTask>) =>
  !!row.original.error || !!row.original.csvDownloadHref;

const columns: Array<ColumnDef<RecentImportTask>> = [
  {
    accessorKey: "task",
    id: "task",
    cell: ({ row }) => {
      const icon = row.original.icon;
      return (
        <FileDisplay
          fileIcon={icon}
          titleText={row.original.name}
          expandable={rowCanExpand(row)}
          expanded={row.getIsExpanded()}
          onClick={row.getToggleExpandedHandler()}
        >
          <Icon icon={Icons.CALENDAR} color={ICON_COLORS.GREY_4} />
          <CreatedDate>
            {formatDateString({
              format: "MM D, YYYY at HH:MM ZZ",
              dateString: row.original.createdAt,
            }).toLocaleString()}
          </CreatedDate>
        </FileDisplay>
      );
    },
  },
  {
    accessorKey: "status",
    id: "status",
    cell: ({ row }) => {
      const actionButton = row.original.actionButton;
      const importJobId = row.original.id;
      return (
        <StatusWrapper>
          <IconWrapper>
            {(!!row.original.error || !!row.original.csvDownloadHref) &&
              row.original.status.toLowerCase() !== "failure" && (
                <Icon
                  icon={Icons.INFORMATION_CIRCLE}
                  color={ICON_COLORS.YELLOW}
                />
              )}
            {row.original.status.toLowerCase() === "failure" && (
              <Icon icon={Icons.REMOVE} />
            )}
          </IconWrapper>
          <Text isFailure={row.original.status.toLowerCase() === "failure"}>
            {row.original.status}
          </Text>
          {actionButton
            ? actionButton({
                importJobId,
                disabled: row.original.disabled,
              })
            : ""}
        </StatusWrapper>
      );
    },
  },
];

const renderSubComponent = (row: Row<RecentImportTask>) => {
  const { error, csvDownloadHref, disabled = false } = row.original;
  if (!error && !csvDownloadHref) return <></>;

  const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
  };

  const errorProps = {
    title: "Error message",
    message: error,
  };

  const csvProps = {
    title: "CSV Download",
    message: (
      <DownloadAnchor
        as={"a"}
        disabled={disabled}
        tabIndex={0}
        href={csvDownloadHref}
        onClick={onClickHandler}
        download
      >
        Download .csv with all relevant errors
      </DownloadAnchor>
    ),
  };

  const { title, message } = csvDownloadHref ? csvProps : errorProps;

  return (
    <ExpandedContainer>
      <Title>{title}</Title>
      <Info>{message}</Info>
    </ExpandedContainer>
  );
};

export default ({
  recentImportTasks,
  manualPaginationConfig,
  loading,
}: Props) => {
  return (
    <Table<RecentImportTask>
      currentData={recentImportTasks}
      previousData={[]}
      columns={columns}
      loadingDetails={{
        loading,
        loadingText: "Loading recent imports",
        noDataText: "No recent imports found",
      }}
      minRows={recentImportTasks.length}
      manualPaginationConfig={manualPaginationConfig}
      tableStyleDetails={{
        hasExpandableRows: true,
        hasFlushedSides: true,
      }}
      rowCanExpand={rowCanExpand}
      renderSubComponent={renderSubComponent}
      excludeTableHeader
    />
  );
};
