import { useContext } from "react";
import React from "react";
import { useHistory } from "react-router";
import { SUBMISSION_TABLE_TO_CATEGORY } from "common/constants";
import { buildLink, RouteNames } from "common/routing";
import {
  isSubmissionTable,
  TABLE_NAMES,
  TopLevelTableNames,
} from "common/utils/queryBuilder";
import { generateDisplayName } from "common-client/utils/mapLayers";
import { colors, spacing } from "../../stitches.config";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import { FlexRow } from "../Common/__styles__/Layout";
import { StyledLink } from "../Common/__styles__/Typography";
import { BottomBanner } from "../Common/BottomBanner";
import { Button } from "../Common/Button";
import { LayerContext, SavedView } from "./layers";
import { SAVED_VIEW_MIN_ZOOM } from "./layers/savedViews";

const TABLE_NAME_TO_ROUTE: Record<TopLevelTableNames, RouteNames> = {
  [TABLE_NAMES.PROPERTIES]: "properties",
  [TABLE_NAMES.FILES]: "documentUploads",
  [TABLE_NAMES.PERMITS]: "recordsTable",
  [TABLE_NAMES.INSPECTIONS]: "recordsTable",
  [TABLE_NAMES.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]: "recordsTable",
  [TABLE_NAMES.CODE_CASES]: "recordsTable",
  [TABLE_NAMES.GRANTS]: "recordsTable",
  [TABLE_NAMES.INSURANCE]: "recordsTable",
  [TABLE_NAMES.MAILINGS]: "recordsTable",
  [TABLE_NAMES.PLANNING]: "recordsTable",
  [TABLE_NAMES.PROJECTS]: "recordsTable",
  [TABLE_NAMES.PAYMENTS]: "recordsTable",
};

export const SavedViewBanner = ({
  visibleSavedView,
  zoom,
}: {
  visibleSavedView?: Maybe<SavedView>;
  zoom: number;
}) => {
  const { isGuest } = useContext(AuthContext);
  const { toggleLayer } = useContext(LayerContext);
  const history = useHistory();

  if (!visibleSavedView) {
    return null;
  }

  let category = null;

  const table = visibleSavedView.query.table;
  if (isSubmissionTable(table)) {
    category = SUBMISSION_TABLE_TO_CATEGORY[table];
  }

  const link = isGuest
    ? buildLink("residentSavedView", { savedViewId: visibleSavedView.id })
    : buildLink(
        TABLE_NAME_TO_ROUTE[table as keyof typeof TABLE_NAME_TO_ROUTE],
        category ? { category } : {},
        { view: visibleSavedView.id }
      );

  const onClose = () => {
    toggleLayer({
      group: "savedViews",
      id: visibleSavedView.id,
      isVisible: false,
    });
  };

  const subtitle =
    zoom < SAVED_VIEW_MIN_ZOOM ? "Zoom in to view data." : undefined;

  return (
    <BottomBanner
      title={generateDisplayName(visibleSavedView)}
      subtitle={subtitle}
      onClose={onClose}
      style="table"
    >
      <FlexRow style={{ gap: spacing.m.value, alignItems: "center" }}>
        <Button
          styleVariant="outlineDark"
          size="small"
          leftIconName="table-properties"
          onClick={() => {
            track("Clicked Back to table", {
              savedViewId: visibleSavedView.id,
            });
            history.push(link);
          }}
        >
          Back to table
        </Button>

        <StyledLink
          style={{
            color: colors.contentInfoDark.toString(),
            cursor: "pointer",
          }}
          onClick={onClose}
          as="div"
        >
          Close view
        </StyledLink>
      </FlexRow>
    </BottomBanner>
  );
};
