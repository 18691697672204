import React, { useContext } from "react";
import { RESOURCE_NAME } from "common/authorization";
import { buildFullLink } from "common/routing";
import { getClientAppPrefix } from "common/utils/url";
import {
  GetUserInvitesAndUsersQuery,
  useImpersonateUserMutation,
  useResetMfaMutation,
  useSuspendUserMutation,
  useUnsuspendUserMutation,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { AuthContext } from "../Authorization/AuthContext";
import { DropdownMenu } from "../Inputs";
import { ActionsProps } from "../Inputs/DropdownMenu";
import { useUpdateUserPermissionsModal } from "./UpdateUserPermissionsModal";

export type UserActionsProps = {
  user: Omit<
    GetUserInvitesAndUsersQuery["usersAndInvites"]["data"][number],
    "__typename"
  > & {
    suspended: boolean;
  };

  onUpdate: () => {};
};

const UserActions = ({ user, onUpdate }: UserActionsProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { authorized, account, admin } = useContext(AuthContext);
  const {
    firstName,
    lastName,
    id,
    suspended,
    canViewPersonalIdentifiableInformation,
  } = user;

  const [suspendUser, { loading: suspending }] = useSuspendUserMutation({
    onCompleted: () => {
      addSuccessToast(`${firstName} ${lastName} has been suspended`);
      onUpdate();
    },
    onError: () => {
      addErrorToast(
        "There was an error suspending this user. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  });

  const [unsuspendUser, { loading: unsuspending }] = useUnsuspendUserMutation({
    onCompleted: () => {
      addSuccessToast(`${firstName} ${lastName} has been unsuspended`);
      onUpdate();
    },
    onError: () => {
      addErrorToast(
        "There was an error unsuspending this user. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  });

  const [impersonateUser, { loading: impersonating }] =
    useImpersonateUserMutation({
      onCompleted: () => {
        const prefix = getClientAppPrefix(account!.subdomain);

        window.location.href = buildFullLink("map", {
          prefix,
          params: {},
        });
      },
      onError: () => {
        addErrorToast(
          "There was an error impersonating this user. Please try again"
        );
      },
    });

  const [resetMFA, { loading: resettingMFA }] = useResetMfaMutation({
    onCompleted: () => {
      addSuccessToast(
        `${user.firstName} ${user.lastName}'s MFA settings have been reset`
      );
    },
    onError: () => {
      addErrorToast("Unable to reset MFA");
    },
  });

  const adminCanImpersonateUser =
    admin?.canViewPersonalIdentifiableInformation ||
    !canViewPersonalIdentifiableInformation;

  const handleSuspension = async () => {
    if (suspending) return;
    await suspendUser({ variables: { id } });
  };

  const handleUnsuspension = async () => {
    if (unsuspending) return;
    await unsuspendUser({ variables: { id } });
  };

  const handleImpersonation = async () => {
    if (impersonating) return;
    await impersonateUser({ variables: { id } });
  };

  const handleResetMFA = async () => {
    if (resettingMFA) return;
    await resetMFA({ variables: { id } });
  };

  const [showUpdateUserPermissionsModal, hideUpdateUserPermissionsModal] =
    useUpdateUserPermissionsModal({
      onCancel: () => {
        hideUpdateUserPermissionsModal();
      },
      onUpdate,
      user,
    });

  const isAuthorizedToUpdateUserPermissions = authorized({
    permission: "updateUserPermissions",
    resource: RESOURCE_NAME.USER,
  });

  const isAuthorizedtoImpersonate = authorized({
    permission: "impersonate",
    resource: RESOURCE_NAME.USER,
  });

  const hasMFAEnabled = account?.securityConfiguration?.multifactorAuthEnabled;

  const actions: Array<ActionsProps> = [];

  if (suspended) {
    actions.push({
      label: "Unsuspend",
      onClick: handleUnsuspension,
    });
  } else {
    actions.push({
      label: "Suspend",
      onClick: handleSuspension,
    });
  }
  if (isAuthorizedtoImpersonate) {
    actions.push({
      label: "Impersonate",
      onClick: handleImpersonation,
      disabled: suspended || !adminCanImpersonateUser,
    });
  }
  if (isAuthorizedToUpdateUserPermissions) {
    actions.push({
      label: "Change role",
      onClick: showUpdateUserPermissionsModal,
    });
  }
  if (hasMFAEnabled && isAuthorizedToUpdateUserPermissions) {
    actions.push({
      label: "Reset MFA",
      onClick: handleResetMFA,
      disabled: suspended,
    });
  }

  return <DropdownMenu actions={actions} data-testid="user-actions" />;
};

export default UserActions;
