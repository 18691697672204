import React from "react";
import { FieldProps } from "@rjsf/utils";
import { JSONSchema7 } from "json-schema";
import { isNil, merge } from "lodash";
import { extractErrorsFromErrorSchema } from "common-client/utils/submissions";
import { Number, Select } from "../../Inputs";
import Wrapper from "../../Inputs/Wrapper";
import {
  Input,
  NumberWithUnitsInput as NumberWithUnitsWrapper,
} from "../__styles__/Inputs";
import { useDefaultedValues } from "../utils";

export const NumberWithUnitsInput = (
  props: FieldProps & {
    isSubmissionBuilder?: boolean;
  }
) => {
  const properties = props.schema.properties!;
  const unitsProperty = properties.units as JSONSchema7;

  const oneOf = unitsProperty.oneOf as Array<JSONSchema7>;
  const options = oneOf
    // we filter out the null option here, which we need in the schema
    // but not the select. This is needed because the input, if not required,
    // has to allow for a type that contains for null as the `const`
    .filter(option => !isNil(option.const))
    .map(option => {
      return {
        value: option.const,
        label: option.title!,
      };
    });

  const { units, value } = useDefaultedValues({
    uiSchema: props.uiSchema,
    values: props.formData,
    onChange: defaultValues => {
      props.onChange(
        merge({ units: null, value: null }, defaultValues, props.formData)
      );
    },
    onNoDefaults: () => {
      // we merge in null values to trigger validations because
      // RJFS does not trigger validations for composite objects
      // without you dirtying it *explicitly*
      props.onChange(merge({ units: null, value: null }, props.formData));
    },
  });

  const errors = extractErrorsFromErrorSchema(props.errorSchema);
  const error = errors.value || errors.units;

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Wrapper
        name={props.schema.title!}
        label={props.schema.title}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        required={props.required}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={error}
        tooltip={props.uiSchema?.["ui:tooltipText"]}
      >
        <NumberWithUnitsWrapper>
          <Number
            name={`${props.idSchema.$id}_value`}
            required={props.required && !props.disabled}
            value={value}
            onChange={value => props.onChange({ value, units })}
            disabled={props.disabled}
            readonly={props.readonly}
            step={props.uiSchema?.["ui:step"]}
            hasErrorWithoutText={!!error}
          />
          <Select
            name={`${props.idSchema.$id}_units`}
            options={options}
            size="medium"
            value={units}
            onChange={units => {
              props.onChange({ units, value });
            }}
            hasErrorWithoutText={!!error}
            disabled={props.disabled}
            readonly={props.readonly}
          />
        </NumberWithUnitsWrapper>
      </Wrapper>
    </Input>
  );
};
