import React, { useContext, useState } from "react";
import { isEmpty, isNumber, join, upperFirst } from "lodash";
import { RESOURCE_NAME } from "common/authorization";
import {
  ErrorCode,
  GetDocumentUploadDocument,
  useIgnoreValidationErrorMutation,
  ValidationError,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { track } from "../../../../utils/tracking";
import { AuthContext } from "../../../Authorization/AuthContext";
import { ICON_COLORS, Icons } from "../../../Common/Icons";
import IconText from "../../../Common/IconText";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { useIssueModal } from "../../../Issues";
import CertificateField from "../../Forms/ElevationCertificates/CertificateField";
import { EMPTY_VALUE } from "../../Forms/ElevationCertificates/ExtractedFields/ExtractedField";
import { DocumentUploadContext, TAB_NAME } from "../../Tabs";
import { FieldDetailWrapper, Label } from "./__styles__/ValidationError";
import { useIgnoreModal } from "./IgnoreModal";

type Coordinates = [number, number];

const UNEDITABLE_ERRORS = [
  ErrorCode.NFIP_LFE_BELOW_BFE,
  ErrorCode.REGULATORY_LFE_BELOW_DFE,
  ErrorCode.NFIP_LFEHAG_BELOW_BFE,
  ErrorCode.REGULATORY_LFEHAG_BELOW_DFE,
  ErrorCode.NFIP_MACHINERY_BELOW_BFE,
  ErrorCode.REGULATORY_MACHINERY_BELOW_DFE,
  ErrorCode.NFIP_MACHINERY_BELOW_AOBFE,
  ErrorCode.REGULATORY_MACHINERY_BELOW_AODFE,
  ErrorCode.LAG_ABOVE_BFE,
  ErrorCode.MISSING_BFE_DATA,
];

const getButtonText = (ignored: boolean) => {
  return ignored ? "Unignore" : "Ignore";
};

const validationErrorActions = ({
  value,
  label,
  error,
  setTab,
  browserWindow,
  refetch = true,
}: ValidationErrorProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { authorized } = useContext(AuthContext);
  const { documentUpload } = useContext(DocumentUploadContext);

  if (!documentUpload.elevationCertificate) return [];

  const address = documentUpload.property?.fullAddress ?? "";
  const coordinates = [
    documentUpload.property?.longitude,
    documentUpload.property?.latitude,
  ] as Coordinates;

  const [isIgnored, setIsIgnored] = useState(error.ignored);

  const [showIssueModal] = useIssueModal({
    address,
    coordinates,
  });

  const [showIgnoreModal] = useIgnoreModal({
    ignoredReason: error.ignoredReason ?? null,
  });

  const initialIgnoreButtonText = getButtonText(error.ignored);

  const [ignoreButtonText, setIgnoreButtonText] = useState(
    initialIgnoreButtonText
  );

  const canUpdateCertificateErrors = authorized({
    resource: RESOURCE_NAME.CERTIFICATE_ERROR,
    permission: "update",
  });

  const canReportDataIssue = authorized({
    resource: RESOURCE_NAME.ISSUE,
    permission: "create",
  });

  const canEditCertificates = authorized({
    resource: RESOURCE_NAME.CERTIFICATE,
    permission: "update",
  });

  const onSuccessfulIgnore = (result: boolean) => {
    setIsIgnored(result);
    const buttonText = getButtonText(result);
    setIgnoreButtonText(buttonText);
  };

  const [updateValidationErrorIgnored, { loading }] =
    useIgnoreValidationErrorMutation({
      onCompleted: ({ updateValidationErrorIgnored }) => {
        const { ignored } = updateValidationErrorIgnored;
        const toastText = ignored
          ? "The issue was successfully ignored"
          : "The issue was successfully unignored";
        addSuccessToast(toastText);
        onSuccessfulIgnore(ignored);
        ignored
          ? track("Ignored Error", { label, error: error.errorCode })
          : track("Unignored Error", { label, error: error.errorCode });
      },
      onError: () => {
        const toastText = isIgnored
          ? "There was an error ignoring this issue. Please refresh your page and try again."
          : "There was an error unignoring this issue. Please refresh your page and try again.";
        addErrorToast(toastText);
      },
      refetchQueries: refetch
        ? [
            {
              query: GetDocumentUploadDocument,
              variables: { id: documentUpload.id, isGuest: false },
            },
          ]
        : [],
    });

  const handleIgnoreClick = async () => {
    const ignore = !isIgnored;
    if (ignore) {
      showIgnoreModal({
        onIgnore: ({ ignoredReason }: { ignoredReason: Maybe<string> }) =>
          updateValidationErrorIgnored({
            variables: { data: { ignore, ignoredReason }, id: error.id },
          }),
      });
    } else {
      await updateValidationErrorIgnored({
        variables: { data: { ignore }, id: error.id },
      });
    }
  };

  const handleReportIssue = () => {
    const placeholder = `${label}\nValue: ${value}\nIssue: ${error.issue}\nType: ${error.type}\nData Issue: Describe data issue here...`;
    showIssueModal({ placeholder });
  };

  const handleEditExtraction = () => {
    setTab(TAB_NAME.EXTRACTED_FIELDS);

    const fields = join(error.valueAttr, ",");
    browserWindow!.location.hash = fields;

    track("Edit extraction/issue", {
      errorCode: error.errorCode,
      fields,
    });
  };

  const ignoreIssueLabel = (
    <IconText
      icon={Icons.DISABLED_ALARM}
      color={
        canUpdateCertificateErrors ? ICON_COLORS.BLACK : ICON_COLORS.GREY_3
      }
    >
      {`${ignoreButtonText} issue`}
    </IconText>
  );

  const reportIssueLabel = (
    <IconText
      icon={Icons.NEWSLETTER}
      color={canReportDataIssue ? ICON_COLORS.BLACK : ICON_COLORS.GREY_3}
    >
      Report issue
    </IconText>
  );

  const editExtractionLabel = (
    <IconText
      icon={Icons.PENCIL}
      color={canEditCertificates ? ICON_COLORS.BLACK : ICON_COLORS.GREY_3}
    >
      Edit extraction
    </IconText>
  );

  const actions: Array<ActionsProps> = [
    {
      label: ignoreIssueLabel,
      disabled: !canUpdateCertificateErrors || loading,
      onClick: () => handleIgnoreClick(),
    },
    {
      label: reportIssueLabel,
      disabled: !canReportDataIssue,
      onClick: () => handleReportIssue(),
    },
  ];

  if (!UNEDITABLE_ERRORS.includes(error.errorCode)) {
    actions.unshift({
      label: editExtractionLabel,
      disabled: !canEditCertificates,
      onClick: handleEditExtraction,
    });
  }

  return actions;
};

type LocationWithHash = Pick<typeof window.location, "hash">;
type WindowWithLocation = { location: LocationWithHash };
export type ValidationErrorProps = {
  label: string;
  value?: Maybe<string>;
  error: ValidationError;
  setTab: (tab: TAB_NAME) => void;
  refetch?: boolean;
  browserWindow?: WindowWithLocation;
};

export default ({
  label,
  value,
  error,
  setTab,
  refetch,
  browserWindow = window,
}: ValidationErrorProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isGuest } = useContext(AuthContext);

  if (isEmpty(value) && !isNumber(value)) {
    value = EMPTY_VALUE;
  } else {
    value = `${value}`;
  }

  return (
    <CertificateField
      style={!error.ignored && error.type ? error.type : "default"}
      label={label}
      value={value}
      isOpen={isExpanded}
      showActions={!isGuest}
      onOpen={() => setIsExpanded(true)}
      onClose={() => setIsExpanded(false)}
      actions={validationErrorActions({
        label,
        value,
        error,
        refetch,
        setTab,
        browserWindow,
      })}
    >
      <FieldDetailWrapper>
        <tbody>
          <tr>
            <Label>{error.ignored ? "Ignored" : upperFirst(error.type)}:</Label>
            <td>{error.issue}</td>
          </tr>
          {error.ignored && error.ignoredReason && (
            <tr>
              <Label>Reason:</Label>
              <td>{error.ignoredReason}</td>
            </tr>
          )}
          {error.note && (
            <tr>
              <Label>Note:</Label>
              <td>{error.note}</td>
            </tr>
          )}
        </tbody>
      </FieldDetailWrapper>
    </CertificateField>
  );
};
