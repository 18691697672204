import React, { useCallback } from "react";
import { debounce } from "lodash";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidSearchQuery } from "common/utils/strings";
import { CategoryFieldValues, Field } from "common-client/utils/Search";
import { Icon } from "../Common/Icons/LucideIcons";
import { Text } from "../Inputs";
import { IconWrapper, InputWrapper } from "./__styles__/Search";

export const SearchField = ({
  listedField,
  control,
  handleOnChange,
  deactivateCategorySelectionStatus,
  onSearchFieldClear,
}: {
  listedField: Field;
  control: Control;
  handleOnChange: () => void;
  deactivateCategorySelectionStatus: () => void;
  onSearchFieldClear: (value: CategoryFieldValues) => void;
}) => {
  const debouncedOnChange = useCallback(debounce(handleOnChange, 250), []);
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={listedField.value}
      render={({ field }) => (
        <InputWrapper>
          <Text
            data-testid={`${listedField.value}-search-input`}
            name={listedField.value}
            placeholder={t("search-placeholder")}
            onChange={value => {
              if (isValidSearchQuery(value)) {
                field.onChange(value);
                debouncedOnChange();
              }
            }}
            onClick={deactivateCategorySelectionStatus}
            size="small"
            value={field.value}
            maxLength={256}
            style={{
              padding: 0,
              border: "none",
            }}
          />
          {field.value && (
            <IconWrapper
              onClick={() => {
                onSearchFieldClear(listedField.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  onSearchFieldClear(listedField.value);
                }
              }}
              data-testid={`${listedField.value}-clear`}
              tabIndex={0}
            >
              <Icon iconName="x" color="contentSecondary" size="16" />
            </IconWrapper>
          )}
        </InputWrapper>
      )}
    />
  );
};
