import React from "react";
import { useHistory } from "react-router-dom";
import { SUBDOMAIN_PATTERN } from "common/constants";
import { buildFullLink } from "common/routing";
import { extractSubdomain, getAppPrefix } from "common/utils/url";
import { useGetUserAccountsQuery } from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import {
  getEmailParamFromURL,
  parsePrevLocationParam,
} from "../../utils/params";
import FullPageLoadingScreen from "../Common/FullPageLoadingScreen";
import { USER_LOGIN } from "./__queries__";
import LoginPage from "./LoginPage";

const UserLoginPage = () => {
  const email = getEmailParamFromURL("provideCredentials");
  const history = useHistory();
  const { addErrorToast } = useStatusToasts();
  const prev = parsePrevLocationParam("provideCredentials");
  const prefix = getAppPrefix();

  if (!email) {
    history.push("/login");

    return <FullPageLoadingScreen />;
  }

  const { data: accounts, loading: accountsLoading } = useGetUserAccountsQuery({
    variables: {
      email,
    },
    onError: () => {
      addErrorToast(
        "There was an issue fetching your accounts. Please try again."
      );
    },
  });

  if (accountsLoading) {
    return <FullPageLoadingScreen />;
  }

  const currentSubdomain = extractSubdomain({
    url: window.location.href,
    pattern: SUBDOMAIN_PATTERN.LOGGED_IN_ENTITY,
  });

  const currentAccount = accounts?.getUserAccountsByEmail?.find(
    account => account.subdomain === currentSubdomain
  );

  const backUrl = buildFullLink("login", {
    params: {},
    queryParams: {
      prev,
    },
    prefix,
  });

  if (!currentAccount) {
    window.location.href = backUrl;
    return null;
  }

  return (
    <LoginPage
      loginMutation={USER_LOGIN}
      fallbackPath="/map"
      event="Logged in"
      initialTitle="Sign in to your account"
      passwordResetPath="/password-reset"
      startingEmail={email}
      accounts={accounts?.getUserAccountsByEmail ?? []}
      currentAccount={currentAccount}
    />
  );
};

export default UserLoginPage;
