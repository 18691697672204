import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
} from "react";
import Collapsible from "react-collapsible";
import ArrowDown from "../../images/arrow-down-black.svg";
import ArrowUp from "../../images/arrow-up-black.svg";
import { track } from "../../utils/tracking";
import { CollapseIcon, Group, Title } from "./__styles__/LayerSection";

export type LayerSectionContextType = {
  show: boolean;
  toggleShow: Dispatch<SetStateAction<boolean>>;
};

export const LayerSectionContext = createContext<
  LayerSectionContextType | undefined
>(undefined);

interface LayerSectionProps {
  title: string;
  testId: string;
  children: ReactNode;
}

const LayerSection = ({ title, testId, children }: LayerSectionProps) => {
  const [show, toggleShow] = React.useState(false);
  const titleId = title.replace(/ /g, "-").toLowerCase();

  const trigger = (
    <Group
      id={titleId}
      role="button"
      aria-expanded={show}
      aria-controls={`${titleId}-content`}
      data-testid={`${testId}-trigger`}
      tabIndex={0}
    >
      <Title>{title}</Title>
      <CollapseIcon src={show ? ArrowUp : ArrowDown} />
    </Group>
  );

  return (
    <LayerSectionContext.Provider value={{ show, toggleShow }}>
      <div data-testid={testId}>
        <Collapsible
          trigger={trigger}
          onOpen={() => {
            toggleShow(true);
            track("Map Layers section opened", {
              sectionName: title,
            });
          }}
          onClose={() => toggleShow(false)}
          transitionTime={50}
        >
          <section id={`${titleId}-content`} aria-labelledby={titleId}>
            {children}
          </section>
        </Collapsible>
      </div>
    </LayerSectionContext.Provider>
  );
};

export default LayerSection;
