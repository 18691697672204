import "./__styles__/Marker.scss";
import React from "react";
import { Marker } from "react-map-gl";

export default ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) => {
  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      offsetTop={-34}
      offsetLeft={-17}
    >
      <span styleName="marker" />
    </Marker>
  );
};
