import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "../Common/FullWidthLayout";
import { PropertiesTable } from "./PropertiesTable";

export default () => (
  <Layout title="Properties">
    <Switch>
      <Route path="/properties" component={PropertiesTable} />
    </Switch>
  </Layout>
);
