import React from "react";
import { useHistory } from "react-router";
import { getPath } from "common/routing";
import { Button } from "../Common/Button";
import { Sections } from "../Common/Layout";
import { Body, Title } from "../Common/Typography";
import { Container, Wrapper } from "./__styles__/UserInvite";

export const UserInviteNotFound = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Container>
        <Sections>
          <Title size="large" type="semiBold">
            This invitation could not be found
          </Title>
          <Body size="large" type="regular" color="contentPrimary">
            Unfortunately, this account invitation does not exist. If you have a
            Forerunner account, sign in using the link below. If you're looking
            to create an account or need help, please reach out to us at{" "}
            <a href="mailto:support@withforerunner.com">
              support@withforerunner.com
            </a>
          </Body>
          <Button
            styleVariant="primary"
            size="medium"
            onClick={() => history.push(getPath("login"))}
            style={{ justifyContent: "center" }}
          >
            Log in
          </Button>
        </Sections>
      </Container>
    </Wrapper>
  );
};
