import React, { ReactNode } from "react";
import Collapsible from "react-collapsible";
import { ExtractVariants } from "../../stitches.config";
import {
  CollapsibleWrapper,
  ExpandableContent,
  ExpandableListWrapper,
  IconContainer,
  Row,
  TriggerWrapper,
} from "./__styles__/ExpandableList";
import { Icon } from "./Icons/LucideIcons";
import { Body } from "./Typography";

type ListItem = {
  title: string;
  expandableContent: ReactNode;
  rightContent?: ReactNode;
  leftContent?: ReactNode;
  showDivider?: boolean;
  // extension allows you to give the list negative margin
  // to offset the padding / margin of any wrapping containers
  extension?: ExtractVariants<typeof ExpandableListWrapper>["extension"];
};

export const ExpandableListRow = ({
  title,
  expandableContent,
  rightContent,
  leftContent,
  extension,
  showDivider = true,
}: ListItem) => {
  const [show, toggleShow] = React.useState(false);

  const trigger = (
    <TriggerWrapper>
      <Body size="default" type="emphasis" as="div">
        {title}
      </Body>
      <IconContainer size="default">
        <Icon
          iconName={show ? "chevron-up" : "chevron-down"}
          color="contentSecondary"
          size={16}
        />
      </IconContainer>
    </TriggerWrapper>
  );

  return (
    <Row data-testid={"row"} showDivider={showDivider} extension={extension}>
      {leftContent}
      <CollapsibleWrapper>
        <Collapsible
          trigger={trigger}
          transitionTime={50}
          onOpen={() => {
            toggleShow(true);
          }}
          onClose={() => {
            toggleShow(false);
          }}
        >
          <ExpandableContent
            tabIndex={0}
            show={show}
            size="default"
            type="regular"
            as="div"
          >
            {expandableContent}
          </ExpandableContent>
        </Collapsible>
      </CollapsibleWrapper>
      {rightContent}
    </Row>
  );
};

export const ExpandableList = ({
  list,
  extension,
  title,
}: {
  list: Array<ListItem>;
  extension?: ExtractVariants<typeof ExpandableListWrapper>["extension"];
  title?: string;
}) => {
  return (
    <ExpandableListWrapper extension={extension}>
      {title && (
        <Body size="large" type="emphasis" as="div">
          {title}
        </Body>
      )}
      {list.map((item, index) => (
        <ExpandableListRow key={index} {...item} extension={extension} />
      ))}
    </ExpandableListWrapper>
  );
};
