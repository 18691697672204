import { default as React, useEffect } from "react";
import { buildFullLink } from "common/routing";
import {
  arrayHasAtLeastTwoItems,
  arrayHasExactlyOneItem,
} from "common/utils/arrays";
import { getClientAppPrefix } from "common/utils/url";
import {
  IDENTIFY_USER_SCREEN_DISPLAY,
  IdentifyUserReducer,
  useIdentifyUserReducer,
} from "common-client/utils/useIdentifyUserFlow";
import { useGetUserAccountsLazyQuery } from "../../../generated/graphql";
import {
  EmailParamInput,
  getEmailParamFromURL,
  getLogoutReasonMessage,
  parsePrevLocationParam,
  PrevLocationParamInput,
} from "../../../utils/params";
import FullPageBackgroundWithLogo from "../../Common/FullPageBackgroundWithLogo";
import { Container } from "../CommonLoginComponents/__styles__/FormWithFullPageBackground";
import { AccountPicker } from "./AccountPicker";
import { ProvideEmail } from "./ProvideEmail";

export type Account = {
  id: string;
  name: string;
  logoUrl: string;
  subdomain: string;
};

export type LoginFormStructure = {
  email?: Maybe<string>;
};

export const IdentifyUser = () => {
  const startingError = getLogoutReasonMessage("login");
  const startingEmail = getEmailParamFromURL("login");

  const [state, dispatch] = useIdentifyUserReducer({
    defaultValue: startingEmail
      ? {
          currentScreen: IDENTIFY_USER_SCREEN_DISPLAY.ACCOUNT_PICKER,
          email: startingEmail,
          accounts: [],
          error: startingError ?? null,
        }
      : {
          currentScreen: IDENTIFY_USER_SCREEN_DISPLAY.PROVIDE_EMAIL,
          email: null,
          accounts: [],
          error: startingError ?? null,
        },
    useReducer: React.useReducer as IdentifyUserReducer,
  });

  const [getUserAccounts, { loading }] = useGetUserAccountsLazyQuery();

  const prev = parsePrevLocationParam("login");

  const onSelectAccount = ({
    email,
    account,
  }: {
    email: string;
    account: { subdomain: string };
  }) => {
    const prefix = getClientAppPrefix(account.subdomain);

    const loginUrl = buildFullLink("provideCredentials", {
      params: {},
      queryParams: {
        prev,
        email,
      } satisfies PrevLocationParamInput & EmailParamInput,
      prefix,
    });

    window.location.href = loginUrl;
  };

  const findAccounts = async (email: string) => {
    await getUserAccounts({
      variables: { email },
      onError: () => {
        dispatch({
          type: "setError",
          data: {
            error: {
              message:
                "An error occurred. Please try again. If the problem persists, please email us at support@withforerunner.com.",
            },
          },
        });
      },
      onCompleted: data => {
        if (arrayHasAtLeastTwoItems(data.getUserAccountsByEmail)) {
          dispatch({
            type: "displayAccountPicker",
            data: {
              accounts: data.getUserAccountsByEmail,
              email,
            },
          });
        } else if (arrayHasExactlyOneItem(data.getUserAccountsByEmail)) {
          onSelectAccount({
            email,
            account: data.getUserAccountsByEmail[0],
          });
        } else {
          dispatch({
            type: "setError",
            data: {
              error: {
                message:
                  "That Forerunner account doesn’t exist. Enter a different email or reach out to your account manager if you need assistance.",
              },
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    if (startingEmail) {
      void findAccounts(startingEmail);
    }
  }, [startingEmail]);

  return (
    <FullPageBackgroundWithLogo>
      <Container>
        {state.currentScreen === IDENTIFY_USER_SCREEN_DISPLAY.PROVIDE_EMAIL && (
          <ProvideEmail
            state={state}
            onSubmit={findAccounts}
            loading={loading}
          />
        )}
        {state.currentScreen ===
          IDENTIFY_USER_SCREEN_DISPLAY.ACCOUNT_PICKER && (
          <AccountPicker
            state={state}
            dispatch={dispatch}
            onSubmit={onSelectAccount}
          />
        )}
      </Container>
    </FullPageBackgroundWithLogo>
  );
};

export default IdentifyUser;
