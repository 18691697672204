import React from "react";
import { useFormContext } from "react-hook-form";
import { Tags } from "../../../../../Inputs/Tags";
import { ParcelImportWizardFormData } from "../types";
import { useFieldError } from "./hooks";

const ImportFieldCastOptionInput = ({
  fieldName,
  castOptionValidator,
}: {
  fieldName: `mapping.${string}.caseValues.${string}`;
  castOptionValidator: () => string | undefined;
}) => {
  const { control } = useFormContext<ParcelImportWizardFormData>();
  const fieldError = useFieldError({ fieldName });

  return (
    <Tags
      control={control}
      error={fieldError}
      name={fieldName}
      rules={{
        validate: castOptionValidator,
      }}
    />
  );
};

export default ImportFieldCastOptionInput;
