import {
  ColumnDef,
  ColumnOrderState,
  ColumnSizingState,
  SortingState,
} from "@tanstack/react-table";
import { uniqBy } from "lodash";
import { useHistory, useLocation } from "react-router";
import { QueryDescription } from "common/utils/queryBuilder";
import { QueryFileTableResult } from "../../DocumentUploads/__queries__/table";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../ActionCell";
import { GenerateTableState } from "../Tables/hooks";

export const sanityCheckLocalColumnOrder = ({
  localColumnOrder,
  defaultColumnIds,
}: {
  localColumnOrder: Maybe<ColumnOrderState>;
  defaultColumnIds: Array<string>;
}): Maybe<ColumnOrderState> => {
  if (!localColumnOrder) {
    return null;
  }

  if (localColumnOrder.some(columnId => !defaultColumnIds.includes(columnId))) {
    return defaultColumnIds;
  }

  //re-insert hidden column ids before action column on refresh
  if (localColumnOrder.length < defaultColumnIds.length) {
    const missingColumnIds = defaultColumnIds.filter(
      defaultColumnId => !localColumnOrder.includes(defaultColumnId!)
    );

    return [
      ...localColumnOrder.filter(
        columnId => columnId !== ACTION_COLUMN_DEF_CONSTANTS.id
      ),
      ...missingColumnIds,
      ACTION_COLUMN_DEF_CONSTANTS.id,
    ];
  }

  if (
    localColumnOrder[localColumnOrder.length - 1] !==
    ACTION_COLUMN_DEF_CONSTANTS.id
  ) {
    return [
      ...localColumnOrder.filter(
        columnId => columnId !== ACTION_COLUMN_DEF_CONSTANTS.id
      ),
      ACTION_COLUMN_DEF_CONSTANTS.id,
    ];
  }

  return null;
};

export const sanityCheckColumnSizing = <T,>({
  columnSizing,
  columns,
}: {
  columnSizing: ColumnSizingState;
  columns: ColumnDef<T>[];
}) => {
  const possibleColumnIds = columns.map(column => column.id!);
  if (
    Object.keys(columnSizing).some(
      columnId => !possibleColumnIds.includes(columnId)
    )
  ) {
    return {};
  }

  return null;
};

export const sanityCheckSorting = <T,>({
  sorting,
  columns,
}: {
  sorting?: SortingState;
  columns: ColumnDef<T>[];
}) => {
  const possibleColumnIds = columns.map(column => column.id!);

  if (
    sorting?.some(
      sortDefinition => !possibleColumnIds.includes(sortDefinition.id)
    )
  ) {
    return [];
  }

  return null;
};

export const fieldId = (field: { table: string; name: string }) =>
  `${field.table}.${field.name}`;

export const mergeFieldGroups = (
  fields: Array<{ name: string; table: string }>,
  requiredFields: Readonly<Array<{ name: string; table: string }>>
) =>
  uniqBy(
    [...fields, ...requiredFields],
    ({ table, name }) => `${table}-${name}`
  );

export const initializeColumns = ({
  initialTableState,
  tanstackColumnDefinitions,
  initialQueryDescription,
  actionsColumn,
}: {
  initialTableState: GenerateTableState;
  tanstackColumnDefinitions: Array<ColumnDef<any>>;
  initialQueryDescription: QueryDescription;
  actionsColumn: ColumnDef<any>;
}) => {
  const tableStateToColumnDefinitions = (
    tableState: GenerateTableState | QueryDescription
  ) => {
    return tableState.fields.reduce((columns, field) => {
      const columnDefinition = tanstackColumnDefinitions.find(
        column => column.id === fieldId(field)
      );
      if (columnDefinition) {
        columns.push(columnDefinition);
      }
      return columns;
    }, [] as ColumnDef<QueryFileTableResult>[]);
  };

  let initialColumns = tableStateToColumnDefinitions(initialTableState);

  //if there are missing column definitions the table url is invalid and
  //we should revert to the default column definitions
  if (initialColumns.length !== initialTableState.fields.length) {
    initialColumns = tableStateToColumnDefinitions(initialQueryDescription);
  }

  //we always want to include the action column last
  initialColumns.push(actionsColumn);

  return initialColumns;
};

export type MinimalColumnDef<
  Fields extends Readonly<Array<{ name: string; table: string }>>
> = ColumnDef<{
  [Field in Fields[number] as `${Field["table"]}.${Field["name"]}`]: any;
}>;

export const useUpdateSavedView = () => {
  const { push } = useHistory();
  const { pathname, search: queryString } = useLocation();
  const searchParams = new URLSearchParams(queryString);

  return {
    updateSavedView: (viewId: string) => {
      searchParams.set("view", viewId);
      push(`${pathname}?${searchParams.toString()}`);
    },
  };
};
