import React, { useContext, useState } from "react";
import { uniq } from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { arrayHasExactlyOneItem } from "common/utils/arrays";
import { getSubmissionCategoryTranslationKey } from "common/utils/submissions";
import {
  CurrentUserQuery,
  SubmissionCategory,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { Radio } from "../../Inputs";
import { Radio as ReactHookFormRadio } from "../../Inputs/react-hook-form";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../__styles__/Modal";
import { Button } from "../Button";
import Modal from "../Modal";
import { Body, Title } from "../Typography";

export const CreateSubmissionButton = ({
  onSubmit,
  disabled,
  submissionTypeFilter,
  buttonText = "Add new",
  hideIfNoOptions = false,
  ...props
}: Omit<SelectSubmissionTypeProps, "onCancel" | "submissionTypes"> & {
  disabled: boolean;
  submissionTypeFilter: (
    submissionType: NonNullable<
      CurrentUserQuery["currentUser"]
    >["account"]["submissionTypes"][number]
  ) => boolean;
}) => {
  const { account } = useContext(AuthContext);
  const submissionTypes: Array<{
    id: string;
    name: string;
    category: SubmissionCategory;
  }> = account?.submissionTypes.filter(submissionTypeFilter) ?? [];

  const [showSelectInspectionModal] = useSelectSubmissionTypeModal({
    ...props,
    submissionTypes,
    onSubmit,
  });

  if (hideIfNoOptions && submissionTypes.length === 0) {
    return null;
  }

  return (
    <Button
      onClick={
        submissionTypes.length === 1
          ? () => onSubmit(submissionTypes[0]!.id)
          : showSelectInspectionModal
      }
      size="small"
      disabled={disabled || submissionTypes.length === 0}
      styleVariant="primary"
      leftIconName="plus"
    >
      {buttonText}
    </Button>
  );
};

export type SelectSubmissionTypeProps = {
  objectDisplay?: string;
  onCancel: () => void;
  onSubmit: (submissionTypeId: string) => void;
  submissionTypes: Array<{
    id: string;
    name: string;
    category: SubmissionCategory;
  }>;
  buttonText?: string;
  hideIfNoOptions?: boolean;
};

const useSelectSubmissionTypeModal = (
  props: Omit<SelectSubmissionTypeProps, "onCancel">
) => {
  const [showModal, hideModal] = useModal(() => (
    <Modal onRequestClose={hideModal}>
      <SelectSubmissionType onCancel={hideModal} {...props} />
    </Modal>
  ));

  return [showModal, hideModal];
};

type SelectSubmissionTypeFormStructure = {
  submissionTypeId: string;
};

const SelectSubmissionType = ({
  onSubmit,
  onCancel,
  objectDisplay,
  submissionTypes,
}: SelectSubmissionTypeProps) => {
  const { t } = useTranslation();
  const categories = uniq(submissionTypes.map(f => f.category));
  const [submissionCategory, setSubmissionCategory] = useState<
    SubmissionCategory | ""
  >(arrayHasExactlyOneItem(categories) ? categories[0] : "");

  const [submissionCategorySelected, setSubmissionCategorySelected] =
    useState<boolean>(!!submissionCategory);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<SelectSubmissionTypeFormStructure>();

  if (categories.length > 1 && !submissionCategorySelected) {
    const categoryTranslationKeys = getSubmissionCategoryTranslationKey({
      isUpper: true,
    });
    return (
      <Container>
        <HeaderSection>
          <Title size="large" type="semiBold">
            {t("property-new-record-category-selection-header")}
          </Title>
          <Body size="default" type="regular">
            {objectDisplay
              ? t(
                  "property-new-record-category-selection-with-location-subtext",
                  { location: objectDisplay }
                )
              : t("property-new-record-category-selection-no-location-subtext")}
          </Body>
        </HeaderSection>
        <ContentSection>
          <Radio
            name="submissionCategory"
            value={submissionCategory}
            options={categories.map(category => ({
              value: category,
              text: t(categoryTranslationKeys[category]),
            }))}
            onChange={value =>
              setSubmissionCategory(value as SubmissionCategory)
            }
          />
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={onCancel} size="medium">
              {t("property-new-record-button-cancel")}
            </Button>
            <Button
              styleVariant="primary"
              onClick={() => setSubmissionCategorySelected(true)}
              disabled={!submissionCategory}
              size="medium"
            >
              {t("property-new-record-button-next")}
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </Container>
    );
  }

  const translatedCategory = submissionCategory
    ? t(getSubmissionCategoryTranslationKey({})[submissionCategory])
    : submissionCategory;

  return (
    <Container>
      <HeaderSection>
        <Title size="large" type="semiBold">
          {t("property-new-record-type-selection-header", {
            category: translatedCategory,
          })}
        </Title>
        <Body size="default" type="regular">
          {objectDisplay
            ? t("property-new-record-type-selection-with-location-subtext", {
                category: translatedCategory,
                location: objectDisplay,
              })
            : t("property-new-record-type-selection-no-location-subtext", {
                category: translatedCategory,
              })}
        </Body>
      </HeaderSection>
      <ContentSection>
        <ReactHookFormRadio
          control={control}
          name="submissionTypeId"
          options={submissionTypes
            .filter(
              submissionType => submissionType.category === submissionCategory
            )
            .map(f => ({ value: f.id, text: f.name }))}
          required={true}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            {t("property-new-record-button-cancel")}
          </Button>
          <Button
            styleVariant="primary"
            onClick={handleSubmit(
              (formData: SelectSubmissionTypeFormStructure) =>
                onSubmit(formData.submissionTypeId)
            )}
            disabled={!isValid}
            size="medium"
          >
            {t("property-new-record-button-create")}
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
