import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from "react";
import Collapsible from "react-collapsible";
import ArrowDown from "../../images/arrow-down-black.svg";
import ArrowUp from "../../images/arrow-up-black.svg";
import { track } from "../../utils/tracking";
import { Switch } from "../Inputs";
import { SwitchWrapper } from "./__styles__/Layer";
import {
  CollapseIcon,
  Group as GroupWrapper,
  GroupTitle,
  Title,
} from "./__styles__/LayerSection";
import { Group, LayerContext } from "./layers";
import { LayerSectionContext } from "./LayerSection";

export type LayerGroupContextType = {
  show: boolean;
  toggleShow: Dispatch<SetStateAction<boolean>>;
};

export const LayerGroupContext = createContext<
  LayerGroupContextType | undefined
>(undefined);

interface LayerGroupProps<G extends Group> {
  title: string;
  sectionName: string;
  testId: string;
  group: G;
  children: ReactNode;
}

const LayerGroup = <G extends Group>({
  group,
  title,
  sectionName,
  testId,
  children,
}: LayerGroupProps<G>) => {
  const [show, toggleShow] = React.useState(false);
  const { toggleLayer, isLayerGroupVisible } = useContext(LayerContext);
  const { show: layerSectionExpanded } = useContext(LayerSectionContext) ?? {};
  const layerGroupId = `${group}-${title.replace(/ /g, "-").toLowerCase()}`;

  const handleToggle =
    ({ group }: { group: LayerGroupProps<G>["group"] }) =>
    (isVisible: boolean) => {
      if (group) {
        track("Map Layers Changed", {
          layerName: title,
          layerGroup: group,
          visible: isVisible,
        });
        toggleLayer({
          group,
          isVisible,
        });
      }
    };

  const stopCollapsibleTrigger = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const trigger = (
    <GroupWrapper>
      <GroupTitle
        id={layerGroupId}
        role="button"
        aria-expanded={show}
        aria-controls={`${layerGroupId}-content`}
        tabIndex={layerSectionExpanded ? 0 : -1}
        data-testid={`${testId}-trigger`}
      >
        <Title layerGroup>{title}</Title>
        <CollapseIcon layerGroup src={show ? ArrowUp : ArrowDown} />
      </GroupTitle>
      <SwitchWrapper onClick={stopCollapsibleTrigger}>
        <Switch
          name={title.toLowerCase().replace(/\s/g, "-")}
          onChange={handleToggle({ group })}
          value={group ? isLayerGroupVisible({ group }) : false}
          size="tiny"
          disabled={false}
          tabIndex={layerSectionExpanded ? 0 : -1}
        />
      </SwitchWrapper>
    </GroupWrapper>
  );

  return (
    <LayerGroupContext.Provider value={{ show, toggleShow }}>
      <div data-testid={testId}>
        <div>
          <Collapsible
            trigger={trigger}
            onOpen={() => {
              toggleShow(true);
              track("Map Layers section opened", {
                sectionName,
                subSectionName: title,
              });
            }}
            onClose={() => toggleShow(false)}
            transitionTime={50}
          >
            <section
              id={`${layerGroupId}-content`}
              aria-labelledby={layerGroupId}
            >
              {children}
            </section>
          </Collapsible>
        </div>
      </div>
    </LayerGroupContext.Provider>
  );
};

export default LayerGroup;
