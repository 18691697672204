import "./__styles__/Icon.scss";
import React from "react";

const ArcGIS = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 1H1V23.5H19V1Z"
      stroke="#63CC6D"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M13.8077 12.3844C13.6879 12.3844 13.5709 12.3969 13.4581 12.4197C13.4581 12.4079 13.4615 12.3969 13.4615 12.3844C13.4615 10.4729 11.9115 8.92285 10 8.92285C8.08855 8.92285 6.53847 10.4729 6.53847 12.3844C6.53847 12.3969 6.54193 12.4079 6.54193 12.4197C6.42909 12.3969 6.31209 12.3844 6.19232 12.3844C5.23624 12.3844 4.46155 13.1591 4.46155 14.1152C4.46155 15.0712 5.23624 15.8459 6.19232 15.8459C6.50939 15.8459 13.4906 15.8459 13.8077 15.8459C14.7638 15.8459 15.5385 15.0712 15.5385 14.1152C15.5385 13.1591 14.7638 12.3844 13.8077 12.3844Z"
      stroke="#63CC6D"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default ArcGIS;
