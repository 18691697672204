import "./__styles__/Icon.scss";
import React from "react";

const InformationOutline = ({ fill = "0c111b" }: { fill?: string }) => {
  return (
    <svg
      width="15px"
      height="15px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <rect id="Artboard1" x="0" y="0" width="15" height="15" fill="none" />
      <path
        d="M7.5,5c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1Z"
        fill={fill}
        fillRule="nonzero"
      />
      <path
        d="M8.056,7.494c-0,-0.307 -0.249,-0.556 -0.556,-0.556c-0.307,0 -0.556,0.249 -0.556,0.556l0,3.982c0,0.307 0.249,0.556 0.556,0.556c0.307,-0 0.556,-0.249 0.556,-0.556l-0,-3.982Z"
        fill={fill}
      />
      <path
        d="M7.5,15c4.142,0 7.5,-3.358 7.5,-7.5c0,-4.142 -3.358,-7.5 -7.5,-7.5c-4.142,0 -7.5,3.358 -7.5,7.5c0,4.142 3.358,7.5 7.5,7.5Zm0,-1c-3.59,0 -6.5,-2.91 -6.5,-6.5c0,-3.59 2.91,-6.5 6.5,-6.5c3.59,0 6.5,2.91 6.5,6.5c0,3.59 -2.91,6.5 -6.5,6.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default InformationOutline;
