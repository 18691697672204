import "./__styles__/IssueBanner.scss";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../Authorization/AuthContext";
import { Button } from "../Common/Button";
import { useIssueModal } from "./IssueModal";

export type IssueBannerProps = {
  address?: Maybe<string>;
  coordinates: readonly [number, number];
  certificateId?: string;
  showEditButton?: boolean;
  propertyId?: Maybe<string>;
};

// factoring this out to use as a hook for EmptyStateComponent buttons
export const useReportDataIssueButton = (
  issueModalProps: Pick<
    IssueBannerProps,
    "address" | "coordinates" | "certificateId"
  >
) => {
  const [showIssueModal] = useIssueModal(issueModalProps);
  const { authorized } = useContext(AuthContext);
  const canShowReportDataIssue = authorized({
    resource: RESOURCE_NAME.ISSUE,
    permission: "create",
  });

  return {
    canShowReportDataIssue,
    showIssueModal,
    issueButtonLabel: "Report data issue",
  };
};

const IssueBanner = ({
  address,
  coordinates,
  certificateId,
  showEditButton = false,
  propertyId,
}: IssueBannerProps) => {
  const history = useHistory();

  const { canShowReportDataIssue, showIssueModal, issueButtonLabel } =
    useReportDataIssueButton({ address, coordinates, certificateId });

  return (
    <div styleName="report-issue-banner">
      <p>See an issue with the data? Let us know!</p>
      <div styleName="report-issue-buttons">
        <Button
          styleVariant="primary"
          onClick={() => showIssueModal()}
          disabled={!canShowReportDataIssue}
          size="small"
        >
          {issueButtonLabel}
        </Button>
        {showEditButton && propertyId && (
          <Button
            onClick={() =>
              history.push({
                pathname: `/backoffice/properties/${propertyId}`,
              })
            }
            size="small"
            styleVariant="outlineLight"
          >
            Edit property
          </Button>
        )}
      </div>
    </div>
  );
};

export default IssueBanner;
