import React, { useContext } from "react";
import { orderBy, toLower } from "lodash";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { isUUID } from "common/utils/strings";
import {
  ErrorCode,
  RevalidateElevationCertificatesMutationFn,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import { Label } from "../../../Inputs";
import {
  Checkbox,
  Datepicker,
  Select,
  Textarea,
} from "../../../Inputs/react-hook-form";
import { InputGrid } from "../../Account/PropertyData/__styles__/floodInfoModals";
import {
  IssuedAtEndDatePickerContainer,
  IssuedAtRow,
  IssuedAtStartDatePickerContainer,
  RevalidateEcsFormSection,
} from "./__styles__/revalidateEcsModal";

type ErrorCodeAll = "ALL";

export type RevalidateEcsFormStructure = {
  certificateIds?: string;
  ecErrors?: Array<ErrorCode | ErrorCodeAll>;
  issuedAtStartDate?: Date;
  issuedAtEndDate?: Date;
  filterType?: FilterOptions;
  shouldPreserveIneligibleErrors: boolean;
};

export interface RevalidateEcsModalProps {
  onCancel: () => void;
  onSubmit: RevalidateElevationCertificatesMutationFn;
  onUpdate: () => void;
}

export type FilterOptions = "certificateIds" | "issuedAtRange";

export const useRevalidateEcsModal = ({
  onSubmit,
}: Pick<RevalidateEcsModalProps, "onSubmit">) => {
  const [show, hideRevalidateEcsModal] = useModal(
    () => (
      <Modal onRequestClose={hideRevalidateEcsModal}>
        <RevalidateEcsModal
          onSubmit={onSubmit}
          onCancel={hideRevalidateEcsModal}
          onUpdate={hideRevalidateEcsModal}
        />
      </Modal>
    ),
    []
  );

  return [show, hideRevalidateEcsModal] as const;
};

const splitCertificateIds = (certificateIds: string | undefined) => {
  return (
    certificateIds
      ?.split(",")
      .map(id => id.trim())
      .filter(Boolean) || []
  );
};

export const RevalidateEcsModal = ({
  onSubmit,
  onUpdate,
  onCancel,
}: RevalidateEcsModalProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    resetField,
    setValue,
  } = useForm<RevalidateEcsFormStructure>();
  const { account } = useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const filterType = watch("filterType");
  const handleSubmitCallback = async (data: RevalidateEcsFormStructure) => {
    // if 'ALL' is selected, we don't want to send any error codes (i.e. we want to revalidate all errors)
    let ecErrors: Array<ErrorCode> | undefined = [];
    ecErrors = data.ecErrors?.includes("ALL" as ErrorCodeAll)
      ? undefined
      : (data.ecErrors as Array<ErrorCode>);

    await onSubmit({
      variables: {
        data: {
          accountId: account!.id,
          certificateIds:
            data.filterType === "certificateIds"
              ? splitCertificateIds(data.certificateIds)
              : undefined,
          errorCodes: ecErrors,
          issuedAtStartDate: data.issuedAtStartDate,
          issuedAtEndDate: data.issuedAtEndDate,
          shouldPreserveIneligibleErrors: data.shouldPreserveIneligibleErrors,
        },
      },
      onCompleted: () => {
        addSuccessToast("EC Revalidation started successfully!");
        onUpdate();
      },
      onError: error => addErrorToast(error.message),
    });
  };

  const errorCodeOptions = [
    ...[
      {
        value: "ALL",
        label: "All codes",
      },
      ...orderBy(Object.values(ErrorCode), toLower).map(value => ({
        value,
        label: value,
      })),
    ],
  ];

  const filterOptions = [
    {
      value: "issuedAtRange" as FilterOptions,
      label: "Issued At Range",
    },
    {
      value: "certificateIds" as FilterOptions,
      label: "IDs",
    },
  ];

  return (
    <Container>
      <HeaderSection>
        <h1>Revalidate Elevation Certificates</h1>
      </HeaderSection>
      <RevalidateEcsFormSection>
        <ContentSection style={{ minHeight: "330px" }}>
          <InputGrid>
            <div>
              <Label text="Filter method" htmlFor="filterType" required />
              <Select
                control={control}
                rules={{ required: "This field is required" }}
                error={errors.filterType?.message}
                name="filterType"
                options={filterOptions}
                size="medium"
                onChange={newFilterType => {
                  switch (newFilterType) {
                    case "certificateIds":
                      resetField("issuedAtStartDate");
                      resetField("issuedAtEndDate");
                      break;
                    case "issuedAtRange":
                      resetField("certificateIds");
                      break;
                  }
                }}
              />
            </div>
            <div>
              <Label text="EC error codes" htmlFor="ecErrors" required />
              <Select
                control={control}
                rules={{ required: "This field is required" }}
                name="ecErrors"
                // @ts-ignore
                options={errorCodeOptions}
                size="medium"
                isMulti={true}
                error={errors.ecErrors?.message}
                onChange={newEcErrors => {
                  //@ts-ignore
                  if (newEcErrors?.includes("ALL")) {
                    setValue("ecErrors", ["ALL"]);
                  }
                }}
              />
            </div>
            <Checkbox
              control={control}
              name="shouldPreserveIneligibleErrors"
              label="Preserve errors on ineligible ECs"
            />
            <div
              style={{
                display: filterType === "certificateIds" ? "block" : "none",
              }}
            >
              <Label
                text="Elevation Certificate IDs"
                htmlFor="certificateIds"
                required
              />
              <Textarea
                id="certificateIds"
                draggable={false}
                {...register("certificateIds", {
                  validate: value => {
                    if (filterType === "issuedAtRange") return;
                    const idList = splitCertificateIds(value);

                    const allUUIDs = idList.every(id => {
                      return isUUID(id);
                    });

                    if (allUUIDs && idList.length > 0) {
                      return;
                    } else {
                      return "Please enter a comma separated list of certificate ids";
                    }
                  },
                })}
                placeholder="Enter a comma separated list of certificate IDs"
                compactLabel={true}
                name="certificateIds"
                data-testid="certificateIds"
                error={errors.certificateIds?.message}
                minRows={3}
              />
            </div>
            <IssuedAtRow visible={filterType === "issuedAtRange"}>
              <IssuedAtStartDatePickerContainer>
                <Datepicker
                  control={control as unknown as Control<FieldValues, any>}
                  name={"issuedAtStartDate"}
                  label={"Issued at start date"}
                  compactLabel={true}
                  rules={
                    filterType === "issuedAtRange"
                      ? { required: "This field is required" }
                      : { required: false }
                  }
                  required={filterType === "issuedAtRange"}
                />
              </IssuedAtStartDatePickerContainer>
              <IssuedAtEndDatePickerContainer>
                <Datepicker
                  control={control as unknown as Control<FieldValues, any>}
                  name={"issuedAtEndDate"}
                  label={"Issued at end date"}
                  compactLabel={true}
                />
              </IssuedAtEndDatePickerContainer>
            </IssuedAtRow>
          </InputGrid>
        </ContentSection>
      </RevalidateEcsFormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            onClick={handleSubmit(handleSubmitCallback)}
            disabled={!!Object.keys(errors).length}
          >
            Submit
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
