import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { PROJECT_TRACKING } from "common/constants";
import { SISDFilterOptions } from "common/utils/rules/types";
import {
  SisdRule,
  useUpdateSisdRuleMutation,
} from "../../../../generated/graphql";
import Table from "../../../Common/Tables/Table";
import { DropdownMenu } from "../../../Inputs";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { useDeleteSiSdRuleModal } from "./deleteSisdRuleModal";
import { useUpsertSisdRuleModal } from "./upsertSisdRuleModal";
import { displayNullableValue, greyWhenEmpty } from "./utils";

type SisdRulesTableData = {
  ruleDefinition?: {
    rules: Array<SisdRule>;
  };
};

const TrackingPeriodAlias = {
  [PROJECT_TRACKING.PER_PROJECT]: "Per project",
  [PROJECT_TRACKING.CUMULATIVE]: "Cumulative",
  [PROJECT_TRACKING.BUILDING_LIFETIME]: "Building lifetime",
  [PROJECT_TRACKING.CALENDAR_YEAR]: "Calendar year",
};

const SISDRulesTable = ({
  currentData,
  previousData,
  filterOptions,
  loading,
  onUpdate,
}: {
  currentData?: Maybe<SisdRulesTableData>;
  previousData?: Maybe<SisdRulesTableData>;
  filterOptions: Array<SISDFilterOptions>;
  loading: boolean;
  onUpdate: () => void;
}) => {
  const [updateSisdRule] = useUpdateSisdRuleMutation();

  const [showUpsertSisdRuleModal] = useUpsertSisdRuleModal({
    onSubmit: updateSisdRule,
    onUpdate,
    filterOptions,
  });

  const [showDeleteSisdRuleModal] = useDeleteSiSdRuleModal({ onUpdate });

  const renderActionButton = (rule: SisdRule) => {
    const actions: Array<ActionsProps> = [
      {
        label: "Edit rule",
        onClick: () =>
          showUpsertSisdRuleModal({
            existingRule: rule,
            maxPriority: currentData?.ruleDefinition?.rules.length,
            filterOptions: filterOptions,
          }),
      },
      {
        label: "Delete",
        variant: "red",
        onClick: () => showDeleteSisdRuleModal({ name: rule.name }),
      },
    ];

    return <DropdownMenu actions={actions} isTableAction />;
  };

  const columns: Array<ColumnDef<SisdRule>> = [
    {
      id: "priority",
      header: "Priority",
      accessorKey: "priority",
      size: 75,
      maxSize: 75,
      enableSorting: false,
      cell: ({ row }) => displayNullableValue(row.original.priority),
      meta: {
        getCellStyles: (context: CellContext<SisdRule, unknown>) => {
          return greyWhenEmpty<SisdRule>(context.row, "priority");
        },
      },
    },
    {
      id: "name",
      header: "Rule name",
      accessorKey: "name",
      enableSorting: false,
    },
    {
      id: "trackingPeriod",
      header: "Tracking period",
      accessorKey: "trackingPeriod",
      size: 140,
      enableSorting: false,
      cell: ({ row }) => TrackingPeriodAlias[row.original.trackingPeriod],
    },
    {
      id: "threshold",
      header: "Threshold",
      accessorKey: "threshold",
      size: 90,
      enableSorting: false,
      cell: ({ row }) => ((row.original.threshold / 1) * 100).toFixed(1) + "%",
    },
    {
      id: "actions",
      size: 75,
      maxSize: 75,
      enableSorting: false,
      cell: ({ row }) => renderActionButton(row.original),
    },
  ];

  return (
    <Table<SisdRule>
      columns={columns}
      currentData={currentData?.ruleDefinition?.rules ?? []}
      previousData={previousData?.ruleDefinition?.rules ?? []}
      loadingDetails={{
        loading,
        loadingText: "Loading Account SISD Rules...",
        noDataText: "No Account SISD Rules Found",
      }}
      tableStyleDetails={{
        hasRowActions: true,
        isCompact: true,
        hasFlushedSides: true,
      }}
      excludePaginationNav
    />
  );
};

export default SISDRulesTable;
