import "./__styles__/Icon.scss";
import React from "react";

const BooksMini = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1V5H0V1C0 0.7 0.2 0.5 0.5 0.5H2.5C2.8 0.5 3 0.7 3 1Z"
        fill="white"
      />
      <path
        d="M0 6V7C0 7.3 0.2 7.5 0.5 7.5H2.5C2.8 7.5 3 7.3 3 7V6H0Z"
        fill="white"
      />
      <path
        d="M7.6 5.30008L4.75 6.25008L5.05 7.20007C5.1 7.40007 5.3 7.60008 5.7 7.50008L7.6 6.85008C7.85 6.75008 8 6.50008 7.9 6.20008L7.6 5.30008Z"
        fill="white"
      />
      <path
        d="M7.25012 4.35005L6.00012 0.55005C5.90012 0.30005 5.65012 0.15005 5.35012 0.25005L3.45012 0.85005C3.35012 0.90005 3.00012 1.05005 3.15012 1.50005L4.45012 5.30005L7.25012 4.35005Z"
        fill="white"
      />
    </svg>
  );
};

export default BooksMini;
