import React, { useContext, useState } from "react";
import { isEmpty, omit } from "lodash";
import { useDropzone } from "react-dropzone";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { MIME_TYPE } from "common/constants";
import { useUpdateAccountBoundaryMutation } from "../../../../generated/graphql";
import { useMultipartFileUpload } from "../../../../hooks/useMultipartFileUpload";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import { SectionDivider } from "../../../Common/__styles__/FullPageFormLayout";
import { Button } from "../../../Common/Button";
import FullPageFormLayout from "../../../Common/FullPageFormLayout";
import { Body, Title } from "../../../Common/Typography";
import { SingleFileUpload } from "../../../Inputs/react-hook-form";
import { Section } from "../../__styles__/Content";
import { FormTitleWrapper } from "./__styles__/Operations";

type DispatchUpdateAccountBoundaryFormData = {
  fileUpload: { blob: File };
};

const DispatchBulkPropertyActionForm = () => {
  const { account } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const formMethods = useForm<DispatchUpdateAccountBoundaryFormData>();
  const { control, getValues, handleSubmit, register, watch } = formMethods;
  const history = useHistory();
  const [hasDispatchedOperation, setHasDispatchedOperation] = useState(false);

  const prevLocation = "/settings/data-imports/property-operations";
  const [uploadSourceFile, { loading: isUploading }] = useMultipartFileUpload({
    onError: () => addErrorToast("Failed to upload input file"),
  });

  const [dispatch, { loading: isDispatching }] =
    useUpdateAccountBoundaryMutation({
      onCompleted: () => {
        addSuccessToast("Dispatched update account boundary operation");
        setHasDispatchedOperation(true);
        history.push(prevLocation);
      },
      onError: () => {
        addErrorToast("Failed to dispatch update account boundary operation");
      },
    });

  const onSubmit = async () => {
    uploadSourceFile({
      file: getValues("fileUpload.blob"),
      onCompleted: async key => {
        await dispatch({
          variables: {
            data: {
              accountId: account!.id,
              inputFileKey: key,
            },
          },
        });
      },
    });
  };

  const fileUploadValue = watch("fileUpload");
  const missingRequiredField = isEmpty(fileUploadValue);
  const isLoading = isUploading || isDispatching;
  const isDisabled =
    isLoading || missingRequiredField || hasDispatchedOperation;

  return (
    <FullPageFormLayout
      subtitle="Account boundary update"
      prevLocation={prevLocation}
      rightContainer={
        <Button
          size={"small"}
          styleVariant={"primary"}
          onClick={handleSubmit(onSubmit)}
          disabled={isDisabled}
          loading={isLoading}
        >
          Update
        </Button>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Section>
            <FormTitleWrapper>
              <Title size="large" type="semiBold">
                Account boundary update
              </Title>
            </FormTitleWrapper>
            <Body type="regular" size="default" color="contentSecondary">
              Upload a GeoJSON file to start the update process. Once the update
              is complete you will receive an email with a link to download a
              CSV containing any properties that fall outside of the new account
              boundaries.
            </Body>
          </Section>
          <SectionDivider />
          <Section>
            <SingleFileUpload
              {...omit(register("fileUpload"), "ref")}
              control={control}
              allowedMimeTypes={[MIME_TYPE.GEOJSON]}
              name={"fileUpload"}
              useDropzone={useDropzone}
              label={"Source file"}
              description={"Upload a GeoJSON file"}
              required
            />
          </Section>
        </form>
      </FormProvider>
    </FullPageFormLayout>
  );
};

export default DispatchBulkPropertyActionForm;
