import React, { useCallback, useContext, useMemo } from "react";
import { debounce } from "lodash";
import { formatCoordinates } from "common/utils/coordinates";
import { SavedView } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { LayerContext, LayerContextType } from "../layers";
import SavedViewsLayer, {
  viewsLayerId,
  viewsSourceId,
} from "../layers/savedViews";
import {
  findRelevantFeature,
  LayerConfig,
  LayerHook,
  LayerHookBuilder,
  LayerId,
  Property,
} from "./types";
import { makePropertyFromFeature } from "./utils";

export type SavedViewsLayerConfig = LayerConfig<
  Array<Pick<SavedView, "id" | "query">>,
  Property
> & { savedViews: Array<{ id: string }> };

export const getInteractiveLayerIds = (
  savedViews: SavedViewsLayerConfig["value"],
  isLayerVisible: LayerContextType["isLayerVisible"]
) => {
  return (savedViews ?? [])
    .filter(({ id }) => isLayerVisible({ group: "savedViews", id }))
    .map(({ id }) => `${viewsLayerId}-${id}` as LayerId);
};

const savedViewsHook: LayerHookBuilder<SavedViewsLayerConfig> = ({
  config,
  helpers,
}) => {
  const { addErrorToast } = useStatusToasts();
  const { isLayerVisible } = useContext(LayerContext);
  const addErrorToastCallback = useCallback(() => {
    addErrorToast(
      `Unable to load table view data. Please try again. If the issue continues, contact support@withforerunner.com`
    );
  }, []);
  return useMemo<LayerHook>(() => {
    return {
      interactiveLayerIds: getInteractiveLayerIds(config.value, isLayerVisible),
      // Need to be lower than properties
      zIndex: -1,
      canHandleHover: () => !!config.interactive?.hover,
      canHandleClick: () => !!config.interactive?.click,
      onHover: ({ event }) => {
        const feature = findRelevantFeature(
          event,
          config.value?.map(({ id }) => `${viewsLayerId}-${id}` as LayerId) ??
            []
        );

        if (feature) {
          helpers.setCursor("pointer");
          helpers.emit({
            type: "setTooltip",
            data: {
              ...event.offsetCenter,
              text:
                feature.properties.address ??
                formatCoordinates({
                  latitude: feature.geometry.coordinates[1],
                  longitude: feature.geometry.coordinates[0],
                }),
            },
          });
        } else {
          const parcel = findRelevantFeature(event, "parcels");
          if (!parcel) {
            helpers.setCursor("grab");
          }
        }
      },
      onClick: ({ event }) => {
        const feature = findRelevantFeature(
          event,
          config.value?.map(({ id }) => `${viewsLayerId}-${id}` as LayerId) ??
            []
        );

        if (feature) {
          config.onClick?.(makePropertyFromFeature(feature));
          return true;
        } else {
          return false;
        }
      },
      render: () => {
        if (!config.savedViews.length) {
          return null;
        }

        return config.savedViews.map(savedView => (
          <SavedViewsLayer key={savedView.id} savedViewId={savedView.id} />
        ));
      },
      onFocusLost: () => {
        helpers.emit({ type: "removeTooltip", data: {} });
      },
      shouldHandleLoadError: error =>
        (
          config.value?.map(({ id }) => `${viewsSourceId}-${id}`) ?? []
        ).includes(error.sourceId),
      onLoadError: () => {
        debounce(() => {
          addErrorToastCallback();
        }, 1000)();
      },
    };
  }, [config]);
};

export default savedViewsHook;
