import React, { ReactNode } from "react";
import Collapsible from "react-collapsible";
import DropdownMenu, {
  ActionsProps,
  DropdownPosition,
} from "../../../Inputs/DropdownMenu";
import {
  ActionButtonWrapper,
  Field,
  FormField,
  FormFieldWrapper,
  Header,
} from "../__styles__/Form";
import { FieldWrapper } from "./ExtractedFields/__styles__/CertificateField";

export type CertificateFieldProps = {
  style?: "error" | "warning" | "default";
  label: string;
  value?: string;
  onClick?: () => void;
  isOpen?: boolean;
  isLowConfidence?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onElementIsOpen?: () => void;
  actions: Array<ActionsProps>;
  children?: ReactNode;
  showActions?: boolean;
  disabled?: boolean;
  position?: DropdownPosition;
};

export default ({
  style = "default",
  label,
  value,
  onClick,
  isOpen = false,
  isLowConfidence,
  onOpen,
  onElementIsOpen,
  onClose,
  children,
  showActions = false,
  actions,
  position,
}: CertificateFieldProps) => {
  const collapsibleField = (
    <Header expanded={isOpen} onClick={onClick}>
      <Field>{label}</Field>
      <Field isLowConfidence={isLowConfidence}>{value}</Field>
    </Header>
  );

  return (
    <FormFieldWrapper>
      <FormField>
        <FieldWrapper
          style={style}
          expanded={isOpen}
          data-testid="collapsed-field"
        >
          <Collapsible
            trigger={collapsibleField}
            transitionTime={50}
            open={isOpen}
            onOpen={() => onElementIsOpen?.()}
            onTriggerOpening={() => {
              onOpen?.();
            }}
            onTriggerClosing={() => {
              onClose?.();
            }}
            overflowWhenOpen="visible"
          >
            {/*temporary fix until we have inputs and issues in the extracted fields tab*/}
            {children ? children : <span>ㅤ</span>}
          </Collapsible>
        </FieldWrapper>
      </FormField>
      {showActions && (
        <ActionButtonWrapper>
          <DropdownMenu
            position={position}
            actions={actions}
            scrollIntoView={true}
          />
        </ActionButtonWrapper>
      )}
    </FormFieldWrapper>
  );
};
