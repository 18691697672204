import React from "react";
import { formatDateString } from "common/utils/dates";
import { Improvement, SubmissionTag } from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { StyleVariant, Tag } from "../../Common/Tag";
import {
  Description,
  Header,
  HeaderInfo,
  Item,
  TagsWrapper,
} from "./__styles__/ListItem";
import EditImprovementDropdown from "./EditImprovementDropdown";

export type PartialImprovement = Pick<
  Improvement,
  | "value"
  | "exclusion"
  | "id"
  | "date"
  | "kind"
  | "description"
  | "progress"
  | "hiddenFromPublic"
  | "__typename"
> & { tags?: Array<Omit<SubmissionTag, "uiSchemaPath">> };

export interface ListItemProps {
  address?: Maybe<string>;
  item: PartialImprovement;
  onUpdate: () => {};
  date: Date;
  title: string;
  info?: string;
  excluded?: boolean;
  showDots?: boolean;
}

const ListItem = ({
  address,
  onUpdate,
  item,
  date,
  title,
  info,
  excluded = false,
  showDots = true,
}: ListItemProps) => {
  const { isGuest } = React.useContext(AuthContext);
  const formattedDate = formatDateString({
    format: "MM/DD/YYYY",
    dateString: date.toString(),
  });

  const shouldShowDots = isGuest
    ? showDots && !item.hiddenFromPublic
    : showDots;

  return (
    <Item excluded={excluded}>
      <Header>
        <div tabIndex={0}>
          {formattedDate} {title}
        </div>
        <HeaderInfo showDots={showDots} tabIndex={0}>
          {info}
          {shouldShowDots && (
            <EditImprovementDropdown
              onUpdate={onUpdate}
              improvement={item}
              address={address}
            />
          )}
        </HeaderInfo>
      </Header>
      {item.description && (
        <Description tabIndex={0}>{item.description}</Description>
      )}
      {!!item.tags?.length && (
        <TagsWrapper role="list">
          {item.tags.map(({ text, display }) => {
            return (
              <Tag styleVariant={display as StyleVariant} key={text}>
                {text}
              </Tag>
            );
          })}
        </TagsWrapper>
      )}
    </Item>
  );
};

export default ListItem;
