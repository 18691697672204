import React from "react";
import { OBJECT_TYPE } from "common/constants";
import InfoPanelActivityFeed from "../ActivityFeed/InfoPanelActivityFeed";
import { ContentWrapper } from "./__styles__/ObjectInformationPanel";
import { ObjectInfoPanelTabProps } from "./types";

const Activity = ({ geometry }: ObjectInfoPanelTabProps) => {
  return (
    <ContentWrapper>
      <InfoPanelActivityFeed
        objectType={OBJECT_TYPE.CUSTOM_MAP_GEOMETRY}
        object={geometry}
      />
    </ContentWrapper>
  );
};

export default Activity;
