import React from "react";
import { map, startCase } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { FLOOD_ZONES, INFO_REQUEST_TYPES } from "common/constants";
import {
  InformationRequestInput,
  InformationRequestStatus,
  InsuranceInfoGiven,
} from "../../generated/graphql";
import {
  Header,
  Section,
  SectionDivider,
} from "../Common/__styles__/FullPageFormLayout";
import FullPageFormLayout from "../Common/FullPageFormLayout";
import { Datepicker, Select, Switch } from "../Inputs";
import { DoublePrecisionNumberInput } from "../Inputs/Number";
import { Text, Textarea } from "../Inputs/react-hook-form";

export const typeLabels = {
  [INFO_REQUEST_TYPES.PHONE]: "Phone",
  [INFO_REQUEST_TYPES.WALKIN]: "Walk-in",
  [INFO_REQUEST_TYPES.WEB]: "Web",
  [INFO_REQUEST_TYPES.WRITTEN]: "Written/email request",
};

const typeOptions = map(INFO_REQUEST_TYPES, value => ({
  value,
  label: typeLabels[value],
}));

const zoneOptions = map(FLOOD_ZONES, value => ({
  value,
  label: value,
}));

const insuranceOptions = map(InsuranceInfoGiven, value => ({
  value,
  label: startCase(value),
}));

const statusOptions = map(InformationRequestStatus, value => ({
  value,
  label: startCase(value),
}));

export type LogMutationFormStructure = {
  address: string;
  phone: Maybe<string>;
  email: Maybe<string>;
  name: Maybe<string>;
  firmPanel: Maybe<string>;
  type: INFO_REQUEST_TYPES;
  zone: Maybe<FLOOD_ZONES>;
  insuranceInfoGiven: Maybe<InsuranceInfoGiven>;
  submittedAt: Date | string;
  stringBfe: Maybe<string>;
  stringBfeDepth: Maybe<string>;
  coastalOrCBRS: Maybe<boolean>;
  pastFlood: Maybe<boolean>;
  erosion: Maybe<boolean>;
  siteVisit: Maybe<boolean>;
  propertyProtectionAdvice: Maybe<boolean>;
  financialAdvice: Maybe<boolean>;
  requestedHelpWith: Maybe<string>;
  internalNotes: Maybe<string>;
  status: InformationRequestStatus;
};
interface FormProps {
  data: Partial<InformationRequestInput> & {
    viaPublicSite?: boolean;
    sharedViaEmail?: boolean;
  };
  propertyLoading?: boolean;
  isNew?: boolean;
  prevLocation?: string;
  subtitle: string;
  rightContainer?: JSX.Element;
}

export default ({
  data,
  propertyLoading,
  isNew = false,
  subtitle,
  prevLocation = "/map",
  rightContainer,
}: FormProps) => {
  const { control, register } = useFormContext<LogMutationFormStructure>();

  const isTypeDisabled = !isNew && data.type === INFO_REQUEST_TYPES.WEB;
  const requiredError = "This field is required";

  return (
    <FullPageFormLayout
      subtitle={subtitle}
      prevLocation={prevLocation}
      rightContainer={rightContainer}
    >
      <form>
        <Header>
          <h1>General</h1>
        </Header>
        <Section>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Select
                size="medium"
                label="Type"
                name="type"
                options={
                  !isTypeDisabled
                    ? typeOptions.filter(t => t.value !== "web")
                    : typeOptions
                }
                value={field.value}
                onChange={field.onChange}
                disabled={isTypeDisabled}
                required={true}
                error={fieldState.error && requiredError}
              />
            )}
          />

          <Controller
            control={control}
            name="submittedAt"
            rules={{ required: true }}
            render={({ field }) => (
              <Datepicker
                label="Date"
                name="submitted_at"
                onChange={field.onChange}
                value={field.value}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="status"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                size="medium"
                label="Status"
                name="status"
                options={statusOptions}
                value={field.value}
                onChange={field.onChange}
                required={true}
              />
            )}
          />
        </Section>
        <SectionDivider />
        <Header>
          <h1>Property</h1>
        </Header>
        <Section>
          <Text
            label="Address"
            {...register("address")}
            disabled={propertyLoading || !!data.propertyId}
            required={!data.propertyId}
          />
          <Text
            label="FIRM Panel"
            {...register("firmPanel")}
            disabled={propertyLoading}
          />
          <DoublePrecisionNumberInput
            label="BFE"
            name={"stringBfe"}
            control={control}
            disabled={propertyLoading}
            required={false}
          />
          <DoublePrecisionNumberInput
            control={control}
            label="Base flood depth"
            name="stringBfeDepth"
            disabled={propertyLoading}
            required={false}
          />
          <Controller
            control={control}
            name="zone"
            render={({ field }) => (
              <Select
                size="medium"
                label="Flood Zone"
                name="flood_zone"
                options={zoneOptions}
                value={field.value}
                onChange={field.onChange}
                disabled={propertyLoading}
                isClearable
              />
            )}
          />
          <Section grid style={{ zIndex: 0 }}>
            <Controller
              control={control}
              name="coastalOrCBRS"
              render={({ field }) => (
                <Switch
                  label="Coastal A Zone or CBRS?"
                  name="coastal_or_cbrs"
                  value={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="pastFlood"
              render={({ field }) => (
                <Switch
                  label="Past flood or Rep loss area?"
                  name="rep_loss_area"
                  value={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="erosion"
              render={({ field }) => (
                <Switch
                  label="Sensitive or Wetland area?"
                  name="sensitive_area"
                  value={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />
          </Section>
        </Section>
        <SectionDivider />
        <Header>
          <h1>Contact Information</h1>
        </Header>
        <Section>
          <Text label="Name" {...register("name")} />
          <Text label="Email" {...register("email")} />
          <Text type="tel" label="Phone" {...register("phone")} />
        </Section>
        <SectionDivider />
        <Header>
          <h1>Discussion</h1>
        </Header>
        <Section>
          <Controller
            control={control}
            name="insuranceInfoGiven"
            render={({ field }) => (
              <Select
                size="medium"
                label="If you gave insurance information, how was it given?"
                name="insurance_info_given"
                options={insuranceOptions}
                value={field.value}
                onChange={field.onChange}
                isClearable
              />
            )}
          />

          <Section grid style={{ zIndex: 0 }}>
            <Controller
              control={control}
              name="siteVisit"
              render={({ field }) => (
                <Switch
                  label="Did on-site visit happen?"
                  name="on_site_visit"
                  value={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="propertyProtectionAdvice"
              render={({ field }) => (
                <Switch
                  label="Did you give property protection advice?"
                  name="property_protection_advice"
                  value={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="financialAdvice"
              render={({ field }) => (
                <Switch
                  label="Did you give financial advice?"
                  name="financial_advice"
                  value={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />
          </Section>
          <Section grid>
            {data.sharedViaEmail !== undefined && (
              <Switch
                label="Information shared via Forerunner"
                value={data.sharedViaEmail}
                name={"sharedViaEmail"}
                disabled
                onChange={() => {}}
              />
            )}
            {data.viaPublicSite !== undefined && (
              <Switch
                label="Help request via public website"
                value={data.viaPublicSite}
                name={"viaPublicSite"}
                disabled
                onChange={() => {}}
              />
            )}
          </Section>
          <Textarea
            label="Requested help with"
            {...register("requestedHelpWith")}
          />
          <Textarea label="Internal notes" {...register("internalNotes")} />
        </Section>
      </form>
    </FullPageFormLayout>
  );
};
