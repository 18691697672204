import "./__styles__/Icon.scss";
import React, { ComponentType } from "react";
import cx from "classnames";
import Activity from "./Activity";
import Alarm from "./Alarm";
import ArcGIS from "./ArcGIS";
import BooksMini from "./BooksMini";
import Calendar from "./Calendar";
import CaretDown from "./CaretDown";
import CaretDownBold from "./CaretDownBold";
import Certificate from "./Certificate";
import Checkmark from "./Checkmark";
import CircleX from "./CircleX";
import Close from "./Close";
import DisabledAlarm from "./DisabledAlarm";
import DoNotEnter from "./DoNotEnter";
import Expand from "./Expand";
import ExportLink from "./ExportLink";
import ExportUp from "./ExportUp";
import Eyeball from "./Eyeball";
import EyeballOff from "./EyeballOff";
import Gpkg from "./Gpkg";
import GreenCheck from "./GreenCheck";
import HouseTrendingUp from "./HouseTrendingUp";
import Image from "./Image";
import ImageServer from "./ImageServer";
import InformationCircle from "./InformationCircle";
import InformationOutline from "./InformationOutline";
import Loading from "./Loading";
import MagnifyingGlass from "./MagnifyingGlass";
import MapboxAttribution from "./MapboxAttribution";
import MapServer from "./MapServer";
import Menu from "./Menu";
import Newsletter from "./Newsletter";
import NoResults from "./NoResults";
import Paperclip from "./Paperclip";
import PDF from "./PDF";
import Pencil from "./Pencil";
import Plus from "./Plus";
import RedX from "./RedX";
import Refresh from "./Refresh";
import Remove from "./Remove";
import RepetitiveLoss from "./RepetitiveLoss";
import Slippy from "./Slippy";
import SmallLoading from "./SmallLoading";
import SpeechBubble from "./SpeechBubble";
import ThumbsUp from "./ThumbsUp";
import TrafficSign from "./TrafficSign";
import Warning from "./Warning";

interface Icon {
  name: string;
  component: ComponentType;
  fill?: boolean;
  stroke?: boolean;
}

const RawIcons = {
  ACTIVITY: {
    name: "activity",
    component: Activity,
    fill: true,
  },
  INFORMATION_CIRCLE: {
    name: "information-circle",
    component: InformationCircle,
    fill: true,
  },
  INFORMATION_OUTLINE: {
    name: "information-outline",
    component: InformationOutline,
    fill: true,
  },
  CLOSE: {
    name: "close",
    component: Close,
    stroke: true,
  },
  DISABLED_ALARM: {
    name: "disabled-alarm",
    component: DisabledAlarm,
    stroke: true,
  },
  EYEBALL: {
    name: "eyeball",
    component: Eyeball,
    stroke: true,
  },
  EYEBALL_OFF: {
    name: "eyeball-off",
    component: EyeballOff,
    fill: true,
  },
  ALARM: {
    name: "alarm",
    component: Alarm,
    stroke: true,
  },
  NEWSLETTER: {
    name: "newsletter",
    component: Newsletter,
    stroke: true,
  },
  CARET_DOWN: {
    name: "caret-down",
    component: CaretDown,
    stroke: true,
  },
  CARET_DOWN_BOLD: {
    name: "caret-down-bold",
    component: CaretDownBold,
    stroke: true,
  },
  LOADING: {
    name: "loading",
    component: Loading,
    stroke: true,
  },
  PAPERCLIP: {
    name: "paperclip",
    component: Paperclip,
    stroke: true,
  },
  CERTIFICATE: {
    name: "certificate",
    component: Certificate,
    fill: true,
  },
  COMMENT: {
    name: "speech-bubble",
    component: SpeechBubble,
    fill: true,
  },
  LOGS_SMALL: {
    name: "logs-small",
    component: BooksMini,
    fill: true,
  },
  IMPROVEMENTS: {
    name: "improvements",
    component: HouseTrendingUp,
    fill: true,
  },
  TRAFFIC_SIGN: {
    name: "traffic-sign",
    component: TrafficSign,
    stroke: true,
  },
  NO_RESULTS: {
    name: "no-results",
    component: NoResults,
    fill: true,
  },
  DO_NOT_ENTER: {
    name: "do-not-enter",
    component: DoNotEnter,
    fill: true,
  },
  WARNING: {
    name: "warning",
    component: Warning,
    fill: true,
  },
  MAPBOX_ATTRIBUTION: {
    name: "mapbox-attribution",
    component: MapboxAttribution,
    stroke: true,
  },
  THUMBS_UP: {
    name: "thumbs-up",
    component: ThumbsUp,
    stroke: true,
  },
  EXPORT_LINK: {
    name: "export-link",
    component: ExportLink,
    fill: true,
  },
  PLUS: {
    name: "plus",
    component: Plus,
    stroke: true,
  },
  EXPORT_UP: {
    name: "export-up",
    component: ExportUp,
    stroke: true,
  },
  IMAGE: {
    name: "image",
    component: Image,
    stroke: true,
  },
  PDF: {
    name: "pdf",
    component: PDF,
    stroke: true,
  },
  CIRCLE_X: {
    name: "circle-x",
    component: CircleX,
    stroke: true,
  },
  GREEN_CHECK: {
    name: "green-check",
    component: GreenCheck,
    fill: true,
  },
  PENCIL: {
    name: "pencil",
    component: Pencil,
    stroke: true,
  },
  SMALL_LOADING: {
    name: "small-loading",
    component: SmallLoading,
    stroke: true,
  },
  ARC_GIS: {
    name: "arc-gis",
    component: ArcGIS,
    stroke: true,
  },
  GPKG: {
    name: "gpkg",
    component: Gpkg,
    stroke: true,
  },
  REPETITIVE_LOSS: {
    name: "repetitive-loss",
    component: RepetitiveLoss,
    stroke: true,
  },
  CALENDAR: {
    name: "calendar",
    component: Calendar,
    stroke: true,
  },
  REMOVE: {
    name: "remove",
    component: Remove,
    fill: true,
  },
  REFRESH: {
    name: "refresh",
    component: Refresh,
    fill: true,
  },
  RED_X: {
    name: "red-x",
    component: RedX,
    fill: true,
  },
  EXPAND: {
    name: "expand",
    component: Expand,
    fill: true,
  },

  MENU: {
    name: "menu",
    component: Menu,
    fill: true,
  },
  MAGNIFYING_GLASS: {
    name: "magnifying-glass",
    component: MagnifyingGlass,
    stroke: true,
  },
  CHECKMARK: {
    name: "checkmark",
    component: Checkmark,
    fill: true,
  },
  SLIPPY: {
    name: "slippy",
    component: Slippy,
    fill: true,
  },
  IMAGE_SERVER: {
    name: "image-server",
    component: ImageServer,
    fill: true,
  },
  MAP_SERVER: {
    name: "map-server",
    component: MapServer,
    fill: true,
  },
} as const;

export const Icons = RawIcons as Record<keyof typeof RawIcons, Icon>;

// sanity check - if neither stroke nor fill is set
// on an icon, there's no way to control its coloring
// which might lead to bugs down the line
export const validateIcons = (
  iconsToValidate: Partial<typeof Icons> = Icons
) => {
  Object.entries(iconsToValidate).forEach(([iconName, icon]) => {
    const { stroke, fill } = icon ?? {};
    if (!stroke && !fill) {
      throw new Error(
        `Icon ${iconName} should have either \`stroke\` or \`fill\` set to \`true\``
      );
    }
  });
};
validateIcons();

// the selected color either impacts the icon's
// fill or stroke, depending on how the icon is
// configured above
export enum ICON_COLORS {
  WHITE = "white",
  BLACK = "black",
  GREY = "grey",
  GREY_2 = "grey-2",
  GREY_3 = "grey-3",
  GREY_4 = "grey-4",
  DARK_GREY = "dark-grey",
  LIGHT_GREY = "light-grey",
  BLUE = "blue",
  DARK_BLUE = "dark-blue",
  YELLOW = "yellow",
  RED = "red",
  GREEN = "green",
}

export interface Props {
  icon: Icon;
  color?: ICON_COLORS;
  hoverable?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}
const Icon = ({
  icon,
  color,
  hoverable = false,
  onClick,
  style = {},
}: Props) => {
  const { stroke, fill, component: IconComponent } = icon;

  return (
    <span
      onClick={onClick}
      data-testid="icon"
      styleName={cx("icon", color, {
        stroke,
        fill,
        hoverable,
        clickable: !!onClick,
      })}
      style={style}
    >
      <IconComponent />
    </span>
  );
};

export default Icon;
