import React from "react";
import { camelCase, startCase } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  Datum,
  FirmSource,
  FIRMType,
} from "../../../../../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../../../Common/__styles__/Modal";
import { Button } from "../../../../../Common/Button";
import { Checkbox, Select, Text } from "../../../../../Inputs/react-hook-form";

export type CreateFirmStageType = {
  type: FIRMType;
  name: string;
  useForRegulation: boolean;
  datum?: Datum;
  source: FirmSource;
};

export interface CreateFirmStageProps {
  onNext: () => void;
  onCancel: () => void;
}

export const CreateFirmStage = ({ onNext, onCancel }: CreateFirmStageProps) => {
  const { control, formState, register } =
    useFormContext<CreateFirmStageType>();

  return (
    <Container width="narrow">
      <HeaderSection>
        <h1>Create FIRM</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <InputRow>
            <Text
              label="Name"
              size="medium"
              required
              {...register("name", {
                required: "Name is required",
              })}
              error={formState.errors.name?.message}
            />
          </InputRow>
          <InputRow>
            <Select
              label="FIRM type"
              name="type"
              placeholder="Select..."
              size="medium"
              control={control}
              rules={{
                required: "Type is required",
              }}
              required
              options={Object.values(FIRMType).map(type => ({
                label: startCase(camelCase(type)),
                value: type,
              }))}
              error={formState.errors.type?.message}
            />
          </InputRow>
          <InputRow>
            <Select
              label="Source"
              name="source"
              placeholder="Select..."
              size="medium"
              control={control}
              rules={{
                required: "Source is required",
              }}
              required
              options={Object.values(FirmSource).map(type => ({
                label: type,
                value: type,
              }))}
              error={formState.errors.source?.message}
            />
          </InputRow>
          <InputRow>
            <Select
              label="Datum"
              name="datum"
              placeholder="Select..."
              size="medium"
              control={control}
              options={Object.values(Datum).map(type => ({
                label: type,
                value: type,
              }))}
            />
          </InputRow>
          <InputRow>
            <Checkbox
              name="useForRegulation"
              control={control}
              label="Use for regulation"
            />
          </InputRow>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="button"
              styleVariant="primary"
              disabled={!formState.isValid}
              size="medium"
              onClick={onNext}
            >
              Next
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default CreateFirmStage;
