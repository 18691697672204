import React from "react";
import { ApolloError } from "@apollo/client";
import { formatCoordinates } from "common/utils/coordinates";
import { capitalizeAddress } from "common/utils/strings";
import { useAddRepetitiveLossPropertyMutation } from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import PropertyForm, { ActionProps, FormData } from "../Property/PropertyForm";
import { Actions, QueueActions } from "./Actions";
import GeocodeRepetitiveLossWarnings from "./AddRepetitiveLossPropertyWarnings";
import { RepetitiveLoss } from "./GeocodeRepetitiveLoss";

const AddRepetitiveLossProperty = ({
  repetitiveLoss,
  issueCount,
  onIgnore,
  onSkip,
  onError,
  showQueueControls,
  redirectAfterMutation,
}: {
  repetitiveLoss: RepetitiveLoss;
  issueCount: number;
  onIgnore: () => void;
  onSkip: () => void;
  onError: (error: ApolloError) => void;
  redirectAfterMutation: (nextIssueId?: Maybe<string>) => void;
  showQueueControls: boolean;
}) => {
  const { addSuccessToast } = useStatusToasts();

  const [updateRepetitiveLoss, { error, loading }] =
    useAddRepetitiveLossPropertyMutation({
      onCompleted: data => {
        const repetitiveLoss = data.updateRepetitiveLoss;
        if (repetitiveLoss.property) {
          const propertyLocation =
            repetitiveLoss.property.fullAddress ??
            formatCoordinates(repetitiveLoss.property);
          addSuccessToast(`Repetitive loss geocoded at ${propertyLocation}`);
        }
        const nextRepetitiveLossId =
          repetitiveLoss.property?.account?.repetitiveLossIssue?.id;
        redirectAfterMutation(nextRepetitiveLossId);
      },
      onError,
    });

  const onSave = async (formData: FormData, options: { next: boolean }) => {
    await updateRepetitiveLoss({
      variables: {
        data: {
          ...formData,
          repetitiveLossId: repetitiveLoss.id,
        },
        getNext: options.next,
        ...(options.next && { currentRLNumber: repetitiveLoss.rLNumber }),
      },
    });
  };

  const { addressLine1, addressLine2, addressCity, addressState, zipCode } =
    repetitiveLoss;
  const streetAddress = [addressLine1, addressLine2]
    .filter(item => !!item)
    .map(item => capitalizeAddress(item!))
    .join(" ");
  const city = addressCity && capitalizeAddress(addressCity);
  const state = addressState;

  const importedAddress = { streetAddress, city, state, zipcode: zipCode };

  return (
    <PropertyForm
      property={importedAddress}
      point={null}
      parcel={null}
      zoom={null}
      actions={({ disabled, validateInput }: ActionProps) => {
        if (showQueueControls) {
          return QueueActions({
            disabled,
            issueCount,
            onIgnore,
            onSave: () =>
              validateInput((data: FormData) => onSave(data, { next: false })),
            onSaveAndContinue: () =>
              validateInput((data: FormData) => onSave(data, { next: true })),
            onSkip,
            loading,
          });
        } else {
          return Actions({
            disabled,
            onSave: () =>
              validateInput((data: FormData) => onSave(data, { next: false })),
            loading,
          });
        }
      }}
      error={error?.message ?? null}
      headerText={"Review geocoding issue"}
      title={"Geocode address"}
      subtitle={
        "Please select the correct location on the map for the address below"
      }
      warningsComponent={props => {
        return (
          <GeocodeRepetitiveLossWarnings
            {...props}
            importedAddress={importedAddress}
          />
        );
      }}
      canSubmitProperty={true}
      editingProperty={false}
    />
  );
};

export default AddRepetitiveLossProperty;
