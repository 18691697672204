import { useState } from "react";
import { uniqueId } from "lodash";
import { MULTIPART_UPLOAD_TYPE } from "common/constants";
import { FILE_PART_SIZE } from "common/utils/files";
import { splitFileNameAndExtension } from "common/utils/strings";
import {
  useCompleteMultipartUploadMutation,
  useCreateMultipartUploadUrlsMutation,
} from "../generated/graphql";

const putFilePartAtUrl = async ({
  url,
  filePart,
  filePartIndex,
}: {
  url: string;
  filePart: Blob;
  filePartIndex: number;
}) => {
  const result = await fetch(url, {
    method: "PUT",
    body: filePart,
  });

  if (!result.ok) {
    throw new Error(
      `Received a ${result.status} when PUTing part ${filePartIndex} at ${url}`
    );
  }

  const ETag = result.headers.get("ETag");

  if (!ETag) {
    throw new Error(`ETag not found in response headers`);
  }

  return ETag;
};

const makeUrlToFilePartMapper = ({
  file,
  partSize,
}: {
  file: File;
  partSize: number;
}) => {
  return async (url: string, i: number) => {
    const sentSize = i * partSize;
    const filePart = file.slice(sentSize, sentSize + partSize);
    const ETag = await putFilePartAtUrl({
      url,
      filePart,
      filePartIndex: i,
    });

    return {
      ETag,
      PartNumber: i + 1,
    };
  };
};

export const useMultipartFileUpload = ({
  onError,
}: {
  onError?: (error: unknown) => void;
}) => {
  const [loadingIds, setLoadingIds] = useState<Array<string>>([]);
  const [completeMultipartUpload] = useCompleteMultipartUploadMutation();
  const [fetchPresignedUrls] = useCreateMultipartUploadUrlsMutation({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const uploadFile = ({
    file,
    onCompleted: onUploadCompleted,
    onUploadError,
    uploadType,
  }: {
    file: File;
    onCompleted?: (key: string) => void;
    onUploadError?: (error: unknown) => void;
    uploadType?: MULTIPART_UPLOAD_TYPE;
  }) => {
    const id = uniqueId();
    setLoadingIds(ids => [...ids, id]);

    const { fileExt, fileName } = splitFileNameAndExtension(file.name);

    if (!fileExt || !fileName) {
      onUploadError?.("File name or extension is missing");
    }

    const partSize = FILE_PART_SIZE;
    void fetchPresignedUrls({
      variables: {
        data: {
          fileName: fileName!,
          fileExt: fileExt!,
          fileSize: file.size,
          partSize,
          uploadType,
        },
      },
      onCompleted: async ({
        createMultipartUploadUrls: multipartUploadConfig,
      }) => {
        const { key, urls, uploadId } = multipartUploadConfig;

        try {
          const parts = await Promise.all(
            urls.map(makeUrlToFilePartMapper({ file, partSize }))
          );

          void completeMultipartUpload({
            variables: {
              data: {
                key,
                uploadId,
                parts,
                uploadType,
              },
            },
            onCompleted: () => {
              setLoadingIds(ids => [...ids.filter(i => i !== id)]);
              if (onUploadCompleted) onUploadCompleted(key);
            },
          });
        } catch (error) {
          setLoadingIds(ids => [...ids.filter(i => i !== id)]);
          onUploadError?.(error) || onError?.(error);
        }
      },
    });
  };

  return [uploadFile, { loading: !!loadingIds.length }] as const;
};
