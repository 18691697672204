import React, { KeyboardEvent, useEffect, useState } from "react";
import {
  Container,
  PageButton,
  PageButtonContainer,
  PageInfoSection,
  PageJumpInput,
} from "./__styles__/Pagination";
import { PaginationDetails } from "./utils";

export interface PaginationSectionProps {
  paginationDetails: PaginationDetails;
  previousPage: () => void;
  nextPage: () => void;
  canGetPreviousPage: () => boolean;
  canGetNextPage: () => boolean;
}

export default ({
  paginationDetails,
  previousPage,
  nextPage,
  canGetPreviousPage,
  canGetNextPage,
}: PaginationSectionProps) => {
  const { pageCount, pagination, setPagination } = paginationDetails;
  const [localPageIndex, setLocalPageIndex] = useState(
    String(pagination.pageIndex + 1)
  );

  const handleClickPrevious = () => {
    previousPage();
    setLocalPageIndex(String(Number(localPageIndex) - 1));
  };

  const handleClickNext = () => {
    nextPage();
    setLocalPageIndex(String(Number(localPageIndex) + 1));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const value = Number(localPageIndex);
    if (e.key === "Enter") {
      if (value > 0 && value <= pageCount) {
        setPagination({
          ...pagination,
          pageIndex: value - 1,
        });
      } else {
        setLocalPageIndex(String(pagination.pageIndex + 1));
      }
    }
  };

  useEffect(() => {
    setLocalPageIndex(String(paginationDetails.pagination.pageIndex + 1));
  }, [pagination.pageIndex]);

  return (
    <Container>
      <PageButtonContainer>
        <PageButton
          type="previous"
          disabled={!canGetPreviousPage()}
          onClick={handleClickPrevious}
          data-testid="previous-page-btn"
        />
      </PageButtonContainer>

      <PageInfoSection>
        <span>
          Page
          <PageJumpInput
            type="number"
            value={localPageIndex}
            max={pageCount}
            min={1} // Should never go below 1
            onKeyDown={handleKeyDown}
            onChange={e => setLocalPageIndex(e.target.value)}
            data-testid="page-jump-input"
          />
          of
          <span data-testid="page-count">{pageCount}</span>
        </span>
      </PageInfoSection>

      <PageButtonContainer>
        <PageButton
          type="next"
          disabled={!canGetNextPage()}
          onClick={handleClickNext}
          data-testid="next-page-btn"
        />
      </PageButtonContainer>
    </Container>
  );
};
