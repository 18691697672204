import React from "react";
import { Body } from "../../../Common/Typography";
import {
  ButtonWrapper,
  MenuOptionWrapper,
  SubtitleDiv,
  TextWrapper,
} from "./__styles__/Operations";

const MenuOption = ({
  title,
  helperText,
  buttons,
}: {
  title: string;
  helperText?: string;
  buttons: React.ReactNode;
}) => {
  return (
    <MenuOptionWrapper>
      <TextWrapper>
        <SubtitleDiv>
          <Body type="emphasis" size="large">
            {title}
          </Body>
        </SubtitleDiv>
        {helperText && (
          <Body type="regular" size="default" color="contentSecondary">
            {helperText}
          </Body>
        )}
      </TextWrapper>
      <ButtonWrapper>{buttons}</ButtonWrapper>
    </MenuOptionWrapper>
  );
};

export default MenuOption;
