import React from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useHistory } from "react-router-dom";
import { colors, css, styled } from "../../stitches.config";
import { baseActionLink } from "../Inputs/__styles__/DropdownMenu";
import { ActionsProps } from "../Inputs/DropdownMenu";
import { ActionCellContainer } from "./__styles__/ActionCell";
import { Button } from "./Button";
import { Icon } from "./Icons/LucideIcons";

export const ActionCell = ({
  actions,
  buttonText,
  disabled,
  customButton,
}: {
  actions: Array<ActionsProps>;
  buttonText?: string;
  disabled?: boolean;
  customButton?: React.FC<{
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
  }>;
}) => {
  return (
    <ActionCellContainer>
      <Actions
        actions={actions}
        buttonText={buttonText}
        disabled={disabled}
        customButton={customButton}
      />
    </ActionCellContainer>
  );
};

export const ACTION_COLUMN_DEF_CONSTANTS = {
  id: "actions",
  minSize: 50,
  sortable: false,
};

const Action = ({ action }: { action: ActionsProps }) => {
  const history = useHistory();
  const className = actionLink({
    variant: action.disabled ? "disabled" : action.variant,
  });
  const { href, track, onClick, target, label, download } = action;

  const onSelect = () => {
    if (track) {
      track();
    }

    if (action.to) {
      history.push(action.to);
    }

    if (onClick) {
      onClick();
    }
  };

  const isLink = !!href;

  return (
    <DropdownItem
      onSelect={onSelect}
      className={className}
      tabIndex={0}
      asChild={isLink}
    >
      {isLink ? (
        <a
          href={href}
          download={download}
          target={target}
          data-testid={`link-for-${label}`}
        >
          {label}
        </a>
      ) : (
        label
      )}
    </DropdownItem>
  );
};

export const Actions = ({
  actions,
  buttonText,
  disabled,
  customButton,
}: {
  actions: Array<ActionsProps>;
  buttonText?: string;
  disabled?: boolean;
  customButton?: React.FC<{
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
  }>;
}) => {
  const [open, setOpen] = React.useState(false);

  const ActionButton = () => {
    if (customButton)
      return customButton({ onClick: () => setOpen(!open), disabled });

    return (
      <Button
        styleVariant={!buttonText ? "hoverOutline" : "secondary"}
        size="small"
        tabIndex={0}
        disabled={disabled}
        style={{ minWidth: "unset" }}
        data-testid="actions"
        aria-label="actions"
        onClick={() => setOpen(!open)}
      >
        {buttonText ? (
          buttonText
        ) : (
          <Icon iconName="more-horizontal" color="contentPrimary" size={16} />
        )}
      </Button>
    );
  };

  return (
    <div>
      <ActionButton />
      <Dropdown.Root modal={false} open={open} onOpenChange={setOpen}>
        <Dropdown.Trigger asChild>
          <div />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <DropdownContent
            sideOffset={4}
            collisionPadding={16}
            data-testid={"actions-list"}
          >
            {actions
              .filter(action => !action.hidden)
              .map((action, i) => {
                return <Action action={action} key={i} />;
              })}
          </DropdownContent>
        </Dropdown.Portal>
      </Dropdown.Root>
    </div>
  );
};

//these styles have to be in the same file as the radix dropdown to work:
const DropdownContent = styled(Dropdown.Content, {
  background: colors.bgUiSurface,
  border: 1,
  borderColor: colors.borderUi,
  borderStyle: "solid",
  borderRadius: 4,
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  padding: "8px 0",
  zIndex: 10,
});

const DropdownItem = styled(Dropdown.Item, {
  display: "flex",
  alignItems: "center",
  gap: 4,

  "&:hover": {
    backgroundColor: colors.bgUiContainer,
  },
  "&:focus-visible": {
    outline: "none",
    backgroundColor: colors.bgUiInteraction,
  },

  cursor: "pointer",

  variants: {
    hide: {
      true: {
        display: "none",
      },
    },
  },
});

const actionLink = css({
  ...baseActionLink,
  width: "100%",
  height: "100%",
});
