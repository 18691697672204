import React from "react";
import { spacing } from "../../../stitches.config";
import { Button } from "../../Common/Button";
import { Section } from "../../Common/Layout";

export const SecondaryActions = ({
  actions,
}: {
  actions: { onClick: () => void; text: string; disabled?: boolean }[];
}) => {
  return (
    <Section gap={spacing.s}>
      {actions.map(({ text, onClick, disabled }) => (
        <Button
          type="button"
          key={text}
          onClick={onClick}
          styleVariant="buttonLink"
          size="small"
          style={{
            padding: 0,
            textAlign: "left",
            height: "auto",
            marginTop: spacing.s.toString(),
          }}
          disabled={disabled}
        >
          {text}
        </Button>
      ))}
    </Section>
  );
};

export default SecondaryActions;
