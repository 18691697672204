import React from "react";
import {
  AccountPropertyAttribute,
  DfeRule,
  Raster,
  SisdRule,
  useCreateDfeRuleMutation,
  useCreateSisdRuleMutation,
  useGetAccountPropertyDataSettingsQuery,
  useGetAccountPropertyInformationSectionsQuery,
} from "../../../../generated/graphql";
import { AuthContext } from "../../../Authorization/AuthContext";
import { Box, FlexRow } from "../../../Common/__styles__/Layout";
import { Button } from "../../../Common/Button";
import Divider from "../../../Common/Divider";
import {
  Header,
  HeaderTitle,
  HelperText,
  SectionTitle,
} from "../../__styles__/Content";
import { PropertyDataSettingsSection } from "./__styles__";
import { useCreateOverviewSectionModal } from "./createOverviewSectionModal";
import DFERulesTable from "./DFERulesTable";
import { OverviewSectionsTable } from "./OverviewSections/Table";
import SISDRulesTable from "./SISDRulesTable";
import { useUpsertDfeRuleModal } from "./upsertDfeRuleModal";
import { useUpsertSisdRuleModal } from "./upsertSisdRuleModal";

export type RasterOptions = Array<
  Pick<Raster, "id" | "layerName"> & {
    bandMappings: Pick<Raster["bandMappings"], "bandNumber">;
  }
>;

const PropertyData = () => {
  const { account } = React.useContext(AuthContext);

  const { data, error, loading, previousData, refetch } =
    useGetAccountPropertyDataSettingsQuery({
      variables: { accountId: account!.id },
    });

  const { data: sectionData, refetch: refetchSections } =
    useGetAccountPropertyInformationSectionsQuery({
      variables: { accountId: account!.id },
    });

  const [createDfeRule] = useCreateDfeRuleMutation();

  const [showCreateOverviewSectionModal] = useCreateOverviewSectionModal({
    onUpdate: refetchSections,
  });

  const [showUpsertDfeRuleModal] = useUpsertDfeRuleModal({
    onSubmit: createDfeRule,
    onUpdate: refetch,
  });

  const [createSisdRule] = useCreateSisdRuleMutation();
  const [showUpsertSisdRuleModal] = useUpsertSisdRuleModal({
    onSubmit: createSisdRule,
    onUpdate: refetch,
    filterOptions: data?.account?.sisdFilterOptions ?? [],
  });

  if (error) {
    return <div />;
  }

  const dfeRuleDefinition: { rules: Array<DfeRule> } | undefined = {
    rules: (data?.account?.dfeRuleDefinition[0]?.rules ?? []) as Array<DfeRule>,
  };
  const sisdRuleDefinition: { rules: Array<SisdRule> } | undefined = {
    rules: (data?.account?.sisdRuleDefinition[0]?.rules ??
      []) as Array<SisdRule>,
  };
  const previousDfeRuleDefinition: { rules: Array<DfeRule> } | undefined = {
    rules: (previousData?.account?.dfeRuleDefinition[0]?.rules ??
      []) as Array<DfeRule>,
  };
  const previousSisdRuleDefinition: { rules: Array<SisdRule> } | undefined = {
    rules: (previousData?.account?.sisdRuleDefinition[0]?.rules ??
      []) as Array<SisdRule>,
  };

  return (
    <Box>
      <Header accountSettings>
        <HeaderTitle accountSettings>Property data settings</HeaderTitle>
      </Header>

      <PropertyDataSettingsSection propertyAttributes>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <SectionTitle>Overview sections</SectionTitle>

          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={showCreateOverviewSectionModal}
          >
            Add section
          </Button>
        </FlexRow>
        <HelperText>
          {account?.publicPortal.enabled
            ? "Update the attributes for each overview section displayed on both the internal and public property information panels."
            : "Update the attributes for each overview section displayed on the property information panel."}
        </HelperText>
        <OverviewSectionsTable
          accountSections={sectionData?.account?.propertyInformationSections}
          ruleDefinition={dfeRuleDefinition}
          onUpdate={refetchSections}
        />
      </PropertyDataSettingsSection>
      <Divider margins="0px 24px 0px 24px" />
      <PropertyDataSettingsSection dfeRules>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <SectionTitle>Design flood elevation logic</SectionTitle>

          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={() =>
              showUpsertDfeRuleModal({
                referenceAttributes:
                  data?.account?.dfeReferencePropertyAttributes.filter(
                    (attribute): attribute is AccountPropertyAttribute =>
                      attribute.__typename === "AccountPropertyAttribute"
                  ),
              })
            }
          >
            Add DFE rule
          </Button>
        </FlexRow>

        <HelperText>
          Add and/or edit DFE logic rules that impact properties within specific
          flood zones. Note that if a property has intersecting rules, we will
          compare logic rules to assign the most restrictive.
        </HelperText>
        <DFERulesTable
          currentData={{
            dfeReferencePropertyAttributes:
              data?.account?.dfeReferencePropertyAttributes.filter(
                (attribute): attribute is AccountPropertyAttribute =>
                  attribute.__typename === "AccountPropertyAttribute"
              ),
            ruleDefinition: dfeRuleDefinition,
          }}
          previousData={{ ruleDefinition: previousDfeRuleDefinition }}
          loading={loading}
          onUpdate={refetch}
        />
      </PropertyDataSettingsSection>
      <Divider margins="0px 24px 0px 24px" />
      <PropertyDataSettingsSection sisdRules>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <SectionTitle>Substantial improvement / damage logic</SectionTitle>
          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={() => {
              showUpsertSisdRuleModal({
                maxPriority: sisdRuleDefinition.rules.length + 1,
                filterOptions: data?.account?.sisdFilterOptions ?? [],
              });
            }}
          >
            Add SI/SD rule
          </Button>
        </FlexRow>
        <HelperText>
          Add and/or edit SI/SD logic rules that impact properties with specific
          property attributes (i.e. zones).
        </HelperText>
        <SISDRulesTable
          currentData={{
            ruleDefinition: sisdRuleDefinition,
          }}
          previousData={{
            ruleDefinition: previousSisdRuleDefinition,
          }}
          filterOptions={data?.account?.sisdFilterOptions ?? []}
          loading={loading}
          onUpdate={refetch}
        />
      </PropertyDataSettingsSection>
    </Box>
  );
};

export default PropertyData;
