import { useContext } from "react";
import React from "react";
import { isNil } from "lodash";
import { buildLink } from "common/routing";
import { formatFeet } from "common/utils/strings";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { getBaseFloodDepthConfiguration } from "common-client/utils/firms";
import { DisplayConfig } from "../../../../../../generated/graphql";
import { track } from "../../../../../../utils/tracking";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { FIRMInfoContext } from "../../FIRMInfoContext";
import { RenderMode } from "../../types";
import { FIRMTableProps } from "..";
import { RequestInfoLink } from "../__styles__/FIRMTable";
import { FIRMInfoRow } from "./FIRMInfoRow";

type BFERowProps = Pick<FIRMTableProps, "property" | "onFIRMEdit"> & {
  hasRequestHelpOption?: boolean;
  label: string;
  isPublic: boolean;
  tooltip?: React.ReactNode;
  size: RenderMode;
  displayConfig?: DisplayConfig;
};

export const BaseFloodDepthRow = ({
  property,
  onFIRMEdit,
  hasRequestHelpOption = false,
  isPublic,
  tooltip,
  label,
  size,
}: BFERowProps) => {
  const { isGuest, authorized } = useContext(AuthContext);
  const { activeFirmIds, firms } = useContext(FIRMInfoContext);
  const activeFirms = firms.filter(firm => activeFirmIds.includes(firm.id));

  const { skipIfBlank } = getBaseFloodDepthConfiguration({
    firms: activeFirms,
    authorized,
    isGuest,
    hasRequestHelpOption,
  });

  const valueForFIRM = (firm: FIRMWithWarnings) => {
    const { value, canEdit, canRequestHelp } = getBaseFloodDepthConfiguration({
      selectedFirmId: firm.id,
      firms: [firm],
      authorized,
      isGuest,
      hasRequestHelpOption,
    });

    if (isNil(value) && canRequestHelp) {
      return (
        <RequestInfoLink
          to={buildLink("getHelp", {}, { propertyId: property?.id })}
          onClick={() => track("Request BFD")}
        >
          Request info
        </RequestInfoLink>
      );
    }

    const formattedValue = isNil(value) ? null : formatFeet(value);

    if (canEdit) {
      return (
        <a onClick={() => onFIRMEdit({ firm, mode: "depth" })}>
          {formattedValue ?? "Add"}
        </a>
      );
    }

    return formattedValue ?? null;
  };

  return (
    <FIRMInfoRow
      property="stringDepth"
      valueForFIRM={valueForFIRM}
      label={label}
      skipIfBlank={skipIfBlank}
      tooltip={tooltip}
      isPublic={isPublic}
      size={size}
    />
  );
};
