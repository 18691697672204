import React from "react";
import {
  entries,
  flatten,
  groupBy,
  partition,
  sortBy,
  startCase,
} from "lodash";
import { orderPropertyWarningDefinitions } from "common-client/utils/warnings";
import {
  AccountPropertyWarningDefinition,
  PropertyWarning,
  PropertyWarningGroup,
} from "../../generated/graphql";
import { spacing } from "../../stitches.config";
import { SEVERITY_TO_COLOR } from "../AddressPanel/Warnings";
import { IconContainer } from "../Common/__styles__/ExpandableList";
import { ExpandableList } from "../Common/ExpandableList";
import { Icon } from "../Common/Icons/LucideIcons";

export interface EditableWarning extends AccountPropertyWarningDefinition {
  applies?: boolean;
}

export interface WarningListProps {
  warnings: EditableWarning[];
  warningRightSection: (
    warning: EditableWarning,
    key: string
  ) => React.ReactNode;
}
const WarningList = ({ warnings, warningRightSection }: WarningListProps) => {
  const sortedWarnings = sortBy(warnings, "group");
  const warningGroups = groupBy(
    flatten(
      partition(
        sortedWarnings,
        warning => warning.group !== PropertyWarningGroup.CUSTOM_WARNING
      )
    ),
    "group"
  );

  return (
    <div
      style={{ gap: spacing.l.value, display: "flex", flexDirection: "column" }}
    >
      {entries(warningGroups).map(([groupTitle, warnings], index) => {
        const list = orderPropertyWarningDefinitions(warnings).map(warning => {
          return {
            title: warning.title,
            expandableContent: warning.message,
            rightContent: warningRightSection(warning, warning.id),
            leftContent: <WarningListItemIcon severity={warning.severity} />,
          };
        });

        return (
          <ExpandableList
            list={list}
            key={index}
            title={`${startCase(groupTitle)} warnings`}
          />
        );
      })}
    </div>
  );
};

export const WarningListItemIcon = ({
  severity,
}: {
  severity: PropertyWarning["severity"];
}) => {
  return (
    <IconContainer size={"default"}>
      <Icon
        iconName="alert-circle"
        color={SEVERITY_TO_COLOR[severity]}
        size={16}
      />
    </IconContainer>
  );
};

export default WarningList;
