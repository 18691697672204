import React from "react";
import { Properties } from "@stitches/react/types/css";
import { colors } from "../../stitches.config";
import { Body } from "../Common/Typography";
import {
  RelevantFIRMsContainer,
  RelevantFIRMsItem,
  RelevantFIRMsItemText,
  RelevantFIRMsItemTextSeparator,
  RelevantFIRMsList,
} from "./__styles__/RelevantFIRMs";

type RelevantFIRMsProps = {
  firms: Array<{ label: string; value?: Maybe<string> }>;
  paddingTop?: Properties["paddingTop"];
  title: string;
  color: keyof typeof colors;
};

export const RelevantFIRMs = ({
  paddingTop,
  title,
  color,
  firms,
}: RelevantFIRMsProps) => {
  return (
    <RelevantFIRMsContainer style={{ paddingTop, color: colors[color].value }}>
      <Body size="default" type="regular" color="inherit">
        {title}
      </Body>
      <RelevantFIRMsList>
        {firms.map(({ label, value }) => (
          <RelevantFIRMsItem key={label}>
            <RelevantFIRMsItemText type="emphasis">
              {label}
            </RelevantFIRMsItemText>
            <RelevantFIRMsItemTextSeparator />
            <RelevantFIRMsItemText>{value ?? "N/A"}</RelevantFIRMsItemText>
          </RelevantFIRMsItem>
        ))}
      </RelevantFIRMsList>
    </RelevantFIRMsContainer>
  );
};
