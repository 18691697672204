import "./__styles__/Icon.scss";
import React from "react";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.414 25.5861L20.34 17.5121C19.5963 18.6347 18.6346 19.5964 17.512 20.3401L25.586 28.4141C25.9632 28.7784 26.4684 28.98 26.9928 28.9754C27.5172 28.9709 28.0188 28.7605 28.3896 28.3897C28.7604 28.0189 28.9708 27.5173 28.9753 26.9929C28.9799 26.4685 28.7783 25.9633 28.414 25.5861Z"
        fill="#111111"
      />
      <path
        d="M5.63904 18.361C4.76138 17.5354 4.05846 16.5418 3.57198 15.4394C3.0855 14.337 2.82535 13.1481 2.80697 11.9432C2.78859 10.7383 3.01235 9.54205 3.46499 8.42529C3.91762 7.30853 4.5899 6.29403 5.44197 5.44197C6.29403 4.5899 7.30853 3.91762 8.42529 3.46499C9.54205 3.01235 10.7383 2.78859 11.9432 2.80697C13.1481 2.82535 14.337 3.0855 15.4394 3.57198C16.5418 4.05846 17.5354 4.76138 18.361 5.63904L19.775 4.22504C18.765 3.1556 17.5508 2.2996 16.2041 1.70769C14.8575 1.11577 13.4058 0.799968 11.935 0.778955C10.4641 0.757941 9.00402 1.03215 7.64101 1.58535C6.27801 2.13855 5.0398 2.95951 3.99966 3.99966C2.95951 5.0398 2.13855 6.27801 1.58535 7.64101C1.03215 9.00402 0.757941 10.4641 0.778955 11.935C0.799968 13.4058 1.11577 14.8575 1.70769 16.2041C2.2996 17.5508 3.1556 18.765 4.22504 19.775L5.63904 18.361Z"
        fill="#111111"
      />
      <path
        d="M20.1131 8.12907C20.9193 9.80624 21.1846 11.6925 20.8722 13.5269C20.5598 15.3614 19.6853 17.0536 18.3694 18.3694C17.0536 19.6853 15.3614 20.5598 13.5269 20.8722C11.6925 21.1846 9.80624 20.9193 8.12907 20.1131L6.64307 21.6001C8.73213 22.7707 11.1464 23.2268 13.5186 22.899C15.8907 22.5712 18.0908 21.4774 19.7841 19.7841C21.4774 18.0908 22.5712 15.8907 22.899 13.5186C23.2268 11.1464 22.7707 8.73213 21.6001 6.64307L20.1131 8.12907Z"
        fill="#111111"
      />
      <path
        d="M2.00003 23.0001C1.80228 23 1.60898 22.9414 1.44457 22.8315C1.28016 22.7216 1.15202 22.5654 1.07635 22.3827C1.00068 22.2 0.98088 21.999 1.01945 21.805C1.05802 21.6111 1.15322 21.4329 1.29303 21.2931L21.293 1.29308C21.3853 1.19757 21.4956 1.12139 21.6176 1.06898C21.7396 1.01657 21.8708 0.988985 22.0036 0.987831C22.1364 0.986677 22.2681 1.01198 22.391 1.06226C22.5139 1.11254 22.6255 1.18679 22.7194 1.28069C22.8133 1.37458 22.8876 1.48623 22.9379 1.60913C22.9881 1.73202 23.0134 1.8637 23.0123 1.99648C23.0111 2.12926 22.9835 2.26048 22.9311 2.38249C22.8787 2.50449 22.8025 2.61483 22.707 2.70708L2.70703 22.7071C2.51954 22.8946 2.26523 23 2.00003 23.0001Z"
        fill="#111111"
      />
    </svg>
  );
};
