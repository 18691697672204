import React from "react";
import { isEqual, omitBy } from "lodash";
import { unsplitAddress } from "common/utils/address";
import { CreateWarningProps } from "common-client/utils/createAndEditPropertyWarnings";
import { fontSizes } from "../../stitches.config";
import PropertyWarnings from "../Property/PropertyWarnings";

export default ({
  point,
  geocacheParcelId,
  error,
  overlap,
  selectedProperty,
  formAddress,
  importedAddress,
}: CreateWarningProps & {
  importedAddress: {
    streetAddress?: Maybe<string>;
    city?: Maybe<string>;
    state?: Maybe<string>;
    zipcode?: Maybe<string>;
  };
}) => {
  let warnings = [];

  if (!point) {
    warnings.push("Please select the property's location on the map.");
  }

  if (overlap && !selectedProperty) {
    warnings.push(
      "There are multiple parcels that intersect this location. Please ensure the right parcel ID is selected from the dropdown above."
    );
  }

  if (point && !geocacheParcelId && !selectedProperty) {
    warnings.push(
      "Your location does not fall within a parcel geometry. This is not necessarily an issue, but please ensure that your new property location is correct."
    );
  }

  if (error) {
    warnings.push(error);
  }

  if (selectedProperty) {
    warnings.push(`Add to existing property at ${formAddress.streetAddress}.`);
  }

  const inputHasChanged = !isEqual(
    omitBy(importedAddress, value => !value),
    omitBy(formAddress, value => !value)
  );

  return (
    <div style={{ marginTop: "28px" }}>
      <PropertyWarnings warnings={warnings}>
        {inputHasChanged && (
          <div
            style={{
              fontSize: `${fontSizes.body3}`,
              lineHeight: "20px",
            }}
          >
            Original imported address:{" "}
            {unsplitAddress([
              importedAddress.streetAddress,
              importedAddress.city,
              importedAddress.state,
              importedAddress.zipcode,
            ])}
          </div>
        )}
      </PropertyWarnings>
    </div>
  );
};
