import React from "react";
import { FieldProps } from "@rjsf/utils";
import { cloneDeep, get, isEmpty, set } from "lodash";
import { TagsInput } from "../../../../../../Submissions/Fields";
import BaseFieldPreview from "./BaseFieldPreview";

const TagFieldPreview = (props: FieldProps) => {
  const itemsEnum = get(props.schema, "items.enum") as Array<string>;

  const schema = cloneDeep(props.schema);

  set(
    schema,
    "items.enum",
    itemsEnum.filter(value => !isEmpty(value))
  );

  const widgetPreview = <TagsInput {...props} schema={schema} />;

  return (
    <BaseFieldPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseFieldPreview>
  );
};

export default TagFieldPreview;
