import React from "react";
import {
  EditWarningProps,
  getEditPropertyWarnings,
} from "common-client/utils/createAndEditPropertyWarnings";
import PropertyWarnings from "./PropertyWarnings";

export default ({
  header,
  ...props
}: EditWarningProps & { header: Maybe<string> }) => {
  const warnings = getEditPropertyWarnings(props);

  return <PropertyWarnings header={header} warnings={warnings} />;
};
