import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { SUBMISSION_TYPE_MODULE } from "common/constants";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import {
  BuilderFieldButton,
  BuilderFieldCategoryName,
  BuilderFieldListItem,
  BuilderFieldsList,
} from "../__styles__/SubmissionsBuilder";
import { MODULES } from "../constants";
import { SubmissionsBuilderFormDataStructure } from "../types";

const ModulesList = () => {
  const { account } = useContext(AuthContext);
  const { getValues, setValue, watch } =
    useFormContext<SubmissionsBuilderFormDataStructure>();
  const selectedModules = watch("modules");

  const addableModules = MODULES.filter(
    ({ type }) => !selectedModules.includes(type)
  );

  if (!addableModules.length) {
    return <></>;
  }

  return (
    <div>
      <BuilderFieldCategoryName>Modules</BuilderFieldCategoryName>
      <BuilderFieldsList>
        {addableModules.map(({ label, type, inputs }) => (
          <BuilderFieldListItem key={label}>
            <BuilderFieldButton
              onClick={() => {
                setValue("modules", [...selectedModules, type]);

                const existingInputs = getValues("inputs");
                const filteredInputs =
                  existingInputs?.filter(input =>
                    inputs.every(({ name }) => name !== input.name)
                  ) ?? [];

                const allInputs = [...filteredInputs, ...inputs];
                setValue("inputs", allInputs);

                if (
                  type ===
                    SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE &&
                  account?.publicPortal.hideSISD
                ) {
                  setValue("hiddenFromPublicByDefault", true);
                }
              }}
              styleVariant="ghost"
              leftIconName={"component"}
              aria-label={`Add ${label} Module`}
            >
              {label}
            </BuilderFieldButton>
          </BuilderFieldListItem>
        ))}
      </BuilderFieldsList>
    </div>
  );
};

export default ModulesList;
