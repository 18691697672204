import React, { useContext, useState } from "react";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Button } from "../../Common/Button";
import { DropdownMenu } from "../../Inputs";
import { LayerContext } from "../layers";
import { RasterMeasure } from "./Shared";

export type ObserveRasterMeasureToolProps = {
  clipboard?: Pick<Clipboard, "writeText">;
  rasterId: string;
};

export const ObserveRasterMeasureTool = ({
  clipboard = navigator.clipboard,
  rasterId,
}: ObserveRasterMeasureToolProps) => {
  const { t } = useTranslation();
  const { addSuccessToast } = useStatusToasts();
  const {
    toggleLayer,
    measureToolState,
    rasters,
    visibleRaster: getVisibleRaster,
  } = useContext(LayerContext);
  const [currentRasterId, setCurrentRasterId] = useState(rasterId);

  const raster = rasters.find(raster => raster.id === currentRasterId);

  if (!raster) {
    return null;
  }

  const onCopy = async (measurementValue: string) => {
    await clipboard.writeText(measurementValue);
    addSuccessToast(t("common-value-copied"));
  };

  const SelectRasterButton = ({
    onClick,
    disabled,
    showCaret = true,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    showCaret?: boolean;
  }) => {
    return (
      <Button
        onClick={onClick}
        size="small"
        styleVariant="outlineDark"
        disabled={disabled}
        rightIconName={showCaret ? "chevron-down" : undefined}
        tabIndex={-1}
      >
        {raster.layerName}
      </Button>
    );
  };

  let title: React.ReactNode, subtitle: string;
  const rasterIsVisible = getVisibleRaster()?.id === raster.id;

  if (rasters.length > 1) {
    const actions = sortBy(rasters, "layerName").map(accountRaster => ({
      label: accountRaster.layerName,
      onClick: () => {
        setCurrentRasterId(accountRaster.id);
        if (rasterIsVisible) {
          toggleLayer({
            group: "rasters",
            id: accountRaster.id,
            isVisible: true,
          });
        }
      },
    }));
    const rasterDropdown = (
      <DropdownMenu
        position={"above-right"}
        actions={actions}
        customButton={SelectRasterButton}
        disabled={measureToolState.loading}
        actionListWidth="376px"
      />
    );

    title = rasterDropdown;
    subtitle = t("measure-raster-subtext-multiple", {
      measurementName: raster.bandMappings.measurementName,
    });
  } else {
    title = raster.layerName;
    subtitle = t("measure-raster-subtext", {
      measurementName: raster.bandMappings.measurementName,
    });
  }

  return (
    <RasterMeasure
      title={title}
      subtitle={subtitle}
      onActionClick={onCopy}
      actionName={t("measure-copy-value")}
      primaryButtonIconName="copy"
      raster={raster}
    />
  );
};
