import React from "react";
import FileUpload from "../../images/file-upload-color.svg";
import { setTitle } from "../../utils/title";
import Layout from "../Guest/Layout";
import { accountTitle } from "../Guest/utils";
import {
  PostambleContainer,
  PreambleContainer,
  SuccessContainer,
  Title,
} from "./__styles__/SubmissionSuccess";

export interface SubmissionSuccessProps {
  preamble: string;
  postamble: string;
}

const SubmissionSuccess = ({ preamble, postamble }: SubmissionSuccessProps) => {
  setTitle(`Submission Success | ${accountTitle()}`);

  return (
    <Layout>
      <SuccessContainer>
        <Title>Successful submission!</Title>
        <div style={{ textAlign: "center" }}>
          <img src={FileUpload} style={{ marginBottom: "40px" }} />
          <PreambleContainer markdown={preamble} />
          <PostambleContainer markdown={postamble} />
        </div>
      </SuccessContainer>
    </Layout>
  );
};

export default SubmissionSuccess;
