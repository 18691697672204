import React from "react";
import { DropzoneOptions, DropzoneState } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { MIME_TYPE } from "common/constants";
import { FirmComponent } from "../../../../../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
  SecondaryButtons,
} from "../../../../../Common/__styles__/Modal";
import { Button } from "../../../../../Common/Button";
import { SingleFileUpload } from "../../../../../Inputs/react-hook-form";
import { DropzoneLabel } from "./__styles__/UploadGeoJsonComponentsStage";

export type UseDropzones = Array<{
  name: FirmComponent;
  useDropzone: (args: DropzoneOptions) => DropzoneState;
}>;

export type UploadGeoJsonComponentsStageType = {
  components?: Array<GeoJsonFirmComponent>;
};

export interface GeoJsonFirmComponent {
  label: string;
  name: FirmComponent;
  id?: string;
  originalFilename: string;
  blob: any;
}

export const DefaultComponents = [
  { label: "Floodzones", name: FirmComponent.FLOODZONES },
  { label: "FIRM panels", name: FirmComponent.PANELS },
  { label: "LiMWA", name: FirmComponent.LIMWAS },
  { label: "Cross sections", name: FirmComponent.CROSS_SECTIONS },
  {
    label: "Base flood elevations",
    name: FirmComponent.BASE_FLOOD_ELEVATIONS,
  },
  {
    label: "Profile baselines",
    name: FirmComponent.PROFILE_BASELINES,
  },
] satisfies Partial<GeoJsonFirmComponent>[];

export type UploadGeoJsonComponentsStageProps = {
  onCancel: () => void;
  onBack?: () => void;
  onNext: () => void;
  useDropzones?: UseDropzones;
};

export const UploadGeoJsonComponentsStage = ({
  onCancel,
  onNext,
  onBack,
  useDropzones,
}: UploadGeoJsonComponentsStageProps) => {
  const { watch, control } = useFormContext<UploadGeoJsonComponentsStageType>();

  const components = watch("components");

  return (
    <Container width="narrow">
      <HeaderSection>
        <h1>Import FIRM files</h1>
        <h2>
          All files must be geojson (.geojson) files. Please verify that your
          files match the required schema{" "}
          <a
            href="https://forerunner.helpscoutdocs.com/article/74-firm-requirements"
            target="_blank"
          >
            here
          </a>
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          {components!.map((component, index) => (
            <React.Fragment key={index}>
              <DropzoneLabel>{component.label}</DropzoneLabel>
              <SingleFileUpload
                control={control}
                name={`components.${index}`}
                allowedMimeTypes={[MIME_TYPE.GEOJSON]}
                compact={true}
                hasImage={false}
                key={component.name}
                useDropzone={
                  useDropzones?.find(
                    dropzone => dropzone.name === component.name
                  )?.useDropzone
                }
              />
            </React.Fragment>
          ))}
        </ContentSection>
      </FormSection>
      <ButtonSection>
        {onBack && (
          <SecondaryButtons>
            <Button styleVariant="ghost" onClick={onBack} size="medium">
              Back
            </Button>
          </SecondaryButtons>
        )}
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            onClick={onNext}
            size="medium"
            disabled={
              !components?.find(
                component => component.name === FirmComponent.FLOODZONES
              )!.blob
            }
          >
            Next
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default UploadGeoJsonComponentsStage;
