import "./__styles__/Icon.scss";
import React from "react";

const InformationCircle = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6 9C5.55 9 5.25 8.7 5.25 8.25C5.25 7.8 5.55 7.5 6 7.5C6.45 7.5 6.75 7.8 6.75 8.25C6.75 8.7 6.45 9 6 9ZM6.75 6.75H5.25V3H6.75V6.75Z"
        fill="#B9BCC1"
      />
    </svg>
  );
};

export default InformationCircle;
