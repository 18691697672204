import React, { Fragment, useContext } from "react";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { AuthContext } from "../../Authorization/AuthContext";
import { Account } from "../../Authorization/types";
import Divider from "../../Common/Divider";
import { Section } from "../__styles__/AddressPanel";
import BuildingAttrInfo from "./BuildingAttrInfo";
import { CustomSection } from "./CustomSection";
import FIRMInfo from "./FIRMInfo";
import ParcelData from "./ParcelData";
import { RepetitiveLossData } from "./RepetitiveLoss";

const sectionMapper = ({
  shouldShowRepLoss,
  isGuest,
  section,
}: {
  isGuest: boolean;
  section: Account["propertyInformationSections"][number];
  shouldShowRepLoss: boolean;
}) => {
  const attributeCount = isGuest
    ? section.accountPropertyAttributes.filter(attribute => attribute.isPublic)
        .length
    : section.accountPropertyAttributes.length;

  switch (section.code) {
    case PROPERTY_INFORMATION_SECTION_CODE.FLOOD_INFO:
      return {
        include: !!attributeCount,
        render: () => <FIRMInfo section={section} />,
      };

    case PROPERTY_INFORMATION_SECTION_CODE.BUILDING_INFO:
      return {
        include: !!attributeCount,
        render: () => <BuildingAttrInfo section={section} />,
      };

    case PROPERTY_INFORMATION_SECTION_CODE.PARCEL_INFO:
      return {
        include: true,
        render: () => <ParcelData section={section} />,
      };

    case PROPERTY_INFORMATION_SECTION_CODE.REPETITIVE_LOSS:
      return {
        include: shouldShowRepLoss,
        render: () => <RepetitiveLossData section={section} />,
      };

    case PROPERTY_INFORMATION_SECTION_CODE.CUSTOM:
      return {
        include: !!attributeCount,
        render: () => <CustomSection section={section} />,
      };
  }
};

export const PropertyOverviewSections = ({
  shouldShowRepLoss,
}: {
  shouldShowRepLoss: boolean;
}) => {
  const { isGuest, account } = useContext(AuthContext);

  return (
    <>
      {account?.propertyInformationSections.map(section => {
        const sectionComponent = sectionMapper({
          shouldShowRepLoss,
          isGuest,
          section,
        });

        if (!sectionComponent.include) {
          return null;
        }

        return (
          <Fragment key={section.id}>
            <Section>{sectionComponent.render()}</Section>
            <Divider />
          </Fragment>
        );
      })}
    </>
  );
};
