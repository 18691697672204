import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DocumentNode } from "graphql";
import { keys } from "lodash";
import { useForm } from "react-hook-form";
import { FEATURE_FLAG_NAME } from "common/constants";
import { formatDate } from "common/utils/strings";
import {
  FeatureFlagName,
  useUpdateAccountFeatureFlagsMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { CURRENT_ADMIN } from "../../Authorization/__queries__";
import { AuthContext } from "../../Authorization/AuthContext";
import { FlexColumn } from "../../Common/__styles__/Layout";
import { SettingsForm } from "../../Common/__styles__/Settings";
import { Button } from "../../Common/Button";
import { SettingsPanel } from "../../Common/Settings";
import Table from "../../Common/Tables/Table";
import { Checkbox } from "../../Inputs/react-hook-form";
import {
  DescriptionCell,
  LastModifiedCell,
  NameCell,
} from "./__styles__/FeatureFlag";

interface FeatureFlag {
  id: string;
  feature: string;
  isEnabled: boolean;
  updatedAt?: string;
}

type FeatureFlagsState = {
  [key in FEATURE_FLAG_NAME]: boolean;
};

const buildFormState = (featureFlags: Array<FeatureFlag>) => {
  return featureFlags.reduce((convertedFlags, flag) => {
    return {
      ...convertedFlags,
      [flag.feature as FEATURE_FLAG_NAME]: flag.isEnabled,
    };
  }, {} as FeatureFlagsState);
};

type TableStructure = {
  feature: FEATURE_FLAG_NAME;
  name?: Maybe<string>;
  updatedAt?: Maybe<string>;
  isEnabled: boolean;
  description?: Maybe<string>;
};
const FeatureFlags = ({
  refetchQueries = [{ query: CURRENT_ADMIN }],
}: {
  refetchQueries?: Array<{ query: DocumentNode }>;
}) => {
  const { account } = React.useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const {
    control,
    formState: { isDirty, dirtyFields },
    reset,
    getValues,
  } = useForm<FeatureFlagsState>({
    defaultValues: buildFormState(account?.featureFlags || []),
  });

  const [updateAccountFeatureFlags, { loading }] =
    useUpdateAccountFeatureFlagsMutation({
      onCompleted: data => {
        addSuccessToast("Feature flags were successfully updated.");
        reset(buildFormState(data.updateAccountFeatureFlags.featureFlags));
      },
      onError: () => {
        addErrorToast("There was a problem updating the feature flags.");
      },
      refetchQueries,
    });

  const columns: Array<ColumnDef<TableStructure>> = [
    {
      id: "feature",
      header: "Feature name",
      enableSorting: false,
      maxSize: 200,
      cell: ({ row }) => {
        return <NameCell>{row.original.name}</NameCell>;
      },
    },
    {
      id: "description",
      header: "Description",
      enableSorting: false,
      maxSize: 500,
      cell: ({ row }) => {
        const text = row.original.description || "No description available";
        return <DescriptionCell title={text}>{text}</DescriptionCell>;
      },
    },
    {
      id: "updatedAt",
      header: "Last modified",
      enableSorting: false,
      maxSize: 200,
      cell: ({ row }) => {
        const text = row.original.updatedAt
          ? formatDate(row.original.updatedAt)
          : "N/A";
        return <LastModifiedCell>{text}</LastModifiedCell>;
      },
    },
    {
      id: "isEnabled",
      header: "Enabled",
      enableSorting: false,
      cell: ({ row }) => {
        return <Checkbox name={row.original.feature} control={control} />;
      },
    },
  ];

  return (
    <SettingsPanel title="Feature flags">
      <SettingsForm>
        <FlexColumn style={{ width: "100%" }} data-testid="feature-flags-table">
          <Table<TableStructure>
            columns={columns}
            currentData={account?.featureFlags || []}
            loadingDetails={{
              loading,
              loadingText: "Loading account feature flags...",
              noDataText: "No feature flags found",
            }}
            tableStyleDetails={{
              hasRowActions: true,
              isCompact: true,
              hasFlushedSides: true,
            }}
            excludePaginationNav
          />
        </FlexColumn>
        <Button
          styleVariant="primary"
          size="medium"
          disabled={loading || account?.featureFlags.length === 0 || !isDirty}
          data-testid="update-feature-flags-button"
          onClick={async () => {
            const filteredArray = keys(dirtyFields).map(feature => {
              return {
                feature: feature as FeatureFlagName,
                isEnabled: getValues(feature as FeatureFlagName),
              };
            });

            await updateAccountFeatureFlags({
              variables: {
                data: filteredArray,
              },
            });
          }}
        >
          Update feature flags
        </Button>
      </SettingsForm>
    </SettingsPanel>
  );
};

export default FeatureFlags;
