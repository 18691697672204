import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { RESOURCE_NAME } from "common/authorization";
import { SUBMISSION_CATEGORY } from "common/constants";
import { buildLink } from "common/routing";
import {
  ImprovementExclusionReason,
  useToggleImprovementExclusionMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";
import { AuthContext } from "../../Authorization/AuthContext";
import Modal from "../../Common/Modal";
import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";
import { AddressPanelContext } from "../AddressPanelContext";
import EditVisibility from "../Submissions/EditVisibility";
import DeleteImprovementConfirmation from "./DeleteImprovementConfirmation";
import { useDeleteModal } from "./DeleteModal";
import { PartialImprovement } from "./ListItem";

interface EditImprovementDropdownProps {
  onUpdate: () => {};
  improvement: PartialImprovement;
  address?: Maybe<string>;
}

const EditImprovementDropdown = ({
  onUpdate,
  improvement,
  address,
}: EditImprovementDropdownProps) => {
  const isExcluded = !!improvement.exclusion?.excluded;

  const { account, authorized, isGuest } = useContext(AuthContext);
  const { property } = useContext(AddressPanelContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [toggleImprovementExclusion] = useToggleImprovementExclusionMutation({
    fetchPolicy: "network-only",
  });

  const [showDeleteModal] = useDeleteModal({
    address,
    onSave: () => {
      onUpdate();
    },
    onCancel: () => {},
    modalProps: { improvement },
    DeletePanel: DeleteImprovementConfirmation,
  });

  const [showEditVisibilityModal, hideEditVisibilityModal] = useModal(
    () => (
      <Modal onRequestClose={hideEditVisibilityModal}>
        <EditVisibility
          submission={{
            ...improvement,
            hiddenFromPublic: improvement.hiddenFromPublic!,
          }}
          closeModal={hideEditVisibilityModal}
          submissionTypeName="SI/SD"
          onUpdate={onUpdate}
        />
      </Modal>
    ),
    [improvement.hiddenFromPublic]
  );

  const toggleExclusion = async () => {
    const toastPhrasalVerb = isExcluded
      ? "removed from manual exclusion"
      : "excluded from the summary";

    await toggleImprovementExclusion({
      variables: {
        id: improvement.id,
        data: { excluded: !isExcluded },
      },
      onCompleted: () => {
        addSuccessToast(`Your record was ${toastPhrasalVerb}.`);
      },
      onError: () => {
        addErrorToast(`Your record failed to be ${toastPhrasalVerb}.`);
      },
    });
  };

  const canUpdateImprovement = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "update",
  });

  const canDeleteImprovement = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "delete",
  });

  const canUpdateVisibility = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "updateVisibility",
  });

  const actions: Array<ActionsProps> = [
    {
      label: "View",
      href: buildLink("viewSubmission", { submissionId: improvement.id }, {}),
      target: "_blank",
      track: () => {
        track("Submission viewed", {
          submissionId: improvement.id,
          "Submission Category":
            SUBMISSION_CATEGORY.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE,
        });
      },
    },
  ];

  if (!isGuest) {
    actions.push({
      label: isExcluded ? "Include" : "Exclude",
      onClick: () => toggleExclusion(),
      disabled: !canUpdateImprovement,
      hidden:
        improvement.exclusion?.reason === ImprovementExclusionReason.AUTOMATIC,
    });

    if (
      account?.publicPortal.enabled &&
      !account.publicPortal.hideSISD &&
      !property?.hiddenFromPublic
    ) {
      actions.push({
        label: improvement.hiddenFromPublic
          ? "Show on public website"
          : "Hide on public website",
        onClick: () => showEditVisibilityModal(),
        disabled: !canUpdateVisibility,
        track: () => {
          track("Submission visibility updated", {
            submissionId: improvement.id,
            "Submission Category":
              SUBMISSION_CATEGORY.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE,
          });
        },
      });
    }

    actions.push({
      label: "Delete",
      disabled: !canDeleteImprovement,
      variant: "red",
      onClick: () => showDeleteModal(),
      closeOnClick: false,
    });
  }

  return <DropdownMenu actions={actions} />;
};

export default EditImprovementDropdown;
