import React, { ReactNode } from "react";
import { Icon } from "../../Common/Icons/LucideIcons";
import { Body } from "../../Common/Typography";
import {
  ComponentWrapper,
  SelectionCardTextBlock,
} from "../../Inputs/__styles__/SelectionCardGroup";
import { NavigationCard as StyledNavigationCard } from "./__styles__/NavigationCard";

const NavigationCard = ({
  children,
  onClick,
}: {
  children?: ReactNode;
  onClick: () => void;
}) => {
  return (
    <StyledNavigationCard
      type="button"
      data-testid="navigation-card"
      onClick={onClick}
      tabIndex={0}
    >
      <ComponentWrapper>
        <Icon iconName="chevron-left" color={"contentSecondary"} size={16} />
      </ComponentWrapper>
      <SelectionCardTextBlock>
        <Body as="div" size="default" type="emphasis">
          {children}
        </Body>
      </SelectionCardTextBlock>
    </StyledNavigationCard>
  );
};

export default NavigationCard;
