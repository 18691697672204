import React from "react";
import { useModal } from "react-modal-hook";
import { useCreateRasterMutation } from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import Modal from "../../../Common/Modal";
import { RasterForm, RasterFormData } from "./Form";

export const useAddRasterModal = () => {
  const [showAddRasterModal, hideAddRasterModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideAddRasterModal();
        }}
      >
        <AddRasterModal
          onSave={hideAddRasterModal}
          onCancel={hideAddRasterModal}
        />
      </Modal>
    ),
    []
  );
  return [showAddRasterModal, hideAddRasterModal] as const;
};

export const AddRasterModal = ({
  onSave,
  onCancel,
  refetchQueries = ["GetAccountRasters"],
}: {
  onSave: () => void;
  onCancel: () => void;
  refetchQueries?: string[];
}) => {
  const [createRaster, { loading }] = useCreateRasterMutation();
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const onCreate = async (data: RasterFormData) => {
    const createRasterPayload = {
      wkid: data.wkid,
      layerName: data.layerName,
      layerSection: data.layerSection,
      url: data.url,
      bandMappingsUrl: data.bandMappingsUrl,
      bandMappings: [
        {
          bandNumber: data.bandNumber,
          measurementUnit: data.measurementUnit,
          measurementName: data.measurementName,
        },
      ],
      username: data.username || undefined,
      password: data.password || undefined,
      tokenGenerationUrl: data.tokenGenerationUrl || undefined,
    };

    await createRaster({
      variables: {
        data: createRasterPayload,
      },
      refetchQueries,
      onError: error => {
        addErrorToast(error.message);
      },
      onCompleted: () => {
        addSuccessToast("Raster successfully created!");
      },
    });

    onSave();
  };

  return <RasterForm onCancel={onCancel} onSave={onCreate} loading={loading} />;
};
