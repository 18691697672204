import React, { createRef, useContext, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import { SUBMISSION_TYPE_MODULE } from "common/constants";
import { buildLink } from "common/routing";
import { getObjectDisplayForAttached } from "common-client/utils/customObject";
import {
  useGetPropertyForSubmissionQuery,
  useGetSisdRuleDataQuery,
  useGetSubmissionQuery,
  useUpdateSubmissionMutation,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { setTitle } from "../../utils/title";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import { Button } from "../Common/Button";
import FullPageFormLayout from "../Common/FullPageFormLayout";
import { ButtonContainer } from "./__styles__/Submission";
import ActionButton from "./ActionButton";
import { FormComponent, FormDataType } from "./Form";
import SubmissionHeader from "./SubmissionHeader";
import {
  generateUpsertErrorMessage,
  hasSummary,
  useSubmissionPrevLocation,
} from "./utils";

interface URLParams {
  submissionId: string;
}

export interface EditSubmissionProps extends RouteComponentProps<URLParams> {}

const EditSubmission = ({ match }: EditSubmissionProps) => {
  const history = useHistory();
  const { authorized, user, admin, isGuest, account } = useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const [disabled, setDisabled] = useState(true);

  const { submissionId } = match.params;

  const { data, loading, error } = useGetSubmissionQuery({
    variables: { submissionId, isGuest },
    fetchPolicy: "no-cache",
  });

  const [updateSubmission, { loading: updateLoading }] =
    useUpdateSubmissionMutation({
      onCompleted: data => {
        const submission = data.updateSubmission;
        track("Submission updated", {
          submissionId: submission.id,
          "Submission Category": submissionType.category,
          "Submission Type": submissionType.name,
        });
        addSuccessToast("Your submission has been updated.");
        history.push({
          pathname: buildLink("editSubmission", {
            submissionId: submission.id,
          }),
        });
      },
      onError: error => {
        const message = generateUpsertErrorMessage(error);
        addErrorToast(message);
      },
    });

  const propertyId = data?.submission?.property?.id;
  const { data: propertyData, loading: propertyDataLoading } =
    useGetPropertyForSubmissionQuery({
      variables: { propertyId: propertyId!, isGuest },
      skip: !propertyId,
    });

  const submissionTypeModules =
    data?.submission?.submissionTypeVersion.submissionType.modules;

  const { data: sisdRuleData, loading: sisdRuleDataLoading } =
    useGetSisdRuleDataQuery({
      variables: { propertyId: propertyId! },
      skip:
        !submissionTypeModules?.includes(
          SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
        ) || !propertyId,
    });

  const prevLocation = useSubmissionPrevLocation();
  const submission = data?.submission;
  if (
    loading ||
    propertyDataLoading ||
    sisdRuleDataLoading ||
    error ||
    !submission
  ) {
    return <div />;
  }

  const submitFormRef = createRef<HTMLButtonElement>();

  const submissionType = submission.submissionTypeVersion.submissionType;

  if (isGuest) {
    setTitle(submissionType.name);
  }

  const canUpdateSubmission = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "update",
  });

  const handleSubmit = async (formData: FormDataType) => {
    await updateSubmission({
      variables: { data: { submissionId, formData, isGuest } },
    });
  };

  const handleOnChange = ({
    isDirty,
    documentUploadsInProgress,
  }: {
    isDirty: boolean;
    documentUploadsInProgress: boolean;
  }) => {
    setDisabled(!isDirty || documentUploadsInProgress);
  };

  const rightContainer = (
    <ButtonContainer>
      {!isGuest && (
        <Button
          size="small"
          styleVariant="primary"
          onClick={() => {
            submitFormRef.current!.click();
          }}
          disabled={!canUpdateSubmission || disabled}
          loading={updateLoading}
        >
          Update
        </Button>
      )}
      <ActionButton
        isEditView={true}
        submission={{
          ...submission,
          hasSummary: hasSummary(submission),
          category: submissionType.category,
        }}
        objectIsHiddenFromPublic={submission.property?.hiddenFromPublic}
      />
    </ButtonContainer>
  );

  const property = propertyData?.property;
  const parcel = propertyData?.property?.parcel;

  return (
    <FullPageFormLayout
      subtitle={submissionType.name}
      prevLocation={prevLocation}
      rightContainer={rightContainer}
    >
      <div>
        <SubmissionHeader
          account={{
            logoUrl: account?.logoUrl ?? "",
            name: account?.name ?? "",
          }}
          objectDisplay={getObjectDisplayForAttached(submission)}
          submissionType={submissionType}
        />
        <FormComponent
          readonly={!canUpdateSubmission}
          submissionType={{
            currentVersion: submission.submissionTypeVersion,
            modules: submission.submissionTypeVersion.submissionType.modules,
          }}
          existingSubmission={submission}
          relatedSubmissions={submission.relatedSubmissions}
          onSubmit={handleSubmit}
          onChange={handleOnChange}
          submitFormRef={submitFormRef}
          defaultValues={{
            property: {
              sisd: {
                adjustmentRatio: property?.sisd.rule?.adjustmentRatio,
              },
            },
            parcel,
            user: user ?? admin,
          }}
          account={sisdRuleData?.account}
          property={{ FIRMInfo: sisdRuleData?.FIRMInfo }}
        />
      </div>
    </FullPageFormLayout>
  );
};

export default EditSubmission;
