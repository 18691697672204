import React from "react";
import { pick } from "lodash";
import { useForm } from "react-hook-form";
import { DAY_IN_MINUTES } from "common/utils/dates";
import { UpdateAccountSecurityConfigurationMutationFn } from "../../../generated/graphql";
import { SettingsForm, SettingsGrid } from "../../Common/__styles__/Settings";
import { Button } from "../../Common/Button";
import { Label } from "../../Inputs";
import { Select } from "../../Inputs/react-hook-form";

const SESSION_TIMEOUT_MINUTES = {
  "Log users out after 15 minutes of inactivity": 15,
  "Log users out after 30 minutes of inactivity": 30,
  "Log users out after 60 minutes of inactivity": 60,
  "Log users out after 90 minutes of inactivity": 90,
  "Log users out after 24 hours of inactivity": DAY_IN_MINUTES,
  "Log users out after 7 days of inactivity": DAY_IN_MINUTES * 7,
};

const PASSWORD_EXPIRATION_DAYS = {
  "Passwords never expire": null,
  "Passwords expire after 15 days": 15,
  "Passwords expire after 60 days": 60,
  "Passwords expire after 90 days": 90,
  "Passwords expire after 120 days": 120,
  "Passwords expire after 6 months": 168,
  "Passwords expire after 1 year": 365,
};

const MAX_FAILED_LOGINS = {
  "Lock user accounts after 3 failed login attempts": 3,
  "Lock user accounts after 5 failed login attempts": 5,
  "Lock user accounts after 10 failed login attempts": 10,
};

const MINIMUM_PASSWORD_LENGTHS = {
  "8 characters": 8,
  "10 characters": 10,
  "12 characters": 12,
  "15 characters": 15,
};

const makeSecurityOptions = (constants: Record<string, null | number>) => {
  return Object.entries(constants).map(([label, value]) => ({
    value,
    label,
  }));
};
interface FormStructure {
  sessionTimeoutMinutes?: Maybe<number>;
  passwordExpirationDays?: Maybe<number>;
  maxFailedLoginAttempts: number;
  minimumPasswordLength: number;
}

export const SecurityConfigurationForm = ({
  securityConfiguration,
  canUpdateAccountInformation,
  onUpdate,
}: {
  securityConfiguration: FormStructure;
  canUpdateAccountInformation: boolean;
  onUpdate: UpdateAccountSecurityConfigurationMutationFn;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormStructure>({
    defaultValues: {
      ...securityConfiguration,
    },
  });

  const sessionTimeoutMinutesOptions = makeSecurityOptions(
    SESSION_TIMEOUT_MINUTES
  );

  const maxFailedLoginAttemptsOptions = makeSecurityOptions(MAX_FAILED_LOGINS);

  const passwordExpirationDaysOptions = makeSecurityOptions(
    PASSWORD_EXPIRATION_DAYS
  );

  const minimumPasswordLengthOptions = makeSecurityOptions(
    MINIMUM_PASSWORD_LENGTHS
  );

  return (
    <SettingsForm
      onSubmit={handleSubmit(async data => {
        await onUpdate({
          variables: {
            data: pick(data, [
              "sessionTimeoutMinutes",
              "maxFailedLoginAttempts",
              "passwordExpirationDays",
              "minimumPasswordLength",
            ]),
          },
        });
      })}
    >
      <SettingsGrid>
        <div>
          <Label
            text="Session timeout configuration"
            tooltip="Select the number of minutes of inactivity in a session before a user is logged out"
          />
          <Select
            control={control}
            name="sessionTimeoutMinutes"
            options={sessionTimeoutMinutesOptions}
            disabled={!canUpdateAccountInformation}
            required={true}
            error={errors.sessionTimeoutMinutes?.message}
            size="medium"
          />
        </div>
        <div>
          <Label
            text="Lock accounts after failed login attempts"
            tooltip="Select the number of failed login attempts until user accounts are locked"
          />
          <Select
            control={control}
            name="maxFailedLoginAttempts"
            options={maxFailedLoginAttemptsOptions}
            disabled={!canUpdateAccountInformation}
            size="medium"
          />
        </div>
        <div>
          <Label
            text="Password expiration configuration"
            tooltip="Enable password expiration for all users in your account after a given time period"
          />
          <Select
            control={control}
            name="passwordExpirationDays"
            options={passwordExpirationDaysOptions}
            disabled={!canUpdateAccountInformation}
            size="medium"
          />
        </div>
        <div>
          <Label text="Minimum password length" />
          <Select
            control={control}
            name="minimumPasswordLength"
            options={minimumPasswordLengthOptions}
            disabled={!canUpdateAccountInformation}
            size="medium"
          />
        </div>
      </SettingsGrid>
      <Button
        styleVariant="primary"
        size="medium"
        type="submit"
        disabled={!canUpdateAccountInformation}
      >
        Update security settings
      </Button>
    </SettingsForm>
  );
};
