import React from "react";
import {
  ActivityTargetType,
  ActivityType,
  SubmissionCategory,
} from "../../generated/graphql";
import { Icon, IconNames } from "../Common/Icons/LucideIcons";
import { IconContainer } from "./__styles__/Bullet";
import { Activity } from "./types";

export const ICONS_PER_TYPE = {
  [ActivityTargetType.COMMENT]: "file",
  [ActivityTargetType.CERTIFICATE]: "file",
  [ActivityTargetType.DESIGN_FLOOD_ELEVATION]: "home",
  [ActivityTargetType.FIRM]: "home",
  [ActivityTargetType.LOG]: "calendar",
  [ActivityTargetType.PROPERTY]: {
    [ActivityType.SHARED]: "home",
    [ActivityType.CREATED]: "home",
    [ActivityType.DELETED]: "trash-2",
    [ActivityType.UPDATED]: "home",
    [ActivityType.HID]: "home",
    [ActivityType.DISPLAYED]: "home",
  },
  [ActivityTargetType.DOCUMENT_UPLOAD]: {
    [ActivityType.CREATED]: "file",
    [ActivityType.DELETED]: "trash-2",
    [ActivityType.UPDATED]: "file",
    [ActivityType.UPLOADED]: "file",
    [ActivityType.SHARED]: "file",
  },
  [ActivityTargetType.BATCH_CERTIFICATE_UPLOAD]: "file",
  [ActivityTargetType.PROPERTY_WARNING]: "circle-alert",
  [ActivityTargetType.REPETITIVE_LOSS]: "home",
  [ActivityTargetType.SUBMISSION]: {
    [SubmissionCategory.INSPECTIONS]: "file-text",
    [SubmissionCategory.PERMITTING]: "clipboard-check",
    [SubmissionCategory.GRANTS]: "hand-coins",
    [SubmissionCategory.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]: "home",
    [SubmissionCategory.PAYMENTS]: "dollar-sign",
    [SubmissionCategory.MAILINGS]: "mailbox",
    [SubmissionCategory.CODE_CASES]: "shield-alert",
    [SubmissionCategory.PROJECTS]: "folder-closed",
    [SubmissionCategory.PLANNING]: "chart-gantt",
    [SubmissionCategory.INSURANCE]: "shield-check",
  },
  [ActivityTargetType.SAVED_VIEW]: "table-properties",
} as const satisfies Record<
  ActivityTargetType,
  | IconNames
  | Partial<Record<ActivityType, IconNames>>
  | Record<SubmissionCategory, IconNames>
>;

type SubmissionActivity = {
  targetType: ActivityTargetType.SUBMISSION;
  category: SubmissionCategory;
  activityType: ActivityType;
};

type PropertyActivity = {
  targetType: ActivityTargetType.PROPERTY;
  category?: undefined;
  activityType: keyof typeof ICONS_PER_TYPE[ActivityTargetType.PROPERTY];
};

type DocumentUploadActivity = {
  targetType: ActivityTargetType.DOCUMENT_UPLOAD;
  category?: undefined;
  activityType: keyof typeof ICONS_PER_TYPE[ActivityTargetType.DOCUMENT_UPLOAD];
};

type OtherActivity = {
  targetType: Exclude<
    ActivityTargetType,
    | ActivityTargetType.SUBMISSION
    | ActivityTargetType.PROPERTY
    | ActivityTargetType.DOCUMENT_UPLOAD
  >;
  category?: undefined;
  activityType: ActivityType;
};

export type BulletProps =
  | SubmissionActivity
  | PropertyActivity
  | DocumentUploadActivity
  | OtherActivity;

const Bullet = ({ activity }: { activity: Activity }) => {
  const { targetType, activityType, category } =
    getBulletPropsForActivity(activity);

  let iconName: IconNames;

  if (targetType === ActivityTargetType.SUBMISSION) {
    iconName = ICONS_PER_TYPE[targetType][category];
  } else if (targetType === ActivityTargetType.PROPERTY) {
    iconName = ICONS_PER_TYPE[targetType][activityType];
  } else if (targetType === ActivityTargetType.DOCUMENT_UPLOAD) {
    iconName = ICONS_PER_TYPE[targetType][activityType];
  } else {
    iconName = ICONS_PER_TYPE[targetType];
  }

  return (
    <IconContainer>
      <Icon iconName={iconName} color="contentSecondary" size={16} />
    </IconContainer>
  );
};

const getBulletPropsForActivity = (activity: Activity) => {
  const { targetType, target, targetInfo, type: activityType } = activity;

  let bulletProps: BulletProps;
  if (targetType === ActivityTargetType.SUBMISSION) {
    const category =
      targetInfo.category ??
      (target?.__typename === "Submission"
        ? target.submissionTypeVersion.submissionType.category
        : undefined) ??
      SubmissionCategory.INSPECTIONS;
    bulletProps = {
      targetType,
      category,
      activityType,
    };
  } else if (targetType === ActivityTargetType.PROPERTY) {
    bulletProps = {
      targetType,
      activityType:
        activityType in ICONS_PER_TYPE[ActivityTargetType.PROPERTY]
          ? (activityType as keyof typeof ICONS_PER_TYPE[ActivityTargetType.PROPERTY])
          : ActivityType.CREATED,
    };
  } else if (targetType === ActivityTargetType.DOCUMENT_UPLOAD) {
    bulletProps = {
      targetType,
      activityType:
        activityType in ICONS_PER_TYPE[ActivityTargetType.DOCUMENT_UPLOAD]
          ? (activityType as keyof typeof ICONS_PER_TYPE[ActivityTargetType.DOCUMENT_UPLOAD])
          : ActivityType.CREATED,
    };
  } else {
    bulletProps = {
      targetType,
      activityType,
    };
  }
  return bulletProps;
};

export default Bullet;
