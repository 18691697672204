import React, { useState } from "react";
import * as Sentry from "@sentry/browser";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { FormProvider, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { MIME_TYPE } from "common/constants";
import {
  useGetPresignedRepetitiveLossUploadS3UrlLazyQuery,
  useScheduleRepetitiveLossDataImportMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import { HeaderRowComponent } from "../../../FileUploads";
import { SingleFileUpload } from "../../../Inputs/react-hook-form";

interface UseCSVImportModalProps {
  onUpdate: () => void;
}
export const useCSVImportModal = ({ onUpdate }: UseCSVImportModalProps) => {
  const [showCSVImportModal, hideCSVImportModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideCSVImportModal();
        }}
      >
        <CSVImportModal onCancel={hideCSVImportModal} onUpdate={onUpdate} />
      </Modal>
    ),
    []
  );
  return [showCSVImportModal, hideCSVImportModal] as const;
};

type CSVUpload = {
  blob: any;
  id?: string;
  originalFilename: string;
};

export interface RepetitiveLossImportForm {
  csvUpload: CSVUpload;
}

export const CSVImportModal = ({
  onCancel,
  onUpdate,
  useDropzone = undefined,
}: {
  onCancel: () => void;
  onUpdate: () => void;
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
}) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [isDisabled, setIsDisabled] = useState(false);

  const formMethods = useForm<RepetitiveLossImportForm>();
  const {
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const onUpload = async ({
    s3Key,
    forerunnerJobId,
    uploadURL,
  }: {
    s3Key: string;
    forerunnerJobId: string;
    uploadURL: string;
  }) => {
    setIsDisabled(true);
    try {
      const result = await fetch(uploadURL!, {
        method: "PUT",
        body: getValues("csvUpload.blob"),
      });

      if (!result.ok) {
        throw new Error(`Received a ${result.status} when uploading ${s3Key}`);
      }
    } catch (error) {
      Sentry.captureException(error);

      addErrorToast(
        "Your upload failed. Please remove the file and try reuploading it."
      );

      setIsDisabled(false);

      return;
    }

    await scheduleRepetitiveLossDataImport({
      variables: {
        data: {
          name: getValues("csvUpload.originalFilename"),
          forerunnerJobId: forerunnerJobId,
          s3Key: s3Key,
        },
      },
    });
  };

  const [getPresignedUrl, { loading }] =
    useGetPresignedRepetitiveLossUploadS3UrlLazyQuery({
      onCompleted: async data => {
        const { s3Key, forerunnerJobId, uploadURL } =
          data.getPresignedRepetitiveLossUploadS3Url;

        await onUpload({ s3Key, forerunnerJobId, uploadURL });
      },
      fetchPolicy: "network-only",
    });

  const [scheduleRepetitiveLossDataImport] =
    useScheduleRepetitiveLossDataImportMutation({
      onCompleted: () => {
        addSuccessToast("Your repetitive loss data import was scheduled.");
        onUpdate();
        onCancel();
      },
      onError: () => {
        addErrorToast("Your repetitive loss data import failed.");

        setIsDisabled(false);
      },
    });

  const csvUpload = watch("csvUpload");

  return (
    <FormProvider {...formMethods}>
      <Container>
        <HeaderSection>
          <h1>Import csv file</h1>
          <h2>File must be a csv (.csv) file</h2>
        </HeaderSection>
        <FormSection onSubmit={onCancel}>
          <ContentSection>
            <SingleFileUpload
              useDropzone={useDropzone}
              allowedMimeTypes={[MIME_TYPE.CSV]}
              control={control}
              name={"csvUpload"}
              fileHeader={
                <HeaderRowComponent hasGreenCheckmark={true}>
                  <div tabIndex={0}>File name</div>
                </HeaderRowComponent>
              }
            />
          </ContentSection>
          <ButtonSection>
            <PrimaryButtons>
              <Button
                styleVariant="secondary"
                size="medium"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                styleVariant="primary"
                disabled={
                  !csvUpload?.blob ||
                  !!errors.csvUpload ||
                  isDisabled ||
                  loading
                }
                size="medium"
                onClick={handleSubmit(() => getPresignedUrl())}
              >
                Import
              </Button>
            </PrimaryButtons>
          </ButtonSection>
        </FormSection>
      </Container>
    </FormProvider>
  );
};
