import React, { useContext, useEffect, useState } from "react";
import { groupBy, isNil } from "lodash";
import { useTranslation } from "react-i18next";
import {
  DOCUMENT_TYPE_NAME,
  ELEVATION_DATUM,
  PROPERTY_ATTRIBUTE_NAMES,
} from "common/constants";
import { formatDateString } from "common/utils/dates";
import { formatFeet } from "common/utils/strings";
import { isNotNil } from "common/utils/tools";
import {
  defaultBuildingInformationDisclaimer,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";
import {
  DisclaimerName,
  PropertyAttributeSource,
  useGetBuildingAttributesQuery,
} from "../../../generated/graphql";
import {
  formatCertificateType,
  showCertificateType,
} from "../../../utils/certificates";
import { AuthContext } from "../../Authorization/AuthContext";
import { Account } from "../../Authorization/types";
import { BANNER_TYPES } from "../../Common/__styles__/Banner";
import { FlexRow } from "../../Common/__styles__/Layout";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../Common/__styles__/StripedTable";
import Banner from "../../Common/Banner";
import Disclaimer from "../../Common/Disclaimer";
import { EmptyState } from "../../Common/EmptyState";
import { MarkdownTooltip } from "../../Common/Tooltip";
import { Body } from "../../Common/Typography";
import { RelevantFIRMs } from "../../FIRM/RelevantFIRMs";
import { LayerContext } from "../../Maps/layers";
import { AddressPanelContext } from "../AddressPanelContext";
import { BuildingAttrCertificate } from "./__queries__/getBuildingAttributes";
import { Label, TooltipContainer } from "./__styles__";
import { DatumButton } from "./__styles__/BuildingAttrInfo";
import { CustomMapRow } from "./Common/CustomMapRow";
import { RasterRow } from "./Common/RasterRow";

type RowValue = Maybe<string | number> | undefined;

interface RowProps {
  label: string;
  nfipBadge?: boolean;
  regulatoryBadge?: boolean;
  data: RowValue;
  format?: <T>(data: T) => string;
  tooltip?: Maybe<string>;
  isPublic: boolean;
}

const Row = ({
  label,
  data,
  format = data => `${data}`,
  tooltip,
  isPublic,
}: RowProps) => {
  const { isGuest } = useContext(AuthContext);

  if (!isPublic && isGuest) {
    return <></>;
  }

  let tooltipElement = null;
  if (isGuest && tooltip) {
    tooltipElement = (
      <TooltipContainer data-testid="tooltip" style={{ display: "inline" }}>
        <MarkdownTooltip tooltipText={tooltip} place="right" />
      </TooltipContainer>
    );
  }

  return (
    <TableRow header={false}>
      <TableCell containsTooltip>
        <div style={{ overflowX: "hidden", whiteSpace: "normal" }}>
          {/* Ran into centering problems with the badges */}
          <Label size={"narrow"} style={{ margin: "auto 0" }}>
            {label}
          </Label>
          {tooltipElement}
        </div>
      </TableCell>
      <TableCell>
        {isNil(data) || data === "" ? <span>-</span> : format(data)}
      </TableCell>
    </TableRow>
  );
};

// Return the elevation data for the specific datum requested or null if not found
// using the adjusted values in the corresponding Certificate.buildingElevations.
const getBuildingElevationForDatum = (
  certificate: BuildingAttrCertificate,
  datum: string
): Maybe<
  NonNullable<BuildingAttrCertificate["buildingElevations"]>[number]
> => {
  const elevations = certificate.buildingElevations;

  return elevations?.find(e => e.elevationDatum === datum) ?? null;
};

const getSingleCertificate = (
  certificates: Array<BuildingAttrCertificate>
): Maybe<BuildingAttrCertificate> => {
  // The GQL query sorts descending by issue date. We are interested here in the
  // most recent certificate for a given address. If there are multiple certs
  // for a given address, drop all the old ones.
  const groupedCertificates = groupBy(
    certificates,
    c => c.property.standardizedAddress
  );
  const sortedCertificates = Object.values(groupedCertificates).map(
    certs => certs[0]
  );

  return sortedCertificates.length === 1 ? sortedCertificates[0]! : null;
};

const BuildingAttrInfo = ({
  section,
}: {
  section: Account["propertyInformationSections"][number];
}) => {
  const { t } = useTranslation();
  const { account, isGuest } = useContext(AuthContext);
  const { property, loadingProperty } = useContext(AddressPanelContext);
  const propertyId = property?.id;

  const { visibleFIRM } = useContext(LayerContext);
  const [datum, setDatum] = useState(
    visibleFIRM()?.datum || ELEVATION_DATUM.NAVD_1988
  );

  useEffect(() => {
    setDatum(visibleFIRM()?.datum || ELEVATION_DATUM.NAVD_1988);
  }, [visibleFIRM()]);

  const certificateDocumentTypeId = account?.accountDocumentTypes.find(
    document => document.name === DOCUMENT_TYPE_NAME.ELEVATION_CERTIFICATE
  )?.id;

  const { data, loading, error } = useGetBuildingAttributesQuery({
    variables: {
      propertyId: propertyId!,
      accountDocumentTypeId: certificateDocumentTypeId!,
      filterHidden: isGuest,
    },
    fetchPolicy: "no-cache",
    // some accounts don't have the EC document type
    skip: !propertyId || !certificateDocumentTypeId,
  });

  if (loading || loadingProperty) {
    return <em>{t("common-loading")}</em>;
  }

  if (error) {
    return <em>{t("property-building-info-error")}</em>;
  }

  const certificates =
    data?.property?.documentUploads
      .map(document => document.elevationCertificate)
      .filter(isNotNil) || [];

  const certificate = getSingleCertificate(certificates);

  if (!propertyId || !certificate) {
    return (
      <div>
        <Body size="large" type="emphasis" style={{ maxWidth: "50%" }}>
          {section.label}
        </Body>
        <EmptyState compact title={t("property-building-info-empty-state")} />
      </div>
    );
  }

  const buildingElevation = getBuildingElevationForDatum(certificate, datum);

  const elevationData = buildingElevation || {
    ...certificate,
    nfipLowestFloorElevationDetails: { lfe: null, firm: null },
    regulatoryLowestFloorElevationDetails: { lfe: null, firm: null },
  };

  const lfeByJurisdiction = {
    nfip: elevationData?.nfipLowestFloorElevationDetails,
    regulatory: elevationData?.regulatoryLowestFloorElevationDetails,
  };

  const getElementForAttribute = (
    propertyAttributeName: PROPERTY_ATTRIBUTE_NAMES
  ): ((_: {
    isPublic: boolean;
    label: string;
    tooltip?: Maybe<string>;
  }) => JSX.Element) => {
    switch (propertyAttributeName) {
      case PROPERTY_ATTRIBUTE_NAMES.BUILDING_DIAGRAM:
        return props => (
          <Row {...props} data={certificate.buildingDiagramNumber} />
        );

      case PROPERTY_ATTRIBUTE_NAMES.TOP_OF_BOTTOM_FLOOR:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.topOfBottomFloor}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.TOP_OF_NEXT_HIGHER_FLOOR:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.topOfNextHigherFloor}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.BOTTOM_OF_THE_LOWEST_HORIZONTAL_STRUCTURAL_MEMBER:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.bottomOfLowestHorizontalStructuralMember}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.ATTACHED_GARAGE:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.attachedGarage}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.LOWEST_ELEVATION_OF_MACHINERY:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.lowestElevationOfMachineryOrEquipment}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.LOWEST_ADJACENT_GRADE:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.lowestAdjacentGrade}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.HIGHEST_ADJACENT_GRADE:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={elevationData.highestAdjacentGrade}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.LOWEST_ADJACENT_GRADE_AT_DECK_OR_STAIRS:
        return props => (
          <Row
            {...props}
            format={formatFeet}
            data={
              elevationData.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs
            }
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.LOWEST_FLOOR_ELEVATION:
        return props => (
          <>
            <Row
              {...props}
              label="NFIP Lowest Floor Elevation"
              format={formatFeet}
              data={lfeByJurisdiction.nfip.lfe}
            />
            <Row
              {...props}
              label="Regulatory Lowest Floor Elevation"
              format={formatFeet}
              data={lfeByJurisdiction.regulatory.lfe}
            />
          </>
        );

      case PROPERTY_ATTRIBUTE_NAMES.CERTIFICATE_TYPE:
        return props => (
          <>
            {showCertificateType(certificate) && (
              <Row {...props} data={formatCertificateType(certificate)} />
            )}
          </>
        );

      default:
        return () => <></>;
    }
  };

  const certificateIssuedAt = formatDateString({
    format: "MM-YYYY",
    dateString: certificate.issuedAt,
  });

  const disclaimerText = getDisclaimerText({
    disclaimerOverrides: account?.disclaimerOverrides,
    name: DisclaimerName.BUILDING_INFO,
    isPublic: isGuest,
    defaultDisclaimers: {
      public: defaultBuildingInformationDisclaimer({
        certificateIssuedAt,
      }),
      internal: defaultBuildingInformationDisclaimer({
        certificateIssuedAt,
      }),
    },
  });

  return (
    <div>
      <Table spacing="negative">
        <TableHeader>
          <TableRow header>
            <TableCell wrap={true}>{section.label}</TableCell>
            <TableCell>
              <FlexRow style={{ float: "right" }}>
                <DatumButton
                  size="small"
                  styleVariant={
                    datum === ELEVATION_DATUM.NGVD_1929
                      ? "primary"
                      : "outlineLight"
                  }
                  onClick={() => setDatum(ELEVATION_DATUM.NGVD_1929)}
                  hideOnPrint={datum !== ELEVATION_DATUM.NGVD_1929}
                  disabled={!buildingElevation}
                  tabIndex={0}
                >
                  NGVD29
                </DatumButton>
                <DatumButton
                  size="small"
                  onClick={() => setDatum(ELEVATION_DATUM.NAVD_1988)}
                  styleVariant={
                    datum === ELEVATION_DATUM.NAVD_1988
                      ? "primary"
                      : "outlineLight"
                  }
                  hideOnPrint={datum !== ELEVATION_DATUM.NAVD_1988}
                  disabled={!buildingElevation}
                  tabIndex={0}
                >
                  NAVD88
                </DatumButton>
              </FlexRow>
            </TableCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {section.accountPropertyAttributes!.map(attribute => {
            if (!attribute.sourceId) {
              const attributeName = attribute.name as PROPERTY_ATTRIBUTE_NAMES;
              const Element = getElementForAttribute(attributeName);
              return <Element {...attribute} key={attribute.id} />;
            }

            if (attribute.source === PropertyAttributeSource.CUSTOM_MAP) {
              return (
                <CustomMapRow
                  accountPropertyAttribute={attribute}
                  key={attribute.id}
                  size={"narrow"}
                />
              );
            }

            return (
              <RasterRow
                accountPropertyAttribute={attribute}
                key={attribute.id}
                property={property}
                size={"narrow"}
              />
            );
          })}
        </TableBody>
      </Table>

      <div>
        <Banner type={BANNER_TYPES.NEUTRAL} style={{ marginTop: "16px" }}>
          <RelevantFIRMs
            color="contentPrimary"
            title="Lowest Floor Elevations have been determined using the following FIRM data:"
            firms={[
              {
                label: "NFIP LFE",
                value: elevationData.nfipLowestFloorElevationDetails.firm?.name,
              },
              {
                label: "Regulatory LFE",
                value:
                  elevationData.regulatoryLowestFloorElevationDetails.firm
                    ?.name,
              },
            ]}
          />
        </Banner>
        {!buildingElevation && (
          <div style={{ margin: "16px 0" }} tabIndex={0}>
            <Banner>
              The most recent Elevation Certificate assigned to this property is
              not in a supported vertical datum, and so no lowest floor
              elevation can be shown.
            </Banner>
          </div>
        )}

        <Disclaimer message={disclaimerText} />
      </div>
    </div>
  );
};

export default BuildingAttrInfo;
