import React from "react";
import Icon, { ICON_COLORS, Icons } from "../../../../Common/Icons";
import {
  AddressRow,
  IconWrapper,
  NeedsReviewText,
  NeedsReviewWrapper,
} from "./__styles__/RepetitiveLossTable";
import { RepetitiveLossForTable } from "./utils";

const AddressCell = ({
  repetitiveLoss,
}: {
  repetitiveLoss: RepetitiveLossForTable;
}): JSX.Element => {
  const iconColor = repetitiveLoss.issueIgnored
    ? ICON_COLORS.GREY_4
    : ICON_COLORS.YELLOW;
  const secondondaryText = repetitiveLoss.issueIgnored
    ? "Issue ignored"
    : "Needs review";
  return (
    <AddressRow>
      {repetitiveLoss.addressLine1}
      {repetitiveLoss.issue && (
        <NeedsReviewWrapper>
          <IconWrapper>
            <Icon icon={Icons.INFORMATION_CIRCLE} color={iconColor} />
          </IconWrapper>
          <NeedsReviewText>{secondondaryText}</NeedsReviewText>
        </NeedsReviewWrapper>
      )}
    </AddressRow>
  );
};

export default AddressCell;
