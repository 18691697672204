import "./__styles__/Icon.scss";
import React from "react";

const PDF = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 1V7H19" stroke="#D84D48" strokeMiterlimit="10" />
      <path
        d="M19 20.25V7L13 1H1V10V20.25"
        stroke="#D84D48"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M1 20.5V23.5H19V20.5"
        stroke="#D84D48"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7.85729 14.5788C7.85729 15.0573 7.71629 15.427 7.43429 15.688C7.15229 15.949 6.75104 16.0788 6.23129 16.0788H5.90579V17.5H4.74854V13.2168H6.23129C6.77204 13.2168 7.17854 13.3345 7.45004 13.5715C7.72154 13.8085 7.85729 14.143 7.85729 14.5788ZM5.90579 15.136H6.11654C6.29054 15.136 6.42854 15.0873 6.53129 14.9898C6.63404 14.8923 6.68504 14.7573 6.68504 14.5855C6.68504 14.296 6.52529 14.152 6.20429 14.152H5.90579V15.136Z"
        fill="#D84D48"
      />
      <path
        d="M12.1751 15.2673C12.1751 15.982 11.9786 16.5333 11.5848 16.9195C11.1911 17.3058 10.6383 17.5 9.92506 17.5H8.53906V13.2168H10.0218C10.7096 13.2168 11.2406 13.3923 11.6141 13.744C11.9876 14.0958 12.1751 14.6035 12.1751 15.2673ZM10.9743 15.3085C10.9743 14.9163 10.8963 14.6253 10.7411 14.4355C10.5858 14.2458 10.3503 14.1513 10.0338 14.1513H9.69706V16.5505H9.95506C10.3068 16.5505 10.5641 16.4485 10.7283 16.2445C10.8926 16.0405 10.9743 15.7285 10.9743 15.3085Z"
        fill="#D84D48"
      />
      <path
        d="M14.0824 17.5H12.9424V13.2168H15.4736V14.1453H14.0824V14.9628H15.3656V15.8913H14.0824V17.5Z"
        fill="#D84D48"
      />
    </svg>
  );
};

export default PDF;
