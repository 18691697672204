import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { CustomText } from "../../../../../../Submissions/Widgets";
import BaseWidgetPreview from "./BaseWidgetPreview";

const TextWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <CustomText {...props} />;
  return <BaseWidgetPreview widgetPreview={widgetPreview} {...props} />;
};

export default TextWidgetPreview;
