import React from "react";
import { FLOOD_ZONE_AGGREGATORS, FLOOD_ZONES } from "common/constants";
import { MapFilter as GQLMapFilterType } from "../../../../../../generated/graphql";
import Select from "../../../../../Inputs/Select";
import { MapFilterWrapper } from "../__styles__/FilterInputs";
import useFilterBuilderState from "../useFilterBuilderState";

type MapFilterProps = {
  onFilterInputValuesChange: ReturnType<
    typeof useFilterBuilderState
  >["changeFilterInputsValues"];
  values: Partial<Omit<GQLMapFilterType, "__typename">>;
};

const operatorOptions = [
  { value: true, label: "is" },
  { value: false, label: "is not" },
];

const mapFilterZoneOptions = [
  ...Object.values(FLOOD_ZONE_AGGREGATORS).map(value => ({
    value,
    label: value,
  })),
  ...Object.values(FLOOD_ZONES).map(value => ({
    value,
    label: value,
  })),
];

const MapFilterInputs = ({
  onFilterInputValuesChange,
  values,
}: MapFilterProps) => {
  return (
    <MapFilterWrapper>
      <Select
        name="filterConditional"
        onChange={value => {
          onFilterInputValuesChange({
            inclusion: value,
          });
        }}
        options={operatorOptions}
        label="Select conditional"
        placeholder="Select conditional"
        size="medium"
        value={values.inclusion}
      />

      <Select<
        FLOOD_ZONES | FLOOD_ZONE_AGGREGATORS,
        { value: FLOOD_ZONES | FLOOD_ZONE_AGGREGATORS; label: string },
        true
      >
        name="filterValues"
        onChange={value => {
          onFilterInputValuesChange({
            zones: value,
          });
        }}
        options={mapFilterZoneOptions}
        label="Select value"
        placeholder="Select value"
        size="medium"
        value={values.zones}
        isMulti
      />
    </MapFilterWrapper>
  );
};

export default MapFilterInputs;
