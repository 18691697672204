import React from "react";
import { startCase } from "lodash";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { UpdateAccountMultifactorAuthMutationFn } from "../../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { SettingsForm, SettingsGrid } from "../../Common/__styles__/Settings";
import { Button } from "../../Common/Button";
import Modal from "../../Common/Modal";
import { Label } from "../../Inputs";
import { Select } from "../../Inputs/react-hook-form";

const useUpdateMultifactorAuthModalConfirmationModal = ({
  onCancel,
  onSubmit,
  multifactorAuthEnabled,
}: UpdateMultifactorAuthModalConfirmationModalHookArgs) => {
  const [
    showUpdateMultifactorAuthModalConfirmationModal,
    hideUpdateMultifactorAuthModalConfirmationModal,
  ] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          onCancel();
        }}
      >
        <UpdateMultifactorAuthModalConfirmationModal
          onCancel={onCancel}
          onSubmit={onSubmit}
          multifactorAuthEnabled={multifactorAuthEnabled}
        />
      </Modal>
    ),
    [multifactorAuthEnabled]
  );

  return [
    showUpdateMultifactorAuthModalConfirmationModal,
    hideUpdateMultifactorAuthModalConfirmationModal,
  ] as const;
};

interface UpdateMultifactorAuthModalConfirmationModalHookArgs {
  onCancel: () => void;
  onSubmit: () => void;
  multifactorAuthEnabled: boolean;
}

const UpdateMultifactorAuthModalConfirmationModal = ({
  onCancel,
  onSubmit,
  multifactorAuthEnabled,
}: UpdateMultifactorAuthModalConfirmationModalHookArgs) => {
  const action = multifactorAuthEnabled ? "enable" : "disable";

  return (
    <Container width={"narrow"}>
      <HeaderSection>
        <h1>{startCase(action)} Multi-Factor Authentication</h1>
      </HeaderSection>
      <ContentSection>
        Are you sure you would like to{" "}
        <strong>{action} Multi-Factor Authentication</strong> for all users in
        your account? All users will be required to verify their phone numbers{" "}
        {multifactorAuthEnabled
          ? "upon next log in."
          : "the next time this feature is enabled."}
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button onClick={onCancel} size="medium" styleVariant="secondary">
            Cancel
          </Button>
          <Button onClick={onSubmit} size="medium" styleVariant="primary">
            Yes, {action}
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

interface FormStructure {
  multifactorAuthEnabled: boolean;
}

export const MultifactorAuthSettingsForm = ({
  securityConfiguration,
  canUpdateAccountInformation,
  onUpdate,
}: {
  securityConfiguration: FormStructure;
  canUpdateAccountInformation: boolean;
  onUpdate: UpdateAccountMultifactorAuthMutationFn;
}) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormStructure>({
    defaultValues: securityConfiguration,
  });

  const [
    showUpdateMultifactorAuthModalConfirmationModal,
    hideUpdateMultifactorAuthModalConfirmationModal,
  ] = useUpdateMultifactorAuthModalConfirmationModal({
    onCancel: () => {
      hideUpdateMultifactorAuthModalConfirmationModal();
    },
    onSubmit: async () => {
      await onUpdate({
        variables: {
          data: { multifactorAuthEnabled: getValues("multifactorAuthEnabled") },
        },
      });
      hideUpdateMultifactorAuthModalConfirmationModal();
    },
    multifactorAuthEnabled: getValues("multifactorAuthEnabled"),
  });

  return (
    <SettingsForm
      onSubmit={handleSubmit(showUpdateMultifactorAuthModalConfirmationModal)}
    >
      <SettingsGrid>
        <div>
          <Label
            text="Require Multi-Factor Authentication?"
            tooltip="Enable this feature to require every user in your account to verify their phone number and use an authentication code at each sign in"
          />
          <Select
            control={control}
            name="multifactorAuthEnabled"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            disabled={!canUpdateAccountInformation}
            required={true}
            error={errors.multifactorAuthEnabled?.message}
            size="medium"
          />
        </div>
      </SettingsGrid>
      <Button
        styleVariant="primary"
        size="medium"
        type="submit"
        disabled={!canUpdateAccountInformation}
      >
        Update Multi-Factor Authentication settings
      </Button>
    </SettingsForm>
  );
};
