import React, { useMemo } from "react";
import CBRSLayer, { id as layerId } from "../layers/cbrs";
import { LayerConfig, LayerHook, LayerHookBuilder } from "./types";

export type CBRSLayerConfig = LayerConfig;

const cbrsHook: LayerHookBuilder<CBRSLayerConfig> = ({ config }) => {
  return useMemo<LayerHook>(() => {
    return {
      interactiveLayerIds: [layerId],
      render: () => <CBRSLayer key={"cbrs"} />,
    };
  }, [config]);
};

export default cbrsHook;
