import React, { useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { buildLink, getPath } from "common/routing";
import { AuthContext } from "../Authorization/AuthContext";
import { Button } from "../Common/Button";
import { DropdownMenu } from "../Inputs";
import { AdminNav, Page } from "./__styles__/AdminLandingPage";
import AccountSelector from "./AccountsApp/AccountSelector";

export default function AdminLandingPage() {
  const { admin } = useContext(AuthContext);
  const history = useHistory();

  const allowedToViewDashboard = admin?.allowedToViewDashboard;
  const allowedToTranscribe = !!admin?.allowedTranscriptionTasks.length;

  if (!allowedToViewDashboard && !allowedToTranscribe) {
    return (
      <Redirect
        to={{
          pathname: getPath("logout"),
        }}
      />
    );
  }

  if (!allowedToViewDashboard && allowedToTranscribe) {
    return (
      <Redirect
        to={{
          pathname: getPath("tasks"),
        }}
      />
    );
  }

  const adminActions = [
    {
      label: "Add account",
      onClick: () => history.push(getPath("adminAccountCreate")),
    },
    { label: "View tasks", onClick: () => history.push(getPath("tasks")) },
    {
      label: "Import Attio data",
      onClick: () =>
        history.push(buildLink("csvImports", { importType: "attio" })),
    },
    {
      label: "Import translation data",
      onClick: () =>
        history.push(buildLink("csvImports", { importType: "translation" })),
    },
  ];

  const actionButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <Button
      onClick={onClick}
      styleVariant="outlineLight"
      rightIconName="chevron-down"
      size="small"
      data-testid="actions"
    >
      Actions
    </Button>
  );

  return (
    <Page>
      <AdminNav>
        <Button
          styleVariant="hoverOutline"
          size="small"
          onClick={() => {
            window.location.href = getPath("logout");
          }}
          leftIconName="logout"
        >
          Log out
        </Button>
        <DropdownMenu actions={adminActions} customButton={actionButton} />
      </AdminNav>
      <AccountSelector />
    </Page>
  );
}
