import React from "react";
import { toLower } from "lodash";
import {
  DateInput,
  Fieldset,
  InputRow,
  Note,
  RadioInput,
  SectionTitle,
  TextInput,
} from "./Common";

export default function FullDataExtractionGroup5({ get, set, errors }) {
  return (
    <>
      <SectionTitle>PROPERTY INFORMATION</SectionTitle>

      <TextInput
        name="buildingOwnerName"
        value={get("buildingOwnerName")}
        onChange={set("buildingOwnerName")}
        error={errors.buildingOwnerName}
        label="Building Owner's Name"
      />

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        error={errors.address}
        label="Street Address"
      />

      <TextInput
        name="propertyDescription"
        value={get("propertyDescription")}
        onChange={set("propertyDescription")}
        error={errors.propertyDescription}
        label="Other Description"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          error={errors.address}
          label="City"
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          error={errors.address}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          error={errors.address}
          label="Zipcode"
        />
      </InputRow>

      <Note>If Zipcode is not present, please leave blank.</Note>

      <SectionTitle>SECTION I - BUILDING ELEVATION INFORMATION</SectionTitle>

      <TextInput
        name="buildingDiagramNumber"
        value={get("buildingDiagramNumber")}
        onChange={set("buildingDiagramNumber")}
        error={errors.buildingDiagramNumber}
        label="1. Using the flood insurance manual... indicate the proper diagram number"
      />

      <TextInput
        name="topOfBottomFloor"
        value={get("topOfBottomFloor")}
        onChange={set("topOfBottomFloor")}
        error={errors.topOfBottomFloor}
        label="2. FIRM Zones A1-A30... is at an elevation of"
      />
      <Note>
        If there is a unit of measurement (example: feet) please do not include
        it. However, do include any N/A values.
      </Note>

      <TextInput
        name="bottomOfLowestHorizontalStructuralMember"
        value={get("bottomOfLowestHorizontalStructuralMember")}
        onChange={set("bottomOfLowestHorizontalStructuralMember")}
        error={errors.bottomOfLowestHorizontalStructuralMember}
        label="3. FIRM Zones V1-V30... is at an elevation of"
      />
      <Note>
        If there is a unit of measurement (example: feet) please do not include
        it. However, do include any N/A values.
      </Note>

      <TextInput
        name="benchmarkUtilized"
        value={get("benchmarkUtilized")}
        onChange={set("benchmarkUtilized")}
        error={errors.benchmarkUtilized}
        label="C2. Benchmark utilized"
      />

      <Fieldset legend="5. Indicate the elevation datum system used in determining the above reference level elevations">
        <RadioInput
          name="verticalDatum"
          value={get("verticalDatum")}
          valueFormatter={toLower}
          onChange={set("verticalDatum")}
          error={errors.verticalDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD" },
            { value: "other", text: "Other" },
          ]}
        />
        <Note>If blank, please skip.</Note>
      </Fieldset>

      <Fieldset legend="6. Indicate the elevation datum system used on the FIRM for base flood elevations">
        <RadioInput
          name="baseFloodElevationDatum"
          value={get("baseFloodElevationDatum")}
          valueFormatter={toLower}
          onChange={set("baseFloodElevationDatum")}
          error={errors.baseFloodlevationDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD" },
            { value: "other", text: "Other" },
          ]}
        />
        <Note>If blank, please skip.</Note>
      </Fieldset>

      <Fieldset legend="7. Is the reference level based on actual construction?">
        <RadioInput
          name="buildingElevationSource"
          value={
            get("buildingElevationSource") === null
              ? "null"
              : get("buildingElevationSource")
          }
          valueFormatter={toLower}
          onChange={value => {
            set("buildingElevationSource")(value === "null" ? null : value);
          }}
          error={errors.buildingElevationSource}
          options={[
            { value: "finished_construction", text: "Yes" },
            { value: "null", text: "No" },
          ]}
        />
      </Fieldset>

      <SectionTitle>
        SECTION II - FLOOD INSURANCE RATE MAP INFORMATION
      </SectionTitle>
      <TextInput
        name="nfipCommunityNumber"
        value={get("nfipCommunityNumber")}
        onChange={set("nfipCommunityNumber")}
        error={errors.nfipCommunityNumber}
        label="Community No."
      />

      <TextInput
        name="firmMapNumber"
        value={get("firmMapNumber")}
        onChange={set("firmMapNumber")}
        error={errors.firmMapNumber}
        label="Panel No."
      />
      <Note>
        Note: If there are two separate numbers, please transcribe the entire
        field as-is.
      </Note>

      <TextInput
        name="firmSuffix"
        value={get("firmSuffix")}
        onChange={set("firmSuffix")}
        error={errors.firmSuffix}
        label="Suffix"
      />

      <DateInput
        name="firmIndexDate"
        value={get("firmIndexDate")}
        onChange={set("firmIndexDate")}
        error={errors.firmIndexDate}
        label="Date of FIRM"
      />

      <TextInput
        name="floodZone"
        value={get("floodZone")}
        onChange={set("floodZone")}
        error={errors.floodZone}
        label="FIRM Zone"
      />
      <Note>
        Note: If multiple Flood Zones are present, please enter them separated
        by commas.
      </Note>

      <TextInput
        name="baseFloodElevation"
        value={get("baseFloodElevation")}
        onChange={set("baseFloodElevation")}
        error={errors.baseFloodElevation}
        label="Base Flood Elev."
      />
      <Note>
        Note: if there is a unit of measurement (example: feet) please do not
        include it. However, do include any N/A values. If multiple elevations
        are present, please enter them separated by commas.
      </Note>

      <SectionTitle>SECTION III - CERTIFICATION</SectionTitle>

      <TextInput
        name="certifierName"
        value={get("certifierName")}
        onChange={set("certifierName")}
        error={errors.certifierName}
        label="Certifier's Name"
      />

      <TextInput
        name="certifierLicenseNumber"
        value={get("certifierLicenseNumber")}
        onChange={set("certifierLicenseNumber")}
        error={errors.certifierLicenseNumber}
        label="License Number"
      />

      <DateInput
        label="Date"
        name="issuedAt"
        value={get("issuedAt")}
        onChange={set("issuedAt")}
        error={errors.issuedAt}
      />
    </>
  );
}
