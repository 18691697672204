import React, { useReducer } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getPath } from "common/routing";
import { validatePassword } from "common/utils/validate-password";
import {
  MFAReducer,
  SCREEN_DISPLAY,
  useMFAFlow,
} from "common-client/utils/useMFAFlow";
import {
  SecurityConfiguration,
  useAcceptInviteMutation,
  UserInvite as UserInviteType,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { colors, spacing } from "../../stitches.config";
import { ProvideCode } from "../Authentication/ProvideCode";
import { ProvidePhoneNumber } from "../Authentication/ProvidePhoneNumber";
import { Button } from "../Common/Button";
import { Section, Sections } from "../Common/Layout";
import { Body, Title } from "../Common/Typography";
import { Checkbox, Text } from "../Inputs/react-hook-form";
import { Container, Wrapper } from "./__styles__/UserInvite";

type UserInviteProps = {
  userInvite: Omit<
    UserInviteType,
    "role" | "canViewPersonalIdentifiableInformation" | "account"
  > & {
    account: {
      securityConfiguration: Pick<
        SecurityConfiguration,
        "minimumPasswordLength" | "multifactorAuthEnabled"
      >;
    };
  };
};

interface FormStructure {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: Maybe<string>;
  password: string;
  terms: boolean;
  code?: Maybe<string>;
}

export const UserInvite = ({ userInvite }: UserInviteProps) => {
  const { addErrorToast } = useStatusToasts();
  const [state, dispatch] = useMFAFlow({
    defaultValue: { currentScreen: SCREEN_DISPLAY.PROVIDE_CREDENTIALS },
    useReducer: useReducer as MFAReducer,
  });

  const onLoginSuccess = () => (window.location.href = getPath("map"));

  const [acceptInvite, { loading: mutationLoading }] = useAcceptInviteMutation({
    onCompleted: data => {
      if (data.acceptInvite.ok) {
        onLoginSuccess();
      } else {
        dispatch({ type: "login", data: data.acceptInvite });
      }
    },
    onError: () => {
      addErrorToast(
        "There was an error accepting this invite. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  });

  const useFormProps = useForm<FormStructure>({
    defaultValues: {
      firstName: userInvite.firstName,
      lastName: userInvite.lastName,
      email: userInvite.email,
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useFormProps;

  const hasMultifactorAuthEnabled =
    userInvite.account.securityConfiguration.multifactorAuthEnabled;

  return (
    <Wrapper>
      <Container>
        <FormProvider {...useFormProps}>
          <Sections as="form" gap={spacing.m}>
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CREDENTIALS && (
              <>
                <Title size="large" type="semiBold">
                  Create your account
                </Title>
                <Section>
                  <Body
                    size="large"
                    type="emphasis"
                    color="contentPrimary"
                    style={{
                      backgroundColor: colors.bgUiContainer.value,
                      padding: spacing.m.value,
                    }}
                  >
                    {userInvite.email}
                  </Body>
                </Section>
                <Section flexDirection="row">
                  <Text
                    label="First Name"
                    error={errors.firstName?.message}
                    required
                    {...register("firstName", {
                      required: "Required field",
                    })}
                  />
                  <Text
                    label="Last Name"
                    error={errors.lastName?.message}
                    required
                    {...register("lastName", {
                      required: "Required field",
                    })}
                  />
                </Section>
                <Section>
                  <Text
                    type="tel"
                    label="Mobile phone number"
                    placeholder="(000) 000-0000"
                    error={errors.phoneNumber?.message}
                    {...register("phoneNumber", {
                      validate: {
                        required: value => {
                          if (!value && hasMultifactorAuthEnabled) {
                            return "Phone number is required";
                          }
                          return true;
                        },
                      },
                    })}
                    required={hasMultifactorAuthEnabled}
                  />
                </Section>
                {hasMultifactorAuthEnabled && (
                  <Body size="small" type="regular" color="contentSecondary">
                    Note: Enter a phone number where we can send an
                    authenticationcode via SMS.
                  </Body>
                )}
                <Section gap={spacing.s}>
                  <Text
                    label="Password"
                    type="password"
                    error={errors.password?.message}
                    placeholder="********"
                    required
                    {...register("password", {
                      validate: (val: Maybe<string>) => {
                        return validatePassword({
                          password: val ?? "",
                          minimumPasswordLength:
                            userInvite.account.securityConfiguration
                              .minimumPasswordLength,
                        });
                      },
                    })}
                  />
                  <Body size="small" type="regular" color="contentSecondary">
                    Create a password with at least{" "}
                    {
                      userInvite.account.securityConfiguration
                        .minimumPasswordLength
                    }{" "}
                    characters. Include at least one number, one upper case
                    letter, and one special character.
                  </Body>
                </Section>
                <Section>
                  <Section flexDirection="row" gap={spacing.s}>
                    <Checkbox
                      name="terms"
                      control={control}
                      rules={{
                        required: "You must accept the Terms of Use",
                      }}
                    />
                    <Body size="small" type="regular" color="contentPrimary">
                      By checking the box, you are confirming that you have read
                      and agree to our{" "}
                      <a
                        href="https://www.withforerunner.com/legal/terms-of-use"
                        target="_blank"
                      >
                        TERMS OF USE
                      </a>
                      . View{" "}
                      <a
                        href="https://www.withforerunner.com/legal/privacy"
                        target="_blank"
                      >
                        PRIVACY POLICY
                      </a>
                    </Body>
                  </Section>
                  {errors.terms?.message && (
                    <Body size="small" type="regular" color="contentCritical">
                      {errors.terms.message}
                    </Body>
                  )}
                  <Button
                    style={{
                      justifyContent: "center",
                      marginTop: spacing.m.value,
                    }}
                    type="submit"
                    styleVariant="primary"
                    size="medium"
                    onClick={handleSubmit(async data => {
                      await acceptInvite({
                        variables: {
                          ...data,
                          phoneNumber: data.phoneNumber,
                          inviteId: userInvite!.id,
                        },
                      });
                    })}
                    disabled={mutationLoading}
                  >
                    Create account
                  </Button>
                </Section>
              </>
            )}

            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_PHONE && (
              <ProvidePhoneNumber dispatch={dispatch} error={state.error} />
            )}
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CODE && (
              <ProvideCode
                allowAnotherNumber={!state.phoneNumberVerified!}
                dispatch={dispatch}
                error={state.error}
                maskedPhoneNumber={state.phoneNumber!}
                onSuccess={onLoginSuccess}
              />
            )}
          </Sections>
        </FormProvider>
      </Container>
    </Wrapper>
  );
};
