import React from "react";
import { useFormContext } from "react-hook-form";
import { Tag } from "../../../../Common/Tag";
import { creatorTypeLabel, viewerTypeLabel } from "./constants";
import { FlexRowStyled } from "./SidebarColumn/__styles__/HeaderMiddleContainer";
import { SubmissionsBuilderFormDataStructure } from "./types";
import {
  submissionHiddenFromPublicByDefaultToViewerType,
  submissionIntakeSourceToCreatorType,
} from "./utils";

export const HeaderMiddleContainer = () => {
  const { watch } = useFormContext<SubmissionsBuilderFormDataStructure>();
  const intakeSource = watch("intakeSource");
  const hiddenFromPublicByDefault = watch("hiddenFromPublicByDefault");

  const creatorType = submissionIntakeSourceToCreatorType(intakeSource);
  const viewerType = submissionHiddenFromPublicByDefaultToViewerType(
    hiddenFromPublicByDefault
  );

  return (
    <FlexRowStyled>
      {creatorType && (
        <Tag
          styleVariant="neutral"
          leftIconName="clipboard-pen"
          data-testid="creator-type-tag"
        >
          {creatorTypeLabel[creatorType]}
        </Tag>
      )}
      {viewerType && (
        <Tag
          styleVariant="neutral"
          leftIconName="eye"
          data-testid="viewer-type-tag"
        >
          {viewerTypeLabel[viewerType]}
        </Tag>
      )}
    </FlexRowStyled>
  );
};
