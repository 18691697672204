import "./__styles__/Icon.scss";
import React from "react";

const DisabledAlarm = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.5C10 14.0304 9.78929 14.5391 9.41421 14.9142C9.03914 15.2893 8.53043 15.5 8 15.5C7.46957 15.5 6.96086 15.2893 6.58579 14.9142C6.21071 14.5391 6 14.0304 6 13.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 11.5H11.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.442 3.44189C3.14974 4.08858 2.99905 4.79024 3 5.49989V9.49989C3 10.0303 2.78929 10.539 2.41421 10.9141C2.03914 11.2892 1.53043 11.4999 1 11.4999"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 11.5C14.4696 11.5 13.9609 11.2893 13.5858 10.9142C13.2107 10.5391 13 10.0304 13 9.50001V5.50001C13 4.17392 12.4732 2.90215 11.5355 1.96447C10.5979 1.02679 9.32608 0.500005 8 0.500005V0.500005C6.91789 0.498411 5.86473 0.849464 5 1.50001"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L15 15"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DisabledAlarm;
