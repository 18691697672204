import React, { useContext } from "react";
import { NetworkStatus } from "@apollo/client";
import { ColumnDef } from "@tanstack/table-core/build/lib/types";
import { useLocation } from "react-router";
import { UserFriendlyCertificateStatus } from "common/constants";
import { buildLink } from "common/routing";
import { TABLE_NAMES } from "common/utils/queryBuilder";
import {
  formatBuildingElevationSource,
  formatECReviewStatus,
} from "common/utils/strings";
import { CertificateUploadStatus } from "common-client/generated/graphql";
import {
  GetQueryFilesQueryVariables,
  useGetFileTableConfigQuery,
  useGetQueryFilesLazyQuery,
  useGetSavedViewsQuery,
} from "../../generated/graphql";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../hooks/useTableDisplayConfig";
import { AuthContext } from "../Authorization/AuthContext";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../Common/ActionCell";
import { pillsCell } from "../Common/FullWidthTable/customCells";
import { FullWidthTable } from "../Common/FullWidthTable/FullWidthTable";
import { Attribute } from "../Common/FullWidthTable/types";
import {
  fieldId,
  initializeColumns,
  mergeFieldGroups,
  MinimalColumnDef,
  sanityCheckLocalColumnOrder,
} from "../Common/FullWidthTable/utils";
import LinkCell from "../Common/LinkCell";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../Common/Tables/hooks";
import {
  buildColumnDefinitions,
  CellRenderConfig,
} from "../Common/Tables/utils";
import { CUSTOM_PROPERTY_LINK_CELLS } from "../Property/PropertiesTable";
import { QueryFileTableResult } from "./__queries__/table";

export const FILES_TABLE_REQUIRED_FIELDS = [
  { table: TABLE_NAMES.FILES, name: "id" },
  { table: TABLE_NAMES.PROPERTIES, name: "id" },
  { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
  { table: TABLE_NAMES.PROPERTIES, name: "latitude" },
  { table: TABLE_NAMES.PROPERTIES, name: "longitude" },
  { table: TABLE_NAMES.FILES, name: "originalFilename" },
  { table: TABLE_NAMES.FILES, name: "hiddenFromPublic" },
  { table: TABLE_NAMES.FILES, name: "issuedAt" },
  { table: TABLE_NAMES.FILES, name: "documentType" },
  { table: TABLE_NAMES.FILES, name: "isDeletable" },
  { table: TABLE_NAMES.CERTIFICATE_UPLOADS, name: "status" },
  { table: TABLE_NAMES.CERTIFICATES, name: "id" },
  { table: TABLE_NAMES.SUBMISSIONS, name: "id" },
  { table: TABLE_NAMES.SUBMISSIONS, name: "formData" },
] as const;

export const FILES_TABLE_CUSTOM_CELLS: Record<string, CellRenderConfig> = {
  "Files.issues": {
    cell: ({ row }) => {
      const issues = row.original["Files.issues"];

      return pillsCell({
        row: issues,
        iconName: "alert-circle",
      });
    },
  },
  "Certificates.buildingElevationSource": {
    accessorFn: (row: any) => {
      return formatBuildingElevationSource(
        row["Certificates.buildingElevationSource"]
      );
    },
  },
  "CertificateUploads.status": {
    accessorFn: (row: any) => {
      return UserFriendlyCertificateStatus[
        row["CertificateUploads.status"] as CertificateUploadStatus
      ];
    },
  },
  "Permits.ecReviewStatus": {
    accessorFn: (row: any) => {
      return formatECReviewStatus(row["Permits.ecReviewStatus"]);
    },
  },
  "Files.adminLink": {
    cell: ({ row }) => {
      const linkProps = row.original["Files.adminLink"];
      const streetAddress = row.original["Properties.streetAddress"];

      if (!linkProps) {
        return null;
      }

      const url = buildLink(
        "documentUploadDetail",
        { id: linkProps.fileId },
        {}
      );

      return <LinkCell href={url} target={"_blank"} label={streetAddress} />;
    },
  },
  "Files.relatedRecord": {
    cell: ({ row }) => {
      const linkProps = row.original["Files.relatedRecord"];

      if (!linkProps || !linkProps.submissionId) {
        return null;
      }

      const url = buildLink("viewSubmission", {
        submissionId: linkProps.submissionId,
      });

      return (
        <LinkCell
          href={url}
          target={"_blank"}
          label={linkProps.submissionTypeName}
        />
      );
    },
  },
  ...CUSTOM_PROPERTY_LINK_CELLS,
};

type DocumentUploadsTableProps = {
  actionCell: (data: {
    original: QueryFileTableResult;
    onUpdate: () => {};
    prevLocation?: string;
  }) => JSX.Element;
  tableActions?: React.ReactNode;
};

export const DocumentUploadsTable = ({
  actionCell,
  tableActions,
}: DocumentUploadsTableProps) => {
  const location = useLocation();

  const { user, admin } = useContext(AuthContext);

  const {
    data: savedViewsResponse,
    loading: loadingSavedViews,
    refetch: refetchSavedViews,
  } = useGetSavedViewsQuery({
    variables: { table: TABLE_NAMES.FILES },
    fetchPolicy: "cache-and-network",
  });

  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetFileTableConfigQuery({
      fetchPolicy: "network-only",
    });

  const [
    queryFiles,
    { previousData, data: currentData, networkStatus, loading, error, refetch },
  ] = useGetQueryFilesLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const actionsColumn: MinimalColumnDef<typeof FILES_TABLE_REQUIRED_FIELDS> = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      const original = {
        property: {
          id: row.original["Properties.id"],
          streetAddress: row.original["Properties.streetAddress"],
          longitude: row.original["Properties.longitude"],
          latitude: row.original["Properties.latitude"],
        },
        elevationCertificate: { id: row.original["Certificates.id"] },
        issuedAt: row.original["Files.issuedAt"],
        accountDocumentType: { name: row.original["Files.documentType"] },
        id: row.original["Files.id"],
        originalFilename: row.original["Files.originalFilename"],
        formData: row.original["Submissions.formData"],
        submission: { id: row.original["Submissions.id"] },
        isDeletable: row.original["Files.isDeletable"],
      };

      return actionCell({
        original,
        onUpdate: refetch!,
        prevLocation: location.pathname + location.search,
      });
    },
  };

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    currentView,
  } = useQueryDescription({
    defaultSort: [{ id: "Files.documentType", desc: false }],
    savedViews: savedViewsResponse?.account?.savedViews,
  });

  const generatedColumnDefinitions = buildColumnDefinitions({
    columnConfig: columnConfigResponse?.getFileTableConfig.data || [],
    cellRenderers: {
      ...FILES_TABLE_CUSTOM_CELLS,
      ...CUSTOM_PROPERTY_LINK_CELLS,
    },
  });

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname: location.pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: [
          ...queryDescription.fields.map(field => fieldId(field)),
          ACTION_COLUMN_DEF_CONSTANTS.id,
        ],
        columnSizing: {},
        sorting: [],
      },
    });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.queryFiles.pageInfo.totalPages,
    previousTotalPages: previousData?.queryFiles.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<ColumnDef<QueryFileTableResult>> = [
    ...generatedColumnDefinitions,
    actionsColumn,
  ];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (
    loadingColumnConfig ||
    !columnConfigResponse?.getFileTableConfig.data ||
    loadingSavedViews ||
    !currentView
  ) {
    return null;
  }

  const loadingDetails = {
    loading,
    loadingText: "Loading files",
    noDataText: "No files found",
  };

  const search = ({ page }: { page: number }) => {
    const variables: GetQueryFilesQueryVariables = {
      description: {
        table: TABLE_NAMES.FILES,
        fields: mergeFieldGroups(
          queryDescription.fields,
          FILES_TABLE_REQUIRED_FIELDS
        ),

        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void queryFiles({
      variables,
    });
  };

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: queryDescription.fields.map(field => fieldId(field)),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = initializeColumns({
    initialTableState,
    tanstackColumnDefinitions,
    initialQueryDescription: queryDescription,
    actionsColumn,
  });

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  return (
    <FullWidthTable
      actions={tableActions}
      columns={initialColumns}
      previousData={previousData?.queryFiles.data}
      currentData={data?.queryFiles.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.queryFiles.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      filterable={{
        newFilterConfiguration: columnConfigResponse.getFileTableConfig
          .data as Array<Attribute>,
        search,
      }}
      columnSettingProps={{
        columnConfiguration: columnConfigResponse.getFileTableConfig.data,
        columnDefinitions: tanstackColumnDefinitions,
      }}
      timeoutError={timeoutError}
      queryDescription={queryDescription}
      updateQueryDescription={updateQueryDescription}
      refetchSavedViews={refetchSavedViews}
      savedViews={savedViewsResponse?.account?.savedViews}
      currentView={currentView}
      clientSideColumnConfig={columnConfigResponse.getFileTableConfig.data}
      dataSourceComputationsAffectingQuery={
        data?.queryFiles.dataSourceComputationsAffectingQuery
      }
    />
  );
};
