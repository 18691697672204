import React from "react";
import { isUndefined } from "lodash";
import { useFormContext } from "react-hook-form";
import { ParcelImportFrequency } from "../../../../../generated/graphql";
import { Description } from "../../../../Inputs/__styles__/Label";
import { Select, Text } from "../../../../Inputs/react-hook-form";
import {
  ConfigStageFields,
  ConfigStageFieldWrapper,
  ConfigStageWrapper,
  FormReminder,
} from "./__styles__/ParcelImportWizard";
import { ArcGISImportWizardFormData } from "./types";

const ArcGISParcelImportConfigStage = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<ArcGISImportWizardFormData>();

  return (
    <ConfigStageWrapper>
      <FormReminder>All fields are required</FormReminder>
      <ConfigStageFields>
        <ConfigStageFieldWrapper>
          <Text
            label="Connection name"
            error={errors.name?.message}
            required
            {...register("name", {
              required: "Name is required",
            })}
          />
        </ConfigStageFieldWrapper>
        <ConfigStageFieldWrapper>
          <Text
            placeholder="https://"
            label="Connection url"
            error={errors.url?.message}
            required
            {...register("url", {
              required: "Connection url is required",
              validate: async (val: string) => {
                try {
                  new URL(val);
                } catch (_) {
                  return "The url provided is malformed.";
                }
                return;
              },
            })}
          />
          <Description>
            Layer ID required in URL (typically ending in “.../MapServer/#” or
            “...FeatureServer/#”)
          </Description>
        </ConfigStageFieldWrapper>
        <ConfigStageFieldWrapper>
          <Select
            control={control}
            name={"frequency"}
            label="Update frequency"
            required
            rules={{
              validate: val => {
                if (isUndefined(val)) {
                  return "Frequency is required";
                }

                return true;
              },
            }}
            error={errors.frequency?.message}
            options={[
              { value: null, label: "Only once" },
              { value: ParcelImportFrequency.DAILY, label: "Every day" },
              { value: ParcelImportFrequency.WEEKLY, label: "Every week" },
              {
                value: ParcelImportFrequency.BIWEEKLY,
                label: "Every other week",
              },
              { value: ParcelImportFrequency.MONTHLY, label: "Every month" },
            ]}
          />
        </ConfigStageFieldWrapper>
      </ConfigStageFields>
    </ConfigStageWrapper>
  );
};

export default ArcGISParcelImportConfigStage;
