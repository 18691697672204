import React from "react";
import { isString } from "lodash";
import { CERTIFICATE_VERSION, SUPPORTED_FORM_YEARS } from "common/constants";
import { Form, Select } from "./Common";
import FullDataExtractionGroup1 from "./FullDataExtractionGroup1";
import FullDataExtractionGroup2 from "./FullDataExtractionGroup2";
import FullDataExtractionGroup3 from "./FullDataExtractionGroup3";
import FullDataExtractionGroup4 from "./FullDataExtractionGroup4";
import FullDataExtractionGroup5 from "./FullDataExtractionGroup5";
import FullDataExtractionGroup6 from "./FullDataExtractionGroup6";
import FullDataExtractionGroup7 from "./FullDataExtractionGroup7";
import FullDataExtractionGroup8 from "./FullDataExtractionGroup8";

const GROUP_YEARS = {
  [CERTIFICATE_VERSION.GROUP_1]: [1984, 1987],
  [CERTIFICATE_VERSION.GROUP_2]: [1993, 1996, 1999],
  [CERTIFICATE_VERSION.GROUP_3]: [2002, 2005],
  [CERTIFICATE_VERSION.GROUP_4]: [2015, 2018, 2022],
  [CERTIFICATE_VERSION.GROUP_5]: [1990],
  [CERTIFICATE_VERSION.GROUP_6]: [2009, 2012],
  [CERTIFICATE_VERSION.GROUP_7]: [1982],
  [CERTIFICATE_VERSION.GROUP_8]: [2026],
};

const getCertificateVersion = expirationYear => {
  let targetYear;

  if (isString(expirationYear)) {
    targetYear = parseInt(expirationYear);
  } else {
    targetYear = expirationYear;
  }

  const pair = Object.entries(GROUP_YEARS).find(([_group, years]) => {
    return years.includes(targetYear);
  });

  if (!pair) return null;

  return pair[0];
};

// The Task component will look up the CertificateUpload#certificateVersion
// in this Object to find the right form to show. As such, all new full data
// extraction forms must be added here.
const FullDataExtraction = {
  [CERTIFICATE_VERSION.GROUP_1]: FullDataExtractionGroup1,
  [CERTIFICATE_VERSION.GROUP_2]: FullDataExtractionGroup2,
  [CERTIFICATE_VERSION.GROUP_3]: FullDataExtractionGroup3,
  [CERTIFICATE_VERSION.GROUP_4]: FullDataExtractionGroup4,
  [CERTIFICATE_VERSION.GROUP_5]: FullDataExtractionGroup5,
  [CERTIFICATE_VERSION.GROUP_6]: FullDataExtractionGroup6,
  [CERTIFICATE_VERSION.GROUP_7]: FullDataExtractionGroup7,
  [CERTIFICATE_VERSION.GROUP_8]: FullDataExtractionGroup8,
};

const getFormForFormYear = year => {
  const version = getCertificateVersion(year);
  return FullDataExtraction[version];
};

export default function FullDataExtractionForm({
  get,
  set,
  data,
  errors,
  year,
  handleFormYearChange,
}) {
  const onFormYearChange = value => {
    handleFormYearChange(value);
    for (const key of Object.keys(data)) {
      set(key)(null);
    }
  };

  const ExtractionInputs = getFormForFormYear(year);

  const supportedYears = SUPPORTED_FORM_YEARS.map(year => {
    return { value: year, label: year.toString() };
  });

  return (
    <Form get={get} set={set} errors={errors}>
      <Select
        name={"formExpirationYear"}
        value={year}
        onChange={onFormYearChange}
        label="Form Expiration Year"
        options={supportedYears}
      />
      {ExtractionInputs && (
        <ExtractionInputs get={get} set={set} errors={errors} />
      )}
    </Form>
  );
}
