import React, { useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import { captureMessage } from "@sentry/browser";
import { omit } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import {
  PROPERTY_ATTRIBUTE_NAME_ALREADY_EXISTS_ERROR,
  PROPERTY_ATTRIBUTE_NAMES,
  PROPERTY_ATTRIBUTE_SOURCE,
} from "common/constants";
import {
  CreateCustomAccountPropertyAttributeMutationFn,
  CustomMap,
  CustomMapType,
  UpdateOrCreatePropertyAttributeMutationFn,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { setTextValueAs } from "../../../../../utils/react-hook-form";
import { track } from "../../../../../utils/tracking";
import { AuthContext } from "../../../../Authorization/AuthContext";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { Button } from "../../../../Common/Button";
import Modal from "../../../../Common/Modal";
import { Label, Radio } from "../../../../Inputs";
import { Select, Text, Textarea } from "../../../../Inputs/react-hook-form";
import { RasterOptions } from "..";
import { InputGrid } from "../__styles__/floodInfoModals";
import { DisplaySettings } from "./DisplaySettings";
import { BasePropertyAttributeTableProps } from "./SectionAttributesTable";

export type UpdateOrCreatePropertyAttributesFormStructure = {
  id: string;
  label: string;
  name: string;
  source?: Maybe<PROPERTY_ATTRIBUTE_SOURCE>;
  sourceId?: Maybe<string>;
  isPublic: boolean;
  tooltip?: Maybe<string>;
  displayValueAsLink: boolean;
  defaultDisplay?: Maybe<string>;
  displayConfig?: {
    showApproximateBfeTool?: Maybe<boolean>;
  };
};

export interface UpdateOrCreatePropertyAttributeModalProps {
  onCancel: () => void;
  onSubmit:
    | UpdateOrCreatePropertyAttributeMutationFn
    | CreateCustomAccountPropertyAttributeMutationFn;
  onUpdate: () => void;
  existingPropertyAttribute?: BasePropertyAttributeTableProps;
  rasterOptions: RasterOptions;
  customMapOptions: Array<Pick<CustomMap, "id" | "type" | "name">>;
  sectionId: string;
}

export const useUpdateOrCreateAttributeModal = ({
  onSubmit,
  onUpdate,
  customMapOptions,
  sectionId,
}: Omit<
  UpdateOrCreatePropertyAttributeModalProps,
  "existingPropertyAttribute" | "onCancel"
>) => {
  const [props, setProps] = useState<{
    existingPropertyAttribute?: BasePropertyAttributeTableProps;
    rasterOptions: RasterOptions;
  }>({ rasterOptions: [] });

  const [show, hideUpdateOrCreatePropertyAttributeModal] = useModal(
    () => (
      <Modal onRequestClose={hideUpdateOrCreatePropertyAttributeModal}>
        <UpdateOrCreatePropertyAttributeModal
          onSubmit={onSubmit}
          onCancel={hideUpdateOrCreatePropertyAttributeModal}
          onUpdate={() => {
            hideUpdateOrCreatePropertyAttributeModal();
            onUpdate();
          }}
          customMapOptions={customMapOptions}
          sectionId={sectionId}
          {...props}
        />
      </Modal>
    ),
    [props]
  );

  const showUpdateOrCreatePropertyAttributeModal = ({
    existingPropertyAttribute,
    rasterOptions,
  }: {
    existingPropertyAttribute?: BasePropertyAttributeTableProps;
    rasterOptions: RasterOptions;
  }) => {
    setProps({
      existingPropertyAttribute,
      rasterOptions,
    });
    show();
  };

  return [
    showUpdateOrCreatePropertyAttributeModal,
    hideUpdateOrCreatePropertyAttributeModal,
  ] as const;
};

enum NO_DATA_DISPLAY_OPTIONS {
  DASH = "dash",
  CUSTOM = "custom",
  HIDE = "hide",
}

export const UpdateOrCreatePropertyAttributeModal = ({
  onSubmit,
  onCancel,
  onUpdate,
  existingPropertyAttribute,
  rasterOptions,
  customMapOptions,
  sectionId,
}: UpdateOrCreatePropertyAttributeModalProps) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  let startingNoDataDisplayValue: NO_DATA_DISPLAY_OPTIONS;
  if (existingPropertyAttribute) {
    if (existingPropertyAttribute.displayConfig.defaultDisplay === null) {
      startingNoDataDisplayValue = NO_DATA_DISPLAY_OPTIONS.HIDE;
    } else if (existingPropertyAttribute.displayConfig.defaultDisplay === "-") {
      startingNoDataDisplayValue = NO_DATA_DISPLAY_OPTIONS.DASH;
    } else {
      startingNoDataDisplayValue = NO_DATA_DISPLAY_OPTIONS.CUSTOM;
    }
  } else {
    startingNoDataDisplayValue = NO_DATA_DISPLAY_OPTIONS.DASH;
  }
  const [noDataDisplay, setNoDataDisplay] = useState(
    startingNoDataDisplayValue
  );

  let defaultValues;
  if (existingPropertyAttribute) {
    defaultValues = {
      ...existingPropertyAttribute,
      defaultDisplay: existingPropertyAttribute.displayConfig.defaultDisplay,
    };
  } else {
    defaultValues = {
      isPublic: false,
      displayValueAsLink: false,
    };
  }

  const formMethods = useForm<UpdateOrCreatePropertyAttributesFormStructure>({
    defaultValues,
    reValidateMode: "onChange",
  });

  const {
    clearErrors,
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = formMethods;

  const headerText = `${existingPropertyAttribute ? "Edit" : "Add"} attribute`;

  const sourceOptions = [
    {
      label: "Raster layers",
      options: rasterOptions.map(raster => ({
        value: raster.id,
        label: `${raster.layerName} - Band number ${raster.bandMappings.bandNumber}`,
      })),
    },
    {
      label: "Custom maps",
      options: customMapOptions
        .filter(customMap => customMap.type !== CustomMapType.VISUAL)
        .map(customMap => ({
          value: customMap.id,
          label: `${customMap.name} label`,
        })),
    },
  ];

  const sourceId = watch("sourceId");
  const isGreatestInclusionCustomMapAttribute =
    customMapOptions.find(customMap => customMap.id === sourceId)?.type ===
    CustomMapType.GREATEST_INCLUSION;
  useEffect(() => {
    if (!isGreatestInclusionCustomMapAttribute) {
      setValue("displayValueAsLink", false);
    }
  }, [isGreatestInclusionCustomMapAttribute]);

  const isGreatestOrMultiInclusionCustomMapAttribute =
    isGreatestInclusionCustomMapAttribute ||
    customMapOptions.find(customMap => customMap.id === sourceId)?.type ===
      CustomMapType.MULTIPLE_INCLUSIONS;

  const label = watch("label");
  useEffect(() => {
    clearErrors("label");
  }, [label]);

  const isDFEAttribute =
    existingPropertyAttribute?.name === PROPERTY_ATTRIBUTE_NAMES.DFE;

  const isBFEAttribute =
    existingPropertyAttribute?.name === PROPERTY_ATTRIBUTE_NAMES.BFE;

  const nameDisabled =
    existingPropertyAttribute?.source === PROPERTY_ATTRIBUTE_SOURCE.FEMA ||
    isDFEAttribute;

  const submitButtonText = existingPropertyAttribute ? "Update" : "Save";
  const tooltipLabel = isDFEAttribute
    ? "Tooltip verbiage"
    : "Public website tooltip verbiage";

  const handleSubmitCallback = async (
    data: UpdateOrCreatePropertyAttributesFormStructure
  ) => {
    const onError = (error: Error, verb: string) => {
      if (error.message === PROPERTY_ATTRIBUTE_NAME_ALREADY_EXISTS_ERROR) {
        setError("label", {
          message: PROPERTY_ATTRIBUTE_NAME_ALREADY_EXISTS_ERROR,
        });
      } else {
        addErrorToast(`${verb} attribute failed to process.`);
      }
    };

    const successVerb = existingPropertyAttribute ? "updated" : "added";
    const errorVerb = existingPropertyAttribute ? "Update" : "Add new";

    let onSubmitParams = {
      onCompleted: () => {
        addSuccessToast(`Attribute successfully ${successVerb}.`);
        onUpdate();
      },
      onError: (error: ApolloError) => onError(error, errorVerb),
    };

    data.label = data.label.trim();

    if (existingPropertyAttribute) {
      track("Section attribute updated", {
        id: existingPropertyAttribute.id,
        label: data.label,
      });

      //The graphql query will return null for these values, even if they are not applicable to the attribute type
      //So, we have to set them to undefined if they are not applicable to avoid misconfiguring the displayConfig with the wrong fields
      const displayConfig = {
        ...omit(existingPropertyAttribute.displayConfig, "__typename"),
        showApproximateBfeTool: isBFEAttribute
          ? data.displayConfig?.showApproximateBfeTool
          : undefined,
        defaultDisplay: isGreatestOrMultiInclusionCustomMapAttribute
          ? data.defaultDisplay
          : undefined,
      };

      await (onSubmit as UpdateOrCreatePropertyAttributeMutationFn)({
        variables: {
          data: {
            accountId: account!.id,
            propertyAttributeId: data.id,
            label: data.label,
            isPublic: data.isPublic,
            tooltip: data.tooltip,
            displayValueAsLink: isGreatestInclusionCustomMapAttribute
              ? data.displayValueAsLink
              : false,
            displayConfig,
          },
        },
        ...onSubmitParams,
      });
    } else if (data.sourceId) {
      track("Section attribute added", {
        label: data.label,
        sourceId: data.sourceId,
      });
      const source = rasterOptions.some(raster => raster.id === data.sourceId)
        ? PROPERTY_ATTRIBUTE_SOURCE.RASTER
        : PROPERTY_ATTRIBUTE_SOURCE.CUSTOM_MAP;
      await (onSubmit as CreateCustomAccountPropertyAttributeMutationFn)({
        variables: {
          data: {
            accountId: account!.id,
            label: data.label,
            isPublic: data.isPublic,
            tooltip: data.tooltip,
            displayValueAsLink: data.displayValueAsLink,
            source,
            sourceId: data.sourceId,
            sectionId,
            displayConfig: isGreatestOrMultiInclusionCustomMapAttribute
              ? { defaultDisplay: data.defaultDisplay }
              : {},
          },
        },
        ...onSubmitParams,
      });
    } else {
      captureMessage("No sourceId provided for new section attribute");
    }
  };

  return (
    <Container overflows>
      <HeaderSection>
        <h1>{headerText}</h1>
      </HeaderSection>
      <FormSection overflows>
        <FormProvider {...formMethods}>
          <ContentSection overflows>
            <InputGrid style={{ marginTop: "8px" }}>
              <Text
                compactLabel={true}
                required
                error={errors.label?.message}
                {...register("label", {
                  required: "Attribute name is required.",
                })}
                label="Attribute name"
                disabled={nameDisabled}
                data-testid="attributeName"
              />

              {!existingPropertyAttribute && (
                <div>
                  <Label
                    text="Source"
                    htmlFor="sourceId"
                    compact={true}
                    required
                  />
                  <Controller
                    control={control}
                    name="sourceId"
                    rules={{ required: "Attribute source is required" }}
                    render={({ field, fieldState }) => {
                      return (
                        <Select
                          name="sourceId"
                          required={true}
                          control={control}
                          value={field.value}
                          error={fieldState.error?.message}
                          options={sourceOptions}
                          onChange={field.onChange}
                          data-testid="sourceSelect"
                        />
                      );
                    }}
                  />
                </div>
              )}
              <DisplaySettings
                isGreatestInclusionCustomMapAttribute={
                  isGreatestInclusionCustomMapAttribute
                }
                isBFEAttribute={isBFEAttribute}
              />
              {isGreatestOrMultiInclusionCustomMapAttribute && (
                <div>
                  <Label
                    text="No-data display options"
                    required
                    style={{ marginBottom: "4px" }}
                  />
                  <Radio
                    name="noDataDisplayRadio"
                    value={noDataDisplay}
                    required
                    options={[
                      { text: "Dash (-)", value: NO_DATA_DISPLAY_OPTIONS.DASH },
                      {
                        text: "Custom",
                        value: NO_DATA_DISPLAY_OPTIONS.CUSTOM,
                        children: (
                          <>
                            {noDataDisplay ===
                              NO_DATA_DISPLAY_OPTIONS.CUSTOM && (
                              <Text
                                {...register("defaultDisplay", {
                                  required:
                                    // We only consider this field required if custom radio is selected

                                    noDataDisplay ===
                                      NO_DATA_DISPLAY_OPTIONS.CUSTOM &&
                                    "This is a required field",
                                })}
                                error={errors.defaultDisplay?.message}
                                aria-label="Custom default display value"
                              />
                            )}
                          </>
                        ),
                      },
                      { text: "Hide", value: NO_DATA_DISPLAY_OPTIONS.HIDE },
                    ]}
                    onChange={value => {
                      setNoDataDisplay(value as NO_DATA_DISPLAY_OPTIONS);
                      if (value === NO_DATA_DISPLAY_OPTIONS.DASH) {
                        setValue("defaultDisplay", "-", { shouldDirty: true });
                      } else {
                        // We need to reset it if custom, and set to null if hide
                        setValue("defaultDisplay", null, { shouldDirty: true });
                      }
                    }}
                  />
                </div>
              )}

              {account?.publicPortal.enabled && (
                <div>
                  <Textarea
                    id="tooltip"
                    {...register("tooltip", {
                      setValueAs: setTextValueAs,
                      validate: () => true,
                    })}
                    draggable={false}
                    label={tooltipLabel}
                    compactLabel={true}
                    name="tooltip"
                    error={errors.tooltip?.message}
                    styleOverride={{
                      maxHeight: "74px",
                      marginBottom: "15px",
                      resize: "none",
                      borderRadius: "1px",
                    }}
                    disabled={isDFEAttribute}
                    required
                  />
                </div>
              )}
            </InputGrid>
          </ContentSection>
        </FormProvider>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            onClick={handleSubmit(handleSubmitCallback)}
            disabled={!isDirty}
            data-testid="attribute-submit-button"
          >
            {submitButtonText}
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
