import React, { Dispatch, useContext } from "react";
import { flatten, isEqual } from "lodash";
import { QueryDescription } from "common/utils/queryBuilder";
import { AuthContext } from "../../../../Authorization/AuthContext";
import MultiLogicFilter, {
  FilterSegmentProps,
} from "../../../MultiLogicFilter";
import { TableContext } from "../../TableContext";
import {
  FilterAction,
  FilterReducerState,
  FilterSegmentContext,
} from "./useFilterReducer";
import { buildFilterDescriptionFromFilterStateObject } from "./utils";

const FilterRows = ({
  filterState,
  dispatch,
}: {
  filterState: FilterReducerState;
  dispatch: Dispatch<FilterAction>;
}) => {
  const { isGuest } = useContext(AuthContext);
  const { currentView } = useContext(TableContext);
  const { filterValues } = filterState;

  const filterSegments = flatten(Object.values(filterValues)).map(
    filterValue => {
      if (!isGuest) {
        return {
          filters: filterValue.filterSegment,
          isRemovable: true,
        };
      }

      const localDescription = buildFilterDescriptionFromFilterStateObject({
        filter: filterValue,
        field: filterValue.filterSegment[0]?.context?.key ?? "",
      });

      // If the user is a guest, we do not allow them to remove filters
      // that come from the initial saved view, but we do allow them to
      // remove filters that they add themselves.
      const isOriginal =
        (currentView?.query as QueryDescription | undefined)?.filters?.some(
          originalDescription => isEqual(originalDescription, localDescription)
        ) ?? false;

      return {
        filters: filterValue.filterSegment,
        isRemovable: !isOriginal,
      };
    }
  );

  const onFilterRemove = (
    _index: number,
    filterData: Array<FilterSegmentProps<FilterSegmentContext>>
  ) => {
    dispatch({ type: "removeFilter", data: { row: filterData } });
  };

  return (
    <div style={{ padding: "8px 0px" }}>
      <MultiLogicFilter
        filters={filterSegments}
        onFilterRemove={onFilterRemove}
        includeAndPrefix
      />
    </div>
  );
};

export default FilterRows;
