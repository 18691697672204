import React, { useEffect, useState } from "react";
import { capitalize, isArray, isNil, omit } from "lodash";
import { Control, FieldValues, FormProvider, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { BOOLEAN_OPTIONS, SAME_RULE_NAME_ERROR } from "common/constants";
import {
  CreateDfeRuleMutationFn,
  DfeRuleHandleOverride,
  DfeRuleType,
  DfeUnitOfMeasurement,
  Filter,
  RuleMapFilterInput,
  UpdateDfeRuleMutationFn,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Divider from "../../../Common/Divider";
import Modal from "../../../Common/Modal";
import { Label } from "../../../Inputs";
import { Required } from "../../../Inputs/__styles__/Label";
import {
  Datepicker,
  Radio,
  Select,
  Text,
} from "../../../Inputs/react-hook-form";
import { InputGrid } from "./__styles__/floodInfoModals";
import {
  HandleOverridesText,
  HelpText,
  RequiredMessage,
} from "./__styles__/upsertDfeRuleModal";
import LogicFilters from "./LogicFilters";
import { RevalidateEcsFormSection } from "./RevalidateEcsFormSection";

const REQUIRED_FIELDS_BY_TYPE: Record<
  DfeRuleType,
  Array<keyof UpsertDfeRuleFormStructure>
> = {
  [DfeRuleType.AUTOMATIC]: [
    "filters",
    "name",
    "referenceAttribute",
    "staticValue",
    "type",
    "units",
    "shouldRevalidate",
    "revalidateStartDate",
  ],
  [DfeRuleType.MANUAL]: ["filters", "name", "type"],
  [DfeRuleType.MINIMUM]: [
    "filters",
    "name",
    "staticValue",
    "type",
    "units",
    "shouldRevalidate",
    "revalidateStartDate",
  ],
};

export type UpsertDfeRuleFormStructure = {
  handleOverride?: DfeRuleHandleOverride;
  filters: Array<Filter>;
  name: string;
  originalName?: string;
  referenceAttribute?: Maybe<string>;
  staticValue?: Maybe<string>;
  type: DfeRuleType;
  units?: Maybe<DfeUnitOfMeasurement>;
  shouldRevalidate?: Omit<BOOLEAN_OPTIONS, "UNKNOWN">;
  revalidateStartDate?: Date;
};

export interface UpsertDfeRuleModalProps {
  onCancel: () => void;
  onSubmit: CreateDfeRuleMutationFn | UpdateDfeRuleMutationFn;
  onUpdate: () => void;
  referenceAttributes?: Array<{ id: string; label: string }>;
  existingRule?: Omit<UpsertDfeRuleFormStructure, "handleOverride">;
}

export const useUpsertDfeRuleModal = ({
  onSubmit,
  onUpdate,
}: Omit<
  UpsertDfeRuleModalProps,
  "referenceAttribute" | "existingRule" | "onCancel"
>) => {
  const [props, setProps] =
    useState<
      Pick<UpsertDfeRuleModalProps, "referenceAttributes" | "existingRule">
    >();

  const [show, hideUpsertDfeRuleModal] = useModal(
    () => (
      <Modal onRequestClose={hideUpsertDfeRuleModal}>
        <UpsertDfeRuleModal
          onSubmit={onSubmit}
          onCancel={hideUpsertDfeRuleModal}
          onUpdate={() => {
            hideUpsertDfeRuleModal();
            onUpdate();
          }}
          {...props}
        />
      </Modal>
    ),
    [props]
  );

  const showUpsertDfeRuleModal = ({
    existingRule,
    referenceAttributes,
  }: Pick<UpsertDfeRuleModalProps, "referenceAttributes" | "existingRule">) => {
    setProps({ existingRule, referenceAttributes });
    show();
  };

  return [showUpsertDfeRuleModal, hideUpsertDfeRuleModal] as const;
};

export const UpsertDfeRuleModal = ({
  onSubmit,
  onCancel,
  onUpdate,
  referenceAttributes,
  existingRule,
}: UpsertDfeRuleModalProps) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const formMethods = useForm<UpsertDfeRuleFormStructure>({
    defaultValues: {
      ...omit(existingRule, "__typename"),
      filters:
        existingRule?.filters.map(filter => ({
          type: filter.type,
          filter: omit(filter.filter, "__typename"),
        })) ?? [],
    },
  });
  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = formMethods;

  const ruleType = watch("type");
  useEffect(() => {
    switch (ruleType) {
      case DfeRuleType.AUTOMATIC:
        setValue("handleOverride", undefined);
        break;
      case DfeRuleType.MANUAL:
        setValue("referenceAttribute", null);
        setValue("staticValue", null);
        setValue("units", null);
        break;
      case DfeRuleType.MINIMUM:
        setValue("referenceAttribute", null);
        setValue("handleOverride", undefined);
        break;
    }
  }, [ruleType]);

  const [isValid, setIsValid] = useState(false);
  const [revalidateEcs, setRevalidateEcs] = useState<boolean | undefined>();

  useEffect(() => {
    watch(value => {
      if (value.type) {
        let valid = REQUIRED_FIELDS_BY_TYPE[value.type].every(field => {
          const fieldValue = value[field];

          if (isArray(fieldValue)) {
            return fieldValue.length > 0;
          } else if (field === "revalidateStartDate") {
            return (
              value.shouldRevalidate !== BOOLEAN_OPTIONS.YES ||
              !isNil(value[field])
            );
          } else {
            return !isNil(value[field]) && value[field] !== "";
          }
        });

        if (existingRule && value.type === DfeRuleType.MANUAL) {
          valid = valid && !isNil(value.handleOverride);
        }

        setIsValid(valid);
      } else {
        setIsValid(false);
      }

      if (
        value.revalidateStartDate &&
        (value.type === DfeRuleType.MANUAL ||
          value.shouldRevalidate === BOOLEAN_OPTIONS.NO)
      ) {
        setValue("revalidateStartDate", undefined);
      }

      if (value.type === DfeRuleType.MANUAL && value.shouldRevalidate) {
        setValue("shouldRevalidate", undefined);
      }

      setRevalidateEcs(value.shouldRevalidate === BOOLEAN_OPTIONS.YES);
    });
  }, []);

  // Label for minimum is different than the enum value
  const typeOptions = [
    { value: DfeRuleType.AUTOMATIC, label: "Automatic" },
    { value: DfeRuleType.MANUAL, label: "Manual" },
    { value: DfeRuleType.MINIMUM, label: "Minimum height" },
  ];

  const unitOptions = Object.values(DfeUnitOfMeasurement).map(value => ({
    value,
    label: capitalize(value),
  }));

  const referenceAttributeOptions =
    referenceAttributes?.map(attribute => ({
      value: attribute.id,
      label: attribute.label,
    })) ?? [];

  const headerText = `${
    existingRule ? "Edit" : "Add"
  } Design Flood Elevation logic rule`;

  const handleOverrideOptions = [
    {
      value: DfeRuleHandleOverride.DELETE,
      text: "Delete existing DFE values for properties in zones removed from this rule",
    },
    {
      value: DfeRuleHandleOverride.KEEP,
      text: "Retain existing DFE values for properties in zones removed from this rule",
    },
  ];

  const ecRevalidationOptions = [
    {
      value: BOOLEAN_OPTIONS.YES,
      text: "Yes",
      children: revalidateEcs ? (
        <div style={{ margin: "0 0 8px 48px" }}>
          <Datepicker
            control={control as unknown as Control<FieldValues, any>}
            name={"revalidateStartDate"}
            rules={{
              validate: _ =>
                getValues("shouldRevalidate") === BOOLEAN_OPTIONS.YES &&
                !getValues("revalidateStartDate")
                  ? "Start date is required when revalidating ECs"
                  : undefined,
            }}
            onChange={_ => trigger("revalidateStartDate")}
            label={"Start date"}
            compactLabel={true}
            required={true}
          />
        </div>
      ) : undefined,
    },
    {
      value: BOOLEAN_OPTIONS.NO,
      text: "No",
    },
  ];

  const handleSubmitCallback = async (data: UpsertDfeRuleFormStructure) => {
    const onError = (error: Error, verb: string) => {
      if (error.message === SAME_RULE_NAME_ERROR) {
        setError("name", {
          message: SAME_RULE_NAME_ERROR,
        });
      } else {
        addErrorToast(`Failed to ${verb} DFE rule.`);
      }
    };

    if (existingRule) {
      await onSubmit({
        variables: {
          data: {
            ...data,
            filters: data.filters as RuleMapFilterInput[],
            accountId: account!.id,
            originalName: existingRule.name,
            shouldRevalidate: data.shouldRevalidate === BOOLEAN_OPTIONS.YES,
          },
        },
        onCompleted: () => {
          addSuccessToast("DFE rule successfully updated.");
          onUpdate();
        },
        onError: error => onError(error, "update"),
      });
    } else {
      await (onSubmit as CreateDfeRuleMutationFn)({
        variables: {
          data: {
            ...data,
            filters: data.filters as RuleMapFilterInput[],
            accountId: account!.id,
            shouldRevalidate: data.shouldRevalidate === BOOLEAN_OPTIONS.YES,
          },
        },
        onCompleted: () => {
          addSuccessToast("DFE rule successfully added.");
          onUpdate();
        },
        onError: error => onError(error, "add"),
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Container>
        <HeaderSection>
          <h1>{headerText}</h1>
        </HeaderSection>
        <FormSection>
          <ContentSection>
            <RequiredMessage>
              All fields are required<Required>*</Required>
            </RequiredMessage>
            <h2>
              New or updated DFE rules apply immediately, with possible delays
              in recomputing warnings and EC issues on properties, if
              applicable.
            </h2>

            <InputGrid style={{ marginTop: "24px" }}>
              <Text
                label="Logic rule name"
                error={errors.name?.message}
                {...register("name")}
              />
              <LogicFilters />
              <div>
                <Label text="DFE determination method" htmlFor="type" />
                <Select
                  control={control}
                  disabled={!!existingRule}
                  name="type"
                  options={typeOptions}
                  size="medium"
                />
              </div>
              <InputGrid leftPadded>
                {ruleType === DfeRuleType.AUTOMATIC && (
                  <div>
                    <Label
                      text="Reference attribute"
                      htmlFor="referenceAttribute"
                    />
                    <Select
                      control={control}
                      name="referenceAttribute"
                      options={referenceAttributeOptions}
                      size="medium"
                    />
                    <HelpText style={{ margin: "4px 0 0 0" }}>
                      This attribute value will be added to hardcoded value
                      provided below for calculating final DFE (example: BFE 4'
                      + Value 5'= DFE 9').
                    </HelpText>
                  </div>
                )}

                {(ruleType === DfeRuleType.AUTOMATIC ||
                  ruleType === DfeRuleType.MINIMUM) && (
                  <InputGrid row>
                    <div>
                      <Text label="Value" {...register("staticValue")} />
                    </div>
                    <div>
                      <Label text="Units" htmlFor="units" />
                      <Select
                        control={control}
                        name="units"
                        options={unitOptions}
                        size="medium"
                      />
                    </div>
                  </InputGrid>
                )}
              </InputGrid>
              {existingRule && ruleType === DfeRuleType.MANUAL && (
                <>
                  <Divider margins="8px 0" />
                  <div>
                    <HandleOverridesText>
                      Please select how to proceed with manually-entered DFE
                      values in zones removed from this DFE rule.
                    </HandleOverridesText>
                    <h2>
                      Note: deleting values triggers re-calculation of
                      DFE-related EC errors and property warnings.
                    </h2>
                    <div style={{ marginTop: "12px" }}>
                      <Radio
                        control={control}
                        name="handleOverride"
                        options={handleOverrideOptions}
                        required={false}
                      />
                    </div>
                  </div>
                </>
              )}
              {ruleType && ruleType !== DfeRuleType.MANUAL && (
                <RevalidateEcsFormSection
                  name="shouldRevalidate"
                  control={control}
                  ecRevalidationOptions={ecRevalidationOptions}
                  disabled={!isDirty}
                />
              )}
            </InputGrid>
          </ContentSection>
        </FormSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button size="medium" styleVariant="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              size="medium"
              styleVariant="primary"
              onClick={handleSubmit(handleSubmitCallback)}
              disabled={!isValid}
            >
              {existingRule ? "Update" : "Save"}
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </Container>
    </FormProvider>
  );
};
