import React from "react";
import { ApolloError } from "@apollo/client";
import { parse } from "query-string";
import { useHistory, useLocation, useParams } from "react-router";
import { buildLink } from "common/routing";
import { getUserErrors } from "common-client/utils/apollo";
import {
  GetRepetitiveLossQuery,
  useGetNextRepetitiveLossIssueLazyQuery,
  useGetRepetitiveLossQuery,
  useToggleIssueIgnoredMutation,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { colors } from "../../stitches.config";
import FullPageFormLayout from "../Common/FullPageFormLayout";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { LoadingContainer } from "./__styles__/GeocodeRepetitiveLoss";
import AddRepetitiveLossProperty from "./AddRepetitiveLossProperty";
import EditRepetitiveLossProperty from "./EditRepetitiveLossProperty";

export type RepetitiveLoss = Omit<
  GetRepetitiveLossQuery["repetitiveLoss"],
  "account"
>;
export type RepetitiveLossWithProperty = RepetitiveLoss & {
  property: NonNullable<RepetitiveLoss["property"]>;
};

const GeocodeRepetitiveLoss = () => {
  const { id: repetitiveLossId } = useParams<{ id: string }>();

  const history = useHistory();
  const { addErrorToast } = useStatusToasts();

  const location = useLocation<
    undefined | { prevLocation?: { pathname: string; search?: string } }
  >();

  const { forceRegeocode } = parse(location.search);

  const prevLocation = location?.state?.prevLocation || {
    pathname: "/repetitive-losses",
  };

  const { loading, data, error } = useGetRepetitiveLossQuery({
    variables: { repetitiveLossId },
    fetchPolicy: "no-cache",
  });

  const [toggleIssueIgnored] = useToggleIssueIgnoredMutation({
    onCompleted: data => {
      const nextIssueId =
        data.toggleIssueIgnored.account?.repetitiveLossIssue?.id;
      if (nextIssueId) {
        history.replace({
          pathname: buildLink("geocodeRepetitiveLoss", {
            id: nextIssueId,
          }),
          state: { prevLocation },
        });
      } else {
        history.replace(prevLocation);
      }
    },
  });

  const [getNextIssue, { error: nextIssueError }] =
    useGetNextRepetitiveLossIssueLazyQuery({
      onCompleted: data => {
        if (data.account?.repetitiveLossIssue?.id) {
          history.replace({
            pathname: buildLink("geocodeRepetitiveLoss", {
              id: data.account.repetitiveLossIssue.id,
            }),
            state: { prevLocation },
          });
        }
      },
    });

  if (loading)
    return (
      <FullPageFormLayout
        prevLocation={prevLocation}
        subtitle={""}
        centered={true}
      >
        <LoadingContainer>
          <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
        </LoadingContainer>
      </FullPageFormLayout>
    );

  if (error || nextIssueError || !data?.repetitiveLoss) {
    return (
      <em style={{ color: `${colors.contentPrimaryDark}` }}>
        Problem loading geocode page.
      </em>
    );
  }

  const isRegeocoding = forceRegeocode === "true";

  const redirectAfterMutation = (nextIssueId?: Maybe<string>) => {
    if (nextIssueId && !isRegeocoding) {
      history.replace(buildLink("geocodeRepetitiveLoss", { id: nextIssueId }), {
        prevLocation,
      });
    } else {
      history.replace(prevLocation);
    }
  };

  const onIgnore = async () => {
    await toggleIssueIgnored({
      variables: {
        repetitiveLossId: repetitiveLossId,
        getNext: true,
        currentRLNumber: repetitiveLoss.rLNumber,
      },
    });
  };

  const onSkip = async () => {
    await getNextIssue({
      variables: { currentRLNumber: repetitiveLoss.rLNumber },
    });
  };

  const onError = (error: ApolloError) => {
    const userErrors = getUserErrors(error);

    if (userErrors) {
      addErrorToast(userErrors);
    } else {
      addErrorToast(
        "There was an issue requesting a password reset. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    }
  };

  const { account, ...repetitiveLoss } = data.repetitiveLoss;

  // I removed the ?? 0, a snapshot failed because it showed NaN instead of 0
  // While this was likely due to test setup not accurately reflecting the real world,
  // I'm hesitant to remove the `?? 0` in case we have types wrong somewhere and it
  // results in us showing NaN to users

  const issueCount = Math.max((account?.repetitiveLossIssueCount ?? 0) - 1, 0);

  if (repetitiveLoss.property && !isRegeocoding) {
    return (
      <EditRepetitiveLossProperty
        repetitiveLoss={repetitiveLoss as RepetitiveLossWithProperty}
        onIgnore={onIgnore}
        onSkip={onSkip}
        onError={onError}
        issueCount={issueCount}
        redirectAfterMutation={redirectAfterMutation}
      />
    );
  } else {
    return (
      <AddRepetitiveLossProperty
        repetitiveLoss={repetitiveLoss}
        issueCount={issueCount}
        onIgnore={onIgnore}
        onSkip={onSkip}
        onError={onError}
        redirectAfterMutation={redirectAfterMutation}
        showQueueControls={!isRegeocoding}
      />
    );
  }
};

export default GeocodeRepetitiveLoss;
