import React from "react";
import { capitalize } from "lodash";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getPath } from "common/routing";
import { useGetAccessDeniedDataQuery } from "../../generated/graphql";
import { setTitle } from "../../utils/title";
import { EmptyState } from "../Common/EmptyState";
import { AccessDeniedMessageContainer } from "./__styles__/AccessDeniedPage";
import { Page } from "./__styles__/Layout";
import { Header } from "./Layout";
import { accountTitle } from "./utils";

export enum ACCESS_DENIED_RESOURCE {
  PROPERTY = "property",
  FILE = "file",
  SAVED_VIEW = "saved-view",
}
const getLoginPageURL = (pathName: string) => {
  return `/login?prev=${encodeURIComponent(pathName)}`;
};

const AccessDeniedPage = ({
  resource,
}: {
  resource: ACCESS_DENIED_RESOURCE;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { data } = useGetAccessDeniedDataQuery();

  const translatedResource = t(`common-${resource}`);
  const accountContactLink =
    data?.account?.contactInformation.email &&
    data.account.contactInformation.name ? (
      <a href={`mailto:${data.account.contactInformation.email}`}>
        {data.account.contactInformation.name}
      </a>
    ) : null;

  const requestHelpLink = (
    <Link to={getPath("getHelp")}>{t("common-request-help")}</Link>
  );

  const body = accountContactLink ? (
    <Trans
      i18nKey="access-denied-subtext-contact"
      components={{ accountContactLink, requestHelpLink }}
      values={{ resource: translatedResource }}
    >
      You may not have access to this {translatedResource}, or it may have been
      removed. For more information, contact {accountContactLink} or{" "}
      {requestHelpLink}.
    </Trans>
  ) : (
    <Trans
      i18nKey="access-denied-subtext-no-contact"
      components={{ requestHelpLink }}
      values={{ resource: translatedResource }}
    >
      You may not have access to this {translatedResource}, or it may have been
      removed. For more information, {requestHelpLink}.
    </Trans>
  );

  const icon = resource === "property" ? "missing-location" : "missing-file";

  setTitle(
    `${t("tab-access-denied", {
      resource: capitalize(translatedResource),
    })} | ${accountTitle()}`
  );

  return (
    <Page>
      <Header />

      <AccessDeniedMessageContainer>
        <EmptyState
          icon={icon}
          title={t("access-denied-header", {
            resource: translatedResource,
          })}
          body={<>{body}</>}
          primaryButtonProps={{
            label: t("access-denied-button-login"),
            onClick: () => {
              let pathName = location.pathname.includes("/file/")
                ? location.pathname.replace("/file/", "/files/")
                : location.pathname;

              const loginPageURL = getLoginPageURL(pathName);

              window.location.href =
                window.location.protocol +
                "//" +
                window.env.APPLICATION_DOMAIN +
                loginPageURL;
            },
          }}
          secondaryButtonProps={{
            label: t("access-denied-button-explore"),
            onClick: () => history.push(getPath("explore")),
          }}
        />
      </AccessDeniedMessageContainer>
    </Page>
  );
};

export default AccessDeniedPage;
