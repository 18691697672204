import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import { Error } from "../../generated/graphql";
import { spacing } from "../../stitches.config";
import { LoginForm as MFACodeForm } from "../Authentication/__styles__/LoginPage";
import { LoginFormStructure } from "../Authentication/LoginPage";
import { ProvideCode } from "../Authentication/ProvideCode";
import { Container } from "../Common/__styles__/Modal";

const ResetMFAPhone = ({
  maskedPhoneNumber,
  userProvidedPhoneNumber,
  updatePhoneNumber,
}: {
  maskedPhoneNumber: string;
  updatePhoneNumber: () => void;
  userProvidedPhoneNumber: string;
}) => {
  const useFormProps = useForm<LoginFormStructure>({
    defaultValues: { phoneNumber: userProvidedPhoneNumber },
  });

  const [error, setError] = useState<Maybe<Error>>(null);
  const dispatch = (action: MFAFlowAction) => {
    // These are the only two action types that can be dispatched from this component
    // If more functionality is added, this list will have to be expaned upon
    if (action.type === "sendCode" || action.type === "verifyCode") {
      setError(action.data?.error ?? null);
    }
  };

  return (
    <FormProvider {...useFormProps}>
      <Container style={{ padding: spacing.xl.toString() }} width="narrow">
        <MFACodeForm>
          <ProvideCode
            allowAnotherNumber={false}
            dispatch={dispatch}
            error={error}
            maskedPhoneNumber={maskedPhoneNumber}
            onSuccess={() => {
              updatePhoneNumber();
            }}
          />
        </MFACodeForm>
      </Container>
    </FormProvider>
  );
};

export default ResetMFAPhone;
