import { useContext, useState } from "react";
import React from "react";
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { QueryDescription } from "common/utils/queryBuilder";
import {
  useCreateSavedViewMutation,
  useUpdateSavedViewMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { track } from "../../../../../utils/tracking";
import { Text } from "../../../../Inputs/react-hook-form";
import { InputRow } from "../../../__styles__/Modal";
import { CommonModal } from "../../../CommonModal";
import Modal from "../../../Modal";
import { Body } from "../../../Typography";
import { TableContext } from "../../TableContext";
import { useUpdateSavedView } from "../../utils";
import { SavedView } from "../utils";

type UpsertSavedViewForm = {
  name: string;
};

const textByUpsertType = {
  create: {
    title: "Create new view",
    primaryButtonText: "Create view",
    body: "Table views are visible to everyone and can only be edited by the person that created it. If filters or columns that reference sensitive data are present, only users with permissions will be able to see this view.",
  },
  edit: {
    title: "Edit view",
    primaryButtonText: "Save view",
    body: "Any changes saved to this existing view will be updated for everyone. If filters or columns that reference sensitive data are present, only users with permissions will be able to see this view.",
  },
};

export const UpsertSavedViewModal = ({
  hideModal,
  savedView,
  query,
  onSave,
}: {
  hideModal: () => void;
  savedView?: Maybe<SavedView>;
  query?: QueryDescription;
  onSave?: () => void;
}) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { updateSavedView } = useUpdateSavedView();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm<UpsertSavedViewForm>({
    defaultValues: { name: savedView?.name },
  });

  const [createSavedViewMutation, { loading: createLoading }] =
    useCreateSavedViewMutation({
      onCompleted: data => {
        track("Created table view", {
          table: query?.table,
          name: data.createSavedView.name,
        });
        onSave?.();
        hideModal();
        updateSavedView(data.createSavedView.id);
        addSuccessToast(`Your table view was successfully created`);
      },
      onError: () => {
        addErrorToast(
          `There was an issue creating your table view. Please try again. If the problem persists, please email us at support@withforerunner.com`
        );
      },
    });

  const [updateSavedViewMutation, { loading: updateLoading }] =
    useUpdateSavedViewMutation({
      onCompleted: data => {
        track("Edited table view", {
          table: savedView!.query.table,
          name: data.updateSavedView.name,
        });
        hideModal();
        addSuccessToast(`Your table view was successfully edited`);
      },
      onError: () => {
        addErrorToast(
          `There was an issue editing your table view. Please try again. If the problem persists, please email us at support@withforerunner.com`
        );
      },
    });

  const onSubmit = async (formData: UpsertSavedViewForm) => {
    if (savedView) {
      const data = {
        ...formData,
        ...{ query: !isEqual(query, savedView.query) ? query : undefined },
        id: savedView.id,
      };

      await updateSavedViewMutation({ variables: { data } });
    } else {
      const data = {
        ...formData,
        query,
      };

      await createSavedViewMutation({ variables: { data } });
    }
  };

  const text = savedView ? textByUpsertType.edit : textByUpsertType.create;
  const loading = updateLoading || createLoading;

  return (
    <CommonModal
      title={text.title}
      primaryButtonText={text.primaryButtonText}
      onSubmit={handleSubmit(onSubmit)}
      disabled={!isValid}
      loading={loading}
      onCancel={hideModal}
      size="large"
      content={
        <>
          <Body size="default" type="regular">
            {text.body}
          </Body>
          <InputRow css={{ marginTop: "18px" }}>
            <Text
              {...register("name", { required: true })}
              error={errors.name?.message}
              size="small"
              label="Name"
              required
            />
          </InputRow>
        </>
      }
    />
  );
};

export const useUpsertSavedViewModal = ({
  onSave,
}: {
  onSave?: () => void;
}) => {
  const { currentQuery } = useContext(TableContext);
  const [savedView, setSavedView] = useState<Maybe<SavedView>>(null);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal onRequestClose={hideModal}>
        <UpsertSavedViewModal
          hideModal={hideModal}
          savedView={savedView}
          query={currentQuery}
          onSave={onSave}
        />
      </Modal>
    ),
    [currentQuery, savedView]
  );

  const showUpsertModal = (savedView: Maybe<SavedView>) => {
    setSavedView(savedView);
    showModal();
  };

  return [showUpsertModal, hideModal] as const;
};
