import React from "react";
import { isNil, omit } from "lodash";
import { useFormContext } from "react-hook-form";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import {
  useGetResetMfaUserOptionsQuery,
  useRequestMfaResetMutation,
  UserLoginErrorCode,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { Button } from "../Common/Button";
import Loading from "../Common/Icons/Loading";
import { Section, Sections } from "../Common/Layout";
import { Body, Title } from "../Common/Typography";
import { Select } from "../Inputs/react-hook-form";
import { SecondaryActions } from "./CommonLoginComponents/SecondaryActions";
import { ErrorMessage } from "./ErrorMessage";
import { LoginFormStructure } from "./LoginPage";

export interface SelectRequestUserProps {
  dispatch: React.Dispatch<MFAFlowAction>;
  error?: Maybe<{ message: string; code?: UserLoginErrorCode }>;
}

export const SelectRequestUser = ({
  dispatch,
  error,
}: SelectRequestUserProps) => {
  const { addErrorToast } = useStatusToasts();
  const { control, getValues, handleSubmit, watch } =
    useFormContext<LoginFormStructure>();

  const [email, password] = getValues(["email", "password"]);
  const { data, loading: loadingAdmins } = useGetResetMfaUserOptionsQuery({
    variables: {
      data: { email: email!, password: password! },
    },
  });

  const [requestMfaReset, { loading: resetRequestLoading }] =
    useRequestMfaResetMutation({
      onCompleted: data =>
        dispatch({ type: "requestReset", data: data.requestMFAReset }),
      onError: () =>
        addErrorToast(
          `There was an issue requesting an MFA reset. Please try again. If the problem persists, please email us at support@withforerunner.com`
        ),
    });

  if (loadingAdmins) {
    return (
      <div
        style={{
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  if (data?.resetMFAUserOptions.error) {
    dispatch({
      type: "requestReset",
      data: omit(data.resetMFAUserOptions, "resetUserOptions"),
    });
  }

  if (data && !data.resetMFAUserOptions.resetUserOptions?.length) {
    dispatch({ type: "requestReset" });
  }

  const requestedUserOptions =
    data?.resetMFAUserOptions.resetUserOptions?.map(user => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    })) ?? [];

  const handleRequestReset = async (loginData: LoginFormStructure) => {
    await requestMfaReset({
      variables: {
        data: {
          email: loginData.email!,
          password: loginData.password!,
          requestedUserId: loginData.requestedUserId!,
        },
      },
    });
  };

  const returnToCode = () => {
    dispatch({
      type: "sendCode",
      data: omit(data?.resetMFAUserOptions, "resetUserOptions"),
    });
  };

  const requestedUserId = watch("requestedUserId");
  const disabled = isNil(requestedUserId);

  return (
    <Sections>
      {error && <ErrorMessage error={error} />}
      <Section>
        <Title type="semiBold" size="large">
          Request an MFA reset
        </Title>
        <Body size="default" type="regular">
          Please select the name of the manager on your account you would like
          to request a reset from
        </Body>
        <div>
          <Select
            label="Manager"
            control={control}
            name="requestedUserId"
            options={requestedUserOptions}
          />
          <SecondaryActions
            actions={[
              {
                onClick: returnToCode,
                text: "Return to authentication code entry",
              },
            ]}
          />
        </div>
      </Section>
      <Button
        onClick={handleSubmit(handleRequestReset)}
        disabled={disabled || resetRequestLoading}
        size="medium"
        styleVariant="primary"
        stretch
      >
        Submit
      </Button>
    </Sections>
  );
};
