import React from "react";
import { Route, Switch } from "react-router-dom";
import EditCertificate from "./EditCertificate";
import EditProperty from "./EditProperty";

export default function BackofficeApp() {
  return (
    <Switch>
      <Route
        path="/backoffice/properties/:propertyId"
        component={EditProperty}
      />
      <Route
        path="/backoffice/certificates/:certificateId"
        component={EditCertificate}
      />
    </Switch>
  );
}
