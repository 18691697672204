import React from "react";
import { AuthContext } from "../../Authorization/AuthContext";
import { MenuItems, SubTabMenu } from "../SubTabMenu";
import Disclaimers from "./Disclaimers";
import General from "./General";
import PropertyData from "./PropertyData";
import PublicWebsite from "./PublicWebsite";
import Records from "./Records";
import Security from "./Security";
import Warnings from "./Warnings/WarningSettings";

export const Account = () => {
  const { admin, account } = React.useContext(AuthContext);
  const menuItems: MenuItems = [
    {
      name: "General",
      path: "/general",
      component: General,
    },
    {
      name: "Security",
      path: "/security",
      component: Security,
    },
    {
      name: "Public website",
      path: "/public-website",
      hide: !admin || !account?.publicPortal?.enabled,
      restrict: !admin,
      component: PublicWebsite,
    },
    {
      name: "Property data",
      path: "/property-data",
      hide: !admin,
      restrict: !admin,
      component: PropertyData,
    },
    {
      name: "Records",
      path: "/records",
      hide: !admin,
      restrict: !admin,
      component: Records,
    },
    { name: "Warnings", path: "/warnings", component: Warnings },
    {
      name: "Disclaimers",
      path: "/disclaimers",
      hide: !admin,
      restrict: !admin,
      component: Disclaimers,
    },
  ];

  return <SubTabMenu menuItems={menuItems} />;
};
