import React from "react";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router";
import {
  AccountDocumentType,
  useDeleteDocumentUploadMutation,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { CAROUSEL_DOCUMENTS_QUERY } from "../AddressPanel/PropertyOverview/__queries__/getCarouselDocuments";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import { Button } from "../Common/Button";
import { Icon } from "./__styles__/DeleteConfirmation";

export interface DeleteConfirmationProps
  extends RouteComponentProps<
    {},
    {},
    { prevLocation?: string; forceRefresh?: boolean }
  > {
  isDetailView: boolean;
  documentUploadId: string;
  accountDocumentType: Pick<AccountDocumentType, "name">;
  issuedAt: string;
  closeModal: () => void;
  onUpdate?: () => void;
  address: Maybe<string>;
  propertyId: Maybe<string>;
}

export const DeleteDocumentUploadConfirmation = withRouter(
  ({
    isDetailView,
    documentUploadId,
    accountDocumentType,
    issuedAt,
    closeModal,
    history,
    onUpdate,
    location,
    address,
    propertyId,
  }: DeleteConfirmationProps) => {
    const { addSuccessToast, addErrorToast } = useStatusToasts();

    const refetchQueries = propertyId
      ? [
          {
            query: CAROUSEL_DOCUMENTS_QUERY,
            variables: { propertyId, filterHidden: false },
          },
        ]
      : [];

    const [deleteDocumentUpload, { loading }] = useDeleteDocumentUploadMutation(
      {
        onCompleted: () => {
          onUpdate?.();
          closeModal();
          if (isDetailView) {
            const newPath = location.state?.prevLocation ?? "/";
            history.push(newPath, { forceRefresh: true });
          }
          addSuccessToast("Your file was successfully deleted");
        },
        onError: () => {
          closeModal();
          addErrorToast(
            "There was an issue deleting this file. Please try again. If the problem persists, please email us at support@withforerunner.com"
          );
        },
        refetchQueries,
      }
    );

    return (
      <Container width={"narrow"}>
        <HeaderSection>
          <h1>Delete {accountDocumentType.name}</h1>
        </HeaderSection>
        <ContentSection style={{ textAlign: "center" }}>
          <Icon />
          <p>
            Are you certain that you would like to delete the following{" "}
            {accountDocumentType.name}:
          </p>
          <p>
            {address && (
              <>
                Address: <strong>{address}</strong>
              </>
            )}
            <br />
            Date Issued:
            <strong>
              {issuedAt ? moment(issuedAt).format("MM/DD/YYYY") : "-"}
            </strong>
          </p>
          <p>Warning: this action is irreversible</p>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={closeModal} size="medium">
              Cancel
            </Button>
            <Button
              styleVariant="alert"
              onClick={async () => {
                await deleteDocumentUpload({
                  variables: { documentUploadId },
                });
              }}
              disabled={loading}
              loading={loading}
              size="medium"
            >
              Delete file
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </Container>
    );
  }
);
