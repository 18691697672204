import React, { ComponentProps, useContext, useEffect, useState } from "react";
import { flatMap } from "lodash";
import { useTranslation } from "react-i18next";
import { PROPERTY_ATTRIBUTE_NAMES } from "common/constants";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { getCustomDisclaimerTexts } from "common-client/utils/disclaimerOverrides";
import {
  FIRMWithWarnings,
  PROPERTY_PANEL_FIRM_WARNINGS,
} from "common-client/utils/firmInfoWarnings";
import { EditableMode } from "common-client/utils/firms";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { AuthContext } from "../../../Authorization/AuthContext";
import { Account } from "../../../Authorization/types";
import { TableCell, TableRow } from "../../../Common/__styles__/StripedTable";
import Disclaimer from "../../../Common/Disclaimer";
import { EmptyState } from "../../../Common/EmptyState";
import { Body } from "../../../Common/Typography";
import { ApproximateBfeTool } from "../../../Maps/ApproximateBfeTool";
import { canUseApproximateBfeTool } from "../../../Maps/ApproximateBfeTool/utils";
import { LayerContext } from "../../../Maps/layers";
import { AddressPanelContext } from "../../AddressPanelContext";
import {
  NO_FIRM_ID,
  PropertyOverviewContext,
} from "../PropertyOverviewContext";
import { BannerWrapper } from "./__styles__/FIRMInfo";
import { BASE_FLOOD_MODES, useEditBaseFloodModal } from "./BaseFloodEditModal";
import DigitizedFIRMDisclaimer from "./DigitizedFIRMDisclaimer";
import {
  BFDOverriddenDisclaimer,
  BFEOverriddenDisclaimer,
  GeometryDisclaimer,
} from "./Disclaimers";
import { useFIRMComparisonModal } from "./FIRMComparisonModal";
import { FIRMEditFormProps, useFIRMEditModal } from "./FIRMEditModal";
import { FIRMInfoContext, FIRMInfoContextProvider } from "./FIRMInfoContext";
import FIRMSelect from "./FIRMSelect";
import FIRMTable from "./FIRMTable";
import { FirmWarningsBanner } from "./FirmWarningsBanner";
import { useFloodzoneEditModal } from "./FloodzoneEditModal";
import { Property } from "./types";

interface EditingMode {
  firm: FIRMWithWarnings;
  mode: EditableMode;
  property: NonNullable<Property>;
}

const FIRMInfo = ({
  section,
}: {
  section: Account["propertyInformationSections"][number];
}) => {
  const { account, isGuest } = useContext(AuthContext);
  const {
    toggleLayer,
    visibleFIRM: getMapLayerFIRM,
    approximateBfeToolDispatch,
  } = useContext(LayerContext);
  const { property, loadingProperty } = useContext(AddressPanelContext);
  const {
    activeFirmIds,
    firms,
    loading: loadingFirmData,
    setActiveFirmIds,
    getPopulatedFirms,
  } = useContext(FIRMInfoContext);
  const propertyId = property?.id;
  const { t } = useTranslation();

  const [editing, setEditing] = useState<Maybe<EditingMode>>(null);
  const onFIRMEdit = ({ mode, firm }: Omit<EditingMode, "property">) => {
    if (property) {
      setEditing({ mode, firm, property });
    }
  };
  const activeFIRM = firms.find(firm => firm.id === activeFirmIds[0]);

  const [showFIRMEditModal] = useFIRMEditModal({
    property: editing?.property!,
    firm: editing?.firm,
    mode: editing?.mode as FIRMEditFormProps["mode"],
    onCancel: () => setEditing(null),
    onSave: () => setEditing(null),
  });

  useEffect(() => {
    if (property) {
      if (editing?.mode && editing.mode in BASE_FLOOD_MODES) {
        showEditBaseFloodModal();
      } else if (editing?.mode === "floodzone") {
        showFloodzoneEditModal();
      } else if (editing) {
        showFIRMEditModal();
      }
    }
  }, [editing?.mode, property?.id]);

  const mapLayerFIRM = getMapLayerFIRM() ?? null;
  const mapLayerFIRMId = mapLayerFIRM?.id;

  // FIRM layer changes in layer control update PIP FIRM
  useEffect(() => {
    if (mapLayerFIRMId) {
      setActiveFirmIds([mapLayerFIRMId]);
    }
  }, [mapLayerFIRMId]);

  // If the map has FIRM layer on, then changing activeFIRMId updates FIRM layer on map
  useEffect(() => {
    if (
      getMapLayerFIRM() &&
      activeFirmIds[0] &&
      activeFirmIds[0] !== mapLayerFIRMId
    ) {
      toggleLayer({ group: "firms", id: activeFirmIds[0], isVisible: true });
    }
  }, [activeFirmIds]);

  const [showFIRMComparisonModal, hideFIRMComparisonModal] =
    useFIRMComparisonModal({
      property,
      account: account!,
      onFIRMEdit: ({ mode, firm }: Omit<EditingMode, "property">) => {
        hideFIRMComparisonModal?.();
        onFIRMEdit({ mode, firm });
        setActiveFirmIds([firm.id]);
      },
      onFIRMClick: (firmId: string) => {
        hideFIRMComparisonModal?.();
        setActiveFirmIds([firmId]);
      },
      section,
    });

  const windowWidth = useWindowSize().width;

  const showApproximateBfeToolOption = canUseApproximateBfeTool({
    displayConfig: section.accountPropertyAttributes.find(
      attribute => attribute.name === PROPERTY_ATTRIBUTE_NAMES.BFE
    )?.displayConfig,
    firm: activeFIRM ?? null,
    windowWidth,
  });

  const openApproximateBfeTool = () => {
    setEditing(null);

    if (!mapLayerFIRM || !property?.id || !activeFIRM) return;

    approximateBfeToolDispatch?.({
      type: "setMode",
      data: {
        mode: "on",
        render: () => {
          return (
            <ApproximateBfeTool
              openEditFIRM={() =>
                onFIRMEdit({ firm: activeFIRM, mode: "elevation" })
              }
              propertyId={property.id}
              mapLayerFirm={mapLayerFIRM}
            />
          );
        },
      },
    });
  };

  const [showFloodzoneEditModal, hideFloodzoneEditModal] =
    useFloodzoneEditModal({
      ...editing!,
      onCancel: () => setEditing(null),
      onSave: () => setEditing(null),
      ...(showApproximateBfeToolOption && {
        openApproximateBfeTool: () => {
          hideFloodzoneEditModal();
          openApproximateBfeTool();
        },
      }),
    });

  const [showEditBaseFloodModal, hideEditBaseFloodModal] =
    useEditBaseFloodModal({
      property: editing?.property!,
      firm: editing?.firm,
      mode: editing?.mode as keyof typeof BASE_FLOOD_MODES,
      onCancel: () => setEditing(null),
      onSave: () => setEditing(null),
      ...(showApproximateBfeToolOption && {
        openApproximateBfeTool: () => {
          hideEditBaseFloodModal();
          openApproximateBfeTool();
        },
      }),
    });

  if (loadingProperty || loadingFirmData || !activeFIRM)
    return <em>{t("common-loading")}</em>;

  if (arrayHasAtLeastOneItem(firms) && firms[0].id === NO_FIRM_ID) {
    return (
      <div>
        <Body size="large" type="emphasis" style={{ maxWidth: "50%" }}>
          {section.label}
        </Body>
        <EmptyState compact title={t("property-flood-info-empty-state")} />
      </div>
    );
  }

  const showFIRMSelect = getPopulatedFirms().length > 1;

  const TableHeader = () => {
    return (
      <TableRow header>
        <TableCell wrap={true}>{section.label}</TableCell>
        <TableCell>
          <FIRMSelect onComparisonSelection={showFIRMComparisonModal!} />
        </TableCell>
      </TableRow>
    );
  };

  const customDisclaimerTexts = getCustomDisclaimerTexts({
    disclaimerOverrides: account?.disclaimerOverrides,
    isPublic: isGuest,
  });

  return (
    <div data-testid="firm-info">
      <FIRMTable
        account={account!}
        property={property}
        onFIRMEdit={onFIRMEdit}
        Header={showFIRMSelect ? TableHeader : undefined}
        showTitle={true}
        section={section}
      />

      <BannerWrapper>
        <FirmWarningsBanner
          firms={[activeFIRM]}
          propertyId={propertyId!}
          warningTextConfig={PROPERTY_PANEL_FIRM_WARNINGS}
          title={section.label}
        />
      </BannerWrapper>

      {customDisclaimerTexts.map((disclaimerText, i) => (
        <Disclaimer key={i} message={disclaimerText} />
      ))}
      <DigitizedFIRMDisclaimer accountFIRMData={firms} />
      <BFEOverriddenDisclaimer
        firm={activeFIRM}
        property={property}
        accountPropertyAttributes={flatMap(
          account?.propertyInformationSections.map(
            section => section.accountPropertyAttributes
          )
        )}
      />
      <BFDOverriddenDisclaimer firm={activeFIRM} />
      <GeometryDisclaimer />
    </div>
  );
};

const FIRMInfoWrapper = (props: ComponentProps<typeof FIRMInfo>) => {
  const { longitude, latitude } = useContext(PropertyOverviewContext);
  return (
    <FIRMInfoContextProvider longitude={longitude!} latitude={latitude!}>
      <FIRMInfo {...props} />
    </FIRMInfoContextProvider>
  );
};

export default FIRMInfoWrapper;
