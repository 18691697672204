import * as Sentry from "@sentry/browser";
import { MapController } from "react-map-gl";

export default class CustomMapController extends MapController {
  setOptions(options: any) {
    try {
      super.setOptions(options);
    } catch (error) {
      // for reasons beyond my meager powers of
      // comprehension, sometimes calling `setOptions`
      // will result in some weird exceptions like so:
      // https://sentry.io/organizations/forerunner/issues/2378812990/?project=1518444&referrer=slack
      // it is unclear how or why these happen, but they occur with
      // some frequency and so I'd like to make sure it *doesn't* entirely
      // break the UI. It *might* break interactions with the map, but
      // I'd like to figure that out.
      Sentry.withScope(scope => {
        scope.setExtras({ options });
        Sentry.captureException(error);
      });
    }
  }

  _onWheel(event: Event) {
    // prevent scrolls on map overlays from scrolling map
    if ((event.target as Element).className !== "overlays") return;

    // @ts-ignore
    super._onWheel(event);
  }

  _onPan(event: Event) {
    if ((event.target as Element).className !== "overlays") return;

    // @ts-ignore
    super._onPan(event);
  }
}
