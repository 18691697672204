import React, { useContext } from "react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { OBJECT_TYPE, SUBMISSION_CATEGORY } from "common/constants";
import { RECORDS_TAB_NAME } from "common/routing";
import useNavigationTab from "../../hooks/useNavigationTabs";
import { getOptionalTabs } from "../../utils/submissions";
import { AuthContext } from "../Authorization/AuthContext";
import { FlexRow } from "../Common/__styles__/Layout";
import TabGroup from "../Common/TabGroup";
import { Body } from "../Common/Typography";
import { AddressPanelTabProps } from ".";
import {
  AddressPanelTitle,
  Section,
  TabContainer,
  Wrapper,
} from "./__styles__/AddressPanel";
import Improvements from "./Improvements";
import Logs from "./Logs";
import Submissions from "./Submissions";

export const Layout = ({
  actionButton,
  children,
  title,
}: {
  actionButton?: React.ReactNode;
  children: React.ReactNode;
  title: string;
}) => (
  <div>
    <FlexRow style={{ justifyContent: "space-between" }}>
      <AddressPanelTitle>
        <Body size="large" type="emphasis">
          {title}
        </Body>
      </AddressPanelTitle>
      {actionButton}
    </FlexRow>
    {children}
  </div>
);

export const Records = (props: AddressPanelTabProps) => {
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();

  const categories = new Set(
    account?.submissionTypes
      .filter(type =>
        type.attachments.some(
          attachment => attachment.type === OBJECT_TYPE.PROPERTY
        )
      )
      .map(type => type.category)
  );

  let tabs = [{ value: RECORDS_TAB_NAME.LOGS, label: "Logs" }];
  const optionalTabs = getOptionalTabs(t);

  tabs = tabs.concat(
    ...optionalTabs
      .filter(({ category }) => categories.has(category))
      .map(({ recordTabName, title }) => ({
        value: recordTabName,
        label: title,
      }))
  );

  const defaultTab = tabs[0]!;

  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab.value,
    allowedTabs: map(tabs, "value"),
    queryParamName: "recordTab",
  });

  const currentOptionalTab = optionalTabs.find(
    ({ category, recordTabName }) =>
      category !==
        SUBMISSION_CATEGORY.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE &&
      tab === recordTabName
  );

  return (
    <>
      <TabContainer hasOutlineStyleActiveTabs>
        <TabGroup
          currentTab={tab}
          setTab={setTab}
          tabs={tabs}
          activeTabStyle="outline"
        />
      </TabContainer>

      <Wrapper>
        <Section>
          {tab === RECORDS_TAB_NAME.IMPROVEMENTS && <Improvements {...props} />}
          {tab === RECORDS_TAB_NAME.LOGS && <Logs {...props} />}
          {currentOptionalTab && (
            <Submissions
              {...props}
              category={currentOptionalTab.category}
              title={currentOptionalTab.title}
              titleWithinCopy={currentOptionalTab.titleWithinCopy}
            />
          )}
        </Section>
      </Wrapper>
    </>
  );
};
