import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  MFAReducer,
  SCREEN_DISPLAY,
  useMFAFlow,
} from "common-client/utils/useMFAFlow";
import {
  AdminLoginDocument,
  GetUserAccountsQuery,
  UserLoginDocument,
} from "../../generated/graphql";
import { getRedirectPath, LoginLocationState } from "../../utils/params";
import { track } from "../../utils/tracking";
import FullPageBackgroundWithLogo from "../Common/FullPageBackgroundWithLogo";
import { LoginForm } from "./__styles__/LoginPage";
import { Container } from "./CommonLoginComponents/__styles__/FormWithFullPageBackground";
import { ProvideCode } from "./ProvideCode";
import { ProvideCredentials } from "./ProvideCredentials";
import { ProvidePhoneNumber } from "./ProvidePhoneNumber";
import { RequestMFAResetConfirmation } from "./RequestMFAResetConfirmation";
import { SelectRequestUser } from "./SelectRequestUser";

export interface LoginPageProps {
  loginMutation: typeof AdminLoginDocument | typeof UserLoginDocument;
  passwordResetPath?: string;
  initialTitle: string;
  fallbackPath: string;
  event: string;
  startingEmail?: string;
  accounts: NonNullable<GetUserAccountsQuery["getUserAccountsByEmail"]>;
  currentAccount: Maybe<
    NonNullable<GetUserAccountsQuery["getUserAccountsByEmail"]>[0]
  >;
}

export type LoginFormStructure = {
  email?: Maybe<string>;
  password?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  code?: Maybe<string>;
  requestedUserId?: Maybe<string>;
};

const LoginPage = ({
  loginMutation,
  passwordResetPath,
  initialTitle,
  event,
  startingEmail,
  fallbackPath,
  accounts,
  currentAccount,
}: LoginPageProps) => {
  const location = useLocation<LoginLocationState>();

  const useFormProps = useForm<LoginFormStructure>({
    defaultValues: {
      email: startingEmail,
    },
  });

  const redirectPath = getRedirectPath({
    routeName: "provideCredentials",
    routerLocation: location,
    fallbackPath,
  });

  const onLoginSuccess = () => {
    track(event);

    window.location.href = redirectPath;
  };

  const [state, dispatch] = useMFAFlow({
    defaultValue: {
      currentScreen: SCREEN_DISPLAY.PROVIDE_CREDENTIALS,
      error: null,
    },
    useReducer: React.useReducer as MFAReducer,
  });

  const error = state.error ?? null;

  return (
    <FullPageBackgroundWithLogo>
      <Container>
        <FormProvider {...useFormProps}>
          <LoginForm>
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CREDENTIALS && (
              <ProvideCredentials
                currentAccount={currentAccount ?? null}
                dispatch={dispatch}
                error={error}
                loginMutation={loginMutation}
                onSuccess={onLoginSuccess}
                passwordResetPath={passwordResetPath}
                title={initialTitle}
                accounts={accounts}
              />
            )}
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_PHONE && (
              <ProvidePhoneNumber dispatch={dispatch} error={error} />
            )}
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CODE && (
              <ProvideCode
                allowAnotherNumber={!state.phoneNumberVerified!}
                allowResetRequest={state.phoneNumberVerified!}
                dispatch={dispatch}
                error={error}
                maskedPhoneNumber={state.phoneNumber!}
                onSuccess={onLoginSuccess}
              />
            )}
            {state.currentScreen === SCREEN_DISPLAY.REQUEST_RESET && (
              <SelectRequestUser dispatch={dispatch} error={error} />
            )}
            {state.currentScreen === SCREEN_DISPLAY.CONFIRM_RESET && (
              <RequestMFAResetConfirmation />
            )}
          </LoginForm>
        </FormProvider>
      </Container>
    </FullPageBackgroundWithLogo>
  );
};

export default LoginPage;
