import React from "react";
import { useTranslation } from "react-i18next";
import {
  formatFirstOptionRow,
  formatSecondOptionRow,
} from "common-client/utils/Search";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { Body } from "../Common/Typography";
import { SearchResultProps, Status } from ".";
import { SearchResult } from "./__queries__/getSearchResults";
import {
  CenterContentContainer,
  Dropdown,
  Option,
} from "./__styles__/Dropdown";

export const ResultsDropdown = ({
  results,
  onClear,
  handleResultClick,
  reset,
  resetSearchCategory,
  status,
}: {
  results: Array<Maybe<SearchResult>>;
  onClear: () => void;
  handleResultClick: ({
    point,
    address,
    propertyId,
    streetAddress,
    city,
    state,
    zip,
  }: SearchResultProps) => void;
  reset: () => void;
  resetSearchCategory: () => void;
  status: Status;
}) => {
  const { t } = useTranslation();

  const handleResult = (result: SearchResult) => {
    reset();
    onClear();
    resetSearchCategory();
    const [longitude, latitude] = result!.coordinates.split(",").map(Number);
    handleResultClick({
      point: { longitude: longitude!, latitude: latitude! },
      address: result?.address!,
      propertyId: result?.propertyId,
      geocacheParcelId: result?.geocacheParcelId,
      streetAddress: result.streetAddress,
      city: result.city,
      state: result.state,
      zip: result.zip,
    });
  };

  return (
    <Dropdown data-testid="search-dropdown">
      {status === "search_complete" && !results.length && (
        <CenterContentContainer>
          <Body size="default" type="regular">
            {t("search-no-results")}
          </Body>
        </CenterContentContainer>
      )}
      {status === "loading" && (
        <CenterContentContainer data-testid="loading" tabIndex={0}>
          <Icon icon={Icons.SMALL_LOADING} color={ICON_COLORS.LIGHT_GREY} />
        </CenterContentContainer>
      )}
      {results.map((result, index) => (
        <Option
          key={index}
          tabIndex={0}
          onClick={() => handleResult(result!)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleResult(result!);
            }
          }}
        >
          <Body size="large" type="emphasis">
            {formatFirstOptionRow({
              searchResult: result!,
              blockLabel: t("search-block"),
              lotLabel: t("search-lot"),
            })}
          </Body>
          <Body size="large" type="regular">
            {formatSecondOptionRow(result!)}
          </Body>
        </Option>
      ))}
    </Dropdown>
  );
};
