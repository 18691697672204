import React, { Dispatch, SetStateAction, useContext } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { Header } from "@tanstack/react-table";
import { colors, fontSizes, styled } from "../../../stitches.config";
import { track } from "../../../utils/tracking";
import { Icon } from "../Icons/LucideIcons";
import { QueryDescriptionReducerAction } from "../Tables/hooks";
import { TableContext } from "./TableContext";

type DropdownMenuProps<T> = {
  header: Header<T, unknown>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  removeColumn: (id: string) => void;
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  updateQueryDescription?: (arg: QueryDescriptionReducerAction) => void;
};

export const DropdownMenu = <T,>({
  header,
  setColumnOrder,
  open,
  setOpen,
  removeColumn,
  updateQueryDescription,
}: DropdownMenuProps<T>) => {
  const { name } = useContext(TableContext);

  const waitUntilDropdownCloses = (rerenderFunction: () => void) => {
    setTimeout(() => {
      rerenderFunction();
    }, 0);
  };

  const totalColumns = header.getContext().header.headerGroup.headers.length;

  const isFirstColumn = header.index === 0;
  const leftMovable = !isFirstColumn && header.index !== 1;
  const rightMovable = !isFirstColumn && header.index !== totalColumns - 2;

  const onHideColumn = () => {
    waitUntilDropdownCloses(() => removeColumn(header.column.id));
    track("Removed column", {
      tableName: name,
      column: header.column.id,
    });
  };

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger asChild>
        <div />
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <DropdownContent align="start" alignOffset={-9} sideOffset={9}>
          <DropdownItem
            onSelect={() =>
              waitUntilDropdownCloses(() => header.column.toggleSorting(false))
            }
            data-testid="ascending"
            hide={!header.column.getCanSort()}
          >
            <Icon iconName="arrow-up" color="contentSecondary" size={16} />
            Ascending
          </DropdownItem>
          <DropdownItem
            onSelect={() =>
              waitUntilDropdownCloses(() => header.column.toggleSorting(true))
            }
            data-testid="descending"
            hide={!header.column.getCanSort()}
          >
            <Icon iconName="arrow-down" color="contentSecondary" size={16} />
            Descending
          </DropdownItem>
          <DropdownItem
            onSelect={() =>
              waitUntilDropdownCloses(() => {
                reorderColumns({
                  direction: "left",
                  setColumnOrder,
                  header,
                  updateQueryDescription,
                });
              })
            }
            hide={!leftMovable}
          >
            <Icon iconName="arrow-left" color="contentSecondary" size={16} />{" "}
            Move left
          </DropdownItem>
          <DropdownItem
            onSelect={() =>
              waitUntilDropdownCloses(() => {
                reorderColumns({
                  direction: "right",
                  setColumnOrder,
                  header,
                  updateQueryDescription,
                });
              })
            }
            hide={!rightMovable}
          >
            <Icon iconName="arrow-right" color="contentSecondary" size={16} />
            Move right
          </DropdownItem>
          {!isFirstColumn && (
            <>
              <DropdownSeparator />
              <DropdownItem onSelect={() => onHideColumn()}>
                <Icon iconName="eye-off" color="contentSecondary" size={16} />
                Hide column
              </DropdownItem>
            </>
          )}
        </DropdownContent>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const DropdownContent = styled(Dropdown.Content, {
  display: "flex",
  flexDirection: "column",
  width: "240px",
  backgroundColor: colors.bgUiSurface,
  zIndex: 10,
  padding: "8px 0",
  cursor: "pointer",
  borderRadius: 4,
  border: `1px solid ${colors.borderUi}`,
  boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.18)",
});

const DropdownItem = styled(Dropdown.Item, {
  display: "flex",
  alignItems: "center",
  gap: 4,
  padding: "8px 16px",
  color: colors.contentPrimary,
  fontSize: fontSizes.body3,

  "&:hover": {
    backgroundColor: colors.bgUiContainer,
  },
  "&:focus-visible": {
    outline: "none",
    backgroundColor: colors.bgUiContainer,
  },

  variants: {
    hide: {
      true: {
        display: "none",
      },
    },
  },
});

const DropdownSeparator = styled(Dropdown.Separator, {
  borderTop: `1px solid ${colors.borderUi}`,
  margin: "8px 0",
});

const reorderColumns = <T,>({
  header,
  direction,
  setColumnOrder,
  updateQueryDescription,
}: {
  header: Header<T, unknown>;
  direction: "left" | "right";
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  updateQueryDescription?: (arg: QueryDescriptionReducerAction) => void;
}) => {
  setColumnOrder(currentOrder => {
    const newOrder = [...currentOrder];

    updateQueryDescription?.({
      type: "updateFields",
      data: newOrder,
    });

    const oldIndex = newOrder.indexOf(header.column.id);
    const newIndex = direction === "left" ? oldIndex - 1 : oldIndex + 1;
    newOrder.splice(oldIndex, 1);
    newOrder.splice(newIndex, 0, header.column.id);

    return newOrder;
  });
};
