import React from "react";
import { toLower } from "lodash";
import {
  DateInput,
  Fieldset,
  InputRow,
  Note,
  RadioInput,
  SectionTitle,
  TextInput,
} from "./Common";

export default function FullDataExtractionGroup2({ get, set, errors }) {
  return (
    <>
      <SectionTitle>SECTION A - PROPERTY INFORMATION</SectionTitle>

      <TextInput
        name="buildingOwnerName"
        value={get("buildingOwnerName")}
        onChange={set("buildingOwnerName")}
        error={errors.buildingOwnerName}
        label="Building Owner's Name"
      />

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        error={errors.address}
        label="Street Address"
      />

      <TextInput
        name="propertyDescription"
        value={get("propertyDescription")}
        onChange={set("propertyDescription")}
        error={errors.propertyDescription}
        label="Other Description"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          error={errors.address}
          label="City"
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          error={errors.address}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          error={errors.address}
          label="Zipcode"
        />
      </InputRow>

      <Note>If Zipcode is not present, please leave blank.</Note>

      <SectionTitle>
        SECTION B - FLOOD INSURANCE RATE MAP (FIRM) INFORMATION
      </SectionTitle>
      <TextInput
        name="nfipCommunityNumber"
        value={get("nfipCommunityNumber")}
        onChange={set("nfipCommunityNumber")}
        error={errors.nfipCommunityNumber}
        label="1. Community Number"
      />

      <TextInput
        name="firmMapNumber"
        value={get("firmMapNumber")}
        onChange={set("firmMapNumber")}
        error={errors.firmMapNumber}
        label="2. Panel Number"
      />
      <Note>
        Note: If there are two separate numbers, please transcribe the entire
        field as-is.
      </Note>

      <TextInput
        name="firmSuffix"
        value={get("firmSuffix")}
        onChange={set("firmSuffix")}
        error={errors.firmSuffix}
        label="3. Suffix"
      />

      <DateInput
        name="firmIndexDate"
        value={get("firmIndexDate")}
        onChange={set("firmIndexDate")}
        error={errors.firmIndexDate}
        label="4. Date of FIRM Index"
      />

      <TextInput
        name="floodZone"
        value={get("floodZone")}
        onChange={set("floodZone")}
        error={errors.floodZone}
        label="5. Firm Zone"
      />
      <Note>
        Note: If multiple Flood Zones are present, please enter them separated
        by commas.
      </Note>

      <TextInput
        name="baseFloodElevation"
        value={get("baseFloodElevation")}
        onChange={set("baseFloodElevation")}
        error={errors.baseFloodElevation}
        label="6. Base Flood Elevation(s)"
      />
      <Note>
        Note: if there is a unit of measurement (example: feet) please do not
        include it. However, do include any N/A values. If multiple elevations
        are present, please enter them separated by commas.
      </Note>

      <Fieldset legend="7. Indicate the elevation datum system used on the FIRM for Base Flood Elevations">
        <RadioInput
          name="baseFloodElevationDatum"
          value={get("baseFloodElevationDatum")}
          valueFormatter={toLower}
          onChange={set("baseFloodElevationDatum")}
          error={errors.baseFloodElevationDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <SectionTitle>SECTION C - BUILDING ELEVATION INFORMATION</SectionTitle>

      <TextInput
        name="buildingDiagramNumber"
        value={get("buildingDiagramNumber")}
        onChange={set("buildingDiagramNumber")}
        error={errors.buildingDiagramNumber}
        label="1. The diagram number... that best describes the subject building’s reference level"
      />

      <TextInput
        name="topOfBottomFloor"
        value={get("topOfBottomFloor")}
        onChange={set("topOfBottomFloor")}
        error={errors.topOfBottomFloor}
        label="2. a) FIRM Zones A1-A30... is at an elevation of"
      />
      <Note>
        If there is a unit of measurement (example: feet) please do not include
        it. However, do include any N/A values.
      </Note>

      <TextInput
        name="bottomOfLowestHorizontalStructuralMember"
        value={get("bottomOfLowestHorizontalStructuralMember")}
        onChange={set("bottomOfLowestHorizontalStructuralMember")}
        error={errors.bottomOfLowestHorizontalStructuralMember}
        label="2. b) FIRM Zones V1-V30... is at an elevation of"
      />
      <Note>
        If there is a unit of measurement (example: feet) please do not include
        it. However, do include any N/A values.
      </Note>

      <Fieldset legend="3. Indicate the elevation datum system used in determining the above">
        <RadioInput
          name="elevationDatum"
          value={get("elevationDatum")}
          valueFormatter={toLower}
          onChange={set("elevationDatum")}
          error={errors.elevationDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
            { value: "other", text: "Other" },
          ]}
        />
        <Note>If blank, please skip.</Note>
      </Fieldset>

      <Fieldset legend="5. The reference level elevation is based on">
        <RadioInput
          name="buildingElevationSource"
          value={get("buildingElevationSource")}
          valueFormatter={toLower}
          onChange={set("buildingElevationSource")}
          error={errors.buildingElevationSource}
          options={[
            { value: "finished_construction", text: "Actual Construction" },
            { value: "construction_drawings", text: "Construction Drawings" },
          ]}
        />
      </Fieldset>
      <TextInput
        name="lowestAdjacentGrade"
        value={get("lowestAdjacentGrade")}
        onChange={set("lowestAdjacentGrade")}
        error={errors.lowestAdjacentGrade}
        label="6. The elevation of the lowest grade immediately adjacent"
      />
      <SectionTitle>SECTION E - CERTIFICATION</SectionTitle>

      <DateInput
        label="Date"
        name="issuedAt"
        value={get("issuedAt")}
        onChange={set("issuedAt")}
        error={errors.issuedAt}
      />

      <TextInput
        name="certifierName"
        value={get("certifierName")}
        onChange={set("certifierName")}
        error={errors.certifierName}
        label="Certifier's Name"
      />

      <TextInput
        name="certifierLicenseNumber"
        value={get("certifierLicenseNumber")}
        onChange={set("certifierLicenseNumber")}
        error={errors.certifierLicenseNumber}
        label="License Number"
      />
    </>
  );
}
