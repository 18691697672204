import React, { ChangeEvent, useState } from "react";
import { useModal } from "react-modal-hook";
import { formatDate } from "common/utils/strings";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { EditableMode, formatDatum } from "common-client/utils/firms";
import {
  Datum,
  useUpdatePropertyFirmMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import { Cell, Header, Table } from "./__styles__/EditModal";
import { Property } from "./types";

export interface FIRMEditFormProps {
  property: NonNullable<Property>;
  firm?: FIRMWithWarnings;
  mode: Extract<EditableMode, "floodway" | "datum" | "coastalA" | "panel">;
  onCancel: () => void;
  onSave: () => void;
  refetchQueries?: string[];
}

export const FIRM_UPDATE_REFECTH_QUERIES = [
  "GetPropertyWarnings",
  "getBuildingAttributes",
  "getFloodInfo",
  "getOrCreateProperty",
];

export const useFIRMEditModal = ({
  onSave,
  onCancel,
  ...rest
}: FIRMEditFormProps) => {
  const [showFIRMEditModal, hideFIRMEditModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideFIRMEditModal();
          onCancel();
        }}
      >
        <FIRMEditForm
          onCancel={() => {
            hideFIRMEditModal();
            onCancel();
          }}
          onSave={() => {
            hideFIRMEditModal();
            onSave();
          }}
          {...rest}
        />
      </Modal>
    ),
    [rest, onSave]
  );

  return [showFIRMEditModal, hideFIRMEditModal] as const;
};

type ModeDispatch<Option> = {
  header: (map: string) => string;
  explanatoryText: (map: string) => string;
  columns: Array<{
    label?: string;
    value: string;
    valueForOption: (option: Option) => string;
  }>;
  generateOptions: (firm: FIRMWithWarnings) => Array<Option>;
  findStartingOption: (args: {
    firm: FIRMWithWarnings;
    options: Array<Option>;
  }) => number;
  generateMutationData?: (option: Option) => Option;
};

const floodwayMode: ModeDispatch<Pick<FIRMWithWarnings, "floodway">> = {
  header: map => `${map} Floodway Determination`,
  explanatoryText: map =>
    `Select the correct floodway determination for the structure on the ${map}.`,
  columns: [
    {
      value: "in_floodway",
      valueForOption: ({ floodway }) =>
        floodway ? "In Floodway" : "Out of Floodway",
    },
  ],
  generateOptions: () => [{ floodway: true }, { floodway: false }],
  findStartingOption: ({ firm, options }) => {
    return options.findIndex(({ floodway }) => floodway === firm.floodway);
  },
};

const coastalAMode: ModeDispatch<Pick<FIRMWithWarnings, "coastalA">> = {
  header: map => `${map} Coastal A Determination`,
  explanatoryText: map =>
    `Select the correct Coastal A determination for the structure on the ${map}.`,
  columns: [
    {
      value: "in_coastal_a",
      valueForOption: ({ coastalA }) =>
        coastalA ? "In Coastal A zone" : "Out of Coastal A zone",
    },
  ],
  generateOptions: () => [{ coastalA: true }, { coastalA: false }],
  findStartingOption: ({ firm, options }) => {
    return options.findIndex(({ coastalA }) => coastalA === firm.coastalA);
  },
  generateMutationData: ({ coastalA }) => ({
    coastalA,
  }),
};

const datumMode: ModeDispatch<Pick<FIRMWithWarnings, "datum">> = {
  header: map => `${map} Datum`,
  explanatoryText: map => `Please select the correct ${map} Datum.`,
  columns: [
    {
      value: "datum",
      valueForOption: ({ datum }) =>
        datum === Datum.NAVD_1988
          ? formatDatum(Datum.NAVD_1988)!
          : formatDatum(Datum.NGVD_1929)!,
    },
  ],
  generateOptions: () => [
    { datum: Datum.NAVD_1988 },
    { datum: Datum.NGVD_1929 },
  ],
  findStartingOption: ({ firm, options }) => {
    return options.findIndex(({ datum }) => datum === firm.datum);
  },
  generateMutationData: ({ datum }) => ({ datum }),
};

const panelMode: ModeDispatch<
  Pick<FIRMWithWarnings, "panelNumber" | "effectiveDate">
> = {
  header: map => `${map} Panel Determination`,
  explanatoryText: map =>
    `Select the correct Panel determination for the structure on the ${map}.`,
  columns: [
    {
      label: "FIRM Panel",
      value: "panelNumber",
      valueForOption: ({ panelNumber }) => (panelNumber ? panelNumber : "-"),
    },
    {
      label: "Effective Date",
      value: "effectiveDate",
      valueForOption: ({ effectiveDate }) =>
        effectiveDate ? formatDate(effectiveDate) : "-",
    },
  ],
  generateOptions: firm =>
    firm.panels.map(({ panelNumber, effectiveDate }) => ({
      panelNumber,
      effectiveDate,
    })),
  findStartingOption: ({ firm, options }) => {
    return options.findIndex(
      ({ panelNumber }) => panelNumber === firm.panelNumber
    );
  },
  generateMutationData: ({ panelNumber }) => ({ panelNumber }),
};

const MODES: Record<FIRMEditFormProps["mode"], ModeDispatch<any>> = {
  floodway: floodwayMode,
  coastalA: coastalAMode,
  panel: panelMode,
  datum: datumMode,
};

const FIRMEditForm = ({
  property,
  firm,
  mode,
  onCancel,
  onSave,
  refetchQueries = FIRM_UPDATE_REFECTH_QUERIES,
}: FIRMEditFormProps) => {
  if (!firm) return null;

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const propertyId = property.id;

  let {
    columns,
    generateOptions,
    findStartingOption,
    header,
    explanatoryText,
    generateMutationData,
  } = MODES[mode];

  const options = generateOptions(firm);
  const startingOption = findStartingOption({ firm, options: options as any });
  const [currentOptionIndex, setCurrentOptionIndex] = useState(startingOption);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const optionIndex = parseInt(event.target.value);
    setCurrentOptionIndex(optionIndex);
  };

  const handleComplete = () => {
    onSave();
    addSuccessToast(`${firm.name} data successfully updated`);
  };

  const [updateFIRM, { loading }] = useUpdatePropertyFirmMutation({
    onCompleted: handleComplete,
    refetchQueries,
    awaitRefetchQueries: true,
    onError: () => {
      addErrorToast(
        `There was an issue updating the ${firm.name} data. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleCancel = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onCancel();
  };

  const handleSave = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    // Don't double-submit
    if (loading) return;

    const currentOption = options[currentOptionIndex];
    const data = generateMutationData
      ? generateMutationData(currentOption)
      : currentOption;

    try {
      await updateFIRM({
        variables: {
          propertyId: propertyId,
          firmId: firm.id,
          data,
        },
      });
    } catch (error) {
      return;
    }
  };

  const isTable = !!columns.filter(column => column.label).length;

  return (
    <Container>
      <HeaderSection>
        <h1>{header(firm.name)}</h1>
        <h2>{explanatoryText(firm.name)}</h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <Table>
            <Header showHeader={isTable}>
              <tr>
                <Cell isRadio isTable={isTable} />
                {columns.map(
                  ({ label, value }) =>
                    label && (
                      <Cell isTable={isTable} key={value}>
                        {label}
                      </Cell>
                    )
                )}
              </tr>
            </Header>
            <tbody>
              {options.map((option: string, index: number) => (
                <tr key={index}>
                  <Cell isRadio isTable={isTable}>
                    <input
                      type="radio"
                      name="option"
                      value={index}
                      checked={currentOptionIndex === index}
                      onChange={handleOptionChange}
                      disabled={loading}
                    />
                  </Cell>
                  {columns.map(
                    ({
                      value,
                      valueForOption,
                    }: {
                      value: string;
                      valueForOption: (option: string) => {};
                    }) => (
                      <Cell isTable={isTable} key={value}>
                        {valueForOption(option)}
                      </Cell>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </ContentSection>

        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              onClick={handleCancel}
              disabled={loading}
              size="medium"
            >
              Cancel
            </Button>
            <Button
              styleVariant="primary"
              onClick={handleSave}
              disabled={loading || !options[currentOptionIndex]}
              size="medium"
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default FIRMEditForm;
