import "./__styles__/Icon.scss";
import React from "react";

const Image = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 1V7H19" stroke="#2A70FF" strokeMiterlimit="10" />
    <path
      d="M19 20.25V7L13 1H1V10V20.25"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1 20.5V23.5H19V20.5"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M5.28011 18.1234L4.85264 18.8714H5.71423H13.7142H14.5565L14.1532 18.132L11.7532 13.732L11.3808 13.0493L10.9142 13.6714L8.86014 16.4102L7.66779 15.2179L7.2049 14.755L6.88011 15.3234L5.28011 18.1234Z"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8.31421 13.0715C9.14264 13.0715 9.81421 12.4 9.81421 11.5715C9.81421 10.7431 9.14264 10.0715 8.31421 10.0715C7.48578 10.0715 6.81421 10.7431 6.81421 11.5715C6.81421 12.4 7.48578 13.0715 8.31421 13.0715Z"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default Image;
