import React from "react";
import { WatchQueryFetchPolicy } from "@apollo/client";
import _ from "lodash";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { warningSeveritySortOrder } from "common-client/utils/createAndEditPropertyWarnings";
import { useGetPropertyWarningsQuery } from "../../generated/graphql";
import { spacing } from "../../stitches.config";
import { AuthContext } from "../Authorization/AuthContext";
import { Button } from "../Common/Button";
import { EmptyState } from "../Common/EmptyState";
import { ExpandableList } from "../Common/ExpandableList";
import { Body } from "../Common/Typography";
import { LayerContext } from "../Maps/layers";
import { EditableWarning, WarningListItemIcon } from "../Warnings/WarningList";
import { TitleWrapper } from "./__styles__/Warnings";
import { useUpdateWarningsModal } from "./PropertyOverview/UpdateWarningsModal";

export const SEVERITY_TO_COLOR = {
  low: "contentSecondary",
  medium: "contentAttention",
  high: "contentCritical",
} as const;

interface WarningsContainerProps {
  address?: Maybe<string>;
  propertyId: Maybe<string>;
  publicOnly: boolean;
  canEditWarnings: boolean;
  loading: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}
export default ({
  address,
  propertyId,
  publicOnly,
  canEditWarnings,
  loading: loadingPropertyId,
  fetchPolicy = "cache-and-network",
}: WarningsContainerProps) => {
  const { t } = useTranslation();

  const errorMessage = t("property-warnings-error");
  if (!propertyId && !loadingPropertyId) return <em>{errorMessage}</em>;

  const { updateMap } = React.useContext(LayerContext);
  const { account } = React.useContext(AuthContext);

  const variables = { propertyId: propertyId!, publicOnly };
  const { data, loading, refetch, error } = useGetPropertyWarningsQuery({
    fetchPolicy,
    variables,
    skip: !propertyId,
  });

  const warnings: Array<EditableWarning> =
    account?.accountPropertyWarningDefinitions
      .filter(warning => warning.isActive)
      .map(accountPropertyWarningDefinition => {
        return {
          ...accountPropertyWarningDefinition,
          applies:
            data?.getProperty?.warnings.find(
              warning =>
                warning.accountPropertyWarningDefinitionId ===
                accountPropertyWarningDefinition.id
            )?.applies ?? false,
        };
      }) ?? [];

  const [showUpdateWarningsModal, hideUpdateWarningsModal] =
    useUpdateWarningsModal({
      onCancel: () => {
        hideUpdateWarningsModal();
      },
      onSubmit: async () => {
        updateMap();
        await refetch();
      },
      address,
      propertyId,
      warnings,
    });

  if (loading || loadingPropertyId) return <em>{t("common-loading")}</em>;
  if (error) return <em>{errorMessage}</em>;

  const applicableWarnings = sortBy(
    data?.getProperty?.warnings.filter(propertyWarning =>
      warnings.find(
        accountPropertyWarningDefinition =>
          propertyWarning.accountPropertyWarningDefinitionId ===
          accountPropertyWarningDefinition.id
      )
    ),
    ({ severity }) => warningSeveritySortOrder.indexOf(severity)
  ).filter(warning => warning.applies);

  return (
    <>
      <TitleWrapper>
        <Body size="large" type="emphasis">
          {t("property-warnings-header")}
        </Body>

        {canEditWarnings && propertyId && (
          <Button
            styleVariant="outlineLight"
            data-testid="editWarnings"
            onClick={() => showUpdateWarningsModal()}
            size="small"
            style={{ minWidth: "unset", whiteSpace: "nowrap" }}
          >
            Edit warnings
          </Button>
        )}
      </TitleWrapper>
      {applicableWarnings.length > 0 ? (
        <ExpandableList
          extension={16}
          list={applicableWarnings.map((warning, idx) => {
            const showDivider = idx !== applicableWarnings.length - 1;
            return {
              title: warning.title,
              expandableContent: warning.message,
              leftContent: <WarningListItemIcon severity={warning.severity} />,
              showDivider,
            };
          })}
        />
      ) : (
        <>
          <EmptyState
            compact
            title={t("property-warnings-empty-state")}
            paddingTop={0}
            paddingBottom={spacing.l.value}
          />
        </>
      )}
    </>
  );
};
