import React, { ComponentProps, useContext } from "react";
import { SortingState } from "@tanstack/react-table";
import { useHistory, useLocation } from "react-router";
import { FlexColumn } from "web/components/Common/__styles__/Layout";
import { FullWidthTable } from "web/components/Common/FullWidthTable/FullWidthTable";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import { getUserErrors } from "common-client/utils/apollo";
import {
  SortDir,
  useGetRepetitiveLossesLazyQuery,
  useReGeocodeFailedRepetitiveLossesMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../../../../hooks/useTableDisplayConfig";
import { colors } from "../../../../../stitches.config";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { withAuthorization } from "../../../../Authorization/withAuthorization";
import { Button } from "../../../../Common/Button";
import { FILTER_TYPE } from "../../../../Common/Filters";
import { sanityCheckLocalColumnOrder } from "../../../../Common/FullWidthTable/utils";
import HeaderNav from "../../../../Common/HeaderNav";
import {
  useLegacyTableStateInURL,
  useManualPaginationConfig,
} from "../../../../Common/Tables/hooks";
import { repetitiveLossColumns as columns } from "./utils";

type LocationState = {
  prevLocation: string | undefined;
};

const filterConfig = [
  { type: FILTER_TYPE.CHECKBOX, label: "Has issues", key: "hasIssues" },
];

const RepetitiveLossTable = ({}) => {
  const history = useHistory();
  const [
    queryRepetitiveLosses,
    { previousData, data: currentData, error, loading },
  ] = useGetRepetitiveLossesLazyQuery({ fetchPolicy: "no-cache" });

  const [reGeocodeRepetitiveLosses] =
    useReGeocodeFailedRepetitiveLossesMutation();

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const { user, admin } = useContext(AuthContext);

  const location = useLocation<LocationState | undefined>();
  const prevLocation =
    location.state?.prevLocation ?? "/settings/data-imports/repetitive-loss";

  const [initialTableState, setTableStateInURL] = useLegacyTableStateInURL({
    defaultSortingParams: [{ id: "rLNumber", desc: false }],
    filterConfig,
  });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages:
      currentData?.account?.repetitiveLosses.pageInfo.totalPages,
    previousTotalPages:
      previousData?.account?.repetitiveLosses.pageInfo.totalPages,
  });

  const loadingDetails = {
    loading: loading,
    loadingText: "Loading repetitive losses",
    noDataText: "No repetitive losses found",
  };

  const pathname = location.pathname;

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: columns.map(column => column.id!),
        columnSizing: {},
        sorting: [],
      },
    });

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: columns.map(column => column.id!),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = updatedColumnOrder
    ? updatedColumnOrder.map(id => columns.find(c => c.id === id)!)
    : localColumnOrder.map((id: string) => columns.find(c => c.id === id)!);

  const search = ({
    filters,
    sort,
    page,
  }: {
    filters: Record<string, unknown>;
    sort: SortingState;
    page: number;
  }) => {
    const sortParams = sort.map(s => ({
      sortBy: s.id,
      sortDir: s.desc ? SortDir.DESC : SortDir.ASC,
    }));
    void queryRepetitiveLosses({
      variables: { ...filters, sort: sortParams, page },
    });
  };

  let actions = null;
  let issueId = currentData?.account?.repetitiveLossIssue?.id;
  if (issueId) {
    actions = (
      <>
        <Button
          size="small"
          styleVariant="alert"
          onClick={() =>
            reGeocodeRepetitiveLosses({
              onCompleted: () => {
                addSuccessToast(
                  "Scheduled re-geocoding - an email will be sent when it's complete"
                );
              },
              onError: error => {
                const graphqlError = getUserErrors(error);
                if (graphqlError) {
                  addErrorToast(graphqlError);
                } else {
                  addErrorToast(
                    "Failed to schedule re-geocoding - please reach out to engineering"
                  );
                }
              },
            })
          }
        >
          Geocode via parcel number
        </Button>
        <Button
          size="small"
          styleVariant="primary"
          onClick={() =>
            history.push({
              pathname: buildLink("geocodeRepetitiveLoss", {
                id: issueId,
              }),
              state: {
                prevLocation: {
                  pathname: location.pathname,
                  search: location.search,
                },
              },
            })
          }
        >
          Review issues
        </Button>
      </>
    );
  }

  if (error) {
    return <div />;
  }

  return (
    <>
      <HeaderNav
        subtitle={"Review imported data"}
        prevLocation={prevLocation}
        style={{ zIndex: 100 }}
      />
      <FlexColumn
        css={{
          //64 px is the height of the header nav
          envRelativeHeight: "calc(100vh - 64px)",
          bottom: "0px",
          position: "absolute",
          backgroundColor: colors.bgUiSurface.toString(),
        }}
      >
        <FullWidthTable
          actions={actions}
          columns={initialColumns}
          currentData={currentData?.account?.repetitiveLosses.data ?? []}
          filterable={{ filterConfigurations: filterConfig, search }}
          loadingDetails={loadingDetails}
          manualPaginationConfig={manualPaginationConfig}
          previousData={previousData?.account?.repetitiveLosses.data}
          prevLocation={prevLocation}
          tableStyleDetails={{
            hasHighlights: true,
            hasRowActions: true,
            hasStickyColumn: true,
            isScrollable: true,
          }}
          initialState={initialTableState}
          setTableStateInURL={setTableStateInURL}
          withoutSideNav={true}
          interactiveHeaders={false}
          refetchSavedViews={() => {}}
        />
      </FlexColumn>
    </>
  );
};

export default (props: ComponentProps<typeof RepetitiveLossTable>) => {
  return withAuthorization(RepetitiveLossTable, {
    resource: RESOURCE_NAME.REPETITIVE_LOSS,
    permission: "list",
  })(props);
};
