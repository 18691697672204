import React from "react";
import { omit } from "lodash";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { MIME_TYPE } from "common/constants";
import { HeaderRowComponent } from "../../../../FileUploads";
import { SingleFileUpload, Text } from "../../../../Inputs/react-hook-form";
import {
  ConfigStageFields,
  ConfigStageFieldWrapper,
  ConfigStageWrapper,
  FormReminder,
} from "./__styles__/ParcelImportWizard";
import { GpkgImportWizardFormData } from "./types";

const GpkgParcelImportConfigStage = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<GpkgImportWizardFormData>();

  return (
    <ConfigStageWrapper>
      <FormReminder>All fields are required</FormReminder>
      <ConfigStageFields>
        <ConfigStageFieldWrapper>
          <Text
            label="Name"
            error={errors.name?.message}
            required
            {...register("name", {
              required: "Name is required",
            })}
          />
        </ConfigStageFieldWrapper>
        <ConfigStageFieldWrapper>
          <SingleFileUpload<any, "geopackageUpload">
            {...omit(register("geopackageUpload"), "ref")}
            control={control}
            useDropzone={useDropzone}
            label={"Import File"}
            description={"File must be a geopackage (.gpkg) file"}
            required
            allowedMimeTypes={[MIME_TYPE.GPKG]}
            fileHeader={
              <HeaderRowComponent hasGreenCheckmark={true}>
                <div tabIndex={0}>File name</div>
              </HeaderRowComponent>
            }
          />
        </ConfigStageFieldWrapper>
      </ConfigStageFields>
    </ConfigStageWrapper>
  );
};

export default GpkgParcelImportConfigStage;
