import React, { useContext } from "react";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import { useGetLogsForMapQuery } from "../../generated/graphql";
import { getCurrentURL } from "../../utils/url";
import { AuthContext } from "../Authorization/AuthContext";
import { Button } from "../Common/Button";
import { EmptyState } from "../Common/EmptyState";
import { ActionButton } from "../Logs/ActionButton";
import TypeBadge from "../Logs/TypeBadge";
import { AddressPanelTabProps } from ".";
import { Table } from "./__styles__/Logs";
import { AddressPanelContext } from "./AddressPanelContext";
import MissingProperty from "./MissingProperty";
import { Layout } from "./Records";

const Logs = ({ address, latitude, longitude }: AddressPanelTabProps) => {
  const history = useHistory();
  const location = useLocation();
  const { authorized } = useContext(AuthContext);
  const { property, loadingProperty } = useContext(AddressPanelContext);
  const propertyId = property?.id;
  const canCreateLog = authorized({
    resource: RESOURCE_NAME.INFORMATION_REQUEST,
    permission: "private_create",
  });

  const { data, loading, error, refetch } = useGetLogsForMapQuery({
    variables: { propertyId },
    fetchPolicy: "no-cache",
    skip: loadingProperty || !propertyId,
  });

  const goToNew = () =>
    history.push({
      pathname: buildLink("createLog", { propertyId: propertyId! }),
      state: {
        prevLocation: getCurrentURL(location),
      },
    });

  const title = "Resident logs";
  const actionButton = (
    <Button
      onClick={goToNew}
      size="small"
      disabled={!canCreateLog || loading || loadingProperty}
      data-testid="addNewLog"
      leftIconName="plus"
      styleVariant="primary"
    >
      Add new
    </Button>
  );

  if (loading || loadingProperty) {
    return (
      <Layout title={title} actionButton={actionButton}>
        <em>Loading...</em>
      </Layout>
    );
  }

  if (!propertyId || !data?.getProperty) {
    return (
      <MissingProperty
        title="No logs available"
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  if (error) {
    return (
      <Layout title={title} actionButton={actionButton}>
        <span>Error loading logs</span>
      </Layout>
    );
  }

  const requests = data.getProperty.informationRequests;

  if (!requests.length)
    return (
      <React.Fragment>
        <Layout title={title} actionButton={actionButton}>
          <EmptyState
            icon="add-file"
            title="No logs created"
            body="Add an entry to get started."
          />
        </Layout>
      </React.Fragment>
    );

  return (
    <Layout title={title} actionButton={actionButton}>
      <Table>
        <tbody>
          {requests.map(request => (
            <tr key={request.id}>
              <td>{moment(request.submittedAt).format("l")}</td>
              <td>
                <TypeBadge type={request.type} />
              </td>
              <td>
                <ActionButton
                  infoRequest={request}
                  isDetailView={false}
                  prevLocation={getCurrentURL(location)}
                  onUpdate={refetch}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Layout>
  );
};

export default Logs;
