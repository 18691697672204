import React from "react";
import { isNil } from "lodash";
import { Control, FieldValues, useFormContext } from "react-hook-form";
import { EFFECTIVE_LIKE_FIRM_TYPES, FIRM_TYPES } from "common/constants";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
  SecondaryButtons,
} from "../../../../../Common/__styles__/Modal";
import { Button } from "../../../../../Common/Button";
import { Datepicker, Select } from "../../../../../Inputs/react-hook-form";

export interface RevalidateEcsFormStageProps {
  firmType: FIRM_TYPES;
  disabled?: boolean;
  loading: boolean;
  onBack?: () => void;
  onCancel: () => void;
  onImport: () => void;
}

export type RevalidateEcsStageType = {
  revalidateEcs: boolean;
  issueDate?: Maybe<Date>;
};

export const RevalidateEcsStage = ({
  loading,
  firmType,
  onBack,
  onCancel,
  onImport,
}: RevalidateEcsFormStageProps) => {
  const { control, trigger, formState, watch, getValues } =
    useFormContext<RevalidateEcsStageType>();
  const revalidateEcs = watch("revalidateEcs");

  const isEffectiveLikeFirm = EFFECTIVE_LIKE_FIRM_TYPES.includes(firmType);
  const issuedDateRequired = revalidateEcs === true && !isEffectiveLikeFirm;

  const issuedAtLabel = isEffectiveLikeFirm
    ? "If so, you may optionally provide the date from which we should recompute."
    : "If so, you must provide the date from which we should recompute.";

  const issuedAtDescription = isEffectiveLikeFirm
    ? " If you do not provide a date but request recomputation, the oldest FIRM Panel effective date for this FIRM will be used."
    : null;

  return (
    <Container overflows width="narrow">
      <HeaderSection>
        <h1>Start import</h1>
      </HeaderSection>
      <FormSection overflows>
        <ContentSection overflows>
          <InputRow>
            <Select
              control={control as unknown as Control<FieldValues, any>}
              name="revalidateEcs"
              rules={{
                required: isNil(revalidateEcs) ? "Required field" : undefined,
              }}
              label="Do EC errors related to FIRM data need to be recomputed?"
              description="If errors are recomputed, all existing ignored errors will be removed."
              onChange={_ => trigger("issueDate")}
              required={true}
              options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" },
              ]}
            />
          </InputRow>
          <InputRow>
            <Datepicker
              control={control as unknown as Control<FieldValues, any>}
              name={"issueDate"}
              rules={{
                validate: value =>
                  getValues("revalidateEcs") && !value && !isEffectiveLikeFirm
                    ? "Required field"
                    : undefined,
              }}
              onChange={_ => trigger("issueDate")}
              label={issuedAtLabel}
              required={issuedDateRequired}
              description={issuedAtDescription}
            />
          </InputRow>
        </ContentSection>
      </FormSection>
      <ButtonSection>
        {onBack && (
          <SecondaryButtons>
            <Button
              styleVariant="ghost"
              onClick={onBack}
              size="medium"
              disabled={loading}
            >
              Back
            </Button>
          </SecondaryButtons>
        )}
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            onClick={onImport}
            size="medium"
            disabled={!formState.isValid}
            loading={loading}
          >
            Import
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default RevalidateEcsStage;
