import React, { useState } from "react";
import { NODE_ENV } from "common/env";
import { buildFullLink } from "common/routing";
import { getClientAppPrefix, getPublicPortalPrefix } from "common/utils/url";
import { useAccountsQuery } from "../../../generated/graphql";
import { AccountLogo } from "../../Common/AccountLogo";
import { Button } from "../../Common/Button";
import { Checkbox, Text } from "../../Inputs";
import {
  Account,
  AccountLinks,
  AccountName,
  AccountsGrid,
  FilterContainer,
  SearchBar,
  SearchContainer,
  SearchTitle,
} from "./__styles__/AccountSelector";

export default function AccountSelector() {
  const isProduction = NODE_ENV === "production";
  const { error, data, loading } = useAccountsQuery({
    variables: { fetchFIRMs: !isProduction, fetchParcels: !isProduction },
  });
  const [filters, setFilters] = useState({
    searchTerm: "",
    hasParcels: false,
    hasFloodzones: false,
  });

  if (loading || !data)
    return (
      <SearchContainer>
        <AccountsGrid>Loading...</AccountsGrid>
      </SearchContainer>
    );

  if (error)
    return (
      <SearchContainer>
        <AccountsGrid>Error fetching accounts</AccountsGrid>
      </SearchContainer>
    );

  let accounts = data.accounts
    .filter(account =>
      account.name.toLowerCase().includes(filters.searchTerm.toLowerCase())
    )
    .filter(({ hasParcels }) => !filters.hasParcels || hasParcels)
    .filter(({ firms }) => {
      const hasFloodzones = !!firms?.filter(
        firm => firm.components.floodzones.hasGeometries
      ).length;
      return hasFloodzones || !filters.hasFloodzones;
    });

  return (
    <SearchContainer>
      <SearchBar>
        <SearchTitle>Select an account</SearchTitle>
        <Text
          name="Search"
          value={filters.searchTerm}
          onChange={searchTerm => setFilters({ ...filters, searchTerm })}
          placeholder="Search for an account"
          size="medium"
        />
        {!isProduction && (
          <FilterContainer>
            <Checkbox
              name="hasParcels"
              value={filters.hasParcels}
              onChange={hasParcels => setFilters({ ...filters, hasParcels })}
              label="has parcels"
            />
            <Checkbox
              name="hasFloodzones"
              value={filters.hasFloodzones}
              onChange={hasFloodzones =>
                setFilters({ ...filters, hasFloodzones })
              }
              label="has floodzones"
            />
          </FilterContainer>
        )}
      </SearchBar>
      <AccountsGrid>
        {accounts.map(account => {
          const portalUrl = buildFullLink("guestHome", {
            prefix: getPublicPortalPrefix(account.subdomain),
            params: {},
          });

          const prefix = getClientAppPrefix(account.subdomain);

          const clientAppUrl = buildFullLink("map", {
            prefix,
            params: {},
          });

          return (
            <Account
              key={account.id}
              style={{ backgroundColor: account.publicPortal.color }}
            >
              {account.logoUrl && (
                <AccountLogo
                  size={28}
                  style={{ marginRight: "8px" }}
                  account={account}
                />
              )}
              <AccountName>{account.name}</AccountName>
              <AccountLinks className="links">
                {account.publicPortal.enabled && (
                  <Button
                    styleVariant="ghostWhite"
                    size="small"
                    onClick={() => window.open(portalUrl, "_blank")}
                    style={{ textDecoration: "underline" }}
                  >
                    Public portal
                  </Button>
                )}

                <Button
                  styleVariant="outlineLight"
                  size="small"
                  onClick={() => {
                    window.location.href = clientAppUrl;
                  }}
                >
                  Client app
                </Button>
              </AccountLinks>
            </Account>
          );
        })}
      </AccountsGrid>
    </SearchContainer>
  );
}
