import React, { useContext } from "react";
import { flatMap } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { truncateStringDecimal } from "common/utils/strings";
import { generateDFETooltip } from "common-client/utils/firms";
import {
  DesignFloodElevationType,
  useUpdateDfeMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { track } from "../../../../utils/tracking";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import { ComplexRadio, Number } from "../../../Inputs";
import { DoublePrecisionNumberInput } from "../../../Inputs/Number";
import { Property } from "./types";

export type DfeMutationFormStructure = {
  type?: Maybe<DesignFloodElevationType>;
  defaultDFE?: Maybe<string>;
  customDFE?: Maybe<string>;
};

export interface useDfeEditModalProps {
  onCancel?: () => void;
  onUpdate?: () => void;
  dfe?: Maybe<{ type?: DesignFloodElevationType; stringValue?: Maybe<string> }>;
  property: Property;
}
export const useDesignFloodElevationEditModal = ({
  onUpdate = () => {},
  onCancel = () => {},
  dfe = { type: undefined, stringValue: undefined },
  property,
  ...props
}: useDfeEditModalProps) => {
  const defaultValues = {
    type: dfe?.type,
    defaultDFE:
      dfe?.type === DesignFloodElevationType.DEFAULT ? dfe.stringValue : null,
    customDFE:
      dfe?.type === DesignFloodElevationType.CUSTOM ? dfe.stringValue : null,
  };

  const [showDfeEditModal, hideDfeEditModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideDfeEditModal();
          onCancel();
        }}
      >
        <DfeEditForm
          defaultValues={defaultValues}
          onCancel={() => {
            hideDfeEditModal();
            onCancel();
          }}
          onUpdate={() => {
            hideDfeEditModal();
            onUpdate();
          }}
          property={property}
        />
      </Modal>
    ),
    [props, onUpdate]
  );
  return [showDfeEditModal, hideDfeEditModal] as const;
};

export const DfeEditForm = ({
  defaultValues,
  onUpdate,
  onCancel,
  property,
}: {
  defaultValues: DfeMutationFormStructure;
  onUpdate: () => void;
  onCancel: () => void;
  property: Property;
}) => {
  const { account } = useContext(AuthContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const {
    getValues,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm<DfeMutationFormStructure>({
    defaultValues,
  });

  const [updateDFE, { loading }] = useUpdateDfeMutation({
    refetchQueries: ["getFloodInfo", "getOrCreateProperty"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      track("Updated design flood elevation", {
        address: property?.fullAddress,
      });
      addSuccessToast(`Your Design Flood Elevation was saved`);
      onUpdate();
    },
    onError: () => {
      addErrorToast(
        `There was an error updating this Design Flood Elevation. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const onSave = async () => {
    const type = getValues("type")!;
    const stringValue =
      type === DesignFloodElevationType.CUSTOM
        ? truncateStringDecimal({ value: getValues("customDFE") })
        : type === DesignFloodElevationType.DEFAULT
        ? getValues("defaultDFE")
        : null;

    await updateDFE({
      variables: {
        data: {
          propertyId: property?.id!,
          type,
          stringValue,
        },
      },
    });
  };

  const propertyHasDefaultDFE =
    !!property?.designFloodElevation.calculated?.value;

  const defaultDFE = property?.designFloodElevation.calculated?.value;

  return (
    <Container width="narrow">
      <HeaderSection>
        <h1>Design Flood Elevation</h1>
        <h2>Select the final design flood elevation for this property</h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <>
                {propertyHasDefaultDFE && (
                  <div
                    onClick={() => {
                      setValue("type", DesignFloodElevationType.DEFAULT, {
                        shouldDirty: true,
                      });
                      setValue("customDFE", null);
                      setValue("defaultDFE", defaultDFE!);
                    }}
                    data-testid="default-dfe-radio"
                  >
                    <ComplexRadio
                      label={"Calculated Design Flood Elevation"}
                      checked={
                        getValues("type") === DesignFloodElevationType.DEFAULT
                      }
                      onChange={() => {}}
                      value={DesignFloodElevationType.DEFAULT}
                      name="DesignFloodElevationType"
                      description={generateDFETooltip(
                        property.designFloodElevation.calculated!.rule!,
                        flatMap(
                          account?.propertyInformationSections.map(
                            section => section.accountPropertyAttributes
                          )
                        )
                      )}
                    >
                      <div>
                        <Number
                          name="defaultDFE"
                          size="small"
                          disabled={true}
                          onChange={() => {}}
                          value={+defaultDFE!}
                        />
                      </div>
                    </ComplexRadio>
                  </div>
                )}
                <ComplexRadio
                  label={"Custom Design Flood Elevation"}
                  value={DesignFloodElevationType.CUSTOM}
                  checked={
                    getValues("type") === DesignFloodElevationType.CUSTOM
                  }
                  onChange={field.onChange}
                  name="DesignFloodElevationType"
                  description={
                    "Set a custom Design Flood Elevation for this property. It will get rounded to the nearest tenth of a foot."
                  }
                >
                  <div>
                    <DoublePrecisionNumberInput
                      name="customDFE"
                      control={control}
                      error={errors.customDFE?.message}
                      onClick={() =>
                        setValue("type", DesignFloodElevationType.CUSTOM)
                      }
                    />
                  </div>
                </ComplexRadio>
              </>
            )}
          />
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            size="medium"
            type="submit"
            disabled={!isDirty || loading}
            onClick={onSave}
          >
            Save
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
