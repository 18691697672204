import React from "react";
import { Icon } from "../../../../../../Common/Icons/LucideIcons";
import { Text as TextInput } from "../../../../../../Inputs";
import {
  ActionIconContainer,
  InputContainer,
  LabelContainer,
  LVLabel,
  OptionsContainer,
} from "./__styles__/WidgetSettings";

const OptionsInput = ({
  dropdownOptions,
  setDropdownOption,
  removeDropdownOption,
  disabled = false,
}: {
  dropdownOptions: Array<string>;
  setDropdownOption: (index: number, value: string) => void;
  removeDropdownOption: (index: number) => void;
  disabled?: boolean;
}) => {
  return (
    <div>
      <LabelContainer>
        <LVLabel text={"Tag name"} />
      </LabelContainer>
      {dropdownOptions.map((option, index) => (
        <OptionsContainer key={`option.${index}`}>
          <InputContainer labelOnly>
            <TextInput
              onChange={value => setDropdownOption(index, value)}
              name={`label.${index}`}
              value={option}
              disabled={disabled}
            />
          </InputContainer>
          <ActionIconContainer
            hidden={dropdownOptions.length === 1 || disabled}
          >
            <Icon
              iconName="x"
              color="contentSecondary"
              size="18"
              onClick={() => removeDropdownOption(index)}
            />
          </ActionIconContainer>
        </OptionsContainer>
      ))}
    </div>
  );
};

export default OptionsInput;
