import React from "react";
import { Account } from "../../generated/graphql";
import {
  AccountLogo as StyledAccountLogo,
  AccountTitle,
} from "./__styles__/AccountLogo";

export const AccountLogo = ({
  account,
  ...props
}: { account: Pick<Account, "logoUrl" | "name"> } & Parameters<
  typeof StyledAccountLogo
>[0]) => {
  const { logoUrl, name } = account;

  return <StyledAccountLogo {...props} src={logoUrl} alt={`${name} Logo`} />;
};

export const AccountLogoOrFallbackIcon = ({
  account,
  ...props
}: { account: Pick<Account, "name" | "logoUrl"> } & Parameters<
  typeof StyledAccountLogo
>[0]) => {
  const { name, logoUrl } = account;

  return (
    <>
      {logoUrl ? (
        <AccountLogo {...props} account={account} />
      ) : (
        <AccountTitle {...props}>{name[0]}</AccountTitle>
      )}
    </>
  );
};
