import React from "react";
import { useTranslation } from "react-i18next";
import { Category } from "common-client/utils/Search";
import { SearchCategory } from "../../generated/graphql";
import { Icon } from "../Common/Icons/LucideIcons";
import { Body } from "../Common/Typography";
import { SEARCH_TO_TRANSLATION_KEY } from ".";
import { CategoryOption, Dropdown } from "./__styles__/Dropdown";

export const CategoryDropdown = ({
  categories,
  selectedCategory,
  onClear,
  reset,
  setSelectedCategory,
}: {
  categories: Array<Category>;
  selectedCategory: Category;
  onClear: () => void;
  reset: () => void;
  setSelectedCategory: (category: Category) => void;
}) => {
  const { t } = useTranslation();
  const onCategorySelect = (category: Category) => {
    setSelectedCategory(category);
    reset();
    onClear();
  };

  return (
    <Dropdown data-testid="search-dropdown">
      {categories.map((category, index) => {
        const categoryCopy =
          category.value === SearchCategory.PARCEL_ID
            ? category.label
            : t(SEARCH_TO_TRANSLATION_KEY[category.value]);

        return (
          <CategoryOption
            selected={category.value === selectedCategory.value}
            key={index}
            onClick={() => onCategorySelect(category)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onCategorySelect(category);
              }
            }}
            tabIndex={0}
          >
            <Icon iconName="search" color="contentSecondary" size="16" />
            <Body size="large" type="regular">
              {categoryCopy}
            </Body>
          </CategoryOption>
        );
      })}
    </Dropdown>
  );
};
