import React from "react";
import FullPageWizardLayout, {
  StageConfig,
} from "../../../../Common/FullPageWizardLayout";
import ImportMappingStage from "./ImportMappingStage";
import { PARCEL_IMPORT_WIZARD_STAGES } from "./types";

interface ParcelImportWizardProps {
  firstStageRender: () => JSX.Element;
  isStageComplete: () => boolean;
  loading: boolean;
  skipFirstStage: boolean;
  subtitle: string;
  onFirstStageNext: (changeStage: () => void) => void;
  onLastStageFinish: () => void;
}

const ParcelImportWizard = ({
  firstStageRender,
  isStageComplete,
  loading,
  onFirstStageNext,
  onLastStageFinish,
  skipFirstStage = false,
  subtitle,
}: ParcelImportWizardProps) => {
  const stageConfigs: StageConfig<PARCEL_IMPORT_WIZARD_STAGES>[] = [
    {
      key: PARCEL_IMPORT_WIZARD_STAGES.SOURCE_CONFIG,
      isComplete: isStageComplete,
      onNext: onFirstStageNext,
      primaryButtonText: "Next",
      render: firstStageRender,
    },
    {
      key: PARCEL_IMPORT_WIZARD_STAGES.SOURCE_MAPPING,
      isComplete: isStageComplete,
      onFinish: onLastStageFinish,
      primaryButtonText: "Finish",
      render: () => <ImportMappingStage />,
    },
  ];

  return (
    <FullPageWizardLayout
      initialStage={
        skipFirstStage
          ? PARCEL_IMPORT_WIZARD_STAGES.SOURCE_MAPPING
          : PARCEL_IMPORT_WIZARD_STAGES.SOURCE_CONFIG
      }
      loading={loading}
      onPrimaryButtonClick={() => {}}
      previousLocation="/settings/data-imports/parcels"
      subtitle={subtitle}
      stageConfigs={stageConfigs}
    />
  );
};

export default ParcelImportWizard;
