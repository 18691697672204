import React from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";

export const id = "contour";
export const group = "topology";

class MapLayer extends React.PureComponent {
  render() {
    const { isLayerVisible } = this.context;

    return (
      <Source id={id} type="vector" url="mapbox://mapbox.mapbox-terrain-v2">
        <Layer
          id={`${id}-lines`}
          type="line"
          source-layer="contour"
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "hsl(0, 0%, 100%)",
            "line-width": 1,
            "line-dasharray": [2, 2],
          }}
        ></Layer>
        <Layer
          id={`${id}-labels`}
          type="symbol"
          source-layer="contour"
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
            "text-size": 10,
            "text-ignore-placement": true,
            "text-max-angle": 45,
            "text-font": ["Open Sans Bold", "Arial Unicode MS Regular"],
            "symbol-placement": "line",
            "text-field": ["concat", ["to-string", ["get", "ele"]], "m"],
            "text-letter-spacing": 0.2,
            "text-max-width": 16,
          }}
          paint={{
            "text-color": "hsl(0, 0%, 14%)",
            "text-halo-color": "hsl(0, 0%, 100%)",
            "text-halo-blur": 0,
            "text-halo-width": 0.9,
            "text-opacity": ["interpolate", ["linear"], ["zoom"], 13, 0, 14, 1],
          }}
        />
      </Source>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
