import React, { useContext, useEffect, useRef, useState } from "react";
import { formatCoordinates } from "common/utils/coordinates";
import {
  ObjectInfoContainer,
  ObjectInfoRow,
} from "../AddressPanel/Header/__styles__/Header";
import { CoordinatesContainer } from "../AddressPanel/Header/__styles__/Header";
import { Icon as LucideIcon } from "../Common/Icons/LucideIcons";
import { Body, Title } from "../Common/Typography";
import { LayerContext } from "../Maps/layers";
import { HeaderContainer } from "./__styles__/Header";

type ObjectInfoHeaderProps = {
  loading: boolean;
  objectType: string;
  objectName: string;
  onClose: () => void;
};

const LOADING_TEXT = "Loading...";

const Header = ({
  loading,
  objectType,
  objectName,
  onClose,
}: ObjectInfoHeaderProps) => {
  const { pointInGeometry } = useContext(LayerContext);
  const longitude = pointInGeometry?.longitude ?? null;
  const latitude = pointInGeometry?.latitude ?? null;
  const displayCoordinates = longitude && latitude;

  const objectInfoRef = useRef<HTMLDivElement>(null);
  const coordinatesRef = useRef<HTMLDivElement>(null);
  const [coordinatesWrapped, setCoordinatesWrapped] = useState(false);

  useEffect(() => {
    const checkWrap = () => {
      if (objectInfoRef.current && coordinatesRef.current) {
        setCoordinatesWrapped(
          objectInfoRef.current.offsetTop !== coordinatesRef.current.offsetTop
        );
      }
    };

    checkWrap();
    window.addEventListener("resize", checkWrap);
    return () => window.removeEventListener("resize", checkWrap);
  }, [coordinatesRef.current, objectInfoRef.current]);

  return (
    <HeaderContainer>
      {loading ? (
        <div>
          <em>{LOADING_TEXT}.</em>
        </div>
      ) : (
        <>
          <ObjectInfoRow>
            <Body size="small" type="regular" color="contentSecondaryDark">
              <ObjectInfoContainer ref={objectInfoRef}>
                {objectType}
                <CoordinatesContainer ref={coordinatesRef} displayCoordinates>
                  {displayCoordinates && (
                    <>
                      {!coordinatesWrapped && `• `}
                      {`Coordinates: ${formatCoordinates({
                        latitude,
                        longitude,
                      })}`}
                    </>
                  )}
                </CoordinatesContainer>
              </ObjectInfoContainer>
            </Body>
            <LucideIcon
              iconName="x"
              color="contentSecondaryDark"
              size={18}
              onClick={onClose}
              data-testid="close-icon"
              style={{ flexShrink: 0, cursor: "pointer" }}
            />
          </ObjectInfoRow>
          <Title size="default" type="semiBold" color="contentPrimaryDark">
            {objectName}
          </Title>
        </>
      )}
    </HeaderContainer>
  );
};

export default Header;
