import React from "react";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";
import { OperationsMenuButton } from "./Button";
import MenuOption from "./MenuOption";

const BulkPropertyActionMenu = () => {
  const history = useHistory();

  return (
    <MenuOption
      title="Bulk property actions"
      helperText="Upload a CSV and dispatch a bulk property operation using the CSV as input data."
      buttons={
        <>
          <OperationsMenuButton
            icon="plus"
            text="Create properties"
            onClick={() =>
              history.push({
                pathname: buildLink("dispatchBulkPropertyCreation"),
              })
            }
          />
          <OperationsMenuButton
            icon="plus"
            text="Create records"
            onClick={() =>
              history.push({
                pathname: buildLink("dispatchBulkSubmissionCreation"),
              })
            }
          />
        </>
      }
    />
  );
};

export default BulkPropertyActionMenu;
