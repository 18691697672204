import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { TagSelect as TagSelectNonForm } from "web/components/Inputs";
import { SubmissionIntakeSource } from "../../../../../../generated/graphql";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { Title } from "../../../../../Common/Typography";
import { Label } from "../../../../../Inputs";
import { Select, Text, Textarea } from "../../../../../Inputs/react-hook-form";
import { TagSelect } from "../../../../../Inputs/react-hook-form";
import Wrapper from "../../../../../Inputs/Wrapper";
import { SUBMISSION_CATEGORY_OPTIONS } from "../../../../../Submissions/utils";
import {
  creatorTypeOptions,
  DEFAULT_POSTAMBLE,
  DEFAULT_PREAMBLE,
  editorTypeOptions,
  viewerTypeOptions,
} from "../constants";
import { SubmissionsBuilderContextInstance } from "../context";
import { SubmissionsBuilderFormDataStructure } from "../types";
import {
  creatorTypeToSubmissionIntakeSource,
  submissionHiddenFromPublicByDefaultToViewerType,
  submissionIntakeSourceToCreatorType,
  useIsSisdAndHidden,
  viewerTypeToSubmissionHiddenFromPublicByDefault,
} from "../utils";
import {
  FieldWrapper,
  FormSection,
  FormSectionTitleWrapper,
} from "./__styles__/FormSettingsTab";
import AttachmentSelect from "./AttachmentSelect";

const FormSettingsTab = () => {
  const { account } = useContext(AuthContext);
  const { readOnly } = useContext(SubmissionsBuilderContextInstance);
  const {
    control,
    formState: { defaultValues, errors },
    getValues,
    register,
    setValue,
    watch,
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  const intakeSource = watch("intakeSource");
  const modules = watch("modules");
  const visibilityDisabled = useIsSisdAndHidden(modules);

  const visibilityShown = account?.publicPortal.enabled;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {!readOnly && (
        <div>
          <Label text={"All fields are required"} required />
        </div>
      )}

      <Text
        {...register("name", { required: "Record type is required" })}
        label="Record type"
        error={errors.name?.message}
        disabled={readOnly}
      />
      <div>
        <Label text="Record category" htmlFor="category" />
        <Select
          disabled={!!defaultValues?.inputs?.length || readOnly}
          control={control}
          name="category"
          options={SUBMISSION_CATEGORY_OPTIONS}
          size="medium"
          required={true}
          rules={{ required: "Record category is required" }}
        />
      </div>

      <FormSection>
        <FormSectionTitleWrapper>
          <Title size="small" type="semiBold">
            Permissions and defaults
          </Title>
        </FormSectionTitleWrapper>
        <FieldWrapper>
          <TagSelect
            control={control}
            name="intakeSource"
            options={creatorTypeOptions}
            rules={{ required: "This field is required" }}
            error={errors.intakeSource?.message}
            disabled={readOnly}
            label="Who can fill out this form?"
            leftIconName="clipboard-pen"
            isMulti={false}
            transform={{
              output: creatorTypeToSubmissionIntakeSource,
              input: submissionIntakeSourceToCreatorType,
            }}
            onChange={value => {
              if (value === SubmissionIntakeSource.INTERNAL) {
                const name = getValues("name");
                setValue("preamble", null);
                setValue("postamble", null);
                setValue("successMessage", `Your ${name} has been saved`);
                setValue("notificationEmails", null);
              } else {
                setValue("postamble", DEFAULT_POSTAMBLE);
                setValue("preamble", DEFAULT_PREAMBLE);
                setValue("successMessage", null);
                setValue("notificationEmails", "");
              }
            }}
          />
        </FieldWrapper>
        {visibilityShown && (
          <FieldWrapper>
            <TagSelect
              control={control}
              name="hiddenFromPublicByDefault"
              options={viewerTypeOptions}
              rules={{ required: "This field is required" }}
              error={errors.hiddenFromPublicByDefault?.message}
              disabled={visibilityDisabled || readOnly}
              label="Who can view this form after it is submitted?"
              leftIconName="eye"
              isMulti={false}
              transform={{
                output: viewerTypeToSubmissionHiddenFromPublicByDefault,
                input: submissionHiddenFromPublicByDefaultToViewerType,
              }}
            />
          </FieldWrapper>
        )}
        {/* unconnected to react-hook-form/ read-only for now */}
        <FieldWrapper>
          <Wrapper
            name="editorType"
            label="Who can edit this form after it is submitted?"
          >
            <TagSelectNonForm
              name="editorType"
              options={editorTypeOptions}
              value={editorTypeOptions[0]!.value}
              disabled={true}
              leftIconName="pencil"
              isMulti={false}
              onChange={() => {}}
            />
          </Wrapper>
        </FieldWrapper>
      </FormSection>

      <AttachmentSelect disabled={readOnly} />

      <div>
        <Label text={"Form description"} htmlFor="description" />
        <Textarea
          {...register("description")}
          error={errors.description?.message}
          disabled={readOnly}
        />
      </div>
      {intakeSource === SubmissionIntakeSource.INTERNAL && (
        <div>
          <Label text={"Success message"} htmlFor="successMessage" />
          <Text
            {...register("successMessage", {
              required: "Success message is required",
            })}
            error={errors.successMessage?.message}
            disabled={readOnly}
          />
        </div>
      )}
      {intakeSource === SubmissionIntakeSource.EXTERNAL && (
        <>
          <div>
            <Label text="Success page title" htmlFor="preamble" />
            <Text
              {...register("preamble", {
                required: "Preamble is required",
              })}
              error={errors.preamble?.message}
              disabled={readOnly}
            />
          </div>
          <div>
            <Label text="Success page message" htmlFor="postamble" />
            <Text
              {...register("postamble", {
                required: "Postamble is required",
              })}
              error={errors.postamble?.message}
              disabled={readOnly}
            />
          </div>
          <div>
            <Label
              text="Notification emails"
              htmlFor="notificationEmails"
              tooltip="Please enter each email on its own line"
            />
            <Textarea
              {...register("notificationEmails")}
              error={errors.notificationEmails?.message}
              data-testid="notification-emails"
              disabled={readOnly}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormSettingsTab;
