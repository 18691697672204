import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "../../../../../Inputs/react-hook-form";
import { ParcelImportWizardContext } from "../context";
import { ParcelImportFieldConfig, ParcelImportWizardFormData } from "../types";
import { useFieldError } from "./hooks";

const ImportFieldSourceSelect = ({
  disabled,
  fieldConfig,
  fieldName,
  validator,
}: {
  disabled: boolean;
  fieldConfig: ParcelImportFieldConfig;
  fieldName: `mapping.${string}.source` | `additionalFields.${number}.source`;
  validator?: (value?: unknown) => string | undefined;
}) => {
  const { control, setValue } = useFormContext<ParcelImportWizardFormData>();
  const fieldError = useFieldError({ fieldName });
  const { availableFields } = useContext(ParcelImportWizardContext);

  return (
    <Select<
      string | string[],
      ParcelImportWizardFormData,
      `mapping.${string}.source` | `additionalFields.${number}.source`,
      boolean
    >
      control={control}
      name={fieldName}
      rules={{
        required: fieldConfig.required && "This field is required.",
        validate: validator,
      }}
      error={fieldError}
      isClearable
      isMulti={fieldConfig.isMulti}
      hideSelectedOptions
      options={availableFields.map(availableField => {
        const value = availableField.name;
        return {
          value,
          label: value,
        };
      })}
      inputId={fieldName}
      disabled={disabled}
      onChange={(selectedValue: Maybe<string | string[]>) => {
        if (!selectedValue) {
          setValue(fieldName, "");
        } else {
          setValue(fieldName, selectedValue);
        }
      }}
    />
  );
};

export default ImportFieldSourceSelect;
