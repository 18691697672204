import "./__styles__/Icon.scss";
import React from "react";

const Certificate = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6875 0.5H1.4375C1.175 0.5 1 0.675 1 0.9375V7.0625C1 7.325 1.175 7.5 1.4375 7.5H6.6875C6.95 7.5 7.125 7.325 7.125 7.0625V0.9375C7.125 0.675 6.95 0.5 6.6875 0.5ZM4.0625 6.1875H2.3125V5.3125H4.0625V6.1875ZM5.8125 4.4375H2.3125V3.5625H5.8125V4.4375ZM5.8125 2.6875H2.3125V1.8125H5.8125V2.6875Z"
        fill="white"
      />
    </svg>
  );
};

export default Certificate;
