import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";
import { AuthContext } from "../Authorization/AuthContext";
import { EmptyState } from "../Common/EmptyState";
import { useReportDataIssueButton } from "../Issues/IssueBanner";

export const MISSING_PROPERTY_LABEL = "missing-property";

export interface MissingPropertyParams {
  title: string;
  latitude: number;
  longitude: number;
  address?: Maybe<string>;
}

export default ({
  title,
  address,
  longitude,
  latitude,
}: MissingPropertyParams) => {
  const { isGuest } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  const defaultIssueText =
    "This feature is not available for properties without a street address. Edit property details and add an address to get started.";

  let primaryButtonProps = undefined;

  if (isGuest) {
    primaryButtonProps = {
      label: t("get-help"),
      onClick: () => history.push(buildLink("getHelp")),
    };
  } else {
    const { canShowReportDataIssue, showIssueModal, issueButtonLabel } =
      useReportDataIssueButton({
        address,
        coordinates: [longitude, latitude],
      });
    if (canShowReportDataIssue) {
      primaryButtonProps = {
        label: issueButtonLabel,
        onClick: () => showIssueModal(),
      };
    }
  }

  return (
    <EmptyState
      icon="no-activity-beach"
      title={title}
      body={isGuest ? t("property-missing-address-subtext") : defaultIssueText}
      primaryButtonProps={primaryButtonProps}
      componentLabel={MISSING_PROPERTY_LABEL}
    />
  );
};
