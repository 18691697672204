import React, { ReactNode } from "react";
import { Properties } from "@stitches/react/types/css";
import { toSentenceCase } from "common/utils/strings";
import { spacing } from "../../stitches.config";
import { ButtonBox, Container, TextBox } from "./__styles__/EmptyState";
import { Button } from "./Button";
import Illustration, { IllustrationName } from "./Illustration";
import { Body } from "./Typography";

type ButtonProps = {
  label: string;
  onClick: () => void;
};

type CommonEmptyStateProps = {
  title: string;
  paddingTop?: Properties["paddingTop"];
  paddingBottom?: Properties["paddingBottom"];
  componentLabel?: string;
};

type CompactEmptyStateProps = {
  compact: true;
};

type ExpandedEmptyStateProps = {
  compact?: false;
  icon: IllustrationName;
  body: ReactNode;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  buttonLinkProps?: ButtonProps;
};

export type EmptyStateProps = (
  | CompactEmptyStateProps
  | ExpandedEmptyStateProps
) &
  CommonEmptyStateProps;

export const EMPTY_STATE_LABEL = "empty-state";

export const EmptyState = (props: EmptyStateProps) => {
  const {
    compact,
    title,
    paddingTop = spacing.l.value,
    paddingBottom = spacing["2xs"].value,
    componentLabel = EMPTY_STATE_LABEL,
  } = props;

  const style = { paddingTop, paddingBottom };

  if (compact) {
    return (
      <Container style={style} aria-label={componentLabel}>
        <Body size="large" type="emphasis" color="contentPlaceholder">
          {title}
        </Body>
      </Container>
    );
  }

  const {
    icon,
    body,
    primaryButtonProps,
    secondaryButtonProps,
    buttonLinkProps,
  } = props;

  const showButtons = Boolean(
    primaryButtonProps || secondaryButtonProps || buttonLinkProps
  );

  return (
    <Container style={style} aria-label={componentLabel}>
      <Illustration alt={toSentenceCase(icon)} name={icon} />
      <TextBox>
        <Body size="large" type="emphasis" color="contentPrimary">
          {title}
        </Body>
        {body && (
          <Body size="large" type="regular" color="contentSecondary">
            {body}
          </Body>
        )}
      </TextBox>
      {showButtons && (
        <ButtonBox>
          {secondaryButtonProps && (
            <Button
              styleVariant="secondary"
              size="medium"
              onClick={secondaryButtonProps.onClick}
            >
              {secondaryButtonProps.label}
            </Button>
          )}
          {primaryButtonProps && (
            <Button
              styleVariant="primary"
              size="medium"
              onClick={primaryButtonProps.onClick}
              style={{
                marginLeft: secondaryButtonProps ? spacing.s.value : undefined,
                marginRight: buttonLinkProps ? spacing.m.value : undefined,
              }}
            >
              {primaryButtonProps.label}
            </Button>
          )}
          {buttonLinkProps && (
            <Button
              styleVariant="buttonLink"
              size="medium"
              onClick={buttonLinkProps.onClick}
            >
              {buttonLinkProps.label}
            </Button>
          )}
        </ButtonBox>
      )}
    </Container>
  );
};
