import React from "react";
import { cloneDeep, each } from "lodash";
import { RULE_FILTER_TYPE, RULE_MAP_FILTER_TYPE } from "common/constants";
import { SISDFilterOptions } from "common/utils/rules/types";
import { FilterTarget, FilterTargetSelectOption } from "./LogicFilters/types";

export type FilterTargetOptionsByGroup = Record<
  string,
  Array<FilterTargetSelectOption>
>;

export type FilterTargetMetadataByValue = Record<
  FilterTarget,
  Pick<SISDFilterOptions, "inputType" | "key" | "title" | "values"> & {
    filterType: RULE_FILTER_TYPE;
  }
>;

export type FilterContextStructure = {
  filterTargetOptions: FilterTargetOptionsByGroup;
  filterTargetMetadata: FilterTargetMetadataByValue;
};

const DEFAULT_FILTER_TARGET_OPTIONS_BY_GROUP: FilterTargetOptionsByGroup = {
  "Map Type": [
    {
      label: "Regulatory FIRM on property",
      value: RULE_MAP_FILTER_TYPE.REGULATORY_FIRM,
    },
  ],
  SISD: [],
};

const DEFAULT_FILTER_TARGET_METADATA_BY_VALUE: FilterTargetMetadataByValue = {
  [RULE_MAP_FILTER_TYPE.REGULATORY_FIRM]: {
    key: RULE_MAP_FILTER_TYPE.REGULATORY_FIRM,
    inputType: "map-select",
    title: "Regulatory FIRM on property",
    filterType: RULE_FILTER_TYPE.MAP,
  },
};

export const buildFilterContext = (sisdFilterOptions: SISDFilterOptions[]) => {
  const allFilterTargetOptionsByGroup = cloneDeep(
    DEFAULT_FILTER_TARGET_OPTIONS_BY_GROUP
  );
  const allFilterTargetMetadataByValue = cloneDeep(
    DEFAULT_FILTER_TARGET_METADATA_BY_VALUE
  );

  each(sisdFilterOptions, option => {
    // we can accept other types once we build components to handle them (i.e. dates)
    if (option.inputType === "select") {
      allFilterTargetOptionsByGroup.SISD!.push({
        label: option.title,
        value: option.key,
      });
      allFilterTargetMetadataByValue[option.key] = {
        ...option,
        filterType: RULE_FILTER_TYPE.STRING,
      };
    }
  });

  return {
    filterTargetOptions: allFilterTargetOptionsByGroup,
    filterTargetMetadata: allFilterTargetMetadataByValue,
  };
};

export const FilterContext = React.createContext({
  filterTargetOptions: DEFAULT_FILTER_TARGET_OPTIONS_BY_GROUP,
  filterTargetMetadata: DEFAULT_FILTER_TARGET_METADATA_BY_VALUE,
});
