import React from "react";
import { colors } from "../../stitches.config";
import { BottomBannerWrapper } from "../Maps/__styles__/FullPageMap";
import { usedActivationKey } from "../Maps/MeasureTool/utils";
import {
  Container,
  Header,
  InfoContainer,
  PrimaryContent,
  StyledIcon,
  TitleTextWrapper,
} from "./__styles__/BottomBanner";
import { Icons } from "./Icons/LucideIcons";
import { Body } from "./Typography";

type BottomBannerStyles = "default" | "alert" | "warning" | "table";

const styleConstants: Record<
  BottomBannerStyles,
  {
    color: keyof typeof colors;
    iconName?: keyof typeof Icons;
    iconColor?: keyof typeof colors;
    iconFill?: string;
    shouldTruncate?: boolean;
  }
> = {
  alert: {
    color: "contentCriticalDark",
    iconName: "alert-circle",
  },
  warning: { color: "contentAttention", iconName: "triangle-alert" },
  default: { color: "contentPrimaryDark" },
  table: {
    color: "contentPrimaryDark",
    iconName: "circle",
    iconColor: "bgBannerNeutral",
    iconFill: colors.mapSelected.value,
    shouldTruncate: true,
  },
};

export const BottomBanner = ({
  title,
  subtitle,
  children,
  onClose,
  style = "default",
  panelOpen = false,
  closeTabIndex = -1,
  tooltipComponent,
}: {
  title: React.ReactNode | string;
  subtitle?: string;
  children: React.ReactNode;
  onClose: () => void;
  style?: BottomBannerStyles;
  panelOpen?: boolean;
  closeTabIndex?: number;
  tooltipComponent?: React.ReactNode;
}) => {
  const { color, iconName, iconFill, iconColor, shouldTruncate } =
    styleConstants[style];

  return (
    <BottomBannerWrapper panelOpen={panelOpen}>
      {tooltipComponent}
      <Container>
        {iconName && (
          <StyledIcon
            iconName={iconName}
            color={iconColor ?? color}
            size="16"
            fill={iconFill}
          />
        )}
        <PrimaryContent>
          <Header>
            {typeof title === "string" ? (
              <div>
                <Body size="default" type="emphasis" color={color}>
                  <TitleTextWrapper shouldTruncate={shouldTruncate}>
                    {title}
                  </TitleTextWrapper>
                </Body>
              </div>
            ) : (
              title
            )}
          </Header>
          <InfoContainer hasNoSubtitle={!subtitle}>
            {subtitle && (
              <Body type="regular" size="small" color="contentSecondaryDark">
                {subtitle}
              </Body>
            )}
            {children}
          </InfoContainer>
        </PrimaryContent>

        <StyledIcon
          iconName="x"
          color="contentPrimaryDark"
          onClick={onClose}
          onKeyDown={e => {
            if (usedActivationKey(e)) {
              e.preventDefault();
              onClose();
            }
          }}
          size="16"
          cursor="pointer"
          tabIndex={closeTabIndex}
        />
      </Container>
    </BottomBannerWrapper>
  );
};
