import React, { useContext, useEffect } from "react";
import { intersection, isEmpty } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";
import {
  RowLabel,
  RowWrapper,
  SourceInputWrapper,
} from "../__styles__/ParcelImportWizard";
import { ParcelImportWizardContext } from "../context";
import {
  ImportFieldCastOptionInput as CastOptionInput,
  ImportFieldSourceSelect,
} from "../ImportFieldInputs";
import { CastFieldConfig, ParcelImportWizardFormData } from "../types";

const getCastOptionFieldPath = (fieldName: string, castOption: string) => {
  return `mapping.${fieldName}.caseValues.${castOption}` as const;
};

const useCastableFieldsState = ({
  fieldLabel,
  fieldName,
  castOptions,
}: {
  fieldLabel: string;
  fieldName: string;
  castOptions: string[];
}) => {
  const { trigger } = useFormContext<ParcelImportWizardFormData>();

  const castableFieldValues = useWatch({
    name: `mapping.${fieldName}`,
  });

  const hasUndefinedAndDefinedValues = () => {
    if (!castableFieldValues) return false;
    let undefinedCount = 0;
    let definedCount = 0;

    [
      castableFieldValues.source,
      ...Object.values(castableFieldValues.caseValues),
    ].forEach(value => {
      if (isEmpty(value)) {
        undefinedCount += 1;
      } else {
        definedCount += 1;
      }
    });

    // if some values are defined and some are not, this should
    // return true, and would trigger an error
    return undefinedCount > 0 && definedCount > 0;
  };

  const hasDuplicateValues = () => {
    if (!castableFieldValues) return false;

    return !isEmpty(
      intersection(...Object.values<string[][]>(castableFieldValues.caseValues))
    );
  };

  const castOptionValidator = () => {
    if (hasUndefinedAndDefinedValues()) {
      return `All '${fieldLabel}' fields are required if at least one has been given a value.`;
    }

    if (hasDuplicateValues()) {
      return `Duplicate value(s) present.`;
    }

    return undefined;
  };

  useEffect(() => {
    const baseFieldPath = `mapping.${fieldName}.source` as const;
    const castOptionFieldPaths = castOptions.map(castOption => {
      return getCastOptionFieldPath(fieldName, castOption);
    });

    // whenever any of the inputs within our castable field change,
    // we want to trigger validation on all of them
    void trigger([baseFieldPath, ...castOptionFieldPaths]);
  }, [castableFieldValues]);

  return {
    castOptionValidator,
  };
};

const CastableFieldSection = ({
  fieldConfig,
}: {
  fieldConfig: CastFieldConfig;
}) => {
  const { canEditSourceFields } = useContext(ParcelImportWizardContext);

  const { castOptionValidator } = useCastableFieldsState({
    fieldLabel: fieldConfig.label,
    fieldName: fieldConfig.name,
    castOptions: fieldConfig.castOptions,
  });

  return (
    <>
      <RowWrapper>
        <RowLabel
          text={fieldConfig.label}
          required={fieldConfig.required}
          tooltip={fieldConfig.tooltip}
          htmlFor={`mapping.${fieldConfig.name}.source`}
        />
        <SourceInputWrapper>
          <ImportFieldSourceSelect
            disabled={!canEditSourceFields}
            fieldConfig={fieldConfig}
            fieldName={`mapping.${fieldConfig.name}.source`}
            validator={castOptionValidator}
          />
        </SourceInputWrapper>
      </RowWrapper>
      {fieldConfig.castOptions.map((castOption, i) => (
        <RowWrapper key={`castOption-${i}-${castOption}`}>
          <RowLabel
            text={`${fieldConfig.label} - ${castOption}`}
            required={fieldConfig.required}
          />
          <SourceInputWrapper>
            <CastOptionInput
              fieldName={getCastOptionFieldPath(fieldConfig.name, castOption)}
              castOptionValidator={castOptionValidator}
            />
          </SourceInputWrapper>
        </RowWrapper>
      ))}
    </>
  );
};

export default CastableFieldSection;
