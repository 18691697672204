import "./__styles__/Icon.scss";
import React from "react";

export default () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="23" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="23" stroke="#E39600" />
      <path
        d="M13.5 7.5H6.5C5.94772 7.5 5.5 7.94772 5.5 8.5V15.5C5.5 16.0523 5.94772 16.5 6.5 16.5H13.5C14.0523 16.5 14.5 16.0523 14.5 15.5V8.5C14.5 7.94772 14.0523 7.5 13.5 7.5Z"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49951 11.5002C9.0518 11.5002 9.49951 11.0525 9.49951 10.5002C9.49951 9.94796 9.0518 9.50024 8.49951 9.50024C7.94723 9.50024 7.49951 9.94796 7.49951 10.5002C7.49951 11.0525 7.94723 11.5002 8.49951 11.5002Z"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 13.4996L12.957 11.9566C12.7695 11.7691 12.5152 11.6638 12.25 11.6638C11.9848 11.6638 11.7305 11.7691 11.543 11.9566L7 16.4996"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
