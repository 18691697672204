import React, { ReactNode, useContext, useEffect, useState } from "react";
import { captureException } from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useSwipeable } from "react-swipeable";
import { DOCUMENT_TYPE_NAME } from "common/constants";
import { buildLink } from "common/routing";
import { useGetCarouselDocumentsQuery } from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { Icon } from "../../Common/Icons/LucideIcons";
import {
  ActionButtonContainer,
  CarouselContainer,
  CarouselItem,
  CarouselModal,
  FullscreenToggle,
  IndexIndicator,
  IndexIndicators,
  InnerContainer,
  LeftToggle,
  LoadingContainer,
  RightToggle,
} from "./__styles__/Carousel";
import FullPageCarouselModal from "./FullPageCarouselModal";
import {
  useGoogleStreetViewImage,
  ViewOnStreetViewButton,
} from "./StreetViewImage";

export type CarouselDocument = {
  type: string;
  url: string;
  altText?: Maybe<string>;
  actionButton?: ReactNode;
  id: Maybe<string>;
};

type CarouselSize = "small" | "large" | "fullscreen";

export const Carousel = ({
  documents,
  size = "large",
  toggleFullscreenModal,
  index,
  setIndex,
}: {
  documents: Array<CarouselDocument>;
  size?: CarouselSize;
  toggleFullscreenModal: () => void;
  index: number;
  setIndex: (newIndex: number) => void;
}) => {
  const fullscreen = size === "fullscreen";

  const numDocuments = documents.length;

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = numDocuments - 1;
    } else if (newIndex >= documents.length) {
      newIndex = 0;
    }
    setIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(index + 1),
    onSwipedRight: () => updateIndex(index - 1),
  });

  const getRendererForType = ({
    type,
    index,
  }: {
    type: string;
    index: number;
  }) => {
    switch (type) {
      case DOCUMENT_TYPE_NAME.PROPERTY_IMAGE:
        return (document: CarouselDocument) => (
          <img
            data-testid={`property-image-${index}`}
            src={document.url}
            alt={document.altText ?? "A photo of the property"}
          />
        );
      default:
        return () => null;
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!fullscreen) return;

      if (e.key === "ArrowLeft") {
        updateIndex(index - 1);
      } else if (e.key === "ArrowRight") {
        updateIndex(index + 1);
      } else if (e.key === "Escape") {
        toggleFullscreenModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [index]);

  return (
    <CarouselContainer
      {...handlers}
      size={size}
      data-testid="carousel-container"
    >
      <InnerContainer
        data-testid="inner-container"
        style={{ transform: `translateX(-${index * 100}%)` }}
      >
        {documents.map((document, i) => (
          <CarouselItem key={`document-${i}`}>
            {!fullscreen && (
              <ActionButtonContainer active={i === index}>
                {document.actionButton}
              </ActionButtonContainer>
            )}
            {getRendererForType({ type: document.type, index: i })(document)}
          </CarouselItem>
        ))}
      </InnerContainer>
      {!fullscreen && (
        <FullscreenToggle
          onClick={toggleFullscreenModal}
          data-testid="fullscreen-toggle"
        >
          <Icon iconName="expand" color="contentPrimary" size="16" />
        </FullscreenToggle>
      )}
      {numDocuments > 1 && (
        <>
          <LeftToggle
            tabIndex={0}
            className="toggle"
            data-testid="left-toggle"
            onClick={() => {
              updateIndex(index - 1);
            }}
          />
          <RightToggle
            tabIndex={0}
            className="toggle"
            data-testid="right-toggle"
            onClick={() => {
              updateIndex(index + 1);
            }}
          />
          <IndexIndicators data-testid="index-indicators">
            {documents.map((_, i) => {
              return (
                <IndexIndicator
                  tabIndex={0}
                  active={i === index}
                  onClick={() => {
                    updateIndex(i);
                  }}
                  key={`indicator-${i}`}
                />
              );
            })}
          </IndexIndicators>
        </>
      )}
    </CarouselContainer>
  );
};

const CarouselWrapper = ({
  documents,
  size,
  propertyId,
}: {
  documents: Array<CarouselDocument>;
  size: CarouselSize;
  propertyId: string;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const authContext = useContext(AuthContext);

  const [showFullscreenCarousel, hideFullscreenCarousel] = useModal(() => {
    return (
      <CarouselModal overlayClassName={"modal-overlay dark"}>
        <FullPageCarouselModal
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          documents={documents}
          modalClose={hideFullscreenCarousel}
          propertyId={propertyId}
          authContext={authContext}
        />
      </CarouselModal>
    );
  }, [activeIndex, documents]);

  return (
    <Carousel
      documents={documents}
      size={size}
      index={activeIndex}
      setIndex={setActiveIndex}
      toggleFullscreenModal={showFullscreenCarousel}
    />
  );
};

export const PropertyCarousel = ({
  latitude,
  longitude,
  propertyId,
  address,
  filterHidden = true,
  size = "large",
}: {
  latitude: number;
  longitude: number;
  propertyId: Maybe<string>;
  address?: Maybe<string>;
  filterHidden?: boolean;
  size?: CarouselSize;
}) => {
  const { t } = useTranslation();
  const { imageURL, loading: loadingStreetView } = useGoogleStreetViewImage({
    latitude,
    longitude,
    size: "640x640", // this is the largest image we can get from streetview. We will fetch it once so it works in the expanded view.
    onError: captureException,
  });

  const { data: carouselData, loading: carouselLoading } =
    useGetCarouselDocumentsQuery({
      variables: { propertyId: propertyId!, filterHidden },
      skip: !propertyId,
    });

  let documents: Array<CarouselDocument> = [];

  if (imageURL && carouselData?.property?.streetViewEnabled) {
    const streetViewImage = {
      type: "Property Image",
      url: imageURL,
      actionButton: (
        <ViewOnStreetViewButton latitude={latitude} longitude={longitude} />
      ),
      altText: address && t("alt-text-google-streetview", { address }),
      id: null,
    };

    documents.push(streetViewImage);
  }

  if (!carouselLoading && carouselData?.property) {
    carouselData.property.documentUploads.map(documentUpload => {
      const document = {
        type: documentUpload.accountDocumentType.name,
        url: buildLink("documentUploadFile", {
          id: documentUpload.id,
        }),
        altText: address && t("alt-text-google-property-image", { address }),
        id: documentUpload.id,
      };

      documentUpload.id === carouselData.property?.coverImageDocumentUploadId
        ? documents.unshift(document)
        : documents.push(document);
    });
  }

  if (carouselLoading || loadingStreetView) {
    return (
      <LoadingContainer>
        <em>{t("common-loading")}</em>
      </LoadingContainer>
    );
  }

  if (documents.length === 0 || !carouselData?.property) return null;

  return (
    <CarouselWrapper
      documents={documents}
      size={size}
      propertyId={carouselData.property.id}
    />
  );
};
