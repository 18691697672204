import React from "react";
import { Button } from "../../Common/Button";

export interface PropertyOptionsButtonProps {
  onClick: (_: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  showCaret?: boolean;
}

const PropertyOptionsButton = ({
  onClick,
  disabled,
  showCaret = true,
}: PropertyOptionsButtonProps) => (
  <Button
    onClick={onClick}
    size="small"
    styleVariant="outlineDark"
    disabled={disabled}
    rightIconName={showCaret ? "chevron-down" : undefined}
  >
    Edit property
  </Button>
);

export default PropertyOptionsButton;
