import React, { useContext, useEffect, useRef, useState } from "react";
import { feature, featureCollection } from "@turf/turf";
import { sortBy } from "lodash";
import { Layer, Marker, Source } from "react-map-gl";
import { colors } from "../../../stitches.config";
import { useOutsideAlerter } from "../../../utils/outsideAlerter";
import { Body } from "../../Common/Typography";
import { DeviceLocationsLayerConfig } from "../LayeredMap/userDeviceLocationsLayer";
import { LayerContext } from ".";
import { MarkerBody } from "./__styles__/userDeviceLocation";

export const id = "device-location";

const DeviceMarker = ({
  location,
  currentTime,
  setIsHovered,
  hovered,
}: {
  location: NonNullable<DeviceLocationsLayerConfig["value"]>[number];
  currentTime: Date;
  setIsHovered: (hovered: boolean) => void;
  hovered: boolean;
}) => {
  const timeDelta = formatTrackedAt({
    trackedAt: location.trackedAt,
    currentTime,
  });

  const markerRef = useRef<Maybe<HTMLDivElement>>(null);

  // because the marker can move out from under the mouse, we have to make sure
  // to reset the hovered state if that happens and the user moves the mouse
  useOutsideAlerter({
    ref: markerRef,
    onOutsideInteraction: () => setIsHovered(false),
    eventType: "mousemove",
  });

  return (
    <Marker
      // the marker really doesn't like to re-render if it can avoid it
      // so we include the hovered state and the time delta in the key
      // to force a re-render
      key={`${location.id}-${hovered}-${timeDelta}`}
      latitude={location.location.coordinates[1]}
      longitude={location.location.coordinates[0]}
      offsetLeft={10}
      offsetTop={hovered ? -35 : -15}
    >
      <MarkerBody
        ref={markerRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {hovered && (
          <>
            <Body type="regular" size="small" color="contentPrimaryDark">
              {location.userName}
            </Body>
            <Body type="regular" size="small" color="contentSecondaryDark">
              {location.deviceDescription}
            </Body>
            <Body type="regular" size="small" color="contentSecondaryDark">
              {timeDelta}
            </Body>
          </>
        )}
        {!hovered && (
          <Body type="regular" size="small" color="contentPrimaryDark">
            {location.userName}
          </Body>
        )}
      </MarkerBody>
    </Marker>
  );
};

export default ({
  locations,
}: {
  locations: DeviceLocationsLayerConfig["value"];
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { isLayerVisible } = useContext(LayerContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // We track hovered state of each marker so we can sort the markers
  // by this state and put the hovered marker on top
  const [hovered, setHovered] = useState<Record<string, boolean>>({});
  const setIsHovered = ({ id, hovered }: { id: string; hovered: boolean }) => {
    if (!hovered) {
      setHovered(state => ({ ...state, [id]: hovered }));
    } else {
      // only one can be hovered at a time
      setHovered({ [id]: hovered });
    }
  };

  if (!locations) return null;

  const geojson = featureCollection(
    locations.map(({ location }) => {
      return feature(location);
    })
  );

  const visible = isLayerVisible({
    group: "deviceLocations",
    id: "allDevices",
  });

  return (
    <Source id={id} data={geojson} type="geojson">
      <Layer
        id={`${id}-points`}
        type="circle"
        source={"geojson"}
        layout={{
          visibility: visible ? "visible" : "none",
        }}
        paint={{
          "circle-radius": 5,
          "circle-color": colors.mapSelected.value,
          "circle-stroke-color": colors.bgUiSurface.value,
          "circle-stroke-width": 3,
        }}
      />
      {sortBy(visible ? locations : [], ({ id }) => (hovered[id] ? 1 : 0)).map(
        location => (
          <DeviceMarker
            key={location.id}
            location={location}
            currentTime={currentTime}
            setIsHovered={hovered => setIsHovered({ id: location.id, hovered })}
            hovered={hovered[location.id] ?? false}
          />
        )
      )}
    </Source>
  );
};

const formatTrackedAt = ({
  trackedAt,
  currentTime,
}: {
  trackedAt: string;
  currentTime: Date;
}) => {
  const date = new Date(trackedAt);

  const diffInSeconds = Math.floor(
    (currentTime.getTime() - date.getTime()) / 1000
  );

  if (diffInSeconds <= 0) {
    return "Now";
  }

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${diffInSeconds === 1 ? "second" : "seconds"} ago`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);

  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
};
