import React, { ComponentProps } from "react";
import { ApolloError } from "@apollo/client";
import { spacing } from "../../../../stitches.config";
import { EmptyState } from "../../../Common/EmptyState";
import { Section } from "../../__styles__/Content";
import RecentImportTasksTable from "../RecentImportTasksTable";

export type FirmImportsProps = {
  error?: Pick<ApolloError, "message">;
} & Pick<
  ComponentProps<typeof RecentImportTasksTable>,
  "recentImportTasks" | "loading" | "manualPaginationConfig"
>;

const FirmImports = ({
  recentImportTasks,
  error,
  loading,
  manualPaginationConfig,
}: FirmImportsProps) => {
  if (error) return <div>Error loading recent imports.</div>;

  const hasRecentFirmImports = !!recentImportTasks.length;

  return (
    <>
      {!loading && (
        <>
          {hasRecentFirmImports ? (
            <Section>
              <RecentImportTasksTable
                recentImportTasks={recentImportTasks}
                loading={loading}
                manualPaginationConfig={manualPaginationConfig}
              />
            </Section>
          ) : (
            <EmptyState
              icon="missing-location"
              title="No recent imports for this account"
              body="FIRM imports for this account will appear here."
              paddingTop={spacing["2xl"].value}
              paddingBottom={spacing["2xl"].value}
            />
          )}
        </>
      )}
    </>
  );
};

export default FirmImports;
