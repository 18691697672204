import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Layer, Source } from "react-map-gl";
import { SUPPORTED_LANGUAGES } from "common/constants";
import { FLOODZONES_SOURCE_LAYER } from "common/utils/maps/constants";
import {
  generateFloodzoneLabelLayer,
  generateFloodzoneLabelLayerPaint,
  generateFloodzoneLayerPaint,
  generateFloodzoneOutlineLayerPaint,
} from "common/utils/maps/index";
import { Firm as GraphQLFIRM } from "../../../generated/graphql";
import { LayerContext } from ".";
export type FIRM = Pick<GraphQLFIRM, "id" | "tileset"> & {
  components: Pick<GraphQLFIRM["components"], "limwas">;
};
export const group = "firms";

const MapLayer: React.FC<{ firm: FIRM }> = ({ firm }) => {
  const { isLayerVisible } = React.useContext(LayerContext);
  const { tileset, id, components } = firm;
  const { t, i18n } = useTranslation();

  const labelLayerProps = useMemo(() => {
    if (i18n.language !== SUPPORTED_LANGUAGES.ENGLISH) {
      const translations = i18n.getResourceBundle(i18n.language, "translation");
      const filteredTranslationsObject = Object.keys(translations).reduce(
        (acc, key) => {
          if (key.includes("mapbox")) {
            acc[key] = translations[key] ?? "";
          }
          return acc;
        },
        {} as Record<string, string>
      );

      return {
        zoneCopy: t("flood-layers-zone", { zones: "" }),
        elevationAbbr: t("flood-layers-el"),
        depthAbbr: t("flood-layers-depth"),
        translations: filteredTranslationsObject,
      };
    }
    return {};
  }, [i18n.language]);

  return (
    <React.Fragment>
      <Source
        id={tileset.id}
        url={`mapbox://${tileset.mapboxId}`}
        type="vector"
        minzoom={10}
        maxzoom={16}
      >
        <Layer
          id={`${id}-${FLOODZONES_SOURCE_LAYER}`}
          type="fill"
          source-layer={FLOODZONES_SOURCE_LAYER}
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
          }}
          paint={generateFloodzoneLayerPaint()}
          beforeId="ground"
        />
        <Layer
          id={`${id}-${FLOODZONES_SOURCE_LAYER}-outlines`}
          type="line"
          source-layer="floodzones"
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
          }}
          paint={generateFloodzoneOutlineLayerPaint()}
          beforeId="ground"
        />
        <Layer
          id={`${id}-floodways`}
          type="fill"
          source-layer="floodzones"
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
          }}
          filter={["==", ["get", "floodway"], true]}
          paint={{ "fill-pattern": "shape-line-reversed" }}
          beforeId="ground"
        />
        <Layer
          id={`${id}-labels`}
          type="symbol"
          source-layer="floodzones"
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
            ...generateFloodzoneLabelLayer(labelLayerProps),
          }}
          paint={generateFloodzoneLabelLayerPaint()}
        />
        <Layer
          id={`${id}-limwa-lines`}
          source-layer="limwa"
          type="line"
          layout={{
            visibility:
              isLayerVisible({ group, id }) && components.limwas.hasGeometries
                ? "visible"
                : "none",
          }}
          paint={{ "line-color": "hsl(219, 0%, 0%)", "line-width": 1.5 }}
          beforeId={`${id}-labels`}
        />
        <Layer
          id={`${id}-limwa-icons`}
          type="symbol"
          source-layer="limwa"
          layout={{
            visibility:
              isLayerVisible({ group, id }) && components.limwas.hasGeometries
                ? "visible"
                : "none",
            "icon-offset": [0, -4],
            "icon-image": "triangle-up-black",
            "symbol-avoid-edges": true,
            "symbol-spacing": 30,
            "icon-allow-overlap": true,
            "symbol-placement": "line",
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0.5,
              14,
              0.8,
            ],
            "text-anchor": "bottom",
            "icon-ignore-placement": true,
          }}
          beforeId={`${id}-labels`}
          paint={{}}
        />
      </Source>
    </React.Fragment>
  );
};

export default MapLayer;
