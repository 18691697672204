import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { CustomDatePicker } from "../../../../../../Submissions/Widgets";
import BaseWidgetPreview from "./BaseWidgetPreview";

const DateWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <CustomDatePicker {...props} />;
  return (
    <BaseWidgetPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseWidgetPreview>
  );
};

export default DateWidgetPreview;
