import React, { ChangeEvent, useContext, useEffect } from "react";
import { isUndefined } from "lodash";
import { useFormContext } from "react-hook-form";
import { AuthContext } from "../../../../../../Authorization/AuthContext";
import { Label } from "../../../../../../Inputs";
import {
  Checkbox,
  Select,
  Text,
} from "../../../../../../Inputs/react-hook-form";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import {
  SubmissionsBuilderFieldSettings,
  SubmissionsBuilderFormDataStructure,
} from "../../types";
import { useIsSisdAndHidden } from "../../utils";

export const FieldSettingConfig = ({
  fieldPath,
  isModuleInput,
  onRequiredChange,
  readOnly,
  labelRequired = true,
}: Pick<SelectedSubmissionsBuilderField, "fieldPath"> &
  SubmissionsBuilderFieldSettings & {
    onRequiredChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
    labelRequired?: boolean;
  }) => {
  const { control, register } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <>
      <div>
        <Label
          text={"Field label"}
          htmlFor={`${fieldPath}.title`}
          required={labelRequired}
        />
        <Text {...register(`${fieldPath}.title`)} disabled={readOnly} />
      </div>
      <Checkbox
        control={control}
        name={`${fieldPath}.required`}
        additionalOnChange={onRequiredChange}
        disabled={isModuleInput || readOnly}
        label="Required field"
      />
      <div>
        <Label text={"Tooltip text"} htmlFor={`${fieldPath}.tooltipText`} />
        <Text {...register(`${fieldPath}.tooltipText`)} disabled={readOnly} />
      </div>

      <HiddenFromPublicSetting fieldPath={fieldPath} />
    </>
  );
};

export const HiddenFromPublicSetting = ({
  fieldPath,
}: Pick<SelectedSubmissionsBuilderField, "fieldPath">) => {
  const { account } = useContext(AuthContext);
  const { control, getValues, setValue, watch } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  const modules = watch("modules");
  const visibilityHiddenFromSisd = useIsSisdAndHidden(modules);

  useEffect(() => {
    const startingHiddenFromPublic = getValues(`${fieldPath}.hiddenFromPublic`);
    if (isUndefined(startingHiddenFromPublic)) {
      setValue(`${fieldPath}.hiddenFromPublic`, visibilityHiddenFromSisd);
    }
  }, []);

  if (account?.publicPortal.enabled && !visibilityHiddenFromSisd) {
    return (
      <div>
        <Label text="Hidden from Public" htmlFor="hiddenFromPublic" />
        <Select
          control={control}
          name={`${fieldPath}.hiddenFromPublic`}
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          size="medium"
        />
      </div>
    );
  }

  return null;
};
