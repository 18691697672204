import React, { useState } from "react";
import { captureException } from "@sentry/browser";
import { omit, uniqBy } from "lodash";
import { useDropzone } from "react-dropzone";
import { FormProvider, useForm } from "react-hook-form";
import { MIME_TYPE } from "common/constants";
import { UI_FIELD_NAMES, walkSchema } from "common/services/formBuilderService";
import { extractRequiredFields } from "common/services/submissionHelpers";
import { isNotNil } from "common/utils/tools";
import {
  useDispatchBulkSubmissionCreateScriptJobMutation,
  useGetSubmissionTypesForImportQuery,
} from "../../../../generated/graphql";
import { useMultipartFileUpload } from "../../../../hooks/useMultipartFileUpload";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { Error } from "../../../AdminApp/CreateAccount/__styles__/CreateAccount";
import {
  Container,
  FormContainer,
  Section,
} from "../../../Common/__styles__/FullPageFormLayout";
import { FlexColumn } from "../../../Common/__styles__/Layout";
import { Button } from "../../../Common/Button";
import FullPageFormLayout from "../../../Common/FullPageFormLayout";
import FullPageLoadingScreen from "../../../Common/FullPageLoadingScreen";
import { Body } from "../../../Common/Typography";
import { Required } from "../../../Inputs/__styles__/Label";
import Label from "../../../Inputs/Label";
import { Select, SingleFileUpload } from "../../../Inputs/react-hook-form";
import { List, ListItem } from "./__styles__/Operations";

type DispatchBulkSubmissionActionForm = {
  inputCsv: { blob: File };
  inputZip: { blob: File };
  submissionTypeId: string;
};

export const DispatchBulkSubmissionActionForm = () => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const formMethods = useForm<DispatchBulkSubmissionActionForm>();
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    watch,
    clearErrors,
  } = formMethods;

  const [uploadSourceFile, { loading: isUploading }] = useMultipartFileUpload({
    onError: () => addErrorToast("Failed to upload input file"),
  });

  const [hasDispatchedOperation, setHasDispatchedOperation] = useState(false);
  const [dispatch, { loading: isDispatching }] =
    useDispatchBulkSubmissionCreateScriptJobMutation({
      onCompleted: () => {
        setHasDispatchedOperation(true);
        addSuccessToast("Dispatched bulk record creation");
      },
      onError: () => {
        addErrorToast("Failed to dispatch bulk record creation");
      },
    });
  const { data, loading } = useGetSubmissionTypesForImportQuery();

  const submissionTypeId = watch("submissionTypeId");

  const [hasDocumentUploadFields, setHasDocumentUploadFields] = useState(false);
  const [requireZipFile, setRequireZipFile] = useState(false);
  const [hasPropertyMarketValueFields, setHasPropertyMarketValueFields] =
    useState(false);
  const [labels, setLabels] = useState<
    Array<{ label: string; required: boolean }>
  >([]);

  if (!data || loading) {
    return <FullPageLoadingScreen />;
  }

  const submissionTypeOptions =
    data.account?.submissionTypes.map(type => ({
      value: type.id,
      label: type.name,
    })) ?? [];

  const hasDuplicateLabels = uniqBy(labels, "label").length !== labels.length;

  const onSubmissionIdChange = (newSubmissionId: Maybe<string>) => {
    setLabels([]);
    setHasDocumentUploadFields(false);
    setRequireZipFile(false);
    clearErrors();
    const selectedSubmissionType = data.account?.submissionTypes.find(
      type => type.id === newSubmissionId
    );

    if (!selectedSubmissionType) {
      return;
    }

    const requiredFields = extractRequiredFields(
      selectedSubmissionType.currentVersion.formStructure
    );

    const walkResult = walkSchema<
      Array<{
        label: Maybe<string>;
        required: boolean;
        isDocumentUploader?: boolean;
      }>
    >({
      formStructure: selectedSubmissionType.currentVersion.formStructure,
      visit: ({ titles, isLeaf, fieldIs, uiSchemaPath }) => {
        const isDocumentUploader = fieldIs(UI_FIELD_NAMES.DOCUMENT_UPLOADER);

        if (fieldIs(UI_FIELD_NAMES.PROPERTY_MARKET_VALUE)) {
          setHasPropertyMarketValueFields(true);
          return [
            {
              label: [...titles, "Type"].join("|"),
              required: requiredFields.includes(uiSchemaPath),
            },
            {
              label: [...titles, "Value"].join("|"),
              required: requiredFields.includes(uiSchemaPath),
            },
          ];
        }
        const isTag = fieldIs(UI_FIELD_NAMES.TAGS);
        return [
          {
            label:
              isLeaf || isDocumentUploader || isTag ? titles.join("|") : null,
            isDocumentUploader,
            required: requiredFields.includes(uiSchemaPath),
          },
        ];
      },
      merge: (acc, result) => [...acc, ...result],
      halt: ({ fieldIs }) =>
        fieldIs(UI_FIELD_NAMES.DOCUMENT_UPLOADER) ||
        fieldIs(UI_FIELD_NAMES.PROPERTY_MARKET_VALUE) ||
        fieldIs(UI_FIELD_NAMES.TAGS),
    });

    setLabels(
      walkResult
        .map(({ label, required }) => (label ? { label, required } : null))
        .filter(isNotNil)
    );

    setHasDocumentUploadFields(
      walkResult.some(({ isDocumentUploader }) => isDocumentUploader)
    );

    setRequireZipFile(
      walkResult.some(
        ({ isDocumentUploader, required }) => isDocumentUploader && required
      )
    );
  };

  const onSubmit = async (formData: DispatchBulkSubmissionActionForm) => {
    const csvPromise: Promise<Maybe<string>> = new Promise(resolve =>
      uploadSourceFile({
        file: getValues("inputCsv.blob"),
        onCompleted: key => {
          resolve(key);
        },
        onUploadError: e => {
          captureException(e);
          resolve(null);
        },
      })
    );

    const zipBlobValue = getValues("inputZip.blob");
    const zipPromise: Promise<Maybe<string>> = new Promise(resolve => {
      // When I make inputZip optional, it redlines elsewhere, so just ignoring the yellow line here

      if (!zipBlobValue) {
        resolve(null);
        return;
      }
      uploadSourceFile({
        file: getValues("inputZip.blob"),
        onCompleted: key => {
          resolve(key);
        },
        onUploadError: e => {
          captureException(e);
          resolve(null);
        },
      });
    });

    const [inputCsvKey, inputZipKey] = await Promise.all([
      csvPromise,
      zipPromise,
    ]);

    if (!inputCsvKey) {
      addErrorToast("Failed to upload csv file, please reload and try again.");
      return;
    }

    if (zipBlobValue && !inputZipKey) {
      addErrorToast("Failed to upload zip file, please reload and try again.");
      return;
    }

    await dispatch({
      variables: {
        data: {
          inputCsvKey,
          inputZipKey,
          submissionTypeId: formData.submissionTypeId,
        },
      },
    });
  };

  return (
    <FullPageFormLayout
      subtitle="Bulk property actions"
      prevLocation={"/settings/data-imports/property-operations"}
      rightContainer={
        <Button
          size={"small"}
          styleVariant={"primary"}
          onClick={handleSubmit(onSubmit)}
          disabled={hasDispatchedOperation}
          loading={isDispatching || isUploading}
        >
          Create
        </Button>
      }
    >
      <Container>
        <FormContainer>
          <FormProvider {...formMethods}>
            <form>
              <Section style={{ marginTop: "16px", marginBottom: "16px" }}>
                <Select
                  control={control}
                  name="submissionTypeId"
                  label="Record type"
                  options={submissionTypeOptions}
                  error={errors.submissionTypeId?.message}
                  onChange={onSubmissionIdChange}
                />
                {hasDuplicateLabels && (
                  <div>
                    Cannot bulk create records that have duplicate labels.
                    Please edit the record type so that every input has a unique
                    label.
                  </div>
                )}

                {submissionTypeId && !hasDuplicateLabels && (
                  <FlexColumn style={{ gap: "16px" }}>
                    <List>
                      <ListItem>
                        <Body
                          type="regular"
                          size="default"
                          color="contentSecondary"
                        >
                          The first row of your CSV should contain the column
                          headers
                        </Body>
                      </ListItem>
                      <ListItem>
                        <Body
                          type="regular"
                          size="default"
                          color="contentSecondary"
                        >
                          There must be address columns and they must come in at
                          least one of the following combinations:
                        </Body>
                        <pre>fullAddress</pre>
                        <Body
                          type="emphasis"
                          size="default"
                          color="contentSecondary"
                        >
                          OR
                        </Body>
                        <pre>streetAddress, city, state, zip</pre>
                        <Body
                          type="emphasis"
                          size="default"
                          color="contentSecondary"
                        >
                          OR
                        </Body>
                        <pre>
                          streetNumber, streetName, suffix, city, state, zip
                        </pre>
                      </ListItem>
                      <ListItem>
                        <Body
                          type="regular"
                          size="default"
                          color="contentSecondary"
                        >
                          Including longitude and latitude columns is strongly
                          encouraged.
                        </Body>
                      </ListItem>
                      <ListItem>
                        <Body
                          type="regular"
                          size="default"
                          color="contentSecondary"
                        >
                          The following headers should be present in the first
                          row of the csv. If a header has a{" "}
                          <Required>*</Required>, this is a required field. It
                          must be included as a header in the .csv or the entire
                          backfill will fail. Additionally, every row in the
                          .csv <strong>should</strong> have data present in
                          required column. If data is not present for a required
                          field, the record represented by that row will fail to
                          be created.
                          {labels.map(({ label, required }, index) => (
                            <span
                              key={`${submissionTypeId}-${index}`}
                              style={{ display: "block" }}
                            >
                              - <code>{label}</code>
                              {required && <Required>*</Required>}
                            </span>
                          ))}
                        </Body>
                      </ListItem>
                      <ListItem>
                        <Body
                          type="regular"
                          size="default"
                          color="contentSecondary"
                        >
                          If more than one file name is included in a file
                          upload field, or more than one tag is included in a
                          tag field, they should be separated with a | (called a
                          “pipe”). E.g. building appraisal.pdf|FZD.pdf
                        </Body>
                      </ListItem>
                      <ListItem>
                        <Body
                          type="regular"
                          size="default"
                          color="contentSecondary"
                        >
                          File names in the backfill .csv must match file names
                          in the zipped folder <strong>exactly</strong>. This is
                          both case and character sensitive.
                        </Body>
                      </ListItem>
                      {hasPropertyMarketValueFields && (
                        <ListItem>
                          <Body
                            type="regular"
                            size="default"
                            color="contentSecondary"
                          >
                            This script presently only supports
                            "otherValueEstimate" type for Property Market Value
                            inputs. Please put "otherValueEstimate" (no quotes)
                            in every row for the "type" column. If you need
                            other types, such as square foot, please create an
                            Ask in #dev-requests.
                          </Body>
                        </ListItem>
                      )}
                    </List>
                    <div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Label text={"Source file"} required />
                        {errors.inputCsv && (
                          <Error>{errors.inputCsv.message}</Error>
                        )}
                      </div>
                      <SingleFileUpload
                        {...omit(register("inputCsv"), "ref")}
                        control={control}
                        allowedMimeTypes={[MIME_TYPE.CSV]}
                        name={"inputCsv"}
                        useDropzone={useDropzone}
                        required
                        rules={{
                          required: "This field is required",
                        }}
                      />
                    </div>
                    {hasDocumentUploadFields && (
                      <div>
                        <Label
                          text={"Associated files"}
                          description={
                            "If providing additional files for this record, please include all files in a zip."
                          }
                          required={requireZipFile}
                        />
                        {errors.inputZip && (
                          <Error>{errors.inputZip.message}</Error>
                        )}
                        <SingleFileUpload
                          {...omit(register("inputZip"), "ref")}
                          control={control}
                          allowedMimeTypes={[MIME_TYPE.ZIP]}
                          name={"inputZip"}
                          useDropzone={useDropzone}
                          rules={{
                            ...(requireZipFile
                              ? {
                                  required:
                                    "This field is required for this record type",
                                }
                              : {}),
                          }}
                        />
                      </div>
                    )}
                  </FlexColumn>
                )}
              </Section>
            </form>
          </FormProvider>
        </FormContainer>
      </Container>
    </FullPageFormLayout>
  );
};
