import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { JURISDICTIONS } from "common/constants";
import { TranslationKeys } from "common/utils/i18n/fallbackEnTranslations";
import { track } from "../../../../utils/tracking";
import { AuthContext } from "../../../Authorization/AuthContext";
import Badge from "../../../Common/Badge";
import { Button } from "../../../Common/Button";
import { Icon } from "../../../Common/Icons/LucideIcons";
import { DropdownMenu } from "../../../Inputs";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { LayerContext } from "../../../Maps/layers";
import { FirmName, Option } from "./__styles__/FIRMSelect";
import { FIRMInfoContext } from "./FIRMInfoContext";

const JURISDICTION_TO_TRANSLATION_KEY: Record<JURISDICTIONS, TranslationKeys> =
  {
    [JURISDICTIONS.NFIP]: "common-nfip",
    [JURISDICTIONS.REGULATORY]: "common-regulatory",
  };

export interface FIRMSelectProps {
  onComparisonSelection: () => void;
}

export default ({ onComparisonSelection }: FIRMSelectProps) => {
  const { isGuest } = useContext(AuthContext);
  const { firms, activeFirmIds, setActiveFirmIds } =
    useContext(FIRMInfoContext);
  const { approximateBfeToolState } = useContext(LayerContext);
  const { t } = useTranslation();

  const [selectedFIRMName, setSelectedFIRMName] = useState(
    firms.find(firm => firm.id === activeFirmIds[0])?.name
  );

  const selectFIRM = ({ id, name }: { id: string; name: string }) => {
    setSelectedFIRMName(name);
    track("FIRM Selection Made", { firm: name });
    setActiveFirmIds([id]);
  };

  const FirmSelectButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => {
    return (
      <Button
        onClick={onClick}
        size="small"
        styleVariant="outlineLight"
        tabIndex={0}
        style={{
          height: "initial",
          display: "inline-flex",
        }}
        rightIconName="chevron-down"
        disabled={
          approximateBfeToolState?.mode &&
          approximateBfeToolState.mode !== "off"
        }
      >
        {selectedFIRMName}
      </Button>
    );
  };

  const FirmOption = ({
    name,
    jurisdictions,
    hasWarnings,
  }: {
    name: string;
    jurisdictions: string;
    hasWarnings: boolean;
  }) => {
    return (
      <Option>
        <FirmName>
          {name}
          {hasWarnings && (
            <Icon iconName="alert-circle" color="contentAttention" size="14" />
          )}
        </FirmName>
        {jurisdictions && <Badge text={jurisdictions} color="orange" />}
      </Option>
    );
  };

  const actions: Array<ActionsProps> = firms.map(
    ({ id, name, jurisdictions, warnings }) => ({
      label: FirmOption({
        name,
        jurisdictions: jurisdictions
          .map(jurisdiction => t(JURISDICTION_TO_TRANSLATION_KEY[jurisdiction]))
          .join(" & "),
        hasWarnings: !isGuest ? !!warnings.length : false,
      }),
      onClick: () => selectFIRM({ name, id }),
    })
  );

  if (!isGuest) {
    actions.push({
      label: "Compare All FIRMs",
      onClick: () => onComparisonSelection(),
      variant: "topDivider",
    });
  }

  return <DropdownMenu actions={actions} customButton={FirmSelectButton} />;
};
