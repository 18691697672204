import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { isNil } from "lodash";
import queryString from "query-string";
import { MapRef } from "react-map-gl";
import { useHistory } from "react-router";
import { z } from "zod";
import { RESOURCE_NAME } from "common/authorization";
import {
  buildLink,
  INFO_PANEL_TAB_NAME,
  RECORDS_TAB_NAME,
} from "common/routing";
import { outsideOfBoundary } from "common-client/utils/geospatial";
import { MapsForAccountQuery } from "../../generated/graphql";
import useMapIcons from "../../hooks/useMapIcons";
import useURLParsing from "../../hooks/useURLParsing";
import { isSmall } from "../../utils/size";
import { track } from "../../utils/tracking";
import AccountActivityFeed from "../ActivityFeed/AccountActivityFeed";
import AddressPanel from "../AddressPanel";
import { AuthContext } from "../Authorization/AuthContext";
import { Icon as LucideIcon } from "../Common/Icons/LucideIcons";
import ObjectInformationPanel from "../ObjectInformationPanel";
import { CreatePropertyParamsInput } from "../Property/CreateProperty";
import { SearchResultProps } from "../Search";
import {
  Container,
  LegendControl,
  MapTool,
  MapToolLabel,
} from "./__styles__/FullPageMap";
import { ActivityFeed, Info } from "./__styles__/Map";
import FullPageMapContainer from "./FullPageMap";
import { InternalMapContext } from "./InternalMapContextProvider";
import { CustomMapLayerConfig } from "./LayeredMap/customMapLayer";
import { MarkerLayerConfig } from "./LayeredMap/markerLayer";
import { ParcelLayerConfig } from "./LayeredMap/parcelLayer";
import { PropertyLayerConfig } from "./LayeredMap/propertyLayer";
import { SavedViewsLayerConfig } from "./LayeredMap/savedViewsLayer";
import { Property } from "./LayeredMap/types";
import { LayerContext } from "./layers";
import { useUserDeviceLocations } from "./UserDeviceLocation/useUserDeviceLocations";
import { Viewport } from "./utils/viewportHook";

export const selectedSchema = z.union([
  z
    .object({})
    .strict()
    .transform(() => null),
  z
    .object({
      customMapId: z.string().uuid(),
      geometryId: z.string().uuid(),
      tab: z.nativeEnum(INFO_PANEL_TAB_NAME).optional(),
      recordTab: z.nativeEnum(RECORDS_TAB_NAME).optional(),
    })
    .strict()
    .transform(props => ({ ...props, type: "geometry" as const })),
  z
    .object({
      lat: z.coerce.number(),
      lng: z.coerce.number(),
      address: z.string().nullish(),
      propertyId: z.string().uuid().nullish(),
      parcelId: z.string().uuid().nullish(),
      parcelNumber: z.string().nullish(),
      hasPropertyIds: z
        .string()
        .nullish()
        .transform(value => value === "true"),
      tab: z.nativeEnum(INFO_PANEL_TAB_NAME).optional(),
      recordTab: z.nativeEnum(RECORDS_TAB_NAME).optional(),
    })
    .strict()
    .transform(({ lat, lng, ...rest }) => ({
      type: "address" as const,
      latitude: lat,
      longitude: lng,
      ...rest,
    })),
]);

export type SelectedInput = z.input<typeof selectedSchema>;
type Selected = z.infer<typeof selectedSchema>;

const MapControls = ({ viewport }: { viewport: Viewport }) => {
  const history = useHistory();
  const { authorized, account } = useContext(AuthContext);

  const canCreateProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "create",
  });

  const createProperty = ({ viewport }: { viewport: Viewport }) => {
    const pathname = buildLink("createProperty");

    const queryParameters = {
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: viewport.zoom,
    } satisfies CreatePropertyParamsInput;

    history.push({
      pathname,
      search: `?${queryString.stringify(queryParameters)}`,
    });
  };

  if (
    !canCreateProperty ||
    outsideOfBoundary({ point: viewport, boundary: account!.bounds })
  ) {
    return null;
  }

  return (
    <MapTool>
      <MapToolLabel>Add new property</MapToolLabel>
      <LegendControl
        styleVariant="outlineLight"
        onClick={() => createProperty({ viewport })}
      >
        <LucideIcon iconName="map-pin" color="contentPrimary" size={16} />
      </LegendControl>
    </MapTool>
  );
};

export const InternalMap = ({
  account,
}: {
  account: NonNullable<MapsForAccountQuery["account"]>;
}) => {
  const history = useHistory();
  const [selected, setSelected] = useState<Maybe<Selected>>(null);
  const { resetSearchCategory } = useContext(InternalMapContext);
  const {
    measureToolDispatch,
    measureToolState,
    visibleRaster,
    visibleSavedViews: getVisibleSavedViews,
    toggleLayer,
    approximateBfeToolDispatch,
    approximateBfeToolState,
    pointInGeometry,
    setGeometryBounds,
    setPointInGeometry,
  } = useContext(LayerContext);
  const mapRef = useRef<Maybe<MapRef>>(null);
  useMapIcons(mapRef);

  const userDeviceLocations = useUserDeviceLocations();

  // we don't want the address panel to re-render every time *this* re-renders,
  // so we  memoize this callback so that creating it happens only once
  const onAddressPanelClose = useMemo(
    () => () => {
      if (
        measureToolState.measureToolMode === "raster" &&
        measureToolState.dataMode === "store"
      ) {
        measureToolDispatch({
          type: "setMeasureMode",
          data: {
            measureToolMode: "off",
          },
        });

        toggleLayer({
          group: "rasters",
          id: visibleRaster()?.id,
          isVisible: false,
        });
      }
      if (approximateBfeToolState?.mode !== "off") {
        approximateBfeToolDispatch?.({
          type: "setMode",
          data: {
            mode: "off",
          },
        });
      }

      // clear out customMap related state
      setGeometryBounds(null);
      setPointInGeometry(null);

      history.replace({ pathname: buildLink("map") });
    },
    [
      history,
      measureToolState.measureToolMode,
      measureToolState.dataMode,
      approximateBfeToolState?.mode,
      setGeometryBounds,
      setPointInGeometry,
    ]
  );

  const updateURL = (
    queryParameters: Record<string, string | number | null>
  ) => {
    history.replace({
      pathname: buildLink("map"),
      search: `?${queryString.stringify(queryParameters)}`,
    });
  };

  const onParcelClick: ParcelLayerConfig["onClick"] = parcels => {
    // clear out customMap related state
    setGeometryBounds(null);
    setPointInGeometry(null);

    const parcel = parcels[0]!;

    const tab =
      (selected?.type === "address" && selected.tab) ||
      INFO_PANEL_TAB_NAME.OVERVIEW;
    const { fullAddress, id, parcelNumber, propertyIds } = parcel;
    const { longitude, latitude } = parcel.point;
    const hasPropertyIds = propertyIds.length ? "true" : "false";

    const queryParameters = {
      address: fullAddress ? fullAddress : null,
      lng: longitude,
      lat: latitude,
      tab,
      parcelId: id,
      parcelNumber,
      hasPropertyIds,
    } satisfies SelectedInput;

    navigateToProperty(queryParameters);
  };

  const getAddressQueryParam = (property: Property) => {
    const { address, city, state, zipcode } = property;

    if (isNil(address) || isNil(city) || isNil(state) || isNil(zipcode)) {
      return undefined;
    }

    return `${address}, ${city}, ${state} ${zipcode}`;
  };

  const onFeatureClick = ({
    property,
    defaultTab,
  }: {
    property: Property;
    defaultTab: INFO_PANEL_TAB_NAME;
  }) => {
    // clear out customMap related state
    setGeometryBounds(null);
    setPointInGeometry(null);

    const address = getAddressQueryParam(property);

    const { point, id, parcel } = property;
    const { longitude, latitude } = point;

    const queryParameters = {
      lng: longitude,
      lat: latitude,
      propertyId: id,
      tab: defaultTab,
      ...(address ? { address } : {}),
      ...(parcel
        ? { parcelId: parcel.id, parcelNumber: parcel.parcelNumber }
        : {}),
    } satisfies SelectedInput;

    navigateToProperty(queryParameters);
  };

  const onPropertyClick: PropertyLayerConfig["onClick"] = property => {
    const defaultTab =
      (selected?.type === "address" && selected.tab) ||
      INFO_PANEL_TAB_NAME.OVERVIEW;

    onFeatureClick({
      property,
      defaultTab,
    });
  };

  const onSavedViewClick: SavedViewsLayerConfig["onClick"] = ({
    ...property
  }) => {
    onFeatureClick({ property, defaultTab: INFO_PANEL_TAB_NAME.OVERVIEW });
  };

  const navigateToProperty = (
    queryParameters: SelectedInput & { tab?: string }
  ) => {
    track("Property clicked");
    resetSearchCategory();
    updateURL(queryParameters);
  };

  const navigateToCustomMapGeometry = (
    queryParameters: Record<string, Maybe<string | number>>
  ) => {
    track("Custom Map Geometry clicked");
    resetSearchCategory();
    updateURL(queryParameters);
  };

  const onSearchResult = (data: SearchResultProps) => {
    // clear out customMap related state
    setGeometryBounds(null);
    setPointInGeometry(null);

    const { longitude, latitude } = data.point;
    const address = data.address;
    const propertyId = data.propertyId;
    const parcelId = data.geocacheParcelId;

    track("Address Searched");

    const queryParameters = {
      address,
      lng: longitude,
      lat: latitude,
      propertyId: propertyId || null,
      parcelId: parcelId || null,
      tab: INFO_PANEL_TAB_NAME.OVERVIEW,
    } satisfies SelectedInput;

    updateURL(queryParameters);
  };

  const parsedSchema = useURLParsing({
    routeName: "map",
    parser: selectedSchema,
  });

  useEffect(() => {
    setSelected(parsedSchema);
  }, [parsedSchema]);

  const ADDRESS_PANEL_WIDTH = 548;

  const viewportDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    let newWidth;
    let newHeight;

    if (isSmall()) {
      // On mobile, we center the area width-wise and place it near the top
      // heigth-wise so that it sits above the AddressPanel
      newHeight = height - height / 5;
      newWidth = width / 2;
    } else {
      // On desktop, we center it width-wise taking into account the width of the
      // AddressPanel and center it heighth-wise
      newHeight = height / 2;
      newWidth = (width - ADDRESS_PANEL_WIDTH) / 2;
    }

    return { width: newWidth, height: newHeight };
  };

  const markerConfig = {
    value:
      selected?.type === "address"
        ? { latitude: selected.latitude, longitude: selected.longitude }
        : selected?.type === "geometry"
        ? pointInGeometry
        : null,
  } satisfies MarkerLayerConfig;

  const parcelConfig = {
    interactive: { hover: true, click: true },
    onClick: onParcelClick,
    value:
      selected?.type === "address"
        ? {
            id: selected.parcelId,
            point: {
              latitude: selected.latitude,
              longitude: selected.longitude,
            },
          }
        : null,
  } satisfies ParcelLayerConfig;

  const propertyConfig = {
    filterHidden: false,
    geocacheParcelId:
      selected?.type === "address" ? selected.parcelId : undefined,
    interactive: { hover: true, click: true },
    onClick: onPropertyClick,
  } satisfies PropertyLayerConfig;

  const customMapsConfig = {
    interactive: { click: true, hover: true },
    maps: account.customMaps,
    value: {
      customMapId:
        selected?.type === "geometry" ? selected.customMapId : undefined,
      geometryId:
        selected?.type === "geometry" ? selected.geometryId : undefined,
    },
    onClick: navigateToCustomMapGeometry,
  } satisfies CustomMapLayerConfig;

  const visibleSavedViews = getVisibleSavedViews();

  const savedViewsConfig = {
    onClick: onSavedViewClick,
    value: visibleSavedViews,
    interactive: {
      hover: !!visibleSavedViews?.length,
      click: !!visibleSavedViews?.length,
    },
    savedViews: account.savedViews,
  } satisfies SavedViewsLayerConfig;

  return (
    <Container>
      <FullPageMapContainer
        account={account}
        markerConfig={markerConfig}
        parcelConfig={parcelConfig}
        propertyConfig={propertyConfig}
        customMapsConfig={customMapsConfig}
        savedViewsConfig={savedViewsConfig}
        deviceLocationsConfig={userDeviceLocations}
        onSearchResult={onSearchResult}
        viewportDimensions={viewportDimensions}
        MapControls={MapControls}
        ref={mapRef}
      >
        {!selected && (
          <ActivityFeed>
            <AccountActivityFeed />
          </ActivityFeed>
        )}
      </FullPageMapContainer>
      {selected && (
        <Info>
          {selected.type === "address" ? (
            <AddressPanel
              address={selected.address}
              longitude={selected.longitude}
              latitude={selected.latitude}
              propertyId={selected.propertyId}
              parcelId={selected.parcelId}
              parcelNumber={selected.parcelNumber}
              hasPropertyIds={selected.hasPropertyIds}
              onClose={onAddressPanelClose}
            />
          ) : (
            <ObjectInformationPanel
              customMapId={selected.customMapId}
              geometryId={selected.geometryId}
              onClose={onAddressPanelClose}
            />
          )}
        </Info>
      )}
    </Container>
  );
};
