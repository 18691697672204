import "./__styles__/PropertyForm.scss";
import React, { useState } from "react";
import { useModal } from "react-modal-hook";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import { Button } from "../Common/Button";
import Modal from "../Common/Modal";
import SelectionCardGroup from "../Inputs/SelectionCardGroup";

interface SelectAddressProps {
  suggestedAddress: string;
  fullAddress: string;
  onSelect: (selectedAddress: string) => void;
  onCancel: () => void;
}

export type SelectAddressUseModalProps = Omit<SelectAddressProps, "onCancel">;

export default () => {
  const [props, setProps] = useState<Maybe<SelectAddressUseModalProps>>(null);

  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <SelectAddressModal onCancel={hide} {...props!} />
      </Modal>
    );
  }, [props]);

  const showWithProps = (props: SelectAddressUseModalProps) => {
    setProps(props);
    show();
  };

  return [showWithProps, hide] as const;
};

export const SelectAddressModal = ({
  suggestedAddress,
  fullAddress,
  onSelect,
  onCancel,
}: SelectAddressProps) => {
  const [selectedAddress, setSelectedAddress] = useState(suggestedAddress);
  return (
    <Container data-testid="select-address-modal">
      <HeaderSection>
        <h1>Confirm address</h1>
        <h2>Review the recommended changes and make a selection</h2>
      </HeaderSection>
      <ContentSection
        css={{
          "& p": {
            margin: "0",
          },
        }}
      >
        <SelectionCardGroup
          options={[
            {
              id: "user-provided",
              value: fullAddress,
              title: "You entered",
              description: fullAddress,
            },
            {
              id: "suggested",
              value: suggestedAddress,
              title: "Standardized address",
              description: suggestedAddress,
            },
          ]}
          onChange={value => setSelectedAddress(value!)}
          defaultValue={suggestedAddress}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            styleVariant="primary"
            size="medium"
            onClick={() => {
              onSelect(selectedAddress);
            }}
          >
            Save
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
