import React, { ReactNode, useContext } from "react";
import { checkCoastalA } from "common/utils/coastalA";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import {
  CoastalAFIRMData,
  EditableMode,
  getCoastalAConfiguration,
} from "common-client/utils/firms";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { FIRMInfoContext } from "../../FIRMInfoContext";
import { RenderMode } from "../../types";
import { FIRMInfoRow } from "./FIRMInfoRow";

interface CoastalARowProps {
  size: RenderMode;
  isPublic: boolean;
  tooltip?: ReactNode;
  label: string;
  onFIRMEdit: (args: { firm: FIRMWithWarnings; mode: EditableMode }) => void;
}

export const CoastalARow = ({
  size,
  isPublic,
  tooltip,
  label,
  onFIRMEdit,
}: CoastalARowProps) => {
  const { account, authorized } = useContext(AuthContext);
  const { firms } = useContext(FIRMInfoContext);

  if (!account?.coastalADetermination || !checkCoastalA(account)) {
    return null;
  }

  const valueForFIRM = (firm: CoastalAFIRMData): Maybe<string> => {
    const { value, canAddCoastalA } = getCoastalAConfiguration({
      firm,
      account,
      authorized,
    });

    return value || (canAddCoastalA ? "Add" : null);
  };

  const wrapper = ({
    children,
    firmId,
  }: {
    children?: ReactNode;
    firmId: string;
  }) => {
    const firm = firms.find(({ id }) => id === firmId)!;
    const { canEditCoastalA, canAddCoastalA } = getCoastalAConfiguration({
      firm,
      account,
      authorized,
    });

    if (canEditCoastalA || canAddCoastalA) {
      return (
        <a
          onClick={() => {
            onFIRMEdit({ firm, mode: "coastalA" });
          }}
        >
          {children}{" "}
        </a>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <FIRMInfoRow
      property={"coastalA"}
      valueForFIRM={valueForFIRM}
      label={label}
      wrap={wrapper}
      size={size}
      isPublic={isPublic}
      tooltip={tooltip}
      skipIfBlank
    />
  );
};
