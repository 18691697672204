import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS as dndCSS } from "@dnd-kit/utilities";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  Row,
  SortingState,
  Table as TanstackTable,
  TableState,
  useReactTable,
} from "@tanstack/react-table";
import { get, isEmpty, omit } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { colors, CSS } from "../../../stitches.config";
import { URLSortParams } from "../../../utils/sorting";
import { Box } from "../__styles__/Layout";
import { Filter, Filters, FilterState } from "../Filters";
import {
  Container,
  DataCell,
  DataRow,
  ExpandedDataCell,
  HeaderCell,
  HeaderRow,
  LoadingOverlay,
  LoadingText,
  NoDataOverlay,
  Table as StyledTable,
  TableBody,
  TableHead,
  TableHeader,
} from "./__styles__/Table";
import { ManualPaginationConfig, useLegacyTableStateInURL } from "./hooks";
import PaginationSection from "./PaginationSection";
import {
  calcStickyZIndex,
  DEFAULT_MIN_ROWS,
  DEFAULT_PAGE_SIZE,
  getCellMaxWidth,
  getCellMinWidth,
  LoadingDetails,
  TableStyleDetails,
} from "./utils";

export interface TableProps<T> {
  previousData?: Array<T>;
  currentData: Array<T>;
  columns: Array<ColumnDef<T>>;
  minRows?: number;
  initialState?: InitialTableState & { filters?: FilterState };
  loadingDetails: LoadingDetails;
  manualPaginationConfig?: ManualPaginationConfig;
  tableStyleDetails?: TableStyleDetails;
  actions?: React.ReactNode;
  defaultSortParams?: URLSortParams;
  prevLocation?: string;
  filterable?: {
    filterConfigurations?: Array<Filter>;
    search: (params: {
      filters: Record<string, unknown>;
      sort: SortingState;
      page: number;
    }) => void;
  };
  rowCanExpand?: (row: Row<T>) => boolean;
  renderSubComponent?: (row: Row<T>) => Maybe<JSX.Element>;
  excludePaginationNav?: boolean;
  excludeTableHeader?: boolean;
  setTableStateInURL?: ReturnType<typeof useLegacyTableStateInURL>[1];
  tableState?: Partial<TableState>;
  onDragEnd?: (list: Array<T>) => Promise<void>;
  loadingDragAction?: boolean;
}

type RowProps<T> = {
  row: Row<T>;
  tableStyleDetails: TableStyleDetails;
  renderSubComponent: NonNullable<TableProps<T>["renderSubComponent"]>;
  table: TanstackTable<T>;
  rowIndex: number;
  style?: CSSProperties;
  setNodeRef?: (node: HTMLElement | null) => void;
};

const DraggableRow = <T,>({
  row,
  tableStyleDetails,
  renderSubComponent,
  table,
  rowIndex,
}: Omit<RowProps<T>, "setNodeRef" | "style">) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: get(row, "original.id"),
  });

  const style: CSSProperties = {
    transform: dndCSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    // Have to set as undefined so actions dropdown can have its z index set
    zIndex: isDragging ? 1 : undefined,
    position: "relative",
    backgroundColor: isDragging
      ? colors.bgUiContainer.toString()
      : "transparent",
    borderRadius: "4px",
  };
  return (
    <BaseRow
      key={row.id}
      row={row}
      tableStyleDetails={tableStyleDetails}
      renderSubComponent={renderSubComponent}
      table={table}
      rowIndex={rowIndex}
      style={style}
      setNodeRef={setNodeRef}
    />
  );
};

const BaseRow = <T,>({
  row,
  tableStyleDetails,
  renderSubComponent,
  table,
  rowIndex,
  setNodeRef,
  style,
}: RowProps<T>) => {
  return (
    <React.Fragment key={`${row.id}-fragment`}>
      <DataRow
        ref={setNodeRef}
        style={style}
        key={row.id}
        role="row"
        data-testid="filled-row"
        isExpanded={row.getIsExpanded()}
        striped={tableStyleDetails.striped}
      >
        {row.getVisibleCells().map(cell => {
          let cellStyles = cell.column.columnDef.meta?.getCellStyles;
          return (
            <DataCell
              key={cell.id}
              role="gridcell"
              className={cell.column.columnDef.meta?.className}
              style={{
                ...(cellStyles ? cellStyles(cell.getContext()) : {}),
                flex: `${cell.column.getSize()} 1 0%`,
                width: cell.column.getSize(),
                maxWidth: getCellMaxWidth<T>(cell),
                minWidth: getCellMinWidth<T>(cell),
                zIndex: cell.column.columnDef.meta?.isSticky
                  ? calcStickyZIndex<T>(table, rowIndex)
                  : undefined,
              }}
              isSticky={cell.column.columnDef.meta?.isSticky}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </DataCell>
          );
        })}
      </DataRow>
      {row.getIsExpanded() && (
        <DataRow
          key={`${row.id}-expanded`}
          role="row"
          data-testid="expanded-row"
        >
          <ExpandedDataCell
            colSpan={row.getVisibleCells().length}
            role="gridcell"
          >
            {renderSubComponent(row)}
          </ExpandedDataCell>
        </DataRow>
      )}
    </React.Fragment>
  );
};

const Table = <T,>({
  css,
  previousData,
  currentData,
  columns,
  minRows = DEFAULT_MIN_ROWS,
  initialState = {
    pagination: {
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    },
  },
  loadingDetails,
  manualPaginationConfig,
  prevLocation,
  tableStyleDetails = {},
  actions,
  filterable,
  rowCanExpand = () => false,
  renderSubComponent = () => null,
  excludePaginationNav = false,
  excludeTableHeader = false,
  setTableStateInURL,
  tableState = {},
  onDragEnd,
  loadingDragAction,
}: TableProps<T> & { css?: CSS }) => {
  const { loading, loadingText, noDataText } = loadingDetails;
  const [localLoading, setLocalLoading] = useState(loading);
  const isDraggable = !!onDragEnd;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLocalLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, [loading]);

  // TODO: Move filter state management to a custom hook that returns
  // the filter configuration and filter updater function
  const [filterState, setFilterState] = useState<FilterState>(
    initialState.filters ?? {}
  );

  const [data, setData] = useState<Array<T>>(
    localLoading || loading ? previousData ?? [] : currentData
  );

  useEffect(() => {
    setData(localLoading || loading ? previousData ?? [] : currentData);
  }, [localLoading, loading, currentData]);

  if (manualPaginationConfig) {
    tableState.pagination = manualPaginationConfig.pagination;
  }

  const table = useReactTable({
    data,
    columns,
    initialState: {
      ...omit(initialState, "filters"),
      ...(excludePaginationNav
        ? {
            pagination: {
              pageIndex: 0,
              pageSize:
                DEFAULT_PAGE_SIZE > data.length
                  ? DEFAULT_PAGE_SIZE
                  : data.length,
            },
          }
        : {}),
    },
    state: tableState,
    manualSorting: false,
    getSortedRowModel: getSortedRowModel(),
    ...(manualPaginationConfig
      ? omit(manualPaginationConfig, "pagination")
      : {
          manualPagination: false,
          getPaginationRowModel: getPaginationRowModel(),
        }),
    autoResetPageIndex: false,
    enableExpanding: true,
    getRowCanExpand: rowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const innerTableState = table.getState();
  useEffect(() => {
    // TODO: combine "filterable.search" and "setTableStateInURL" into a single
    // onTableStateUpdate callback prop
    if (setTableStateInURL) {
      setTableStateInURL({
        filters: filterState,
        pagination: innerTableState.pagination,
        sorting: innerTableState.sorting,
        prevLocation,
      });
    }

    filterable?.search({
      filters: filterState,
      sort: innerTableState.sorting,
      page: innerTableState.pagination.pageIndex + 1,
    });
  }, [filterState, innerTableState.pagination, innerTableState.sorting]);

  const handleKeyDown = ({
    keyDownFn,
    event,
  }: {
    keyDownFn: ((event: unknown) => void) | undefined;
    event: React.KeyboardEvent;
  }) => {
    const accessibleKeysToTriggerFn = [" ", "Enter"];
    if (accessibleKeysToTriggerFn.includes(event.key) && keyDownFn) {
      event.preventDefault();
      keyDownFn(event);
    }
  };

  const filterChange = (filters: Record<string, unknown>) => {
    table.setPagination(prevPagination => ({
      ...prevPagination,
      pageIndex: 0,
    }));

    setFilterState(filters);
  };

  const pageCount = table.getPageCount();

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data.map(d => get(d, "id")),
    [data]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    // To avoid race conditions, we exit early if an update is already in progress
    if (loadingDragAction) {
      return;
    }
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setData(data => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        const newArray = arrayMove(data, oldIndex, newIndex);
        void onDragEnd!(newArray);

        return newArray;
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const TableWrapper = ({ children }: { children: ReactNode }) => {
    return isDraggable ? (
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        {children}
      </DndContext>
    ) : (
      <>{children}</>
    );
  };

  const BodyWrapper = ({ children }: { children: ReactNode }) => {
    return isDraggable ? (
      <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    ) : (
      <>{children}</>
    );
  };

  const tableContainer = (
    <Container style={{ display: "flex", flexDirection: "column" }}>
      <StyledTable css={css as any} role="grid" {...tableStyleDetails}>
        {excludeTableHeader ? null : (
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <HeaderRow key={headerGroup.id} role="row">
                {headerGroup.headers.map(header => {
                  const cursorClass = header.column.getCanSort()
                    ? "cursor-pointer"
                    : "";
                  const sortDirectionClass =
                    {
                      asc: "sort-asc",
                      desc: "sort-desc",
                    }[header.column.getIsSorted() as string] ?? "";

                  const additionalClasses =
                    header.column.columnDef.meta?.className ?? "";

                  return (
                    <HeaderCell
                      key={header.id}
                      role="columnheader"
                      className={`${cursorClass} ${sortDirectionClass} ${additionalClasses}`}
                      style={{
                        flex: `${header.column.getSize()} 1 0%`,
                        width: header.column.getSize(),
                        maxWidth: getCellMaxWidth<T>(header),
                        minWidth: getCellMinWidth<T>(header),
                      }}
                      onKeyDown={event =>
                        handleKeyDown({
                          event,
                          keyDownFn: header.column.getToggleSortingHandler(),
                        })
                      }
                      {...{
                        tabIndex: header.column.getCanSort() ? 0 : undefined,
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                      isSticky={header.column.columnDef.meta?.isSticky}
                      isDraggable={isDraggable}
                      data-testid={`header-${header.id}`}
                    >
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </HeaderCell>
                  );
                })}
              </HeaderRow>
            ))}
          </TableHead>
        )}

        <TableBody>
          <BodyWrapper>
            {table.getRowModel().rows.map((row, rowIndex) => {
              const RowComponent = isDraggable ? DraggableRow : BaseRow;

              return (
                <RowComponent
                  key={row.id}
                  row={row}
                  tableStyleDetails={tableStyleDetails}
                  renderSubComponent={renderSubComponent}
                  table={table}
                  rowIndex={rowIndex}
                />
              );
            })}

            {data.length < minRows &&
              [...Array(minRows - data.length)].map(() => (
                <DataRow
                  isEmpty
                  key={`empty-row-${uuidv4()}`}
                  data-testid="empty-row"
                >
                  <DataCell isEmpty />
                </DataRow>
              ))}
          </BodyWrapper>
        </TableBody>
      </StyledTable>

      {!loading && !excludePaginationNav && (
        <PaginationSection
          paginationDetails={{
            pageCount,
            pagination: table.getState().pagination,
            setPagination: table.setPagination,
          }}
          previousPage={table.previousPage}
          nextPage={table.nextPage}
          canGetPreviousPage={table.getCanPreviousPage}
          canGetNextPage={table.getCanNextPage}
        />
      )}

      {!localLoading && !loading && isEmpty(currentData) && (
        <NoDataOverlay>{noDataText}</NoDataOverlay>
      )}

      {(localLoading || loading) && (
        <LoadingOverlay>
          <LoadingText>{loadingText}</LoadingText>
        </LoadingOverlay>
      )}
    </Container>
  );

  if (filterable?.filterConfigurations || actions) {
    return (
      <Box>
        <TableHeader>
          {filterable && (
            <Filters
              filterConfigurations={filterable.filterConfigurations!}
              filterValues={filterState}
              onFilterChange={filterChange}
              handleKeyDown={handleKeyDown}
            />
          )}
          {actions}
        </TableHeader>
        <TableWrapper>{tableContainer}</TableWrapper>
      </Box>
    );
  }

  return <TableWrapper>{tableContainer}</TableWrapper>;
};

export default Table;
