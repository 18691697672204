import React, { useCallback, useContext, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { flatMap, startCase } from "lodash";
import { OBJECT_TYPE } from "common/constants";
import { SUBMISSION_CATEGORY_TO_LABEL } from "common/utils/submissions";
import {
  SubmissionType,
  useGetSubmissionTypesQuery,
  useMapsForAccountQuery,
} from "../../../../generated/graphql";
import { AuthContext } from "../../../Authorization/AuthContext";
import Table from "../../../Common/Tables/Table";
import { ActionButton } from "./ActionButton";

type SubmissionTypesTableProps = Pick<
  SubmissionType,
  "id" | "name" | "intakeSource" | "category" | "attachments" | "isDeletable"
>;

const SubmissionTypesTable = () => {
  const { account } = useContext(AuthContext);
  const {
    data: currentData,
    error,
    loading,
    refetch,
  } = useGetSubmissionTypesQuery({
    variables: { accountId: account!.id },
    // Need to do cache-and-network because refetch queries wasn't working for submission type create
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <div />;
  }

  const { data: customMapsData } = useMapsForAccountQuery({
    variables: {
      filterHidden: false,
      isGuest: false,
    },
    // we catch because submissions builder also uses this query
    fetchPolicy: "cache-and-network",
  });

  const formatAttachment = useCallback(
    (
      attachment: SubmissionTypesTableProps["attachments"][number]
    ): string[] => {
      if (attachment.type === OBJECT_TYPE.PROPERTY) {
        return [`${startCase(attachment.type)}`];
      }
      return (
        attachment.customMapIds?.map(
          id =>
            customMapsData?.account?.customMaps.find(map => map.id === id)
              ?.name ?? id
        ) ?? []
      );
    },
    [customMapsData]
  );

  const columns: Array<ColumnDef<SubmissionTypesTableProps>> = useMemo(
    () => [
      {
        id: "name",
        header: "Record type",
        accessorKey: "name",
        enableSorting: false,
        minSize: 300,
      },
      {
        id: "category",
        header: "Record category",
        enableSorting: false,
        cell: ({ row }) => SUBMISSION_CATEGORY_TO_LABEL[row.original.category],
      },
      {
        id: "attachments",
        header: "Object type",
        enableSorting: false,
        cell: ({ row }) => {
          return flatMap(row.original.attachments, attachment =>
            formatAttachment(attachment)
          ).join(", ");
        },
      },
      {
        id: "intakeSource",
        header: "Display setting",
        enableSorting: false,
        cell: ({ row }) => startCase(row.original.intakeSource),
      },
      {
        id: "actions",
        size: 75,
        maxSize: 75,
        enableSorting: false,
        cell: row => (
          <ActionButton
            submissionType={row.row.original}
            onDeleteSuccess={() => void refetch()}
          />
        ),
      },
    ],
    [formatAttachment, refetch]
  );

  return (
    <Table<SubmissionTypesTableProps>
      columns={columns}
      currentData={currentData?.account?.submissionTypes ?? []}
      loadingDetails={{
        loading,
        loadingText: "Loading Record Types...",
        noDataText: "No Record Types Found",
      }}
      tableStyleDetails={{
        hasRowActions: true,
        isCompact: true,
        hasFlushedSides: true,
      }}
      excludePaginationNav
    />
  );
};

export default SubmissionTypesTable;
