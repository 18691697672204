import React from "react";
import { Account, SubmissionType } from "../../generated/graphql";
import { Header } from "../Common/__styles__/FullPageFormLayout";
import { FlexColumn, FlexRow } from "../Common/__styles__/Layout";
import { AccountLogo } from "../Common/AccountLogo";
import { Body, Title } from "../Common/Typography";
import { ObjectDisplay } from "./__styles__/Submission";

type SubmissionHeaderProps = {
  account: Pick<Account, "logoUrl" | "name">;
  objectDisplay?: string;
  submissionType: Pick<SubmissionType, "name">;
};

const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({
  account,
  objectDisplay,
  submissionType,
  children,
}) => {
  const address = objectDisplay ? (
    <ObjectDisplay>{objectDisplay}</ObjectDisplay>
  ) : null;

  return (
    <Header>
      <FlexRow>
        <FlexColumn>
          {account.logoUrl && (
            <AccountLogo
              account={account}
              displayPrintOnly={true}
              style={{ marginRight: "16px" }}
            />
          )}
        </FlexColumn>
        <FlexColumn>
          <Title
            size="large"
            type="regular"
            color="contentPrimary"
            style={{ marginBottom: "8px" }}
          >
            {submissionType.name}
          </Title>
          <Body size="default" type="regular" color="contentSecondary">
            {address}
          </Body>
          {children}
        </FlexColumn>
      </FlexRow>
    </Header>
  );
};

export default SubmissionHeader;
