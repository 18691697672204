import React, { useContext } from "react";
import { RESOURCE_NAME } from "common/authorization";
import { useHideUnhideDocumentUploadMutation } from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import DisplaySettings from "./DisplaySettings";

interface HideFromPublicActionProps {
  documentUploadId: string;
  hiddenFromPublic: boolean;
  hasSensitiveDocumentType?: boolean;
  // ECs that are NOT complete are NOT viewable
  isViewable: boolean;
  propertyHiddenFromPublic: boolean;
}

export default ({
  documentUploadId,
  hiddenFromPublic,
  hasSensitiveDocumentType,
  isViewable,
  propertyHiddenFromPublic,
}: HideFromPublicActionProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { authorized } = useContext(AuthContext);

  const canHideDocumentUpload =
    authorized({
      resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
      permission: "hide",
    }) &&
    isViewable &&
    !hasSensitiveDocumentType &&
    !propertyHiddenFromPublic;

  const [updateDocumentUploadHiddenFromPublic, { loading }] =
    useHideUnhideDocumentUploadMutation({
      variables: {
        id: documentUploadId,
        data: {
          hide: !hiddenFromPublic,
        },
      },
      onCompleted: ({ updateDocumentUploadHiddenFromPublic }) => {
        const hidden = updateDocumentUploadHiddenFromPublic?.hiddenFromPublic;
        const toastText = hidden
          ? "The file was successfully hidden."
          : "The file was successfully unhidden.";
        addSuccessToast(toastText);
        hidden
          ? track("File Hidden", { documentUploadId })
          : track("File Unhidden", { documentUploadId });
      },
      onError: () => {
        const toastText = hiddenFromPublic
          ? "There was an error hiding this file. Please try again. If the problem persists, please email us at support@withforerunner.com"
          : "There was an error unhiding this file. Please try again. If the problem persists, please email us at support@withforerunner.com";

        addErrorToast(toastText);
      },
    });

  return (
    <DisplaySettings
      hiddenFromPublic={hiddenFromPublic || !isViewable}
      authorized={canHideDocumentUpload}
      onChange={updateDocumentUploadHiddenFromPublic}
      loading={loading}
    />
  );
};
