import React, { useContext } from "react";
import { lowerCase, map } from "lodash";
import { useHistory } from "react-router";
import { canCreateInternalSubmission } from "common/authorization/customPolicies";
import { OBJECT_TYPE, SUBMISSION_CATEGORY } from "common/constants";
import { Attachments, isAttachableToMap } from "common/utils/objectAttachments";
import {
  SubmissionIntakeSource,
  useGetSubmissionsForCustomMapGeometryQuery,
} from "../../generated/graphql";
import useNavigationTab from "../../hooks/useNavigationTabs";
import { TabContainer } from "../AddressPanel/__styles__/AddressPanel";
import ListItem from "../AddressPanel/Submissions/ListItem";
import { AuthContext } from "../Authorization/AuthContext";
import { FlexRow } from "../Common/__styles__/Layout";
import { CreateSubmissionButton } from "../Common/CreateSubmissionButton";
import { createSubmissionRedirect } from "../Common/CreateSubmissionButton/utils";
import { EmptyState } from "../Common/EmptyState";
import TabGroup from "../Common/TabGroup";
import { Title } from "../Common/Typography";
import { SUBMISSION_CATEGORY_OPTIONS } from "../Submissions/utils";
import { ContentWrapper } from "./__styles__/ObjectInformationPanel";
import { ObjectInfoPanelTabProps } from "./types";

export const Layout = ({
  actionButton,
  children,
  title,
}: {
  actionButton?: React.ReactNode;
  children: React.ReactNode;
  title: string;
}) => (
  <div>
    <FlexRow style={{ justifyContent: "space-between" }}>
      <Title size="small" type="regular">
        <span>{title}</span>
      </Title>
      {actionButton}
    </FlexRow>
    {children}
  </div>
);

const SubmissionCategoryDisplay = ({
  customMap,
  geometry,
  title,
  category,
}: Omit<ObjectInfoPanelTabProps, "submissionCategories"> & {
  category: SUBMISSION_CATEGORY;
  title: string;
}) => {
  const { user, admin } = useContext(AuthContext);
  const history = useHistory();

  const { data, loading, error, refetch } =
    useGetSubmissionsForCustomMapGeometryQuery({
      variables: { geometryId: geometry.id, category },
      fetchPolicy: "cache-and-network",
    });

  if (loading) {
    return <em>Loading...</em>;
  }

  if (error) {
    return <span>Error loading {lowerCase(title)}</span>;
  }

  const currentUser = user || admin;
  const canCreateSubmission = canCreateInternalSubmission({
    role: currentUser?.role.name,
  });

  const actionButton = (
    <CreateSubmissionButton
      disabled={!canCreateSubmission}
      objectDisplay={geometry.label}
      onSubmit={submissionTypeId =>
        createSubmissionRedirect({
          objectId: geometry.id,
          objectType: OBJECT_TYPE.CUSTOM_MAP_GEOMETRY,
          submissionTypeId,
          history,
        })
      }
      submissionTypeFilter={submissionType =>
        submissionType.category === category &&
        submissionType.intakeSource === SubmissionIntakeSource.INTERNAL &&
        isAttachableToMap({
          attachments: submissionType.attachments as Attachments,
          customMap,
        })
      }
    />
  );

  const submissions = data?.customMapGeometry?.submissions;
  if (!submissions?.length) {
    return (
      <Layout title={title} actionButton={actionButton}>
        <EmptyState
          icon="add-file"
          title={`No ${lowerCase(title)} for this object`}
          body={`Add ${lowerCase(title)} to get started.`}
        />
      </Layout>
    );
  }

  return (
    <Layout title={title} actionButton={actionButton}>
      {submissions.map((submission: any) => (
        <ListItem
          key={submission.id}
          submission={submission}
          onUpdate={refetch}
          objectIsHiddenFromPublic={false}
        />
      ))}
    </Layout>
  );
};

const Records = ({
  geometry,
  customMap,
  submissionCategories,
}: ObjectInfoPanelTabProps) => {
  const tabs = SUBMISSION_CATEGORY_OPTIONS.filter(tab =>
    submissionCategories.has(tab.value)
  );

  const [tab, setTab] = useNavigationTab({
    defaultTab: tabs[0]?.value ?? SUBMISSION_CATEGORY.INSPECTIONS,
    allowedTabs: map(tabs, "value"),
    queryParamName: "recordTab",
  });

  const currentTab = tabs.find(({ value }) => value === tab);

  return (
    <>
      <TabContainer hasOutlineStyleActiveTabs>
        <TabGroup
          currentTab={tab}
          setTab={setTab}
          tabs={tabs}
          activeTabStyle="outline"
        />
      </TabContainer>
      {currentTab && (
        <ContentWrapper>
          <SubmissionCategoryDisplay
            customMap={customMap}
            geometry={geometry}
            title={currentTab.label}
            category={currentTab.value}
          />
        </ContentWrapper>
      )}
    </>
  );
};

export default Records;
