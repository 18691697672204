import React, { JSXElementConstructor, ReactElement, useRef } from "react";
import Modal, { Props } from "react-modal";
import { spacing } from "../../stitches.config";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import { modalOverlay } from "./__styles__/Modal";
import { IconButton } from "./Icons/LucideIcons";

interface ModalProps extends Omit<Props, "isOpen"> {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export default ({
  children,
  customAppElement = "#app",
  ...modalProps
}: ModalProps & { customAppElement?: string }) => {
  Modal.setAppElement(customAppElement);

  modalOverlay();

  const wrapperRef = useRef(null as Maybe<HTMLDivElement>);

  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => modalProps.onRequestClose,
  });

  return (
    <div ref={wrapperRef}>
      <Modal
        className="modal-content"
        overlayClassName="modal-overlay"
        shouldFocusAfterRender={false}
        shouldCloseOnEsc={true}
        isOpen
        {...modalProps}
      >
        {!!modalProps.onRequestClose && (
          <IconButton
            onClick={modalProps.onRequestClose}
            name="close-modal"
            aria-label="Close"
            testId="closeModal"
            iconName="x"
            style={{
              position: "absolute",
              top: `${spacing.m}`,
              right: `${spacing.l}`,
            }}
          />
        )}
        {React.cloneElement(children, {
          closeModal: modalProps.onRequestClose,
        })}
      </Modal>
    </div>
  );
};
