import React from "react";
import { TitleFieldProps } from "@rjsf/utils";
import { Header } from "./__styles__/TitleFieldTemplate";

export const TitleFieldTemplate = (props: TitleFieldProps) => {
  const { id, title, uiSchema } = props;
  const size = uiSchema?.["ui:heading"] === "h1" ? "large" : "default";

  return (
    <Header id={id} type="regular" size={size}>
      {title}
    </Header>
  );
};
