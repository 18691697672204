import React, { useContext } from "react";
import { flatMap } from "lodash";
import { useFormContext } from "react-hook-form";
import { OBJECT_TYPE } from "common/constants";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { Attachment, Attachments } from "common/utils/objectAttachments";
import { Label } from "../../../../../Inputs";
import Select from "../../../../../Inputs/Select";
import { SubmissionsBuilderContextInstance } from "../context";
import { SubmissionsBuilderFormDataStructure } from "../types";

export const ATTACHMENT_SELECT_LABEL = "Object type";
export const ATTACHMENT_SELECT_NAME = "attachmentSelect";

type AttachmentFormType = Pick<
  SubmissionsBuilderFormDataStructure,
  "attachments"
>;

const PROPERTY_ATTACHMENT_VALUE = "property-tag.property";
type PropertyAttachmentValue = typeof PROPERTY_ATTACHMENT_VALUE;

const CUSTOM_MAP_GEOMETRY_ATTACHMENT_PREFIX = "custom-map-tag.";
type CustomMapGeometryAttachmentValue =
  `${typeof CUSTOM_MAP_GEOMETRY_ATTACHMENT_PREFIX}${string}`;
const makeCustomMapGeometryAttachment = (customMapId: string) =>
  `${CUSTOM_MAP_GEOMETRY_ATTACHMENT_PREFIX}${customMapId}` satisfies CustomMapGeometryAttachmentValue;

export type AttachmentValue =
  | PropertyAttachmentValue
  | CustomMapGeometryAttachmentValue;

const AttachmentSelect = ({ disabled }: { disabled: boolean }) => {
  const {
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext<AttachmentFormType>();

  const { customMaps } = useContext(SubmissionsBuilderContextInstance);

  const attachableTagsOptions = [
    {
      value: PROPERTY_ATTACHMENT_VALUE as AttachmentValue,
      label: "Property",
    },
  ].concat(
    customMaps
      .filter(map => map.isObject)
      .map(map => ({
        label: map.name,
        value: makeCustomMapGeometryAttachment(map.id),
      }))
  );

  const attachments = watch("attachments");

  const attachmentsValue: AttachmentValue[] = flatMap(attachments, attachment =>
    attachment.type === OBJECT_TYPE.PROPERTY
      ? [PROPERTY_ATTACHMENT_VALUE]
      : attachment.customMapIds.map(makeCustomMapGeometryAttachment)
  );

  return (
    <div>
      <Label text={ATTACHMENT_SELECT_LABEL} htmlFor={ATTACHMENT_SELECT_NAME} />
      <Select
        name={ATTACHMENT_SELECT_NAME}
        options={attachableTagsOptions}
        value={attachmentsValue}
        size="medium"
        required={true}
        isMulti={true}
        disabled={disabled}
        onChange={async value => {
          if (!value) {
            setValue("attachments", [] as any);
            await trigger("attachments");
            return;
          }
          const newAttachments: Attachment[] = [];
          const newCustomMapIds: string[] = [];

          for (const attachment of value) {
            if (attachment === PROPERTY_ATTACHMENT_VALUE) {
              newAttachments.push({ type: OBJECT_TYPE.PROPERTY });
            } else {
              newCustomMapIds.push(
                attachment.replace(CUSTOM_MAP_GEOMETRY_ATTACHMENT_PREFIX, "")
              );
            }
          }
          if (arrayHasAtLeastOneItem(newCustomMapIds)) {
            newAttachments.push({
              type: OBJECT_TYPE.CUSTOM_MAP_GEOMETRY,
              customMapIds: newCustomMapIds,
            });
          }
          setValue("attachments", newAttachments as Attachments);
          await trigger("attachments");
        }}
        error={errors.attachments?.message}
      />
    </div>
  );
};

export default AttachmentSelect;
